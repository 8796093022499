import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import * as R from 'ramda'

import URL from '~/Utils/api_base'

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 520px;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CurrentDomain = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Statuses = () => {
  const params = useParams()
  const domain_id = params.id
  const [domain, setDomain] = useState()
  const [status, setStatus] = useState()
  const [stageDomain, setStageDomain] = useState()
  const [currentStageDomain, setCurrentStageDomain] = useState()
  const [availableDomains, setAvailableDomains] = useState()
  const history = useHistory()

  useEffect(() => {
    fetch(`/domains/${domain_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          setDomain(data.domain)
        }
      })

    fetch(`${URL}/admin/availableDomains`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          setAvailableDomains(data)
          setCurrentStageDomain(R.find(R.propEq('domain', domain_id))(data))
        }
      })
  }, [])

  useEffect(() => {
    if (domain) {
      setStatus(domain.status)
    }
  }, [domain])

  const handleSubmit = e => {
    e.preventDefault()

    fetch(`${URL}/admin/setDomainStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        stage_domain: stageDomain,
        domain_id,
        status
      })
    })
      .then(x => x.json())
      .then(() => history.goBack())
  }
  return (
    <FormContainer onSubmit={handleSubmit}>
      <Typography gutterBottom>Domain Staging</Typography>
      <Typography variant="h4" gutterBottom>
        {domain && domain.profile.company.name
          ? domain.profile.company.name
          : ''}
      </Typography>
      <FormControl>
        <InputLabel shrink id="status-label">
          Status
        </InputLabel>
        <Select
          labelId="status-label"
          value={status ? status : ''}
          onChange={e => setStatus(e.target.value)}
        >
          <MenuItem value={'dev'}>Dev</MenuItem>
          <MenuItem value={'staging'}>Staging</MenuItem>
          <MenuItem value={'live'}>Live</MenuItem>
        </Select>
      </FormControl>
      <CurrentDomain>
        {currentStageDomain && (
          <Typography>Current URL: {currentStageDomain.name}</Typography>
        )}
      </CurrentDomain>
      <FormControl disabled={status !== 'staging'}>
        <InputLabel shrink id="domain-label">
          Staging Domain
        </InputLabel>
        <Select
          labelId="domain-label"
          value={stageDomain ? stageDomain : ''}
          onChange={e => setStageDomain(e.target.value)}
        >
          {availableDomains &&
            availableDomains.map(domain => {
              if (domain.domain) {
                return (
                  <MenuItem value={domain.name}>
                    {domain.name+' | Already taken. Select to override'}
                  </MenuItem>
                )
              } else {
                return <MenuItem value={domain.name}>{domain.name}</MenuItem>
              }
            })}
        </Select>
      </FormControl>
      <ButtonRow>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button type="submit">Save</Button>
      </ButtonRow>
    </FormContainer>
  )
}

export default Statuses
