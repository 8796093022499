import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Buttons from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Editor } from '@tinymce/tinymce-react'
import { stringifyUrl, parseUrl } from 'query-string'
import * as R from 'ramda'
import Helmet from 'react-helmet'

import API_URL from '~/Utils/api_base'
import ImageUpload from '~/SharedComponents/ImageUpload'
import { idLens } from '~/State/ducks/domain'

const PageEdit = styled.div`
  width: 100%;
  padding: 1.25rem;

  .tox-statusbar__branding {
    display: none !important;
  }
`

const BlogEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PageMeta = styled.div``

const Permalink = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: rgba(33, 33, 33, 0.5);
  }

  label {
    font-family: 'Roboto';
    font-size: 0.75rem;
  }

  input {
    font-family: 'Roboto';
    font-size: 1.05rem;
    padding: 0.5rem;
  }
`

const SelectImageContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;
`

const MetaFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`

const ExpansionPanelContainer = styled.div`
  margin-bottom: 1rem;
`

const BlogForm = ({ currentBlog, onChange }) => {
  const [image, setImage] = useState(currentBlog.image)
  const [altText, setAltText] = useState(
    parseUrl(currentBlog.image || '').query.alt
  )
  const [titleText, setTitleText] = useState(
    parseUrl(currentBlog.image || '').query.title
  )
  const domain_id = useSelector(R.view(idLens))
  const [metaImage, setMetaImage] = useState(currentBlog.og_image)

  useEffect(() => {
    onChange({ og_image: metaImage })
  }, [metaImage])

  const [scriptLoaded, setScriptLoaded] = useState(
    typeof window !== 'undefined' && typeof myScript !== 'undefined'
  )

  const handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }) =>
          src ===
          'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.0.16/tinymce.min.js'
      )
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true
        })
      }
    }
  }

  return (
    <PageEdit key={currentBlog.id}>
      <PageMeta>
        <Permalink title="Permalink for the website.">
          <label>Permalink:</label>
          <span>companyurl.com</span>
          <input
            defaultValue={currentBlog.path}
            onChange={e => onChange({ path: e.target.value })}
            key={currentBlog.id}
            name="path"
          />
        </Permalink>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={currentBlog.published}
              onChange={e => onChange({ published: e.target.checked })}
              inputProps={{
                'aria-label': 'Blog is Published Toggle',
                title: 'Blog is Published Toggle'
              }}
            />
          }
          label="Is Published"
        />
      </PageMeta>
      <BlogEditorContainer>
        <TextField
          fullWidth
          onChange={e => onChange({ title: e.target.value })}
          defaultValue={currentBlog.title}
          id="blog-title"
          name="blog-title"
          label="Blog Title"
          aria-label="Blog Title"
          title="Blog Title"
          margin="normal"
        />
        <ExpansionPanelContainer>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="meta-content"
              id="meta-header"
            >
              <Typography>Meta</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <MetaFieldContainer>
                <TextField
                  name="meta-title"
                  label="Meta Title"
                  title="Meta Title"
                  aria-label="Meta Title"
                  onChange={e => onChange({ meta_title: e.target.value })}
                  defaultValue={currentBlog.meta_title}
                />
                <TextField
                  multiline
                  name="meta-description"
                  label="Meta Description"
                  title="Meta Description"
                  aria-label="Meta Description"
                  onChange={e => onChange({ meta_description: e.target.value })}
                  defaultValue={currentBlog.meta_description}
                />
                <TextField
                  name="meta-keywords"
                  label="Keywords"
                  title="Keywords"
                  aria-label="Keywords"
                  helperText="Keywords will be a comma-separated list"
                  onChange={e => onChange({ meta_keywords: e.target.value })}
                  defaultValue={currentBlog.meta_keywords}
                />
                <TextField
                  name="meta-author"
                  label="Meta Author"
                  title="Meta Author"
                  aria-label="Meta Author"
                  onChange={e => onChange({ meta_author: e.target.value })}
                  defaultValue={currentBlog.meta_author}
                />
                <ImageUploadContainer>
                  <Typography>og:image</Typography>
                  <ImageUpload
                    name="og-image-upload-comp"
                    onChange={setMetaImage}
                    image={metaImage}
                  />
                  <input hidden value={metaImage} name="op-image-upload" />
                </ImageUploadContainer>
              </MetaFieldContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ExpansionPanelContainer>
        <SelectImageContainer>
          <Typography>Blog image</Typography>
          <ImageUpload
            name="image-upload"
            onChange={e => {
              onChange({ image: e })
              setImage(e)
            }}
            image={image}
          />
          <input hidden value={image} name="image" />
          <TextField
            name="alt-text"
            id="alt-text"
            aria-label="Alt Text"
            title="Alt Text"
            label="Alt Text"
            margin="normal"
            variant="outlined"
            onChange={e => setAltText(e.target.value)}
            value={altText}
          />
          <TextField
            name="title-text"
            id="title-text"
            aria-label="Title Text"
            title="Title Text"
            label="Title Text"
            margin="normal"
            variant="outlined"
            onChange={e => setTitleText(e.target.value)}
            value={titleText}
          />
          <Buttons>
            <Button
              color="secondary"
              onClick={() =>
                onChange({
                  image: stringifyUrl({
                    url: `${image}`,
                    query: { title: titleText, alt: altText }
                  })
                })
              }
            >
              Save
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setAltText()
                setTitleText()
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </SelectImageContainer>
        <Helmet onChangeClientState={handleChangeClientState}>
          {typeof window !== 'undefined' && typeof myScript === 'undefined' && (
            <script
              async
              defer
              src={
                'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.0.16/tinymce.min.js'
              }
            />
          )}
        </Helmet>
        {scriptLoaded && (
          <Editor
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | image | help',
              images_upload_handler: function(blobInfo, success) {
                const data = new FormData()
                data.set('file', blobInfo.blob())

                fetch(`${API_URL}/files/${domain_id}`, {
                  method: 'POST',
                  body: data
                })
                  .then(x => x.json())
                  .then(({ data }) => {
                    success(data.link)
                  })
              }
            }}
            initialValue={currentBlog.content}
            onChange={e => onChange({ content: e.target.getContent() || '' })}
          />
        )}
      </BlogEditorContainer>
    </PageEdit>
  )
}

export default BlogForm
