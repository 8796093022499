import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import ButtonStylePicker from './ButtonStylePicker'
import InputContainer from './Container'
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

import { idsLens } from '~/State/ducks/inventory'

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderContainer = styled.div`
  display: flex;
`

const LeftContainer = styled.div`
  flex: 1;
`

const RightContainer = styled.div`
  flex: 1;
`

const InnerContainer = styled.div`
  display: flex;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`

const Inventory2Edit = ({ data, handleEdit }) => {
  const {
    background = '#0c0c0c',
    text = '#ffffff',
    btnColor = 'primary',
    btnStyle = 'contained'
  } = data

  const [image, setImage] = useState(data.image)

  const inventory = useSelector(state => {
    const ids = R.view(idsLens, state)
    return R.values(ids)
  })

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      ids: [...oldState.ids, R.pathOr(null, [0, 'id'], inventory)]
    }))

  const updateItem = (index, value) =>
    handleEdit(oldState => ({
      ...oldState,
      ids: R.adjust(index, R.always(value), oldState.ids)
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      ids: R.remove(index, 1, oldState.ids)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        <LeftContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ButtonPicker
              color={btnColor}
              name="btnColor"
              title="Button Color"
            />
            <ButtonStylePicker
              style={btnStyle}
              name="btnStyle"
              title="Button Style"
            />
          </ColorInputContainer>
          <Typography align="center">Background Image</Typography>
          <ImageUpload name="image-upload" onChange={setImage} image={image} />
          <input hidden value={image} name="image" />
        </LeftContainer>
        <RightContainer>
          <Typography>Select Inventory to Display</Typography>
          {data.ids.map((dropdown, i) => {
            return (
              <FormContainer key={i}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={`${dropdown}-name`}>
                    Inventory {i + 1}
                  </InputLabel>
                  <Select
                    value={dropdown}
                    onChange={e => {
                      updateItem(i, e.target.value)
                    }}
                    inputProps={{
                      name: `${dropdown}-name`,
                      id: `${dropdown}-name`,
                      'aria-label': `Inventory ${i + 1}`,
                      title: `Inventory ${i + 1}`,
                      label: `Inventory ${i + 1}`
                    }}
                  >
                    {inventory
                      .filter(inv => inv.published)
                      .map(inventory => (
                        <MenuItem key={inventory.id} value={inventory.id}>
                          {inventory.title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <HeaderContainer>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
              </FormContainer>
            )
          })}
          <Button onClick={addItem}>Add Inventory Item</Button>
        </RightContainer>
      </InnerContainer>
    </InputContainer>
  )
}

export default Inventory2Edit
