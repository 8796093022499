import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import '../AdminMenu/Admin.css'

const Header = styled.div`
  width: 100%;
  height: 60px;
  background-color: #eceff1;
  display: flex;
  align-items: center;
`

const AdminMenu = () => (
  <Header>
    <Button component={Link} to="/">
      Admin Dashboard
    </Button>
    <Button component={Link} to="/domains">
      <span style={{ color: 'lightgrey' }}>[ </span>&nbsp; Domains &nbsp;
      <span style={{ color: 'lightgrey' }}> ]</span>
    </Button>
    <Button component={Link} to="/create">
      Create Domain
    </Button>
    <Button component={Link} to="/manufacturers">
      Edit Manufacturers
    </Button>
    <Button component={Link} to="/create-manufacturer">
      Create Manufacturer
    </Button>
    <div style={{ position: 'absolute', right: 0 }}>
      <Button component={Link} to="/Logout">
        Logout
      </Button>
    </div>
  </Header>
)
export default AdminMenu
