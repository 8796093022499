import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { flatMap, map, tap, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import Alert from 'react-s-alert'

import updateItem from '~/Requests/inventory/updateItem'
import createItem from '~/Requests/inventory/createItem'
import deleteItem from '~/Requests/inventory/deleteItem'
import { createActions, createHandler } from '~/State/utils'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_INVENTORY_REQUEST
 * @prop {string} CREATE_INVENTORY_SUCCESS
 * @prop {string} CREATE_INVENTORY_FAILURE
 * @prop {string} UPDATE_ITEM_REQUEST
 * @prop {string} UPDATE_ITEM_SUCCESS
 * @prop {string} UPDATE_ITEM_FAILURE
 * @prop {string} DELETE_ITEM_REQUEST
 * @prop {string} DELETE_ITEM_SUCCESS
 * @prop {string} DELETE_ITEM_FAILURE
 *
 */

/**
 * @type {Actions}
 */
export const actions = createActions('INVENTORY', [
  'CREATE_INVENTORY_REQUEST',
  'CREATE_INVENTORY_SUCCESS',
  'CREATE_INVENTORY_FAILURE',
  'UPDATE_ITEM_REQUEST',
  'UPDATE_ITEM_SUCCESS',
  'UPDATE_ITEM_FAILURE',
  'DELETE_ITEM_REQUEST',
  'DELETE_ITEM_SUCCESS',
  'DELETE_ITEM_FAILURE'
])

export const rootLens = R.lensProp('inventory')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)

export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  inventory: {
    ids: {
      1: {
        id: '1',
        title: 'Inventory 1',
        path: '/inventory/1',
        manufacturer: 'ABC Corp',
        modelID: '1234',
        location: 1,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: true,
        status: 'sold',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: true
      },
      2: {
        id: '2',
        title: 'Inventory 2',
        path: '/inventory/2',
        manufacturer: 'DEF Corp',
        modelID: '1235',
        location: 2,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: true,
        status: 'active',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: true
      },
      3: {
        id: '3',
        title: 'Inventory 3',
        path: '/inventory/3',
        manufacturer: 'ABC Corp',
        modelID: '1236',
        location: 1,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: true,
        status: 'archive',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: false
      },
      4: {
        id: '4',
        title: 'Inventory 4',
        path: '/inventory/4',
        manufacturer: 'ABC Corp',
        modelID: '1237',
        location: 2,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: false,
        status: 'active',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: false
      },
      5: {
        id: '5',
        title: 'Inventory 5',
        path: '/inventory/5',
        manufacturer: 'ABC Corp',
        modelID: '1237',
        location: 2,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: false,
        status: 'active',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: false
      },
      6: {
        id: '6',
        title: 'Inventory 6',
        path: '/inventory/6',
        manufacturer: 'ABC Corp',
        modelID: '1237',
        location: 2,
        footage: 1234,
        bedrooms: 2,
        bathrooms: 1.5,
        description:
          'I am the description that was entered into this system. I am a basic description but a description none the less.',
        features: [
          'acb17c51-6cc7-47ea-b11b-6c2db147321d',
          '28296ca4-87f1-4c30-855f-9729013a5144',
          '9eec3490-9345-41f0-af73-97c1ef2343ea'
        ],
        categories: [
          '0191f26d-294a-42d8-a907-73012d3598f7',
          '308bb1a4-f244-40f1-ab2e-bb75833eec80',
          'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
        ],
        price: 12345.67,
        multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
        published: false,
        status: 'active',
        mainImage:
          'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
        images: [],
        isClayton: false
      }
    }
  }
}

export const reducer = createHandler({
  [actions.UPDATE_ITEM_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),

  [actions.CREATE_INVENTORY_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_ITEM_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const updateItem$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_ITEM_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(updateItem(action.payload, state.token)).pipe(
        map(answer => ({ ...answer, history: action.meta }))
      )
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.UPDATE_ITEM_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_ITEM_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

export const createItem$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.CREATE_INVENTORY_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(createItem(action.payload, state.token)).pipe(
        map(answer => ({ ...answer, history: action.meta }))
      )
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.CREATE_INVENTORY_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.CREATE_INVENTORY_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

export const deleteItem$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.DELETE_ITEM_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(deleteItem(action.payload, state.token)).pipe(
        map(answer => ({ ...answer, history: action.meta }))
      )
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.DELETE_ITEM_FAILURE,
          payload: error,
          meta,
          history
        }
      }

      return {
        type: actions.DELETE_ITEM_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

export const redirectAfterCreate$ = action$ =>
  action$.pipe(
    ofType(
      actions.CREATE_INVENTORY_SUCCESS,
      actions.UPDATE_ITEM_SUCCESS,
      actions.DELETE_ITEM_SUCCESS
    ),
    tap(action => {
      action.history.push('/admin/inventory')
    }),
    map(() => ({
      type: '@@MISC/REDIRECTED_PAGE'
    }))
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_INVENTORY_SUCCESS, actions.UPDATE_ITEM_SUCCESS),
    tap(() => {
      Alert.success('Inventory Item Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_INVENTORY_FAILURE, actions.UPDATE_ITEM_FAILURE),
    tap(() => {
      Alert.error('Error Saving Inventory Item. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  updateItem$,
  createItem$,
  redirectAfterCreate$,
  deleteItem$,
  showSuccessNotification$,
  showErrorNofitication$
]
