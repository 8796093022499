import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { flatMap, map, tap, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import Alert from 'react-s-alert'
import updateTemplate from '~/Requests/templates/updateTemplate'

import { createActions, createHandler } from '~/State/utils'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} UPDATE_TEMPLATE_REQUEST
 * @prop {string} UPDATE_TEMPLATE_SUCCESS
 * @prop {string} UPDATE_TEMPLATE_FAILURE
 *
 */
/**
 * @type {Actions}
 */

export const actions = createActions('TEMPLATE', [
  'UPDATE_TEMPLATE_REQUEST',
  'UPDATE_TEMPLATE_SUCCESS',
  'UPDATE_TEMPLATE_FAILURE'
])

const rootLens = R.lensProp('templates')
const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)

const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  templates: {
    ids: {
      1: {
        id: 1,
        title: 'Blog Template',
        blocks: [
          {
            id: 5,
            component: 'Header1',
            data: {
              header: 'Sup my not gregs?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          },
          {
            id: 3,
            component: 'CONTENT'
          }
        ]
      },
      2: {
        id: 2,
        title: 'Inventory Template',
        blocks: [
          {
            id: 5,
            component: 'Header1',
            data: {
              header: 'Sup my not gregs?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          },
          {
            id: 4,
            component: 'CONTENT'
          }
        ]
      }
    }
  }
}

export const reducer = createHandler({
  [actions.UPDATE_TEMPLATE_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state)
})

const updateTemplate$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_TEMPLATE_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(updateTemplate(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_TEMPLATE_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.UPDATE_TEMPLATE_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.UPDATE_TEMPLATE_SUCCESS),
    tap(() => {
      Alert.success('Template Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.UPDATE_TEMPLATE_FAILURE),
    tap(() => {
      Alert.error('Error Saving Template. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  updateTemplate$,
  showSuccessNotification$,
  showErrorNofitication$
]
