import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const IntroContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const HeaderContainer = styled.div`
  width: 60%;
  color: ${({ color }) => color || 'inherit'};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const SubtitleTextContainer = styled.div`
  width: 90%;
  text-align: center;
  color: ${({ color }) => color || 'inherit'};
  margin-bottom: 0.75rem;
`

const HeaderText = styled(Typography)`
  @media only screen and (max-width: 768px) {
    font-size: ${({ header }) => header == 'h1' && '32px'} !important;
  }
`

const SubtitleText = styled(Typography)``

const Intro1 = ({
  header = 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  ctaText = 'I am the call to action button',
  background,
  text,
  ctaLink = '',
  image,
  headerSize = 'h2',
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  const CTA = path => (
    <Button
      href={path}
      target={isRelative(path) ? '_self' : '_blank'}
      color={btnColor}
      variant={btnStyle}
    >
      {ctaText}
    </Button>
  )

  return (
    <IntroContainer img={image} color={bgColor}>
      <TextContainer>
        <HeaderContainer color={textColor}>
          <HeaderText
            header={headerSize}
            variant={headerSize}
            align="center"
            gutterBottom
          >
            {header}
          </HeaderText>
        </HeaderContainer>
        <SubtitleTextContainer color={textColor}>
          <SubtitleText
            variant="body1"
            align="center"
            color="inherit"
            gutterBottom
          >
            {subtitle}
          </SubtitleText>
        </SubtitleTextContainer>
      </TextContainer>
      {ctaText && CTA(ctaLink)}
    </IntroContainer>
  )
}

Intro1.displayName = 'Intro 1'
Intro1.moduleName = 'Intro1'

export default Intro1
