import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router'
import Input from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Modal from '@material-ui/core/Modal'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import Menu from '@material-ui/icons/Menu'
import UpArrow from '@material-ui/icons/ArrowUpward'
import DownArrow from '@material-ui/icons/ArrowDownward'
import Button from '@material-ui/core/Button'
import Buttons from '@material-ui/core/ButtonGroup'
import DeleteForever from '@material-ui/icons/DeleteForever'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Typography } from '@material-ui/core'

import * as R from 'ramda'
import uuid from 'uuid/v4'

import ImageUpload from '~/SharedComponents/ImageUpload'
import EditModule from './EditModule'
import * as Components from '~/Components'
import ModuleSelector from './ModuleSelector'
import PageTheme from '~/App/PageTheme'

const PageEdit = styled.div`
  width: 100%;
  padding: 1.25rem;
`

const ComponentList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AddLineContainer = styled.div`
  width: 90%;
  border: 1px solid rgba(33, 33, 33, 0.5);
  position: relative;
  margin: 1rem 0;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const AddIcon = styled(Add)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #768390;
  color: #fff;
  border-radius: 50%;
`

const Permalink = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: rgba(33, 33, 33, 0.5);
  }

  label {
    font-family: 'Roboto';
    font-size: 0.75rem;
  }

  input {
    font-family: 'Roboto';
    font-size: 1.05rem;
    padding: 0.5rem;
  }
`

const AddModuleModal = styled.div`
  width: 80%;
  height: 80%;
  overflow-y: auto;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const EditModuleModal = styled.div`
  width: 80%;
  height: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BlockDisplay = styled.div`
  width: 100%;
  display: flex;
`

const RemoveModal = styled.div`
  width: 80%;
  min-height: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RemoveModuleIcon = styled.div`
  margin: 0.5rem 0;
`

const PageMeta = styled.div``

const AddLine = ({ onClick }) => (
  <AddLineContainer onClick={onClick} aria-label="add line">
    <AddIcon />
  </AddLineContainer>
)

const BlockOptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const MetaFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`

const TitleContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
`

const BlockOptions = ({
  onUp = () => {},
  onDown = () => {},
  onMenu = () => {},
  onRemove = () => {},
  isFirst,
  isLast
}) => (
  <BlockOptionsWrap>
    <IconButton onClick={onMenu} aria-label="Edit Module">
      <Menu />
    </IconButton>
    <RemoveModuleIcon>
      <IconButton onClick={onRemove} aria-label="Delete Module">
        <DeleteForever />
      </IconButton>
    </RemoveModuleIcon>
    <IconButton onClick={onUp} disabled={isFirst} aria-label="Move Module Up">
      <UpArrow />
    </IconButton>
    <IconButton
      onClick={onDown}
      disabled={isLast}
      aria-label="Move Module Down"
    >
      <DownArrow />
    </IconButton>
  </BlockOptionsWrap>
)

const PageForm = ({
  path,
  blocks = [],
  id,
  onChange,
  title,
  setPageChanges,
  hasChanged,
  published,
  meta = {}
}) => {
  const [pageTitle, setPageTitle] = useState(title)
  const [pageMeta, setPageMeta] = useState(meta)
  const [currentBlocks, setCurrentBlocks] = useState(blocks)
  const [currentAddIndex, setCurrentAddIndex] = useState(null)
  const [showAddModuleModal, setShowAddModuleModal] = useState(false)
  const [showEditModuleModal, setShowEditModuleModal] = useState(false)
  const [isPublished, setIsPublished] = useState(published)
  const [currentPath, setCurrentPath] = useState(path)
  const [currentModule, setCurrentModule] = useState()
  const [toDelete, setToDelete] = useState(null)
  const [image, setImage] = useState(meta['og:image'])

  const pageId = id
  useEffect(() => {
    const diff = {
      id,
      path: currentPath,
      blocks: currentBlocks,
      published: isPublished,
      title: pageTitle,
      meta: pageMeta
    }

    setPageChanges(diff)
  }, [currentBlocks, currentPath, isPublished, pageMeta, pageTitle])

  const components = currentBlocks.map(({ component, ...rest }) => ({
    ...rest,
    component: Components[component],
    component_name: component
  }))

  const addBlock = (index, block) => {
    setCurrentBlocks(R.insert(index, block))
    onChange()
  }

  const removeBlock = index => {
    setCurrentBlocks(blocks => blocks.filter((_, i) => i !== index))
    onChange()
    teardownModal()
  }

  const updateBlock = (index, block) => {
    setCurrentBlocks(R.adjust(index, R.mergeDeepLeft(block)))
    onChange()
    teardownModal()
  }

  const moveBlockUp = index => {
    // if index is 0, we are not going
    // to even try moving it up!
    if (!index) {
      return
    }

    setCurrentBlocks(R.move(index, index - 1))
    onChange()
  }

  const moveBlockDown = index => {
    // if index is last block, do not
    // allow to move down
    if (index === components.length) {
      return
    }

    setCurrentBlocks(R.move(index, index + 1))
    onChange()
  }

  const setupModal = index => {
    setCurrentAddIndex(index)
    setShowAddModuleModal(true)
  }

  const addBlockFromModal = component => {
    addBlock(currentAddIndex, { id: uuid(), component, data: {} })
    teardownModal()
  }

  const teardownModal = () => {
    setCurrentAddIndex(null)
    setShowAddModuleModal(false)
    setShowEditModuleModal(false)
    setToDelete(null)
  }

  const openEditModal = data => {
    setShowEditModuleModal(true)
    setCurrentModule(data)
  }

  useEffect(() => {
    setPageMeta(R.mergeLeft({ 'og:image': image }))
  }, [image])

  return (
    <React.Fragment>
      <Prompt when={hasChanged} message="Do you want to undo all changes?" />
      <PageEdit key={id}>
        <PageMeta>
          <Permalink title="Permalink for the website.">
            <label>Permalink:</label>
            <span>companyurl.com</span>
            <input
              value={currentPath}
              key={id}
              name="path"
              onChange={e => {
                onChange()
                setCurrentPath(e.target.value)
              }}
            />
          </Permalink>
          <FormControlLabel
            control={
              <Switch
                checked={isPublished}
                onChange={e => {
                  setIsPublished(e.target.checked)
                  onChange()
                }}
                color="primary"
                inputProps={{
                  'aria-label': 'Page is Published Toggle',
                  title: 'Page is Published Toggle'
                }}
              />
            }
            label="Is Published"
          />
        </PageMeta>
        <TitleContainer>
          <Input
            value={pageTitle}
            name="page-name"
            label="Page Name"
            title="Page Name"
            aria-label="Page Name"
            onChange={e => {
              setPageTitle(e.target.value)
              onChange()
            }}
          />
        </TitleContainer>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="meta-content"
            id="meta-header"
          >
            <Typography>Meta</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <MetaFieldContainer>
              <Input
                value={pageMeta.title || ''}
                name="title"
                label="Title"
                title="Title"
                aria-label="Title"
                onChange={e => {
                  setPageMeta(
                    R.mergeLeft({
                      title: e.target.value,
                      'og:title': e.target.value
                    })
                  )
                  onChange()
                }}
              />
              <Input
                value={pageMeta.description || ''}
                name="description"
                label="Description"
                title="Description"
                aria-label="Description"
                onChange={e => {
                  setPageMeta(
                    R.mergeLeft({
                      description: e.target.value,
                      'og:description': e.target.value
                    })
                  )
                  onChange()
                }}
              />
              <Input
                value={pageMeta.keywords || ''}
                name="keywords"
                label="Keywords"
                title="Keywords"
                aria-label="Keywords"
                helperText="Keywords will be a comma-separated list"
                onChange={e => {
                  setPageMeta(R.mergeLeft({ keywords: e.target.value }))
                  onChange()
                }}
              />
              <Input
                value={pageMeta.author || ''}
                name="author"
                label="Author"
                title="Author"
                aria-label="Author"
                onChange={e => {
                  setPageMeta(R.mergeLeft({ author: e.target.value }))
                  onChange()
                }}
              />
              <Input
                value={pageMeta.facebook_domain_verification || ''}
                name="facebook_domain_verification"
                label="Facebook Domain Verification"
                title="Facebook Domain Verification"
                aria-label="Facebook Domain Verification"
                onChange={e => {
                  setPageMeta(R.mergeLeft({ facebook_domain_verification: e.target.value }))
                  onChange()
                }}
              />
              <ImageUploadContainer>
                <Typography>og:image</Typography>
                <ImageUpload
                  name="og-image-upload"
                  onChange={setImage}
                  image={image}
                />
                <input hidden value={image} name="og_image" />
              </ImageUploadContainer>
            </MetaFieldContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ComponentList>
          {components.map(({ id, component: Comp, data, ...rest }, i) => (
            <Block key={id} aria-label="Module Item">
              <AddLine onClick={() => setupModal(i)} />
              <BlockDisplay>
                <PageTheme>
                  <Comp {...data} />
                </PageTheme>
                <BlockOptions
                  isFirst={i === 0}
                  isLast={i === components.length - 1}
                  onUp={() => moveBlockUp(i)}
                  onDown={() => moveBlockDown(i)}
                  onMenu={() =>
                    openEditModal({
                      id,
                      data,
                      pageId,
                      ...rest,
                      index: i
                    })
                  }
                  onRemove={() => setToDelete(i)}
                />
              </BlockDisplay>
            </Block>
          ))}
          <AddLine onClick={() => setupModal(components.length)} />
        </ComponentList>
        <Modal
          aria-labelledby="add-block-title"
          aria-describedby="add-block-description"
          open={showAddModuleModal}
          onClose={teardownModal}
        >
          <AddModuleModal>
            <ModuleSelector
              onSelect={addBlockFromModal}
              closeModal={teardownModal}
            />
          </AddModuleModal>
        </Modal>
        <Modal
          aria-labelledby="edit-block-title"
          aria-describedby="edit-block-description"
          open={showEditModuleModal}
          onClose={teardownModal}
        >
          <EditModuleModal>
            <EditModule
              {...currentModule}
              closeModal={teardownModal}
              updateBlock={updateBlock}
            />
          </EditModuleModal>
        </Modal>
        <Modal
          aria-labelledby="remove-module-title"
          aria-describedby="remove-module-description"
          open={toDelete !== null}
          onClose={teardownModal}
        >
          <RemoveModal>
            <Typography variant="h2" gutterBottom align="center">
              Are you sure you want to delete that module?
            </Typography>
            <Buttons>
              <Button color="secondary" onClick={() => removeBlock(toDelete)}>
                Yes, remove this module.
              </Button>
              <Button color="primary" onClick={teardownModal}>
                No, keep this module.
              </Button>
            </Buttons>
          </RemoveModal>
        </Modal>
      </PageEdit>
    </React.Fragment>
  )
}

export default PageForm
