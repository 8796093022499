import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import styled from '@emotion/styled'
import Alert from 'react-s-alert'

import { useLocation, useHistory } from 'react-router-dom'
import { parse } from 'query-string'

import AdminSettings from './AdminSettings'
import SiteSettings from './SiteSettings'
import CompanyInfo from './Company'
import BrandInfo from './Brand'
import Leads from './Leads'
import Redirects from './Redirects'
import { useDispatch, useStore, useSelector } from 'react-redux'

import { actions } from '~/State/ducks/profile'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 3rem;
`

const ButtonContainer = styled.div`
  margin-left: auto;
  padding: 1rem;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10rem;
`

const successAlert = () =>
  Alert.success('Profile settings saved successfully', {
    effect: 'genie',
    position: 'bottom-right',
    timeout: 1500
  })

const failureAlert = message =>
  Alert.warning(message, {
    effect: 'genie',
    position: 'bottom-right',
    timeout: 5000
  })

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

const errorMessages = {
  email: 'Email is a required field',
  title: 'Site Title is a required field',
  domain: 'Domain is a required field',
  'company-name': 'Company Name is a required field',
  address1: 'Address is a required field',
  city: 'City is a required field',
  state: 'State is a required field',
  zip: 'Zip is a required field',
  'clayton-id': 'Clayton Built® ID is a required field'
}

const Admin = () => {
  const location = useLocation()
  const history = useHistory()
  const domain_id = useSelector(state => state.domain.id)
  const profile = useSelector(state => state.profile)
  const store = useStore()
  const dispatch = useDispatch()
  const [theme, setTheme] = useState(profile.brand.theme)
  const [image, setImage] = useState(profile.brand.logo)
  const [favicon, setFavicon] = useState(profile.settings.favicon)

  const query = parse(location.search)

  React.useEffect(() => {
    return store.subscribe(() => {
      const state = store.getState()
      if (state.profile) {
        successAlert()
      }
    })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const data = {
      admin: {
        first: formData.get('firstName'),
        last: formData.get('lastName'),
        phone: formData.get('phone'),
        email: formData.get('email'),
        contact_email: formData.get('contactEmail')
      },
      settings: {
        title: formData.get('title'),
        domain: formData.get('domain'),
        ss_domain: formData.get('ss_domain'),
        ss_account: formData.get('ss_account'),
        google_analytics: formData.get('google_analytics'),
        google_tag: formData.get('google_tag'),
        fb_pixel: formData.get('fb_pixel'),
        ar_adv_id: formData.get('ar_adv_id'),
        ar_pix_id: formData.get('ar_pix_id'),
        captcha_client: formData.get('captcha_client'),
        captcha_server: formData.get('captcha_server'),
        favicon
      },
      company: {
        name: formData.get('company-name'),
        clayton_id: formData.get('clayton-id'),
        address: formData.get('address1'),
        address2: formData.get('address2'),
        city: formData.get('city'),
        state: formData.get('state'),
        zip: formData.get('zip')
      },
      brand: {
        color1: formData.get('primary-color-value'),
        color2: formData.get('secondary-color-value'),
        theme,
        logo: image
      }
    }

    dispatch({
      type: actions.UPDATE_PROFILE_REQUESTED,
      payload: data,
      meta: domain_id
    })
  }
  const currentTab = query.tab ? Number(query.tab) : 0

  return (
    <Container>
      <Header>
        <Typography variant="h4">Account Settings</Typography>
      </Header>

      <form
        onInvalid={e => {
          const message = errorMessages[e.target.name] || 'Form Error'

          return failureAlert(message)
        }}
        onSubmit={handleSubmit}
      >
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
        >
          <Tab
            onClick={() => history.push(`/admin/profile?tab=${0}`)}
            label="Admin Profile"
            {...a11yProps(0)}
          />
          <Tab
            onClick={() => history.push(`/admin/profile?tab=${1}`)}
            label="Site Settings"
            {...a11yProps(1)}
          />
          <Tab
            onClick={() => history.push(`/admin/profile?tab=${2}`)}
            label="Company Information"
            {...a11yProps(2)}
          />
          <Tab
            onClick={() => history.push(`/admin/profile?tab=${3}`)}
            label="Theme and Brand Settings"
            {...a11yProps(3)}
          />
          <Tab
            onClick={() => history.push(`/admin/profile?tab=${4}`)}
            label="Leads"
            {...a11yProps(4)}
          />
          <Tab
            onClick={() => history.push('/admin/profile?tab=5')}
            label="Redirects"
            {...a11yProps(5)}
          />
          <ButtonContainer>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </ButtonContainer>
        </Tabs>

        <TabPanel value={currentTab} index={0}>
          <AdminSettings admin={profile.admin} />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <SiteSettings
            {...profile.settings}
            favicon={favicon}
            handleImageChange={setFavicon}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <CompanyInfo {...profile.company} />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <BrandInfo
            primary={profile.brand.color1}
            secondary={profile.brand.color2}
            image={image}
            handleImageChange={setImage}
            handleThemeChange={setTheme}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={4}>
          <Leads />
        </TabPanel>
        <TabPanel value={currentTab} index={5}>
          <Redirects />
        </TabPanel>
      </form>
    </Container>
  )
}
export default Admin
