import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import InputContainer from './Container'
import ColorPicker from './ColorPicker'

import { idsLens } from '~/State/ducks/blogs'
import ImageUpload from '~/SharedComponents/ImageUpload'

const FormContainer = styled.div`
  min-width: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const BlogAndSubtitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const BlogContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  padding-right: 1rem;
`

const SubtitleContainer = styled.div`
  width: 100%;
  flex: 1;
  padding-left: 1rem;
`

const RightContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const LeftContainer = styled.div`
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const Blog2Edit = ({ data, handleEdit }) => {
  const { background = '#0c0c0c', text = '#ffffff' } = data

  const [image, setImage] = useState(data.image)

  const blogs = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      blogPosts: [...oldState.blogPosts, blogs[0] ? { id: blogs[0].id } : {}]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      blogPosts: R.adjust(
        index,
        R.mergeLeft({ [key]: value }),
        oldState.blogPosts
      )
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      blogPosts: R.remove(index, 1, oldState.blogPosts)
    }))

  return (
    <InputContainer>
      <MainContainer>
        <LeftContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputContainer>
          <Typography align="center">Background Image</Typography>
          <ImageUpload name="image-upload" onChange={setImage} image={image} />
          <input hidden value={image} name="image" />
        </LeftContainer>
        <RightContainer>
          <Typography>Select Blog Posts to Display</Typography>
          {data.blogPosts.map((blog, i) => {
            return (
              <FormContainer key={i}>
                <HeaderContainer>
                  <InputLabel htmlFor={`${blog.id}-name`}>
                    Blog Post {i + 1}
                  </InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <BlogAndSubtitleContainer>
                  <BlogContainer>
                    <FormControl fullWidth>
                      <InputLabel htmlFor={`${blog.id}-name`}>
                        Blog Name
                      </InputLabel>
                      <Select
                        value={blog.id}
                        onChange={e => {
                          updateItem(i, 'id', e.target.value)
                        }}
                        inputProps={{
                          name: `${blog.id}-name`,
                          id: `${blog.id}-name`,
                          'aria-label': `Blog Post ${i + 1}`,
                          title: `Blog Post ${i + 1}`,
                          label: `Blog Post ${i + 1}`
                        }}
                      >
                        {blogs.map(a => (
                          <MenuItem key={a.id} value={a.id}>
                            {a.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </BlogContainer>
                  <SubtitleContainer>
                    <TextField
                      fullWidth
                      name={`${blog.subtitle}-name`}
                      id={`${blog.subtitle}-name`}
                      onChange={e => updateItem(i, 'subtitle', e.target.value)}
                      aria-label="Subtitle"
                      title="Subtitle"
                      label="Subtitle"
                      margin="normal"
                      variant="outlined"
                      value={blog.subtitle}
                      multiline
                    />
                  </SubtitleContainer>
                </BlogAndSubtitleContainer>
              </FormContainer>
            )
          })}
          <Button onClick={addItem}>Add Blog Post</Button>
        </RightContainer>
      </MainContainer>
    </InputContainer>
  )
}

export default Blog2Edit
