import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import InputContainer from './Container'
import ButtonStylePicker from './ButtonStylePicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageAndTextContainer = styled.div`
  display: flex;
`

const ButtonInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextFieldsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const FeaturedImageTextContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const ColorInputWrapper = styled.div`
  padding-right: 2rem;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Hero1Edit = ({ data }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    btnColor = 'primary',
    btnStyle = 'contained',
    headerSize = 'h1'
  } = data
  const [image, setImage] = useState(data.image)
  const [headerFontSize, setHeaderFontSize] = useState(headerSize)

  return (
    <InputContainer>
      <InnerContainer>
        <ImageAndTextContainer>
          <ImageUploadContainer>
            <FeaturedImageTextContainer>
              <Typography gutterBottom>Featured Image</Typography>
            </FeaturedImageTextContainer>
            <ImageUpload
              name="image-upload-thing"
              onChange={setImage}
              image={image}
            />
            <input hidden value={image} name="image" />
          </ImageUploadContainer>
          <TextFieldsContainer>
            <TextField
              name="header"
              id="header"
              aria-label="Header"
              title="Header"
              label="Header"
              margin="normal"
              variant="outlined"
              defaultValue={data.header}
            />
            <FormControl>
              <InputLabel id="header-size">Header Size</InputLabel>
              <Select
                name="headerSize"
                labelId="header-size"
                id="header-size-select"
                value={headerFontSize}
                onChange={e => setHeaderFontSize(e.target.value)}
              >
                <MenuItem value="h1">H1</MenuItem>
                <MenuItem value="h2">H2</MenuItem>
                <MenuItem value="h3">H3</MenuItem>
                <MenuItem value="h4">H4</MenuItem>
                <MenuItem value="h5">H5</MenuItem>
                <MenuItem value="h6">H6</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="subtitle"
              id="subtitle"
              aria-label="Subtitle"
              title="Subtitle"
              label="Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={data.subtitle}
              multiline
            />
          </TextFieldsContainer>
        </ImageAndTextContainer>
        <Typography gutterBottom>Don't have an image?</Typography>
        <ColorInputContainer>
          <ColorInputWrapper>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ButtonPicker
              color={btnColor}
              name="btnColor"
              title="Button Color"
            />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ButtonStylePicker
              style={btnStyle}
              name="btnStyle"
              title="Button Style"
            />
          </ColorInputWrapper>
        </ColorInputContainer>
        <ButtonInputContainer>
          <Typography>Call to Action Button</Typography>
          <TextField
            name="ctaText"
            id="ctaText"
            aria-label="Call to Action Text"
            title="Call to Action Text"
            label="Call to Action Text"
            margin="normal"
            variant="outlined"
            defaultValue={data.ctaText}
          />
          <TextField
            name="ctaLink"
            id="ctaLink"
            aria-label="Call to Action Link"
            title="Call to Action Link"
            label="Call to Action Link"
            margin="normal"
            variant="outlined"
            defaultValue={data.ctaLink}
          />
        </ButtonInputContainer>
      </InnerContainer>
    </InputContainer>
  )
}

export default Hero1Edit
