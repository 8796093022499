import { reducer as companyReducer } from './ducks/companies'
import { reducer as pagesReducer } from './ducks/pages'
import { reducer as usersReducer } from './ducks/users'
import { reducer as tokenReducer } from './ducks/token'
import { reducer as profileReducer } from './ducks/profile'
import { reducer as menuReducer } from './ducks/menus'
import { reducer as inventoryReducer } from './ducks/inventory'
import { reducer as categoryReducer } from './ducks/categories'
import { reducer as featureReducer } from './ducks/features'
import { reducer as locationReducer } from './ducks/locations'
import { reducer as blogReducer } from './ducks/blogs'
import { reducer as templateReducer } from './ducks/templates'
import { reducer as leadReducer } from './ducks/leads'
import { reducer as domainReducer } from './ducks/domain'

const reducers = [
  companyReducer,
  pagesReducer,
  usersReducer,
  tokenReducer,
  profileReducer,
  menuReducer,
  inventoryReducer,
  categoryReducer,
  featureReducer,
  locationReducer,
  blogReducer,
  templateReducer,
  leadReducer,
  domainReducer
]

export const reducer = (state, action) =>
  reducers.reduce((a, fn) => fn(a, action), state)

export default reducer
