import { createHandler, createActions } from '~/State/utils'
import { ofType } from 'redux-observable'
import { flatMap, map, withLatestFrom } from 'rxjs/operators'
import { from as of$ } from 'rxjs'
import updateMenu from '~/Requests/menus/updateMenu'

import * as R from 'ramda'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} UPDATE_MENU_REQUEST
 * @prop {string} UPDATE_MENU_SUCCESS
 * @prop {string} UPDATE_MENU_FAILURE
 *
 */

/**
 * @type {Actions}
 */
export const actions = createActions('MENUS', [
  'UPDATE_MENU_REQUEST',
  'UPDATE_MENU_SUCCESS',
  'UPDATE_MENU_FAILURE'
])

const rootLens = R.lensProp('menus')

export const defaultState = {
  menus: {
    title: 'Main Menu',
    pages: [
      {
        id: 'f51d7b14-7e41-4e3a-8c08-1bf355486b05',
        children: [
          {
            id: '8630dde7-f0a2-403e-b16d-9eab6b7781b3'
          }
        ]
      }
    ]
  }
}

export const reducer = createHandler({
  [actions.UPDATE_MENU_SUCCESS]: (state, action) =>
    R.set(rootLens, action.payload, state)
})

export const updateMenu$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_MENU_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(updateMenu(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_MENU_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.UPDATE_MENU_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const epics = [updateMenu$]
