import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const Header1Edit = ({ data }) => {
  const {
    header,
    feature,
    background = '#ffffff',
    text = '#eeeeee',
    menuText
  } = data

  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <DescriptionContainer>
            <Typography>Description:</Typography>
            <TextField
              name="header"
              id="header"
              aria-label="Header"
              title="Header"
              label="Header"
              margin="normal"
              variant="outlined"
              defaultValue={header}
            />
          </DescriptionContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ColorPicker color={feature} name="feature" title="Feature Color" />
            <ColorPicker
              color={menuText}
              name="menuText"
              title="Menu Text Color"
            />
          </ColorInputContainer>
        </DescriptionAndColorContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Header1Edit
