import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/styles'
import { parseUrl } from 'query-string'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/inventory'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const InventoryContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const Card = styled(Link)`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  text-decoration: none;
  color: inherit;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  position: relative;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: #ffffff;
  }
`

const PriceAndButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const HomeInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex: 2;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
`

const NameOfHome = styled(Typography)``

// const Subtitle = styled(Typography)``

const Price = styled(Typography)`
  margin-bottom: 2rem;
`

const Divider = styled.hr`
  margin-top: 1rem;
  width: 100%;
  border: none;
  height: 1px;
  background-color: black;
`

const Status = styled(Button)`
  position: absolute !important;
  top: 5%;
  right: 5%;
`

const previewData = [
  {
    title: 'My Default Title',
    path: '/inventory/1',
    manufacturer: 'ABC Corp',
    modelID: '1234',
    location: 1,
    footage: 1234,
    bedrooms: 2,
    bathrooms: 1.5,
    description:
      'I am the description that was entered into this system. I am a basic description but a description none the less.',
    features: [
      'acb17c51-6cc7-47ea-b11b-6c2db147321d',
      '28296ca4-87f1-4c30-855f-9729013a5144',
      '9eec3490-9345-41f0-af73-97c1ef2343ea'
    ],
    categories: [
      '0191f26d-294a-42d8-a907-73012d3598f7',
      '308bb1a4-f244-40f1-ab2e-bb75833eec80',
      'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
    ],
    price: 12345.67,
    multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
    published: true,
    status: 'sold',
    mainImage:
      'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
    images: [],
    isClayton: true
  },
  {
    title: 'My Default Title',
    path: '/inventory/1',
    manufacturer: 'ABC Corp',
    modelID: '1234',
    location: 1,
    footage: 1234,
    bedrooms: 2,
    bathrooms: 1.5,
    description:
      'I am the description that was entered into this system. I am a basic description but a description none the less.',
    features: [
      'acb17c51-6cc7-47ea-b11b-6c2db147321d',
      '28296ca4-87f1-4c30-855f-9729013a5144',
      '9eec3490-9345-41f0-af73-97c1ef2343ea'
    ],
    categories: [
      '0191f26d-294a-42d8-a907-73012d3598f7',
      '308bb1a4-f244-40f1-ab2e-bb75833eec80',
      'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
    ],
    price: 12345.67,
    multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
    published: true,
    status: 'sold',
    mainImage:
      'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
    images: [],
    isClayton: true
  }
]

const Inventory2 = ({
  ids = [],
  isPreview,
  text,
  background,
  image,
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const inventory = useSelector(state => {
    const idHash = R.view(idsLens, state)

    return ids
      .filter(id => idHash[id])
      .map(id => ({
        title: 'My Default Title',
        ...idHash[id]
      }))
  })

  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <InventoryContainer color={bgColor}>
        {previewData.map((inventory, i) => (
          <Card key={i}>
            <PictureContainer
              imagePadding={theme.imagePadding}
              boxShadow={theme.boxShadow}
            >
              <img src={inventory.mainImage} />
            </PictureContainer>
            <HomeInformationContainer color={textColor}>
              <NameOfHome component="p" variant="h2" gutterBottom>
                {inventory.title}
              </NameOfHome>
              {/* <Subtitle variant="body1" align="left">
                {inventory.description}
              </Subtitle> */}
              <Divider></Divider>
              <PriceAndButtonContainer>
                <Price variant="body1" color="primary">
                  {inventory.price && inventory.price == 0
                    ? 'Contact Us for Pricing'
                    : `$${inventory.price.toLocaleString()}`}
                </Price>
                <Button
                  component={Link}
                  to={inventory.path}
                  color="primary"
                  variant="contained"
                >
                  Learn More
                </Button>
              </PriceAndButtonContainer>
            </HomeInformationContainer>
          </Card>
        ))}
      </InventoryContainer>
    )
  }

  return (
    <InventoryContainer img={image} color={bgColor}>
      {inventory.map((inventory, i) => (
        <Card key={i} to={inventory.path}>
          <PictureContainer
            imagePadding={theme.imagePadding}
            boxShadow={theme.boxShadow}
          >
            <img
              src={inventory.mainImage}
              title={parseUrl(inventory.mainImage).query.title}
              alt={parseUrl(inventory.mainImage).query.alt}
            />
            {inventory.status === 'sold' && (
              <Status variant="contained" color="primary">
                SOLD
              </Status>
            )}
          </PictureContainer>
          <HomeInformationContainer color={textColor}>
            <NameOfHome gutterBottom component="p" variant="h2">
              {inventory.title}
            </NameOfHome>
            {/* <Subtitle variant="body1" align="left">
              {inventory.description}
            </Subtitle> */}
            <Divider></Divider>
            <PriceAndButtonContainer>
              <Price variant="body1" color="primary">
                {inventory.price == 0
                  ? 'Contact Us for Pricing'
                  : `$${inventory.price.toLocaleString()}`}
              </Price>
              <Button
                component={Link}
                to={inventory.path}
                color={btnColor}
                variant={btnStyle}
              >
                Learn More
              </Button>
            </PriceAndButtonContainer>
          </HomeInformationContainer>
        </Card>
      ))}
    </InventoryContainer>
  )
}
Inventory2.displayName = 'Inventory 2'
Inventory2.moduleName = 'Inventory2'

export default Inventory2
