import React from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import AdminMenu from '~/Admin/AdminMenu'

import URL from '~/Utils/api_base'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    margin-bottom: 2rem;
  }
`
const CreateManufacturer = () => {
  const handleSubmit = e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const form = {
      name: data.get('name'),
      email: data.get('email')
    }

    fetch(`${URL}/manufacturers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then(x => x.json())
  }

  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Create New Manufacturer
        </Typography>
        <Form className="main-content" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            name="name"
            label="Name of Manufacturer"
            type="text"
          />

          <TextField
            fullWidth
            required
            name="email"
            type="email"
            label="Sales Email for Manufacturers"
            helperText="This should probably not be Jim@manufacturer.com but instead sales@manufacturer.com"
          />

          <ButtonGroup>
            <Button type="submit" color="primary">
              Create
            </Button>
            <Button type="reset">Reset</Button>
          </ButtonGroup>
        </Form>
      </Content>
    </Page>
  )
}

export default CreateManufacturer
