import URL from '~/Utils/api_base'

const prod = action =>
  fetch(`${URL}/users/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    // credentials: 'include',
    body: JSON.stringify(action.payload)
  }).then(x => x.json())

export default prod
