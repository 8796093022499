import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import DescriptionIcon from '@material-ui/icons/Description'

import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const FileContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`

const Card = styled.a`
  text-decoration: none;
  color: inherit;
  max-width: 320px;
  margin: 1rem;
  margin-bottom: 2rem;
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  color: ${({ color }) => `${color}` || `inherit`};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  align-items: center;
  justify-content: center;
`

const Subtitle = styled(Typography)``

const previewData = [
  { title: 'Default File', path: '' },
  { title: 'Default File', path: '' }
]

const FileList = ({ background, text, files, isPreview }) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <FileContainer color={bgColor}>
        {previewData.map((file, i) => (
          <Card href={file.path} target="_blank" key={i}>
            <IconContainer>
              <DescriptionIcon fontSize="large" />
            </IconContainer>
            <CardFooter color={textColor}>
              <Subtitle variant="body2">{file.title}</Subtitle>
            </CardFooter>
          </Card>
        ))}
      </FileContainer>
    )
  }

  return (
    <FileContainer color={bgColor}>
      {files &&
        files.map((file, i) => (
          <Card href={file.path} target="_blank" key={i}>
            <IconContainer>
              <DescriptionIcon fontSize="large" />
            </IconContainer>
            <CardFooter color={textColor}>
              <Subtitle variant="body2">{file.title}</Subtitle>
            </CardFooter>
          </Card>
        ))}
    </FileContainer>
  )
}

FileList.displayName = 'File List'
FileList.moduleName = 'FileList'

export default FileList
