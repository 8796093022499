import { createActions, createHandler } from '~/State/utils'
import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'

import requestProfileUpdate from '~/Requests/profile/updateProfile'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} UPDATE_PROFILE_REQUESTED
 * @prop {string} UPDATE_PROFILE_SUCCESS
 * @prop {string} UPDATE_PROFILE_FAILED
 */

/**
 * @type {Actions}
 */
export const actions = createActions('PROFILE', [
  'UPDATE_PROFILE_REQUESTED',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_FAILED'
])

export const defaultState = {
  profile: {
    admin: {
      first: null,
      last: null,
      phone: null,
      email: null
    },
    company: {
      companyName: null,
      claytonId: null,
      address: null,
      address2: null,
      city: null,
      state: null,
      zip: null
    },
    brand: {
      color1: '',
      color2: '',
      theme: 'Theme1'
    }
  }
}

export const rootLens = R.lensProp('profile')

export const adminLens = R.compose(
  rootLens,
  R.lensProp('admin')
)

export const settingsLens = R.compose(
  rootLens,
  R.lensProp('settings')
)

export const companyLens = R.compose(
  rootLens,
  R.lensProp('company')
)

export const brandLens = R.compose(
  rootLens,
  R.lensProp('brand')
)

export const themeLens = R.compose(
  rootLens,
  R.lensPath(['brand', 'theme'])
)

export const updateProfile = (state, action) =>
  R.compose(
    R.set(brandLens, action.payload.brand),
    R.set(companyLens, action.payload.company),
    R.set(settingsLens, action.payload.settings),
    R.set(adminLens, action.payload.admin)
  )(state)

export const reducer = createHandler({
  [actions.UPDATE_PROFILE_SUCCESS]: updateProfile
})

const updateProfile$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_PROFILE_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(requestProfileUpdate(action, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_PROFILE_FAILED,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_PROFILE_SUCCESS,
        payload: data.data,
        meta
      }
    })
  )

export const epics = [updateProfile$]
