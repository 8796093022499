import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const ModalBody = styled.div`
  position: absolute;
  width: 50%;
  min-height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  flex-direction: column;
`

const ModalContent = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: flex-end;
  background: #e4e6e9;
  padding: 2rem;
`

const HeaderText = styled(Typography)``

const CloseButton = styled(IconButton)``

const HeaderTextContainer = styled.div`
  margin: auto;
`

const Input = styled(TextField)`
  width: 100%;
`

const CreateButton = styled(Button)``

const CreateButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
`

const CreatePageModal = ({ open, onClose, onSelect }) => {
  const [title, setTitle] = useState('')

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <HeaderContainer>
          <HeaderTextContainer>
            <HeaderText variant="h5" align="center" gutterBottom>
              Create A New Page
            </HeaderText>
          </HeaderTextContainer>
          <CloseButton onClick={onClose} aria-label="close modal">
            <CloseIcon />
          </CloseButton>
        </HeaderContainer>
        <form onSubmit={() => onSelect(title)}>
          <ModalContent>
            <Input
              name="page-title"
              title="Page title"
              aria-label="Page Title"
              label="Page Title"
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </ModalContent>
          <CreateButtonContainer>
            <CreateButton
              align="right"
              color="primary"
              type="submit"
              variant="contained"
            >
              Create Page
            </CreateButton>
          </CreateButtonContainer>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default CreatePageModal
