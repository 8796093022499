import URL from '~/Utils/api_base'

const prod = (page, token) =>
  fetch(`${URL}/pages/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(page)
  }).then(x => x.json())

export default prod
