import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import * as R from 'ramda'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FormContainer = styled(FormControl)`
  min-width: 240px;
  margin-left: 2rem;
  margin-right: 2rem;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TextAndImageContainer = styled.div`
  display: flex;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const Reviews1Edit = ({ data, handleEdit }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    feature = '#eeeeee',
    header,
    moduleBackground = '#ffffff'
  } = data

  const [image, setImage] = useState(data.image)

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: [
        ...oldState.reviews,
        {
          content: '',
          name: '',
          subtitle: '',
          img: '',
          rating: '',
          alt_text: '',
          title_text: ''
        }
      ]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: R.adjust(index, R.mergeLeft({ [key]: value }), oldState.reviews)
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      reviews: R.remove(index, 1, oldState.reviews)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        <TextField
          name="header"
          id="header"
          aria-label="Header Text"
          title="Header Text"
          label="Header Text"
          margin="normal"
          variant="outlined"
          defaultValue={header}
        />
        {data.reviews &&
          data.reviews.map((review, i) => {
            return (
              <InnerContainer key={i}>
                <HeaderContainer>
                  <InputLabel>Review {i + 1}</InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <TextAndImageContainer>
                  <TextInputContainer>
                    <TextField
                      name={`review-${i}`}
                      id={`review-${i}`}
                      aria-label="Review"
                      title="Review"
                      label="Review"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'content', e.target.value)}
                      multiline
                      rows={10}
                      defaultValue={review.content}
                    />
                    <TextField
                      name={`name-${i}`}
                      id={`name-${i}`}
                      aria-label="Name and Location of Person"
                      title="Name and Location of Person"
                      label="Name and Location of Person"
                      margin="normal"
                      onChange={e => updateItem(i, 'name', e.target.value)}
                      variant="outlined"
                      defaultValue={review.name}
                    />
                    <TextField
                      name={`subtitle-${i}`}
                      id={`subtitle-${i}`}
                      aria-label="Subtitle"
                      title="Subtitle"
                      label="Subtitle"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'subtitle', e.target.value)}
                      defaultValue={review.subtitle}
                      multiline
                    />
                    <FormContainer>
                      <InputLabel htmlFor={`rating-${1}`}>
                        Select Rating
                      </InputLabel>
                      <Select
                        value={review.rating}
                        onChange={e => updateItem(i, 'rating', e.target.value)}
                        inputProps={{
                          name: `rating-${1}`,
                          id: `rating-${1}`
                        }}
                      >
                        <MenuItem default value="">
                          None
                        </MenuItem>
                        <MenuItem value={1}>One Star</MenuItem>
                        <MenuItem value={12}>Two Stars</MenuItem>
                        <MenuItem value={123}>Three Stars</MenuItem>
                        <MenuItem value={1234}>Four Stars</MenuItem>
                        <MenuItem value={12345}>Five Stars</MenuItem>
                      </Select>
                    </FormContainer>
                  </TextInputContainer>
                  <ImageUploadContainer>
                    <ImageUpload
                      name={`image-${i}`}
                      onChange={e => updateItem(i, 'image', e)}
                      image={review.image}
                    />
                    <input hidden value={review.image} name="img" />
                    <TextField
                      name={`alt-${i}`}
                      id={`alt-${i}`}
                      aria-label="Image Alt Text"
                      title="Image Alt Text"
                      label="Image Alt Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'alt_text', e.target.value)}
                      defaultValue={review.alt_text}
                    />
                    <TextField
                      name={`title-${i}`}
                      id={`title-${i}`}
                      aria-label="Image Title Text"
                      title="Image Title Text"
                      label="Image Title Text"
                      margin="normal"
                      onChange={e =>
                        updateItem(i, 'title_text', e.target.value)
                      }
                      variant="outlined"
                      defaultValue={review.title_text}
                    />
                  </ImageUploadContainer>
                </TextAndImageContainer>
              </InnerContainer>
            )
          })}
        <Button onClick={addItem}>Add Review</Button>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
          <ColorPicker color={feature} name="feature" title="Feature Color" />
          <ColorPicker
            color={moduleBackground}
            name="moduleBackground"
            title="Module Background Color"
          />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Reviews1Edit
