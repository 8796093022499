import URL from '~/Utils/api_base'

const prod = (blog, token) =>
  fetch(`${URL}/blogs/${blog.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify({
      ...blog,
      content: blog.content || '',
      image: blog.image || ''
    })
  }).then(x => x.json())

export default prod
