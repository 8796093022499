import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import DomainsList from './DomainsList'
import Domain from './Domain'
import CreateDomain from './CreateDomain'
import CreateManufacturer from './CreateManufacturer'
import Dashboard from './Dashboard'
import ManufacturerList from './ManufacturersList'
import EditManufacturer from './EditManufacturer'
import DomainLinks from './DomainLinks'
import Login from './Login'
import Logout from './Logout'
import Statuses from './Statuses'

const ProtectedRoute = props => {
  if (typeof window === 'undefined') {
    return 'Loading...'
  }

  if (!localStorage.getItem('token')) {
    return <Redirect to="/login" />
  }

  return <Route {...props} />
}

const Admin = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={Dashboard} />
      <ProtectedRoute exact path="/domains" component={DomainsList} />
      <ProtectedRoute
        exact
        path="/manufacturers"
        component={ManufacturerList}
      />
      <ProtectedRoute exact path="/create" component={CreateDomain} />
      <ProtectedRoute
        exact
        path="/create-manufacturer"
        component={CreateManufacturer}
      />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <ProtectedRoute path="/manufacturers/:id" component={EditManufacturer} />
      <ProtectedRoute path="/domains/:id" component={Domain} />
      <ProtectedRoute path="/statuses/:id" component={Statuses} />
      <ProtectedRoute path="/DomainLinks" component={DomainLinks} />
    </Switch>
  )
}

export default Admin
