import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import * as R from 'ramda'

import { byIdLens } from '~/State/ducks/blogs'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const FeaturedBlogContainer = styled.section`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-size: cover;
`

const TextContainer = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 80%;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const SubtitleTextContainer = styled.div`
  width: 80%;
  text-align: center;
`

const HeaderText = styled(Typography)`
  padding-top: 2rem;
`

const SubtitleText = styled(Typography)`
  padding-bottom: 1rem;
`

const BlogText = styled(Typography)``

const FeaturedBlog2 = ({
  id = '1',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'Click here to read more',
  isPreview,
  background,
  text,
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const blog = useSelector(state => {
    const item = R.view(byIdLens(id), state)

    return {
      title: 'My Default Title',
      image: 'https://i.stack.imgur.com/l60Hf.png',
      ...item
    }
  })

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  return (
    <FeaturedBlogContainer
      img={isPreview ? 'https://i.stack.imgur.com/l60Hf.png' : blog.image}
      color={bgColor}
    >
      <TextContainer color={textColor}>
        <BlogText variant="h4">FEATURED BLOG</BlogText>
        <HeaderText variant="h1" align="center" gutterBottom>
          {blog.title}
        </HeaderText>
        <SubtitleTextContainer>
          <SubtitleText variant="body1" align="center" gutterBottom>
            {subtitle}
          </SubtitleText>
        </SubtitleTextContainer>
      </TextContainer>
      {ctaText && (
        <Button
          component={Link}
          variant={btnStyle}
          to={blog.path}
          color={btnColor}
        >
          {ctaText}
        </Button>
      )}
    </FeaturedBlogContainer>
  )
}

FeaturedBlog2.displayName = 'Featured Blog 2'
FeaturedBlog2.moduleName = 'FeaturedBlog2'

export default FeaturedBlog2
