import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import InputContainer from './Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import ButtonStylePicker from './ButtonStylePicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const FeaturedImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const ColorInputWrapper = styled.div`
  padding-right: 2rem;
`

const ColorInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  flex: 1;
  background-color: #f1f2f4;
`

const ImageAndTextContainer = styled.div`
  display: flex;
`

const ImageUploadContainer = styled.div`
  flex: 1;
`

const Contact3Edit = ({ data, handleEdit }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    subtitle,
    account,
    formID,
    domain,
    formHeight,
    btnColor = 'primary',
    btnStyle = 'contained',
    header = 'Contact Us'
  } = data
  const [image, setImage] = useState(data.image)

  const [isSharpSpring, setIsSharpSpring] = useState(data.isSharpSpring)

  useEffect(() => {
    handleEdit(oldState => ({
      ...oldState,
      isSharpSpring
    }))
  }, [isSharpSpring])

  return (
    <InputContainer>
      <InnerContainer>
        <ImageAndTextContainer>
          <ImageUploadContainer>
            <FeaturedImageContainer>
              <Typography gutterBottom>Featured Image</Typography>
            </FeaturedImageContainer>
            <ImageUpload name="image" onChange={setImage} image={image} />
            <input hidden value={image} name="image" />
          </ImageUploadContainer>
          <TextInputContainer>
            <TextField
              name="header"
              id="header"
              aria-label="Header"
              title="Header"
              label="Header"
              margin="normal"
              variant="outlined"
              defaultValue={header}
            />
            <TextField
              name="subtitle"
              id="subtitle"
              aria-label="Subtitle"
              title="Subtitle"
              label="Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={subtitle}
              multiline
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isSharpSpring}
                  onChange={e => setIsSharpSpring(e.target.checked)}
                  inputProps={{
                    'aria-label': 'SharpSpring Switch',
                    name: 'isSharpSpring',
                    id: 'isSharpSpring'
                  }}
                />
              }
              label="SharpSpring Contact Form"
            />
            {isSharpSpring ? (
              <React.Fragment>
                <TextField
                  name="account"
                  id="account"
                  aria-label="SharpSpring Account"
                  title="SharpSpring Account"
                  label="SharpSpring Account"
                  margin="normal"
                  variant="outlined"
                  defaultValue={account}
                />
                <TextField
                  name="formID"
                  id="formID"
                  aria-label="SharpSpring Form ID"
                  title="SharpSpring Form ID"
                  label="SharpSpring Form ID"
                  margin="normal"
                  variant="outlined"
                  defaultValue={formID}
                />
                <TextField
                  name="domain"
                  id="domain"
                  aria-label="SharpSpring Domain"
                  title="SharpSpring Domain"
                  label="SharpSpring Domain"
                  margin="normal"
                  variant="outlined"
                  defaultValue={domain}
                />
                <TextField
                  name="formHeight"
                  id="formHeight"
                  aria-label="Form Height in Pixels"
                  title="Form Height in Pixels"
                  label="Form Height in Pixels"
                  margin="normal"
                  variant="outlined"
                  defaultValue={formHeight}
                />
              </React.Fragment>
            ) : null}
          </TextInputContainer>
        </ImageAndTextContainer>
        <Typography gutterBottom>Don't have an image?</Typography>
        <ColorInputContainer>
          <ColorInputWrapper>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ButtonPicker
              color={btnColor}
              name="btnColor"
              title="Button Color"
            />
          </ColorInputWrapper>
          <ColorInputWrapper>
            <ButtonStylePicker
              style={btnStyle}
              name="btnStyle"
              title="Button Style"
            />
          </ColorInputWrapper>
        </ColorInputContainer>
      </InnerContainer>
    </InputContainer>
  )
}

export default Contact3Edit
