import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import * as R from 'ramda'

import { byIdLens } from '~/State/ducks/blogs'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const FeaturedBlogContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 7rem;
  padding-bottom: 7rem;
  padding-left: 2rem;
  margin-right: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ color }) => `${color}` || `black`};
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    border-right: none;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 4rem;
  padding-right: 4rem;
  flex: 2;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    flex: 1;
    margin-left: 0;
    padding-right: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  margin-right: 5rem;

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const CallToActionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

const HeaderText = styled(Typography)`
  padding-top: 2rem;
`

const BlogText = styled(Typography)``

const SubtitleText = styled(Typography)``

const FeaturedBlog1 = ({
  id = '1',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'Click to read more',
  background,
  text,
  image,
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const blog = useSelector(state => {
    const item = R.view(byIdLens(id), state)

    return {
      title: 'My Default Title',
      ...item
    }
  })

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  return (
    <FeaturedBlogContainer img={image} color={bgColor}>
      <LeftContainer color={textColor}>
        <TextContainer>
          <BlogText variant="h4">FEATURED BLOG</BlogText>
          <HeaderText variant="h1" gutterBottom>
            {blog.title}
          </HeaderText>
        </TextContainer>
      </LeftContainer>

      <RightContainer color={textColor}>
        <SubtitleText variant="body1" color="inherit" gutterBottom>
          {subtitle}
        </SubtitleText>
        <CallToActionContainer>
          {ctaText && (
            <Button
              component={Link}
              variant={btnStyle}
              to={blog.path}
              color={btnColor}
            >
              {ctaText}
            </Button>
          )}
        </CallToActionContainer>
      </RightContainer>
    </FeaturedBlogContainer>
  )
}

FeaturedBlog1.displayName = 'Featured Blog 1'
FeaturedBlog1.moduleName = 'FeaturedBlog1'

export default FeaturedBlog1
