import { createActions, createHandler } from '~/State/utils'
import * as R from 'ramda'
import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, tap, withLatestFrom } from 'rxjs/operators'
import Alert from 'react-s-alert'

import createBlog from '~/Requests/blogs/createBlog'
import updateBlog from '~/Requests/blogs/updateBlog'
import deleteBlog from '~/Requests/blogs/deleteBlog'
import slugify from '~/Utils/slugify'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_BLOG_REQUEST
 * @prop {string} CREATE_BLOG_SUCCESS
 * @prop {string} CREATE_BLOG_FAILURE
 * @prop {string} UPDATE_BLOG_REQUEST
 * @prop {string} UPDATE_BLOG_SUCCESS
 * @prop {string} UPDATE_BLOG_FAILURE
 * @prop {string} DELETE_BLOG_REQUEST
 * @prop {string} DELETE_BLOG_SUCCESS
 * @prop {string} DELETE_BLOG_FAILURE
 *
 */

/**
 * @type {Actions}
 */

export const actions = createActions('BLOGS', [
  'CREATE_BLOG_REQUEST',
  'CREATE_BLOG_SUCCESS',
  'CREATE_BLOG_FAILURE',
  'UPDATE_BLOG_REQUEST',
  'UPDATE_BLOG_SUCCESS',
  'UPDATE_BLOG_FAILURE',
  'DELETE_BLOG_REQUEST',
  'DELETE_BLOG_SUCCESS',
  'DELETE_BLOG_FAILURE'
])

export const rootLens = R.lensProp('blogs')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)
export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  blogs: {
    ids: {
      1: {
        id: '1',
        title: 'Blog Post 1',
        path: '/abc',
        published: true,
        image: ''
      },
      2: {
        id: '2',
        title: 'Another Blog Post Written by a User',
        path: '/def',
        published: false,
        image: '',
        content: '<p>goodbye</p>'
      },
      3: {
        id: '3',
        title: 'This is Also a Blog Post',
        path: '/ghi',
        published: true,
        image: '',
        content: '<p>goodbye</p>'
      },
      4: {
        id: '4',
        title: 'Blog Post 4',
        path: '/jkl',
        published: false,
        image: '',
        content: '<p><strong>hello</strong></p>'
      },
      5: {
        id: '5',
        title: 'Yes, It is Another Blog Post',
        path: '/mno',
        published: false,
        image: '',
        content: '<p>goodbye</p>'
      }
    }
  }
}

export const reducer = createHandler({
  [actions.CREATE_BLOG_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),

  [actions.UPDATE_BLOG_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),

  [actions.DELETE_BLOG_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const createBlog$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.CREATE_BLOG_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(
        createBlog(
          {
            ...action.payload,
            path: slugify(action.payload.title)
          },
          state.token
        )
      ).pipe(map(answer => ({ ...answer, history: action.meta })))
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.CREATE_BLOG_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.CREATE_BLOG_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

export const updateBlog$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_BLOG_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(updateBlog(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_BLOG_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.UPDATE_BLOG_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const deleteBlog$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_BLOG_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(deleteBlog(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.DELETE_BLOG_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.DELETE_BLOG_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const redirectPage$ = action$ =>
  action$.pipe(
    ofType(actions.CREATE_BLOG_SUCCESS),
    tap(action => {
      action.history.push(`/admin/blog?page=${action.payload.id}`)
    }),
    map(() => ({
      type: '@@MISC/REDIRECTED_PAGE'
    }))
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_BLOG_SUCCESS, actions.UPDATE_BLOG_SUCCESS),
    tap(() => {
      Alert.success('Blog Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_BLOG_FAILURE, actions.UPDATE_BLOG_FAILURE),
    tap(() => {
      Alert.error('Error Saving blog. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  createBlog$,
  updateBlog$,
  deleteBlog$,
  redirectPage$,
  showSuccessNotification$,
  showErrorNofitication$
]
