import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { BrowserRouter, useParams } from 'react-router-dom'
import { Provider } from 'react-redux'

import App from '~/App'
import { mungeAPIIntoStore as mungeData } from '~/Server/ssr/mungeAdminApiIntoStore'
import createStore from '~/State/create'
import AdminMenu from '~/Admin/AdminMenu'

const Page = styled.div`
  height: 100%;
`

const Domain = () => {
  const params = useParams()
  const domain_id = params.id
  const [domain, setDomain] = useState()
  const [error, setError] = useState()
  const [token, setToken] = useState()
  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    setToken(localStorage.getItem('token'))
    setCurrentUser(localStorage.getItem('currentUser'))
  }, [])

  useEffect(() => {
    fetch(`/domains/${domain_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(x => x.json())
      .then(({ data, error }) => {
        if (error) {
          setError(error)
        } else {
          setDomain(data)
        }
      })
  }, [])

  if (!domain && !error) {
    return 'loading...'
  }

  if (error) {
    return error.message
  }

  return (
    <Page>
      <AdminMenu />
      <BrowserRouter basename={`/domains/${domain_id}`}>
        <Provider
          store={createStore({
            ...mungeData(domain),
            baseName: `/domains/${domain_id}`,
            token,
            currentSuperUser: currentUser
          })}
        >
          <App />
        </Provider>
      </BrowserRouter>
    </Page>
  )
}

export default Domain
