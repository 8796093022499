import URL from '~/Utils/api_base'
const prod = (user, token) =>
  fetch(`${URL}/users/${user.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(user)
  }).then(x => x.json())

export default prod
