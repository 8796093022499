import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { connect, useSelector, useDispatch } from 'react-redux'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Add from '@material-ui/icons/Add'
import styled from '@emotion/styled'
import { Typography, Fab } from '@material-ui/core'

import * as R from 'ramda'
import uuid from 'uuid/v4'

import AddUserForm from './AddUser'
import EditUser from './EditUser'
import { idsLens, actions } from '~/State/ducks/users'

const Inputs = styled.div`
  display: flex;
  width: 100%;

  .MuiFormControl-root {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const Input = styled(TextField)`
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const UserHeader = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const UsersContainer = styled.div`
  margin-top: 3rem;
  padding: 0.5rem;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
`

const AddUserButton = styled(Fab)`
  margin-top: 1rem;
`

const ItemWrapper = styled.div`
  flex: 1;
`

const ActionWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

const ModalBody = styled.div`
  width: 70%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const ModalActions = styled.div`
  margin: 0 auto;
`

const Profile = ({ admin = {} }) => {
  const dispatch = useDispatch()
  const users = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const [addUserForms, setAddUserForms] = useState([])
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false)
  const currentSuperUser = useSelector(state => state.currentSuperUser)
  const currentUser = useSelector(state => state.users.currentUser)
  const currentUserObject = useSelector(state => state.users.ids[currentUser])

  const addForm = () => {
    setAddUserForms(oldList => [...oldList, { id: uuid() }])
  }

  const removeForm = i => {
    setAddUserForms(oldList => R.remove(i, 1, oldList))
  }

  const deleteUser = id => {
    dispatch({
      type: actions.DELETE_USER_REQUEST,
      payload: {
        id
      }
    })
  }
  return (
    <React.Fragment>
      <InputsContainer>
        <Inputs>
          <Input
            id="firstName"
            name="firstName"
            label="First Name"
            aria-label="First Name"
            title="First Name"
            margin="normal"
            variant="outlined"
            defaultValue={admin.first}
          />
          <Input
            id="lastName"
            name="lastName"
            label="Last Name"
            aria-label="Last Name"
            title="Last Name"
            margin="normal"
            defaultValue={admin.last}
            variant="outlined"
          />
        </Inputs>
        <Inputs>
          <Input
            id="email"
            name="email"
            label="Admin Email"
            aria-label="Admin Email"
            title="Email"
            type="email"
            margin="normal"
            variant="outlined"
            defaultValue={admin.email}
            required
          />
          <Input
            id="contactEmail"
            name="contactEmail"
            label="Contact Email"
            aria-label="Contact Email"
            title="Contact Email"
            margin="normal"
            variant="outlined"
            defaultValue={admin.contact_email}
          />
          <Input
            id="phone"
            name="phone"
            label="Admin Phone"
            aria-label="Admin Phone"
            title="Admin Phone"
            margin="normal"
            variant="outlined"
            defaultValue={admin.phone}
          />
        </Inputs>
      </InputsContainer>

      {(currentUserObject && currentUserObject.role !== 'Editor') ||
      currentSuperUser ? (
        <React.Fragment>
          <AddUsersContainer>
            <Typography variant="h5">Add Users</Typography>
            <AddUserButton size="small" onClick={addForm}>
              <Add />
            </AddUserButton>
            {addUserForms.map((form, i) => (
              <AddUserForm key={form.id} index={i} removeForm={removeForm} />
            ))}
          </AddUsersContainer>
          <UsersContainer>
            <UserHeader>
              <ItemWrapper>
                <Typography>Name</Typography>
              </ItemWrapper>
              <ItemWrapper>
                <Typography>Account Email</Typography>
              </ItemWrapper>
              <ItemWrapper>
                <Typography>Contact Email</Typography>
              </ItemWrapper>
              <ItemWrapper>
                <Typography>User Role</Typography>
              </ItemWrapper>
              <ActionWrapper>Actions</ActionWrapper>
            </UserHeader>
            {users.map((user, i) => (
              <EditUser
                key={i}
                confirmDeleteUser={setConfirmDeleteUser}
                userInfo={user}
              />
            ))}
          </UsersContainer>
        </React.Fragment>
      ) : null}
      <Modal
        open={!!confirmDeleteUser}
        onClose={() => setConfirmDeleteUser(false)}
      >
        <ModalBody>
          <Typography align="center" variant="h5" gutterBottom>
            Are you sure you want to delete the following account:
          </Typography>
          {confirmDeleteUser && (
            <React.Fragment>
              <Typography variant="subtitle2" align="center" gutterBottom>
                {
                  users.filter(({ id }) => id === confirmDeleteUser)[0]
                    .first_name
                }{' '}
                {
                  users.filter(({ id }) => id === confirmDeleteUser)[0]
                    .last_name
                }
              </Typography>
              <Typography variant="subtitle2" align="center" gutterBottom>
                {users.filter(({ id }) => id === confirmDeleteUser)[0].email}
              </Typography>
            </React.Fragment>
          )}
          <ModalActions>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => setConfirmDeleteUser(false)}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  deleteUser(confirmDeleteUser)

                  setConfirmDeleteUser()
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ModalActions>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  users: state.users
})

export default connect(mapStateToProps)(Profile)
