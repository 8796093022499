import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/locations'
// import groupHours from '~/Components/utils/groupedHours'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const LocationsContainer = styled.section`
  width: 100%;
  display: flex;
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const Card = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 3rem;
  padding-left: 1rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const CardAndPhotoContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const PictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  align-items: center;
  padding-top: 2rem;
  justify-content: flex-start;

  img {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0;
    cursor: pointer;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const LocationName = styled(Typography)``

const OurLocations = styled(Typography)`
  width: 100%;
  text-align: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? 'left'
      : 'center'};
  font-family: ${({ theme }) => theme == 'Theme6' && 'DM Sans'} !important;
  letter-spacing: ${({ theme }) =>
    (theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8') && '1px'};
`

const Address = styled(Typography)`
  padding-bottom: 1rem;
`

const Phone = styled(Typography)`
  padding-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 2rem;
  width: 80%;
  padding-bottom: 2rem;
`

const ImgContainer = styled.a`
  max-height: 150px;
`

const LocationHours = styled(Typography)``

const WeekdayHours = styled(Typography)``

const Divider = styled.hr`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? '5px'
      : `1px`};
  background-color: ${({ color }) => `${color}` || `inherit`};
`

const AddressTag = styled.address`
  font-style: normal;
`

const LogoContainer = styled.div`
  max-width: 272px;
  max-height: 200px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const previewData = [
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: false,
        openTime: '',
        closeTime: ''
      },
      monday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  },
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: false,
        openTime: '',
        closeTime: ''
      },
      monday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  }
]

const Locations2 = ({
  ids = [],
  isPreview,
  header = 'Our Locations',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor.',
  background,
  text,
  feature,
  image,
  showLogo = false
}) => {
  const [protocol, setProtocol] = useState('http')
  const locations = useSelector(state => {
    const idHash = R.view(idsLens, state)

    return ids.filter(id => idHash[id]).map(id => idHash[id])
  })

  const company = useSelector(state => state.profile.company)

  useEffect(() => {
    if (
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPod') != -1 ||
      navigator.platform.indexOf('iPad') != -1
    ) {
      setProtocol('maps')
    } else {
      setProtocol('http')
    }
  }, [])
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  if (isPreview) {
    return (
      <LocationsContainer>
        <OurLocations
          align={
            currentThemeEnum == 'Theme6' ||
            currentThemeEnum == 'Theme7' ||
            currentThemeEnum == 'Theme8'
              ? 'left'
              : 'center'
          }
          theme={currentThemeEnum}
          variant="h2"
          component="p"
        >
          {header}
        </OurLocations>
        <Subtitle
          align={
            currentThemeEnum == 'Theme6' ||
            currentThemeEnum == 'Theme7' ||
            currentThemeEnum == 'Theme8'
              ? 'left'
              : 'center'
          }
          variant="body1"
        >
          {subtitle}
        </Subtitle>
        <CardContainer>
          {previewData.map(({ name, address, phone, hours }, i) => {
            // const groupedHours = groupHours(hours)

            return (
              <CardAndPhotoContainer key={i}>
                <PictureContainer>
                  <img src="https://assets0.biggerpockets.com/uploads/wordpress_blog_post/image/6846/featured_mobile-home-title-issues.jpg"></img>
                </PictureContainer>
                <Card>
                  <AddressTag>
                    <LocationName variant="h3">{name}</LocationName>
                    <Divider theme={currentThemeEnum}></Divider>
                    <Address variant="body2" color="textPrimary">
                      {address}
                    </Address>
                    <Phone variant="body2" color="textPrimary">
                      {phone}
                    </Phone>
                    <LocationHours variant="body2" color="textPrimary">
                      <strong>Location Hours:</strong>
                    </LocationHours>
                    {/* {groupedHours.grouped.map(day => (
                      <WeekdayHours variant="body2" key={day}>
                        {day} {groupedHours.hours[day].open} -{' '}
                        {groupedHours.hours[day].close}
                      </WeekdayHours>
                    ))} */}
                    {hours.sunday.isOpened && (
                      <WeekdayHours>
                        Sunday: {hours.sunday.openTime} -{' '}
                        {hours.sunday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.monday.isOpened && (
                      <WeekdayHours>
                        Monday: {hours.monday.openTime} -{' '}
                        {hours.monday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.tuesday.isOpened && (
                      <WeekdayHours>
                        Tuesday: {hours.tuesday.openTime} -{' '}
                        {hours.tuesday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.wednesday.isOpened && (
                      <WeekdayHours>
                        Wednesday: {hours.wednesday.openTime} -{' '}
                        {hours.wednesday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.thursday.isOpened && (
                      <WeekdayHours>
                        Thursday: {hours.thursday.openTime} -{' '}
                        {hours.thursday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.friday.isOpened && (
                      <WeekdayHours>
                        Friday: {hours.friday.openTime} -{' '}
                        {hours.friday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.saturday.isOpened && (
                      <WeekdayHours>
                        Saturday: {hours.saturday.openTime} -{' '}
                        {hours.saturday.closeTime}
                      </WeekdayHours>
                    )}
                  </AddressTag>
                </Card>
              </CardAndPhotoContainer>
            )
          })}
        </CardContainer>
      </LocationsContainer>
    )
  }

  return (
    <LocationsContainer img={image} textColor={textColor} color={bgColor}>
      <OurLocations
        align={
          currentThemeEnum == 'Theme6' ||
          currentThemeEnum == 'Theme7' ||
          currentThemeEnum == 'Theme8'
            ? 'left'
            : 'center'
        }
        theme={currentThemeEnum}
        variant="h2"
      >
        {header}
      </OurLocations>
      <Subtitle
        align={
          currentThemeEnum == 'Theme6' ||
          currentThemeEnum == 'Theme7' ||
          currentThemeEnum == 'Theme8'
            ? 'left'
            : 'center'
        }
        variant="body1"
      >
        {subtitle}
      </Subtitle>
      <CardContainer>
        {locations.map(
          (
            { name, address, address2, city, state, zip, phone, hours, image },
            i
          ) => {
            // const groupedHours = groupHours(hours)

            return (
              <CardAndPhotoContainer key={i}>
                <PictureContainer>
                  <ImgContainer
                    href={`${protocol}://maps.google.com/maps/place/${address}+${city}+${state}+${zip}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={image} alt={name} title={name} />
                  </ImgContainer>
                </PictureContainer>
                <Card>
                  <AddressTag>
                    {!showLogo && (
                      <LocationName variant="h3">{name}</LocationName>
                    )}
                    {showLogo && (
                      <LogoContainer>
                        <img src={brand.logo} alt={`${company.name} Logo`} />
                      </LogoContainer>
                    )}
                    <Divider color={ftColor} theme={currentThemeEnum} />
                    <Address variant="body2">
                      {address}
                      {address2 !== '' ? ' ' + address2 : ''}
                      <br /> {city}, {state} {zip}
                    </Address>
                    <Phone variant="body2">{phone}</Phone>
                    <Divider color={ftColor} theme={currentThemeEnum} />
                    <LocationHours variant="body2">
                      <strong>Location Hours:</strong>
                    </LocationHours>
                    {/* {groupedHours.grouped.map(day => (
                      <WeekdayHours variant="body2" key={day}>
                        {day} {groupedHours.hours[day].open} -{' '}
                        {groupedHours.hours[day].close}
                      </WeekdayHours>
                    ))} */}
                    {hours.sunday.isOpened && (
                      <WeekdayHours>
                        Sunday: {hours.sunday.openTime} -{' '}
                        {hours.sunday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.monday.isOpened && (
                      <WeekdayHours>
                        Monday: {hours.monday.openTime} -{' '}
                        {hours.monday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.tuesday.isOpened && (
                      <WeekdayHours>
                        Tuesday: {hours.tuesday.openTime} -{' '}
                        {hours.tuesday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.wednesday.isOpened && (
                      <WeekdayHours>
                        Wednesday: {hours.wednesday.openTime} -{' '}
                        {hours.wednesday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.thursday.isOpened && (
                      <WeekdayHours>
                        Thursday: {hours.thursday.openTime} -{' '}
                        {hours.thursday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.friday.isOpened && (
                      <WeekdayHours>
                        Friday: {hours.friday.openTime} -{' '}
                        {hours.friday.closeTime}
                      </WeekdayHours>
                    )}
                    {hours.saturday.isOpened && (
                      <WeekdayHours>
                        Saturday: {hours.saturday.openTime} -{' '}
                        {hours.saturday.closeTime}
                      </WeekdayHours>
                    )}
                  </AddressTag>
                </Card>
              </CardAndPhotoContainer>
            )
          }
        )}
      </CardContainer>
    </LocationsContainer>
  )
}
Locations2.displayName = 'Locations 2'
Locations2.moduleName = 'Locations2'

export default Locations2
