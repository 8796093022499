import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, tap, withLatestFrom } from 'rxjs/operators'
import Alert from 'react-s-alert'

import * as R from 'ramda'

import createNewCategory from '~/Requests/categories/createNew'
import updateCategory from '~/Requests/categories/updateCategory'
import deleteCategory from '~/Requests/categories/deleteCategory'

import { createActions, createHandler } from '~/State/utils'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_CATEGORY_REQUEST
 * @prop {string} CREATE_CATEGORY_SUCCESS
 * @prop {string} CREATE_CATEGORY_FAILURE
 * @prop {string} UPDATE_CATEGORY_REQUEST
 * @prop {string} UPDATE_CATEGORY_SUCCESS
 * @prop {string} UPDATE_CATEGORY_FAILURE
 * @prop {string} DELETE_CATEGORY_REQUEST
 * @prop {string} DELETE_CATEGORY_SUCCESS
 * @prop {string} DELETE_CATEGORY_FAILURE
 *
 *
 *
 */

/**
 * @type {Actions}
 */
export const actions = createActions('CATEGORIES', [
  'CREATE_CATEGORY_REQUEST',
  'CREATE_CATEGORY_SUCCESS',
  'CREATE_CATEGORY_FAILURE',
  'UPDATE_CATEGORY_REQUEST',
  'UPDATE_CATEGORY_SUCCESS',
  'UPDATE_CATEGORY_FAILURE',
  'DELETE_CATEGORY_REQUEST',
  'DELETE_CATEGORY_SUCCESS',
  'DELETE_CATEGORY_FAILURE'
])

export const rootLens = R.lensProp('categories')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)
export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  categories: {
    ids: {
      '0191f26d-294a-42d8-a907-73012d3598f7': {
        id: '0191f26d-294a-42d8-a907-73012d3598f7',
        name: 'Category 1',
        description:
          'I am the description of this category. I might be two or three sentences'
      },
      '308bb1a4-f244-40f1-ab2e-bb75833eec80': {
        id: '308bb1a4-f244-40f1-ab2e-bb75833eec80',
        name: 'Category 2',
        description: 'Some description that needs to be added here'
      },
      'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8': {
        id: 'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8',
        name: 'Category 3',
        description:
          'Look at me, maw! I am a description and I was typed by hand!'
      },
      'f0a877c1-1cc5-47da-94cd-5ce4768a479e': {
        id: 'f0a877c1-1cc5-47da-94cd-5ce4768a479e',
        name: 'Category 4',
        description:
          'Oh my goodness, Becky. Look at these categories. They are so big'
      }
    }
  }
}

export const reducer = createHandler({
  [actions.CREATE_CATEGORY_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.UPDATE_CATEGORY_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_CATEGORY_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const createCategory$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.CREATE_CATEGORY_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(createNewCategory(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.CREATE_CATEGORY_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.CREATE_CATEGORY_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const updateCategory$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_CATEGORY_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(updateCategory(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_CATEGORY_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_CATEGORY_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const deleteCategory$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.DELETE_CATEGORY_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(deleteCategory(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.DELETE_CATEGORY_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.DELETE_CATEGORY_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_CATEGORY_SUCCESS, actions.UPDATE_CATEGORY_SUCCESS),
    tap(() => {
      Alert.success('Category Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_CATEGORY_FAILURE, actions.UPDATE_CATEGORY_FAILURE),
    tap(() => {
      Alert.error('Error Saving Category. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  createCategory$,
  updateCategory$,
  deleteCategory$,
  showSuccessNotification$,
  showErrorNofitication$
]
