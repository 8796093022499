import React from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Alert from 'react-s-alert'
import styled from '@emotion/styled'
import { useStore } from 'react-redux'

import { actions, get } from '~/State/ducks/users'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Form = styled(Paper)`
  width: 95%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ResetLink = styled(Link)`
  text-decoration: none;
`

const successAlert = () =>
  Alert.success('Successfully Logged In.', {
    effect: 'genie',
    position: 'bottom-right',
    timeout: 1500
  })

const errorAlert = error =>
  Alert.error(error.message, {
    effect: 'genie',
    position: 'bottom-right',
    timeout: 2500
  })

const Login = () => {
  const history = useHistory()
  const store = useStore()
  React.useEffect(() => {
    return store.subscribe(() => {
      const state = store.getState()
      if (get.error(state)) {
        errorAlert(get.error(state))
      } else if (get.currentUser(state)) {
        successAlert()
        history.push('/admin')
      }
    })
  }, [])

  const dispatch = store.dispatch
  const handleSubmit = e => {
    e.preventDefault()
    // Grab Form Data
    const formdata = new FormData(e.target)
    // Create Object from it
    const data = {
      password: formdata.get('password'),
      email: formdata.get('email')
    }

    dispatch({
      type: actions.LOGIN_USER_REQUESTED,
      payload: data
    })
  }

  return (
    <Page>
      <Helmet title="PowerSlide Login" />
      <Typography variant="h3" gutterBottom align="center">
        Login
      </Typography>
      <Form component="form" onSubmit={handleSubmit}>
        <TextField
          name="email"
          id="email"
          type="email"
          aria-label="Email"
          title="Email"
          label="Email"
          margin="normal"
          variant="outlined"
          helperText="You must give one of Email or Username"
        />
        <TextField
          name="password"
          id="password"
          type="password"
          aria-label="Password"
          title="Password"
          label="Password"
          margin="normal"
          variant="outlined"
          required
        />
        <Buttons>
          <Button color="primary" type="submit">
            Sign In
          </Button>
          <ResetLink to="/password-reset">
            <Typography>Forgot Password?</Typography>
          </ResetLink>

          <Button color="secondary" type="reset">
            Clear Form
          </Button>
        </Buttons>
      </Form>
    </Page>
  )
}

export default Login
