import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import ColorPicker from './ColorPicker'
import ButtonPicker from './ButtonPicker'
import styled from '@emotion/styled'

import ButtonStylePicker from './ButtonStylePicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2rem 0;
`
const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-direction: column;
  height: 326px;
  border-radius: 1%;
`

const PopupEdit = ({ data, handleEdit }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    content,
    account,
    formID,
    domain,
    formHeight,
    btnColor = 'primary',
    btnStyle = 'contained',
    header,
    popupTime = 1
  } = data
  const [image, setImage] = useState(data.image)

  return (
    <InputContainer>
      <InnerContainer>
        <TextInputContainer>
          <TextField
            name="header"
            id="header"
            aria-label="Popup Title"
            title="Popup Title"
            label="Popup Title"
            margin="normal"
            variant="outlined"
            defaultValue={header}
          />
          <TextField
            name="content"
            id="content"
            aria-label="Content"
            title="Content"
            label="Popup Content"
            margin="normal"
            variant="outlined"
            defaultValue={content}
            multiline
            rows={10}
          />
        </TextInputContainer>
        <ColorInputContainer>
          <TextField
            name="popupTime"
            id="popupTime"
            aria-label="Time"
            title="Time"
            label="Time until popup displays"
            type="number"
            margin="normal"
            variant="outlined"
            defaultValue={popupTime}
          />
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
          <ColorPicker color={text} name="text" title="Text Color" />
        </ColorInputContainer>
      </InnerContainer>

      <Typography align="center">Main Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default PopupEdit
