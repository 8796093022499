import React, { useState } from 'react'
import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

const FormContainer = styled(FormControl)`
  min-width: 120px;
  margin-left: 2rem;
  margin-right: 2rem;
`

const SelectWrapper = styled(Select)`
  width: 150px;
`

const ButtonStylePicker = ({ title, style, name }) => {
  const [current, setCurrent] = useState(style || 'contained')

  return (
    <div>
      <input hidden value={current} name={name} />

      <FormContainer>
        <InputLabel htmlFor={name}>{title}</InputLabel>
        <SelectWrapper
          value={current}
          data-cy={name}
          onChange={e => setCurrent(e.target.value)}
          inputProps={{
            name: name,
            id: name
          }}
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="outlined">Outlined</MenuItem>
          <MenuItem value="contained">Contained</MenuItem>
        </SelectWrapper>
      </FormContainer>
    </div>
  )
}

export default ButtonStylePicker
