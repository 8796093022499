import React, { useState } from 'react'
import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import * as R from 'ramda'
import { useTheme } from '@material-ui/styles'

import PageTheme from '~/App/PageTheme'

const FormContainer = styled(FormControl)`
  min-width: 120px;
  margin-left: 2rem;
  margin-right: 2rem;
`

const SelectWrapper = styled(Select)`
  width: 150px;
`

const ColorSquare = styled.div`
  background: ${({ color }) => `${color}`};
  height: 1rem;
  width: 2rem;
  margin-left: 1rem;
`

const rootLens = R.lensProp('palette')

const primaryLens = R.compose(
  rootLens,
  R.lensPath(['primary', 'main'])
)

const secondaryLens = R.compose(
  rootLens,
  R.lensPath(['secondary', 'main'])
)

const ColorPicker = ({ title, color, name }) => {
  const theme = useTheme()

  const [current, setCurrent] = useState(color || '#ffffff')

  return (
    <div>
      <input hidden value={current} name={name} />

      <FormContainer>
        <InputLabel htmlFor={name}>{title}</InputLabel>
        <SelectWrapper
          value={current}
          data-cy={name}
          onChange={e => setCurrent(e.target.value)}
          inputProps={{
            name: name,
            id: name
          }}
        >
          <MenuItem value="#ffffff">
            white <ColorSquare color="#ffffff"></ColorSquare>
          </MenuItem>
          <MenuItem value="#eeeeee">
            light gray <ColorSquare color="#eeeeee"></ColorSquare>
          </MenuItem>
          <MenuItem value="#7a7a7a">
            gray <ColorSquare color="#7a7a7a"></ColorSquare>
          </MenuItem>
          <MenuItem value="#0c0c0c">
            black <ColorSquare color="#0c0c0c"></ColorSquare>
          </MenuItem>
          <MenuItem value="primary">
            Primary
            <ColorSquare color={R.view(primaryLens, theme)}></ColorSquare>
          </MenuItem>
          <MenuItem value="secondary">
            Secondary
            <ColorSquare color={R.view(secondaryLens, theme)}></ColorSquare>
          </MenuItem>
        </SelectWrapper>
      </FormContainer>
    </div>
  )
}

export const ColorPickerWithTheme = props => (
  <PageTheme fontFamily="Roboto">
    <ColorPicker {...props} />
  </PageTheme>
)

export default ColorPickerWithTheme
