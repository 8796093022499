import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Helmet from 'react-helmet'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import TextField from '@material-ui/core/TextField'

import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ArrowBack'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import MinusIcon from '@material-ui/icons/Remove'

import * as R from 'ramda'

import { idsLens, actions } from '~/State/ducks/categories'
import { idLens } from '~/State/ducks/domain'

const Main = styled.main`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
`

const InventoryHeader = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  background: #768390;
  color: #fff;
`

const BackButton = styled(Link)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`

const InventoryHeading = styled(Typography)``

const MainNav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: ${({ selected }) => (selected ? '#efefef' : 'inherit')};
  padding: 0.5rem 2rem;
  border-left: 1px solid rgba(33, 33, 33, 0.5);
  background: ${({ selected }) =>
    selected ? 'rgba(33, 33, 33, 0.5)' : 'inherit'};

  &:last-of-type {
    border-right: 1px solid rgba(33, 33, 33, 0.5);
  }

  :hover {
    background: rgba(33, 33, 33, 0.5);
    color: #efefef;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const CategoryList = styled(List)``

const Category = styled(ListItem)`
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const ListDisplay = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  li:first-of-type {
    border-top: 1px solid rgba(33, 33, 33, 0.5);
  }

  .MuiListItemSecondaryAction-root {
    button:first-of-type {
      margin-right: 1rem;
    }
  }
`

const ModalBody = styled.div`
  width: 70%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const ModalActions = styled.div`
  margin: 0 auto;
`

const CreateCategory = styled.div``

const CreateToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CreateCategoryForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5rem;
`

const CategoryFormItem = styled.div`
  flex-grow: 1;
  padding: 0.5rem;

  .MuiFormControl-root {
    width: 100%;
  }
`
const FormContent = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`

const CategoryManager = () => {
  const knownCategories = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const domain_id = useSelector(R.view(idLens))

  const [confirmDeleteCat, setConfirmDeleteCat] = useState(false)
  const [addNew, setAddNew] = useState(false)
  const [editMe, setEditMe] = useState([])
  const dispatch = useDispatch()

  const cancelAdd = () => {
    setAddNew(false)
    setConfirmDeleteCat(false)
    setEditMe()
  }

  const handleCategoryCreate = e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const info = {
      domain_id,
      name: data.get('category-name'),
      description: data.get('category-description')
    }

    dispatch({
      type: actions.CREATE_CATEGORY_REQUEST,
      payload: info
    })

    cancelAdd()
  }

  const handleCategoryUpdate = id => e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const info = {
      name: data.get('category-name'),
      description: data.get('category-description')
    }

    dispatch({
      type: actions.UPDATE_CATEGORY_REQUEST,
      payload: {
        id,
        ...info
      }
    })

    cancelAdd()
  }

  const toggleOpen = index => {
    setEditMe(oldList => {
      const oldIndex = oldList.indexOf(index)

      if (oldIndex > -1) {
        return oldList.slice(0, oldIndex).concat(oldList.slice(oldIndex + 1))
      }

      return [...oldList, index]
    })
  }

  return (
    <Main>
      <Helmet title="Category Manager" />
      <InventoryHeader>
        <BackButton to="/admin/inventory">
          <IconButton color="inherit">
            <BackIcon />
          </IconButton>
          <Typography variant="caption">Back to Inventory</Typography>
        </BackButton>
        <InventoryHeading variant="h2" align="center" color="inherit">
          Categories
        </InventoryHeading>
      </InventoryHeader>
      <MainNav>
        <NavItem to="/admin/inventory">
          <Typography>Inventory</Typography>
        </NavItem>
        <NavItem selected to="/admin/inventory/categories">
          <Typography>Category Manager</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/features">
          <Typography>Feature Manager</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/locations">
          <Typography>Location Manager</Typography>
        </NavItem>
      </MainNav>
      <CreateCategory>
        {addNew ? (
          <React.Fragment>
            <CreateToggle onClick={() => cancelAdd()}>
              <IconButton>
                <MinusIcon />
              </IconButton>
              <Typography>Cancel Create Category</Typography>
            </CreateToggle>
            <CreateCategoryForm onSubmit={handleCategoryCreate}>
              <FormContent>
                <CategoryFormItem>
                  <Typography>Details</Typography>
                  <TextField
                    id="category-name"
                    name="category-name"
                    label="Category Name"
                    required
                  />
                </CategoryFormItem>
                <CategoryFormItem>
                  <Typography>Description</Typography>
                  <TextField
                    multiline
                    rows={10}
                    id="category-description"
                    name="category-description"
                    label="Category Description"
                  />
                </CategoryFormItem>
              </FormContent>
              <Button type="submit" align="right" color="secondary">
                Save
              </Button>
            </CreateCategoryForm>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CreateToggle onClick={() => setAddNew(true)}>
              <IconButton>
                <AddIcon />
              </IconButton>
              <Typography>Create Category</Typography>
            </CreateToggle>
          </React.Fragment>
        )}
      </CreateCategory>
      <ListDisplay>
        <Typography variant="h4" gutterBottom align="left">
          Current Categories
        </Typography>
        <CategoryList>
          {knownCategories.map(({ id, name, description }, i) => (
            <React.Fragment key={id}>
              <Category>
                <ListItemText primary={name} secondary={description} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit category"
                    title="Edit Category"
                    onClick={() => toggleOpen(i)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete category"
                    title="Delete Category"
                    onClick={() => setConfirmDeleteCat(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </Category>
              {editMe && editMe.indexOf(i) > -1 && (
                <CreateCategoryForm onSubmit={handleCategoryUpdate(id)}>
                  <FormContent>
                    <CategoryFormItem>
                      <Typography>Details</Typography>
                      <TextField
                        id="category-name"
                        name="category-name"
                        label="Category Name"
                        defaultValue={name}
                        required
                      />
                    </CategoryFormItem>
                    <CategoryFormItem>
                      <Typography>Description</Typography>
                      <TextField
                        multiline
                        rows={10}
                        id="category-description"
                        name="category-description"
                        label="Category Description"
                        defaultValue={description}
                      />
                    </CategoryFormItem>
                  </FormContent>
                  <Button type="submit" align="right" color="secondary">
                    Update
                  </Button>
                </CreateCategoryForm>
              )}
            </React.Fragment>
          ))}
        </CategoryList>
      </ListDisplay>
      <Modal
        open={!!confirmDeleteCat}
        onClose={() => setConfirmDeleteCat(false)}
      >
        <ModalBody>
          <Typography align="center" variant="h5" gutterBottom>
            Are you sure you want to delete
          </Typography>
          {confirmDeleteCat && (
            <Typography variant="subtitle2" align="center" gutterBottom>
              {
                knownCategories.filter(({ id }) => id === confirmDeleteCat)[0]
                  .name
              }
            </Typography>
          )}
          <ModalActions>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => setConfirmDeleteCat(false)}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  dispatch({
                    type: actions.DELETE_CATEGORY_REQUEST,
                    payload: {
                      id: confirmDeleteCat
                    }
                  })

                  setConfirmDeleteCat()
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ModalActions>
        </ModalBody>
      </Modal>
    </Main>
  )
}

export default CategoryManager
