import React, { useRef, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { Link } from 'react-router-dom'

import { useTheme } from '@material-ui/styles'

import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { useMenu } from '~/Hooks/useMenu'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const MainContainer = styled.div`
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: stretch;
  height: 155px;

  @media only screen and (max-width: 1268px) {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 320px;
  height: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8' ||
    theme == 'Theme9'
      ? '90px'
      : `100px`};
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-top: 5px;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const AddressAndCallContainer = styled.div`
  display: flex;
  min-width: 280px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: ${({ theme }) => (theme == 'Theme5' ? 'none' : `auto`)};
  background: ${({ color }) => `${color}` || `inherit`};
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  padding: 0.5rem;
  width: 100%;
`

// Potentially used in theme
// const OtherAddressContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin-left: auto;
//   margin-right: 5%;
//   background: ${({ color }) => `${color}` || `inherit`};
//   color: ${({ textColor }) => `${textColor}` || `inherit`};
//   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
//   padding: 1rem;

//   @media only screen and (max-width: 1268px) {
//     width: 100%;
//     padding: 1rem;
//   }
// `

const MenuContainer = styled.nav`
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 960px) {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
`

const LogoAndMenuContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  li:hover {
    color: #8e8e8e;
  }
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const Drawer = styled.div`
  position: absolute;
  z-index: 1000;
  width: 350px;
  right: -350px;
  transition: 300ms right cubic-bezier(0.77, 0, 0.175, 1);
  padding: 2rem;
  background: ${({ bgColor }) => `${bgColor}` || `inherit`};

  @media only screen and (max-width: 350px) {
    width: 100%;
  }
`

const Address = styled(Typography)``

const MenuSubtitle = styled(Typography)`
  color: ${({ txtColor }) => `${txtColor}` || `inherit`};
`

const Phone = styled(Typography)`
  padding-left: 1rem;
  font-family: ${({ theme }) => theme == 'Theme5' && 'Gelasio'} !important;
  font-weight: ${({ theme }) => theme == 'Theme5' && 600} !important;
  white-space: nowrap;
  text-decoration: none !important;
  color: ${({ txtColor }) => `${txtColor}` || `inherit`};
`

const Or = styled(Typography)`
  padding-left: ${({ theme }) => (theme == 'Theme5' ? '1rem' : `none`)};
`

const CallUs = styled(Typography)`
  text-transform: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8' ||
    theme == 'Theme9'
      ? 'uppercase'
      : `none`};
`

const AddressTag = styled.address`
  font-style: normal;
  display: flex;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`

const CloseButton = styled(IconButton)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`} !important;
`

const HamburgerButton = styled(IconButton)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`} !important;

  svg {
    width: 2em;
    height: 2em;
  }
`

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Header4 = ({
  background,
  text,
  image,
  phone,
  phone2,
  feature = 'primary',
  menuText,
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  isPreview,
  menu: menuColor,
  menuSubtitle = 'Suspendisse maximus, est ac euismod aliqu am dui mi convallis nisi',
  menuSubtitleColor = 'primary',
  orText = 'Or'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const [overflowVisible, setOverflowVisible] = useState(false)

  const [open, setOpen] = useState(false)

  const theme = useTheme()

  const menu = useMenu()

  const pageHash = useSelector(state => state.pages.ids)

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const menuBgColor = getBasedOnTheme(menuColor, currentTheme, brand)

  const primary = theme.palette.primary.main

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const menuSubtextColor = getBasedOnTheme(
    menuSubtitleColor,
    currentTheme,
    brand
  )

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]

  const drawerRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        open &&
        drawerRef.current && !drawerRef.current.contains(event.target)
      ) {
        setOpen(false)
        setTimeout(() => setOverflowVisible(false), 300)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])
  // eslint-disable-next-line no-console
  console.log('bgColor' + bgColor)
  return (
    <MainContainer
      style={{ overflow: overflowVisible ? 'visible' : 'hidden' }}
      img={image}
      color={bgColor}
    >
      <AddressAndCallContainer
        theme={currentThemeEnum}
        textColor={textColor}
        color={ftColor || primary}
      >
        <AddressTag theme={currentThemeEnum}>
          <CallUs theme={currentThemeEnum} align="center">
            Call Us Today!
          </CallUs>
          <Phone
            component="a"
            href={`tel:${phone}`}
            theme={currentThemeEnum}
            align="center"
            color="inherit"
          >
            {phone}
          </Phone>
          {phone2 && (
            <React.Fragment>
              <Or
                style={{ paddingLeft: '1rem' }}
                theme={currentThemeEnum}
                align="center"
              >
                {orText}
              </Or>
              <Phone
                component="a"
                href={`tel:${phone2}`}
                theme={currentThemeEnum}
                align="center"
                color="inherit"
              >
                {phone2}
              </Phone>
            </React.Fragment>
          )}
          <Address align="center"></Address>
        </AddressTag>
      </AddressAndCallContainer>
      <HeaderContainer theme={currentThemeEnum} img={image} color={bgColor}>
        <LogoAndMenuContainer>
          <LogoContainer theme={currentThemeEnum} to={'/'}>
            {brand.logo && (
              <img src={brand.logo} alt={`${company.name} Logo`} />
            )}
            {!brand.logo && isPreview && (
              <img src={brand.logo || logo} alt={`${company.name} Logo`} />
            )}
          </LogoContainer>
          <MenuContainer>
            <HamburgerButton
              style={{ color: 'black' }}
              iconColor={menuTextColor}
              aria-controls="header1-menu"
              aria-haspopup="true"
              onClick={e => {
                setOpen(prevState => !prevState)
                setOverflowVisible(true)
              }}
              size="large"
            >
              <MenuIcon color="inherit" />
            </HamburgerButton>
          </MenuContainer>
        </LogoAndMenuContainer>
      </HeaderContainer>

      <Drawer
        ref={drawerRef}
        style={{
          right: open ? '0px' : '-350px'
        }}
        bgColor={menuBgColor ? menuBgColor : 'white'}
      >
        <CloseButtonContainer>
          <CloseButton
            style={{ color: 'black' }}
            iconColor={menuTextColor}
            aria-controls="header1-menu"
            aria-haspopup="true"
            onClick={e => {
              setOpen(prevState => !prevState)
              setTimeout(() => setOverflowVisible(false), 300)
            }}
          >
            <AddCircleOutlineIcon style={{ transform: 'rotate(45deg)' }} />
          </CloseButton>
        </CloseButtonContainer>

        <MenuItemContainer>
          {isPreview
            ? previewData.map(({ path, label }) => (
                <MenuLink to={path} key={path}>
                  <Typography component="li" variant="h3">
                    {label}
                  </Typography>
                </MenuLink>
              ))
            : menu.map(({ id, children }) => (
                <MenuLink
                  to={pageHash[id].path}
                  key={pageHash[id].path}
                  style={{ color: menuTextColor, paddingLeft: '0px' }}
                >
                  <Typography component="li" variant="h3" color="inherit">
                    {pageHash[id].title}
                  </Typography>
                  {children.map(({ id }) => (
                    <MenuLink
                      to={pageHash[id].path}
                      key={pageHash[id].path}
                      style={{ color: menuTextColor, paddingLeft: '2rem' }}
                    >
                      <Typography component="li" variant="h3" color="inherit">
                        {pageHash[id].title}
                      </Typography>
                    </MenuLink>
                  ))}
                </MenuLink>
              ))}
        </MenuItemContainer>
        <Phone
          style={{ paddingLeft: '0px' }}
          component="a"
          href={`tel:${phone}`}
          color="inherit"
          txtColor={menuSubtextColor}
        >
          {phone}
        </Phone>
        <MenuSubtitle txtColor={menuSubtextColor}>{menuSubtitle}</MenuSubtitle>
      </Drawer>
    </MainContainer>
  )
}

Header4.displayName = 'Header Hero 2'
Header4.moduleName = 'Header4'

export default Header4
