import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import { format, parseISO } from 'date-fns'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/blogs'
import { idsLens as userIds } from '~/State/ducks/users'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const BlogContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`

const Card = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  padding: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const BlogPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  padding-right: 10rem;
  color: ${({ color }) => `${color}`};

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    padding-right: 0;
    width: 100%;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    margin-left: 2rem;
    padding-right: 0;
    width: 100%;
  }
`

const CalendarDayContainer = styled.div`
  padding: 0.5rem;
  height: 80px;
  width: 80px;
  background: ${({ color }) => `${color}` || `inherit`};
  color: ${({ textColor }) => `${textColor}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
`

const BlogName = styled(Typography)`
  padding-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 1rem;
`

const Month = styled(Typography)``

const Day = styled(Typography)``

const previewData = [
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  }
]

const AltBlog1 = ({
  blogPosts = [],
  isPreview,
  background,
  text,
  feature,
  featureText,
  image = ''
}) => {
  const theme = useTheme()
  const secondary = theme.palette.secondary.main

  const blogs = useSelector(state => {
    const blogsById = R.view(idsLens, state)

    return blogPosts
      .filter(({ id }) => blogsById[id])
      .map(({ id, ...rest }) => ({
        title: 'My Default Title',
        ...rest,
        ...blogsById[id],
        id
      }))
  })

  const userHash = useSelector(state => {
    return R.view(userIds, state)
  })

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const ftTextColor = getBasedOnTheme(featureText, currentTheme, brand)

  if (isPreview) {
    return (
      <BlogContainer>
        {previewData.map((blog, i) => (
          <Card to={blog.path} key={i}>
            <div style={{ width: '90px', height: '90px' }}>
              <CalendarDayContainer color={secondary}>
                <Month color="inherit" variant="h6">
                  December
                </Month>
                <Day color="inherit" variant="h2">
                  25
                </Day>
              </CalendarDayContainer>
            </div>

            <BlogPostContainer>
              <BlogName variant="h3">{blog.title}</BlogName>
              <Typography variant="h6">Author Name</Typography>
              <Subtitle variant="body2" align="left">
                {blog.subtitle}
              </Subtitle>
            </BlogPostContainer>
          </Card>
        ))}
      </BlogContainer>
    )
  }

  return (
    <BlogContainer img={image} color={bgColor}>
      {blogs.map((blog, i) => (
        <Card to={blog.path} key={i}>
          <CalendarDayContainer textColor={ftTextColor} color={ftColor}>
            <Month variant="h6">
              {blog.last_updated && format(parseISO(blog.last_updated), 'MMMM')}
            </Month>
            <Day variant="h2">
              {blog.last_updated && format(parseISO(blog.last_updated), 'd')}
            </Day>
          </CalendarDayContainer>
          <BlogPostContainer color={textColor}>
            <BlogName variant="h3">{blog.title}</BlogName>
            <Typography variant="h6">
              {userHash[blog.author_id] && userHash[blog.author_id].first_name}{' '}
              {userHash[blog.author_id] && userHash[blog.author_id].last_name}
            </Typography>
            <Subtitle variant="body2" align="left">
              {blog.subtitle}
            </Subtitle>
          </BlogPostContainer>
        </Card>
      ))}
    </BlogContainer>
  )
}

AltBlog1.displayName = 'Blog 1 (No Image)'
AltBlog1.moduleName = 'AltBlog1'

export default AltBlog1
