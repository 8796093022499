import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { format, parseISO } from 'date-fns'
import { useSelector } from 'react-redux'
import { parseUrl } from 'query-string'
import * as R from 'ramda'

import { byIdLens } from '~/State/ducks/users'

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  height: 80vh;

  img {
    display: block;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media only screen and (max-width: 1080px) {
    height: 60vh;
  }

  @media only screen and (max-width: 768px) {
    height: 40vh;
  }

  @media only screen and (max-width: 540px) {
    height: 20vh;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  padding: 2rem 0;
  flex-direction: column;
  width: 100%;
  max-width: 55rem;

  p {
    margin-bottom: 1rem;
  }
`

const AuthorName = styled.div`
  margin-bottom: 1rem;
`

const DateText = styled.div`
  margin-bottom: 2rem;
`

const HTMLContainer = styled(Typography)`
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
`

const BlogItem = ({ blog }) => {
  const { id, title, content, image, last_updated, author_id } = blog

  const authorUser = useSelector(state => {
    return R.view(byIdLens(author_id), state)
  })

  return (
    <MainContainer key={id}>
      <ImageContainer>
        <img
          src={image}
          alt={parseUrl(image).query.alt}
          title={parseUrl(image).query.title}
        />
      </ImageContainer>
      <TitleContainer>
        <Typography component="h1" variant="h2" gutterBottom>
          {title}
        </Typography>
      </TitleContainer>
      <ContentContainer>
        <AuthorName>
          <Typography variant="subtitle">
            {authorUser && authorUser.first_name}{' '}
            {authorUser && authorUser.last_name}
          </Typography>
        </AuthorName>
        <DateText>
          <Typography variant="caption">
            {format(parseISO(last_updated), 'MMMM do yyyy')}
          </Typography>
        </DateText>
        <HTMLContainer
          component="div"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </ContentContainer>
    </MainContainer>
  )
}

export default BlogItem
