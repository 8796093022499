import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import * as R from 'ramda'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextAndImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const RowContainer = styled.div`
  display: flex;
`

const Promo3Edit = ({ data, handleEdit }) => {
  const { background = '#ffffff' } = data

  const [image, setImage] = useState(data.image)

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      promoRows: [
        ...oldState.promoRows,
        {
          image1: '',
          link1: '',
          alt1: '',
          title1: '',
          image2: '',
          link2: '',
          alt2: '',
          title2: '',
          image3: '',
          link3: '',
          alt3: '',
          title3: ''
        }
      ]
    }))

  const updateItem = (index, key, value) =>
    handleEdit(oldState => ({
      ...oldState,
      promoRows: R.adjust(
        index,
        R.mergeLeft({ [key]: value }),
        oldState.promoRows
      )
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      promoRows: R.remove(index, 1, oldState.promoRows)
    }))

  return (
    <InputContainer>
      <InnerContainer>
        {data.promoRows &&
          data.promoRows.map((promo, i) => {
            return (
              <InnerContainer key={i}>
                <HeaderContainer>
                  <InputLabel>Promo Row {i + 1}</InputLabel>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
                <RowContainer>
                  <TextAndImageContainer>
                    <ImageUploadContainer>
                      <ImageUpload
                        name={`image1-${i}`}
                        onChange={e => updateItem(i, 'image1', e)}
                        image={promo.image1}
                      />
                      <input hidden value={promo.image1} name="img" />
                    </ImageUploadContainer>
                    <TextField
                      name={`link1-${i}`}
                      id={`link1-${i}`}
                      aria-label="Link"
                      title="Link"
                      label="Link"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'link1', e.target.value)}
                      defaultValue={promo.link1}
                    />
                    <TextField
                      name={`alt1-${i}`}
                      id={`alt1-${i}`}
                      aria-label="Image Alt Text"
                      title="Image Alt Text"
                      label="Image Alt Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'alt1', e.target.value)}
                      defaultValue={promo.alt1}
                    />
                    <TextField
                      name={`title1-${i}`}
                      id={`title1-${i}`}
                      aria-label="Image Title Text"
                      title="Image Title Text"
                      label="Image Title Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'title1', e.target.value)}
                      defaultValue={promo.alt1}
                    />
                  </TextAndImageContainer>
                  <TextAndImageContainer>
                    <ImageUploadContainer>
                      <ImageUpload
                        name={`image2-${i}`}
                        onChange={e => updateItem(i, 'image2', e)}
                        image={promo.image2}
                      />
                      <input hidden value={promo.image2} name="img" />
                    </ImageUploadContainer>
                    <TextField
                      name={`link2-${i}`}
                      id={`link2-${i}`}
                      aria-label="Link"
                      title="Link"
                      label="Link"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'link2', e.target.value)}
                      defaultValue={promo.link2}
                    />
                    <TextField
                      name={`alt2-${i}`}
                      id={`alt2-${i}`}
                      aria-label="Image Alt Text"
                      title="Image Alt Text"
                      label="Image Alt Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'alt2', e.target.value)}
                      defaultValue={promo.alt2}
                    />
                    <TextField
                      name={`title2-${i}`}
                      id={`title2-${i}`}
                      aria-label="Image Title Text"
                      title="Image Title Text"
                      label="Image Title Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'title2', e.target.value)}
                      defaultValue={promo.title2}
                    />
                  </TextAndImageContainer>
                  <TextAndImageContainer>
                    <ImageUploadContainer>
                      <ImageUpload
                        name={`image3-${i}`}
                        onChange={e => updateItem(i, 'image3', e)}
                        image={promo.image3}
                      />
                      <input hidden value={promo.image3} name="img" />
                    </ImageUploadContainer>
                    <TextField
                      name={`link3-${i}`}
                      id={`link3-${i}`}
                      aria-label="Link"
                      title="Link"
                      label="Link"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'link3', e.target.value)}
                      defaultValue={promo.link3}
                    />
                    <TextField
                      name={`alt3-${i}`}
                      id={`alt3-${i}`}
                      aria-label="Image Alt Text"
                      title="Image Alt Text"
                      label="Image Alt Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'alt3', e.target.value)}
                      defaultValue={promo.alt3}
                    />
                    <TextField
                      name={`title3-${i}`}
                      id={`title3-${i}`}
                      aria-label="Image Title Text"
                      title="Image Title Text"
                      label="Image Title Text"
                      margin="normal"
                      variant="outlined"
                      onChange={e => updateItem(i, 'title3', e.target.value)}
                      defaultValue={promo.title3}
                    />
                  </TextAndImageContainer>
                </RowContainer>
              </InnerContainer>
            )
          })}
        <Button onClick={addItem}>Add Promo Row</Button>
        <ColorInputContainer>
          <ColorPicker
            color={background}
            name="background"
            title="Background Color"
          />
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default Promo3Edit
