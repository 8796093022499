import React from 'react'
import { useSelector } from 'react-redux'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { themeLens } from '~/State/ducks/profile'

import * as R from 'ramda'

export const configs = {
  Theme1: {
    palette: {
      primary: {
        main: '#ffa614'
      },
      secondary: {
        main: '#fb3523'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Roboto'
      }
    }
  },
  Theme2: {
    palette: {
      primary: {
        main: '#0e2262'
      },
      secondary: {
        main: '#fb3523'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Noto Serif'
      }
    }
  },
  Theme3: {
    palette: {
      primary: {
        main: '#5dba1d'
      },
      secondary: {
        main: '#1779b3'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Ubuntu'
      }
    }
  },
  Theme4: {
    palette: {
      primary: {
        main: '#4cc2d9'
      },
      secondary: {
        main: '#a23c95'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Libre Franklin'
      }
    }
  },
  Theme5: {
    palette: {
      primary: {
        main: '#071D26'
      },
      secondary: {
        main: '#1B546A'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Montserrat'
      }
    }
  },
  Theme6: {
    palette: {
      primary: {
        main: '#455759'
      },
      secondary: {
        main: '#E1B65B'
      }
    },
    typography: {
      body1: {
        fontFamily: 'DM Sans'
      }
    }
  },
  Theme7: {
    palette: {
      primary: {
        main: '#024959'
      },
      secondary: {
        main: '#8C3211'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Hepta Slab'
      }
    }
  },
  Theme8: {
    palette: {
      primary: {
        main: '#131e25'
      },
      secondary: {
        main: '#233341'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Libre Baskerville'
      }
    }
  },
  Theme9: {
    palette: {
      primary: {
        main: '#034C67'
      },
      secondary: {
        main: '#1BA8E5'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Cabin'
      }
    }
  },
  TimbTheme: {
    palette: {
      primary: {
        main: '#131e25'
      },
      secondary: {
        main: '#233341'
      }
    },
    typography: {
      body1: {
        fontFamily: 'Libre Baskerville'
      }
    }
  }
}

export const getBasedOnTheme = (color, theme, brand) => {
  if (color === 'primary') {
    return brand.color1 || theme.palette.primary.main || color
  }

  if (color === 'secondary') {
    return brand.color2 || theme.palette.secondary.main || color
  }

  return color
}

const PageTheme = ({ children, fontFamily }) => {
  const theme = useSelector(R.view(themeLens))
  const brand = useSelector(state => state.profile.brand)

  const conf = {
    Theme1: {
      palette: {
        primary: {
          main: brand.color1 || '#ffa614'
        },
        secondary: {
          main: brand.color2 || '#fb3523'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Roboto Slab'
          }
        }
      },
      typography: {
        fontFamily: fontFamily || 'Roboto Slab',
        h1: {
          fontSize: '46px'
        },
        h2: {
          fontSize: '32px'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px'
        },
        h5: {
          fontFamily: fontFamily || 'Roboto',
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px'
        },
        body1: {
          fontFamily: fontFamily || 'Roboto'
        },
        body2: {
          fontFamily: fontFamily || 'Roboto'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily | 'Roboto'
        }
      },
      boxShadow: 'none',
      imagePadding: 'none'
    },
    Theme2: {
      palette: {
        primary: {
          main: brand.color1 || '#fb3523'
        },
        secondary: {
          main: brand.color2 || '#0e2262'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Fjalla One',
        overline: {
          fontSize: '18px',
          fontFmaily: fontFamily || 'Noto Serif'
        },
        h1: {
          fontSize: '46px'
        },
        h2: {
          fontSize: '32px'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px'
        },
        body1: {
          fontFamily: fontFamily || 'Noto Serif'
        },
        body2: {
          fontFamily: fontFamily || 'Noto Sans'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Noto Sans'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Noto Sans',
            borderRadius: '0px'
          }
        }
      },
      boxShadow: 'none',
      imagePadding: 'none'
    },
    Theme3: {
      palette: {
        primary: {
          main: brand.color1 || '#5dba1d'
        },
        secondary: {
          main: brand.color2 || '#1779b3'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Ubuntu',
        h1: {
          fontSize: '46px'
        },
        h2: {
          fontSize: '32px'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px'
        },
        body2: {
          fontFamily: fontFamily || 'Oxygen'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Oxygen'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Noto Sans'
          }
        }
      },
      boxShadow: '0 0 28px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22)',
      imagePadding: '.25rem'
    },
    Theme4: {
      palette: {
        primary: {
          main: brand.color1 || '#4cc2d9'
        },
        secondary: {
          main: brand.color2 || '#a23c95'
        }
      },
      typography: {
        fontFamily: fontFamily || 'PT Serif',
        overline: {
          fontFamily: fontFamily || 'Arya'
        },
        h1: {
          fontSize: '46px'
        },
        h2: {
          fontSize: '32px'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'LibreFranklin'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'LibreFranklin'
        },
        body2: {
          fontFamily: fontFamily || 'Libre Franklin'
        },
        body1: {
          fontFamily: fontFamily || 'Libre Franklin'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Libre Franklin'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Libre Franklin',
            borderRadius: '0px',
            boxShadow: '0px 5px  0'
          }
        }
      },
      boxShadow: '0 0 28px rgba(0, 0, 0, 0.25), 0 0 10px rgba(0, 0, 0, 0.22)',
      imagePadding: '.25rem'
    },
    Theme5: {
      palette: {
        primary: {
          main: brand.color1 || '#071D26'
        },
        secondary: {
          main: brand.color2 || '#1B546A'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Montserrat',
        overline: {
          fontFamily: fontFamily || 'Montserrat'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'Gelasio'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'Gelasio'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Montserrat'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Montserrat'
        },
        body2: {
          fontFamily: fontFamily || 'Montserrat'
        },
        body1: {
          fontFamily: fontFamily || 'Montserrat'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Montserrat'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Montserrat',
            borderRadius: '0px',
            backgroundColor: 'transparent'
          },
          label: {
            padding: '5px 50px',
            letterSpacing: '2px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: '0 0 6px 0 rgba(0,0,0,0.2)',
      imagePadding: '.25rem'
    },
    Theme6: {
      palette: {
        primary: {
          main: brand.color1 || '#455759'
        },
        secondary: {
          main: brand.color2 || '#E1B65B'
        }
      },
      typography: {
        fontFamily: fontFamily || 'DM Serif',
        overline: {
          fontFamily: fontFamily || 'DM Serif'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'DM Serif'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'DM Serif'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Montserrat'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Montserrat'
        },
        body2: {
          fontFamily: fontFamily || 'DM Sans'
        },
        body1: {
          fontFamily: fontFamily || 'DM Sans'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'DM Sans'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'DM Serif Text',
            borderRadius: '25px',
            textTransform: 'none',
            fontSize: '18px'
          },
          label: {
            padding: '5px 70px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: '0 0 6px 0 rgba(0,0,0,0.2)',
      imagePadding: '.25rem'
    },
    Theme7: {
      palette: {
        primary: {
          main: brand.color1 || '#024959'
        },
        secondary: {
          main: brand.color2 || '#8C3211'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Hepta Slab',
        overline: {
          fontFamily: fontFamily || 'Hepta Slab'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'Hepta Slab'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'Hepta Slab'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Hepta Slab'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Hepta Slab'
        },
        body2: {
          fontFamily: fontFamily || 'Hepta Slab'
        },
        body1: {
          fontFamily: fontFamily || 'Hepta Slab'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'DM Sans'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Hepta Slab',
            borderRadius: '25px',
            textTransform: 'none'
          },
          label: {
            padding: '5px 70px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: '0 0 6px 0 rgba(0,0,0,0.2)',
      imagePadding: '.25rem'
    },
    Theme8: {
      palette: {
        primary: {
          main: brand.color1 || '#131e25'
        },
        secondary: {
          main: brand.color2 || '#233341'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Libre Baskerville',
        overline: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        body2: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        body1: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Libre Baskerville'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Libre Baskerville',
            borderRadius: '25px',
            textTransform: 'none',
            fontSize: '18px'
          },
          label: {
            padding: '5px 70px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: 'none',
      imagePadding: 'none'
    },
    Theme9: {
      palette: {
        primary: {
          main: brand.color1 || '#034C67'
        },
        secondary: {
          main: brand.color2 || '#1BA8E5'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Cabin',
        overline: {
          fontFamily: fontFamily || 'Cabin'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'Cabin'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'Cabin'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Cabin'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Cabin'
        },
        body2: {
          fontFamily: fontFamily || 'Cabin'
        },
        body1: {
          fontFamily: fontFamily || 'Cabin'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Cabin'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Cabin',
            borderRadius: '25px',
            textTransform: 'uppercase',
            fontSize: '18px'
          },
          label: {
            padding: '5px 20px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: 'none',
      imagePadding: 'none'
    },
    TimbTheme: {
      palette: {
        primary: {
          main: brand.color1 || '#131e25'
        },
        secondary: {
          main: brand.color2 || '#233341'
        }
      },
      typography: {
        fontFamily: fontFamily || 'Libre Baskerville',
        overline: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h1: {
          fontSize: '46px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h2: {
          fontSize: '32px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h3: {
          fontSize: '22px'
        },
        h4: {
          fontSize: '16px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        h5: {
          fontSize: '14px'
        },
        h6: {
          fontSize: '12px',
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        body2: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        body1: {
          fontFamily: fontFamily || 'Libre Baskerville'
        },
        caption: {
          fontSize: '11px',
          fontFamily: fontFamily || 'Libre Baskerville'
        }
      },
      overrides: {
        //put material-ui style sheet names here, then CSS under root to change styles of material-ui components, docs: https://material-ui.com/customization/globals/
        MuiButton: {
          root: {
            fontFamily: fontFamily || 'Libre Baskerville',
            borderRadius: '25px',
            textTransform: 'none',
            fontSize: '18px'
          },
          label: {
            padding: '5px 70px'
          }
        },
        PrivateNotchedOutline: {
          root: {
            borderRadius: '0px'
          }
        }
      },
      boxShadow: 'none',
      imagePadding: 'none'
    }
  }

  const themeConfig = conf[theme]

  return (
    <MuiThemeProvider theme={createTheme(themeConfig)}>
      {children}
    </MuiThemeProvider>
  )
}

export default PageTheme
