import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { Typography } from '@material-ui/core'

import { useParams } from 'react-router-dom'

import AdminMenu from '~/Admin/AdminMenu'

import URL from '~/Utils/api_base'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    margin-bottom: 2rem;
  }
`
const EditManufacturer = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const params = useParams()

  useEffect(() => {
    fetch(`${URL}/manufacturers/${params.id}`)
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          setName(data.name)
          setEmail(data.email)
        }
      })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    const form = {
      name,
      email
    }

    fetch(`${URL}/manufacturers/${params.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    })
      .then(x => x.json())
      .then(() => {})
  }

  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Edit New Manufacturer
        </Typography>
        <Form className="main-content" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            name="name"
            label="Name of Manufacturer"
            value={name}
            onChange={e => setName(e.target.value)}
            type="text"
          />

          <TextField
            fullWidth
            required
            name="email"
            type="email"
            label="Sales Email for Manufacturers"
            helperText="This should probably not be Jim@manufacturer.com but instead sales@manufacturer.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <ButtonGroup>
            <Button type="submit" color="primary">
              Update
            </Button>
            <Button
              onClick={() => (window.location.href = window.location.href)}
            >
              Reset
            </Button>
          </ButtonGroup>
        </Form>
      </Content>
    </Page>
  )
}

export default EditManufacturer
