import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import Typography from '@material-ui/core/Typography'
import { Editor } from '@tinymce/tinymce-react'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .tox-statusbar__branding {
    display: none !important;
  }
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const TextEdit = ({ data, handleEdit }) => {
  const { background = '#ffffff', text = '#eeeeee' } = data

  const [content, setContent] = useState(data.content)
  const [image, setImage] = useState(data.image)

  useEffect(() => {
    handleEdit(oldState => ({
      ...oldState,
      content
    }))
  }, [content])

  const [scriptLoaded, setScriptLoaded] = useState(
    typeof window !== 'undefined' && typeof myScript !== 'undefined'
  )

  const handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }) =>
          src ===
          'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.0.16/tinymce.min.js'
      )
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true
        })
      }
    }
  }

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorInputContainer>
          <Typography align="center">Background Image</Typography>
          <ImageUpload name="image-upload" onChange={setImage} image={image} />
          <input hidden value={image} name="image" />
          <DescriptionContainer>
            <Helmet onChangeClientState={handleChangeClientState}>
              {typeof window !== 'undefined' &&
                typeof myScript === 'undefined' && (
                  <script
                    async
                    defer
                    src={
                      'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.0.16/tinymce.min.js'
                    }
                  />
                )}
            </Helmet>
            {scriptLoaded && (
              <Editor
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help | code'
                }}
                initialValue={content}
                onChange={e => setContent(e.target.getContent() || '')}
              />
            )}
          </DescriptionContainer>
        </DescriptionAndColorContainer>
      </InnerContainer>
    </InputContainer>
  )
}

export default TextEdit
