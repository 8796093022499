import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { actions } from '~/State/ducks/users'
import cookies from 'react-cookies'

const Logout = () => {
  const [haveDispatched, setHasDispatched] = useState(false)
  const dispatch = useDispatch()
  const domain = useSelector(state => state.domain)
  useEffect(() => {
    cookies.remove('auth_token', {
      path: '/',
      domain: `.${domain.host}${domain.tld}`
    })

    localStorage.removeItem('auth_token')
    localStorage.removeItem('token')

    dispatch({
      type: actions.LOGOUT
    })

    setHasDispatched(true)
  }, [haveDispatched])

  if (haveDispatched) {
    return <Redirect to="/" />
  }

  return null
}

export default Logout
