export const mungeAPIIntoStore = response => {
  const { domain: domainById = {}, getRoles, currentUser } = response
  const {
    pages,
    categories,
    features,
    locations,
    inventory = [],
    blogs = [],
    templates = [],
    profile = {},
    menu,
    users,
    leads,
    redirects = [],
    ...domain
  } = domainById

  return {
    profile: {
      admin: {},
      settings: {},
      company: {},
      brand: {},
      ...profile
    },
    pages: {
      ids: pages.reduce(
        (a, c) => ({
          ...a,
          [c.id]: {
            ...c,
            blocks: c.blocks.sort((a, b) => a.order - b.order)
          }
        }),
        {}
      )
    },
    categories: {
      ids: categories.reduce((a, c) => ({ ...a, [c.id]: c }), {})
    },
    features: {
      ids: features.reduce((a, c) => ({ ...a, [c.id]: c }), {})
    },
    locations: {
      ids: locations.reduce((a, c) => ({ ...a, [c.id]: c }), {})
    },
    inventory: {
      ids: inventory.reduce(
        (a, c) => ({
          ...a,
          [c.id]: {
            ...c,
            features: c.features.map(({ id }) => id),
            categories: c.categories.map(({ id }) => id),
            location: c.location.id
          }
        }),
        {}
      )
    },
    domain,
    blogs: {
      ids: blogs.reduce(
        (a, c) => ({
          ...a,
          [c.id]: c
        }),
        {}
      )
    },
    templates: {
      ids: templates.reduce(
        (a, c) => ({
          ...a,
          [c.id]: c
        }),
        {}
      )
    },
    menus: {
      ...menu,
      pages: menu.pages.map(page => ({
        ...page,
        children: page.children || []
      }))
    },
    users: {
      ids: users.reduce((a, c) => ({ ...a, [c.id]: c }), {}),
      currentUser: currentUser
    },
    roles: {
      ids: getRoles.reduce((a, c) => ({ ...a, [c.id]: c }), {})
    },
    leads: {
      ids: leads.reduce((a, c) => ({ ...a, [c.id]: c }), {})
    },
    redirects
  }
}
