import React, { useState, useRef, useEffect } from 'react'

import styled from '@emotion/styled'

import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Modal from '@material-ui/core/Modal'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import BackArrow from '@material-ui/icons/ArrowBackIos'
import ForwardArrow from '@material-ui/icons/ArrowForwardIos'
import Buttons from '@material-ui/core/ButtonGroup'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/DeleteForever'

// import { stringifyUrl, parseUrl } from 'query-string'
import { parse, extract, stringify, parseUrl } from 'query-string'

import { useRouteMatch, Link, useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'

import { idsLens as featuresIDs } from '~/State/ducks/features'
import { idsLens as categoriesIDs } from '~/State/ducks/categories'
import { idsLens as locationsIDs } from '~/State/ducks/locations'
import slugify from '~/Utils/slugify'

import { byIdLens, actions } from '~/State/ducks/inventory'
import ImageUpload from '~/SharedComponents/ImageUpload'
import InventoryImageUpload from '~/SharedComponents/InventoryImageUpload'
import FileUpload from '~/SharedComponents/FileUpload'
import API_URL from '~/Utils/api_base'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Page = styled.div``
const Header = styled.div`
  width: 100%;
  display: flex;
  background: #768390;
  align-items: center;
`

const SiteName = styled(Typography)`
  flex-grow: 1;
  color: #fff;
  padding-left: 1rem;
`

const Main = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageOption = styled.button`
  flex: 1 1 auto;
  border: none;
  border-left: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  font-family: 'Roboto';
  padding: 2rem;
  transition: all 0.25s ease-in-out;

  :hover {
    color: #768390;
    background: #fff;
  }
`

const PageOptions = styled.nav`
  flex-grow: 1;
  max-width: 50%;
  display: flex;
  align-items: stretch;
  color: #fff;
  justify-content: space-between;
`

const LeftContainer = styled.div`
  flex: 1; // Adjust flex value as needed
  padding: 2rem;
  max-width: 60%; // Adjust max-width as needed
`;

const RightContainer = styled.div`
  flex: 1; // Adjust flex value as needed
  padding: 2rem;
  max-width: 40%; // Adjust max-width as needed
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
`

const InputRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const ImagesContainer = styled.div`
  padding: 4rem;
  width: 100%;

  img {
    display: block;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

const PriceContainer = styled.div`
  width: 100%;
  background: #f1f2f4;
  padding: 1rem;
`

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
`

const MultimediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const FormContainer = styled.div`
  min-width: 120px;
`

const PageStatusContainer = styled.div`
  margin-left: auto;
  margin-right: 3rem;
`

const MessageBox = styled.textarea`
  width: 100%;
  height: 100%;
  color: #7b7c7c;
  font-family: 'Roboto';
  padding-top: 1rem;
  padding-left: 1rem;
`

const MessageBoxContainer = styled.div`
  padding-left: 4rem;
  padding-right: 4rem;
  width: 100%;
  margin-top: 16px;
`

const LongInputContainer = styled.div`
  flex: 2;
  padding-left: 2rem;
  padding-right: 2rem;
`

const ShortInputContainer = styled.div`
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
`

const FeatureContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 2rem;
`

const CheckboxContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
`

const CheckboxItemContainer = styled.div`
  display: flex;
  align-items: center;
`

const LineDivider = styled.hr`
  width: 80%;
`

const RemoveModal = styled.div`
  width: 80%;
  min-height: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Permalink = styled.div`
  display: flex;
  align-items: center;

  span {
    padding-left: 1rem;
    padding-right: 0.5rem;
    color: rgba(33, 33, 33, 0.5);
  }

  label {
    font-family: 'Roboto';
    font-size: 0.75rem;
  }

  input {
    font-family: 'Roboto';
    font-size: 1.05rem;
    padding: 0.5rem;
  }
`

const PageMeta = styled.div`
  padding: 2rem;
  display: flex;
`

const LinkAndPublishContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LeftAndRightContainer = styled.div`
  display: flex;
  width: 100%;
`

const DeleteModal = styled.div`
  width: 80%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageListOuterContainerNew = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;

  & > div {
    display: flex;
  }
`;

const ImageListItemNew = styled.div`
  margin-right: 10px; // Add some space between items
  flex-shrink: 0; // Prevent the item from shrinking
  display: flex; // Set display to flex to align child elements in a row
  flex-direction: column; // Align children vertically
  align-items: center; // Center align the child elements
  justify-content: space-around; // Distribute space around the child elements
`;



const ButtonAndImageListContainer = styled.div`
  display: flex;
  align-items: center;
`

const MetaFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const ExpansionPanelContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextAndImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

const Edit = () => {
  const stringifyUrl = (input, options) => {
    options = Object.assign({ encode: true, strict: true }, options)

    const url = input.url.split('?')[0] || ''

    const queryFromUrl = extract(input.url)

    const parsedQueryFromUrl = parse(queryFromUrl, { sort: false })

    const query = Object.assign(parsedQueryFromUrl, input.query)
    let queryString = stringify(query, options)

    if (queryString) {
      queryString = `?${queryString}`
    }

    return `${url}${queryString}`
  }

  const match = useRouteMatch()
  const baseName = useSelector(state => state.baseName || '')
  const features = useSelector(state => {
    const ids = R.view(featuresIDs, state)

    return R.values(ids)
  })

  const categories = useSelector(state => {
    const ids = R.view(categoriesIDs, state)

    return R.values(ids)
  })

  const locations = useSelector(state => {
    const ids = R.view(locationsIDs, state)

    return R.values(ids)
  })

  const item = useSelector(state => {
    const selectedItem = R.view(byIdLens(match.params.id), state)

    return selectedItem || {}
  })

  const dispatch = useDispatch()

  const [image, setImage] = useState(item.mainImage)
  const [images, setImages] = useState(item.images)
  const [floorPlan, setFloorPlan] = useState(item.floorPlan)
  const [baths, setBaths] = useState(item.bathrooms)
  const [bedrooms, setBedrooms] = useState(item.bedrooms)
  const [isPublished, setIsPublished] = useState(item.published)
  const [pageStatus, setPageStatus] = useState(item.status || 'active')
  const [location, setLocation] = useState(item.location)
  const [modalOpen, setModalOpen] = useState(false)
  const [tourLink, setTourLink] = useState(item.tourLink)
  const [videoLink, setVideoLink] = useState(item.videoLink)
  const [title, setTitle] = useState(item.title)
  const [path, setPath] = useState(item.path)
  const [left, setLeft] = useState(0)
  const [editModal, setEditModal] = useState()
  const [altText, setAltText] = useState()
  const [titleText, setTitleText] = useState()
  const [currentEditIndex, setCurrentEditIndex] = useState()
  const [mainImgAltText, setMainImgAltText] = useState()
  const [mainImgTitleText, setMainImgTitleText] = useState()
  const [fileUpload, setFileUpload] = useState(item.meta.files || [])
  const formRef = useRef(null)
  const imageContainer = useRef(null)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isClayton = item.isClayton

  const getFormData = form => {
    const formData = new FormData(form)

    const data = {
      images: images,
      title: formData.get('title'),
      id: match.params.id,
      manufacturer: formData.get('manufacturer'),
      footage: isClayton ? item.footage : Number(formData.get('footage')),
      modelNumber: isClayton ? item.modelNumber : formData.get('model-number'),
      bedrooms: Number(bedrooms),
      bathrooms: Number(baths),
      status: formData.get('status'),
      path: formData.get('path'),
      isClayton,
      published: isPublished,
      status: pageStatus,
      description: formData.get('description'),
      price: Number(formData.get('price')),
      location,
      mainImage: image,
      floorPlan: floorPlan,
      videoLink,
      tourLink,
      meta: {
        title: formData.get('meta-title'),
        description: formData.get('meta-description'),
        keywords: formData.get('meta-keywords'),
        files: fileUpload
      }
    }

    /**
     * CODE OF INTEREST:
     *
     * We need a way to gather all of the featuers and categories
     * that have been selected. To do that, we use some naming
     * convetion and then we iterate over them.
     *
     * We expect that all of the `features` and `categories` to
     * have the prefix of `<type>-` in their input name.
     */
    const featuresSelected = [...formData.entries()]
      .filter(([key, value]) => key.indexOf('feature-') === 0 && value)
      .map(([key]) => key.replace('feature-', ''))

    const categoriesSelected = [...formData.entries()]
      .filter(([key, value]) => key.indexOf('category-') === 0 && value)
      .map(([key]) => key.replace('category-', ''))

    // This is mutating the `data` object but that's okay because
    // no one can see it but us!
    data.features = featuresSelected
    data.categories = categoriesSelected

    return data
  }

  const handleSubmit = form => {
    const data = getFormData(form)
    // Now we tell the store about our updated object!
    dispatch({
      type: actions.UPDATE_ITEM_REQUEST,
      payload: data,
      meta: history
    })
  }

  const deleteItem = () => {
    dispatch({
      type: actions.DELETE_ITEM_REQUEST,
      payload: { id: match.params.id },
      meta: history
    })
    setModalOpen(false)
  }

  const itemCategories = item.categories || []
  const itemFeatures = item.features || []

  const setPreview = form => {
    const data = getFormData(form)
    localStorage.setItem('currentUnsavedInventory', JSON.stringify(data))
    window.open(`${baseName}/preview`)
  }

  const editImageItem = index => {
    setImages(oldState =>
      R.update(
        index,
        stringifyUrl({
          url: `${oldState[index]}`,
          query: { title: titleText, alt: altText }
        }),
        oldState
      )
    )
    setCurrentEditIndex()
    setAltText()
    setTitleText()
    setEditModal(false)
  }

  const deleteImage = index => {
    setImages(oldState => R.remove(index, 1, oldState))
  }

  // Scroll functions
const scrollAmount = 200; // Adjust as needed

const scrollLeft = () => {
  document.getElementById('ImageListOuterContainerNew').scrollLeft -= scrollAmount;
};

const scrollRight = () => {
  document.getElementById('ImageListOuterContainerNew').scrollLeft += scrollAmount;
};

  useEffect(() => {
    setMainImgAltText(parseUrl(image || '').query.alt)
    setMainImgTitleText(parseUrl(image || '').query.title)
  }, [])

  const getEndFromIMG = url => {
    const encoded = new URL(url)

    return encoded.pathname
  }

  const deleteImageFromS3 = (img, index) => {
    const end = getEndFromIMG(img)
    const url = `${API_URL}/files${end}`
    return fetch(url, {
      method: 'DELETE'
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          deleteImage(index)
        }
      })
  }

  const addItem = () =>
    setFileUpload(oldState => [...oldState, { title: '', path: '' }])

  const updateItem = (index, key, value) =>
    setFileUpload(oldState =>
      R.adjust(index, R.mergeLeft({ [key]: value }), oldState)
    )

  const removeItem = index =>
    setFileUpload(oldState => R.remove(index, 1, oldState))

    const handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
  
      const items = Array.from(images);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      setImages(items);
    };
    
  return (
    <Page>
      <Header>
        <SiteName variant="h5" component={Link} to="/admin/inventory">
          Back to My Inventory
        </SiteName>
        <PageOptions>
          <PageOption
            color="inherit"
            onClick={() => (window.location.href = window.location.href)}
          >
            Revert to Previous Version
          </PageOption>
          <PageOption color="inherit" onClick={() => setModalOpen(true)}>
            Delete Inventory Item
          </PageOption>

          <PageOption
            onClick={() => setPreview(formRef.current)}
            color="inherit"
          >
            Preview
          </PageOption>
          <PageOption
            color="inherit"
            onClick={() => {
              handleSubmit(formRef.current)
            }}
          >
            Save
          </PageOption>
        </PageOptions>
      </Header>

      <Main ref={formRef}>
        <PageMeta>
          <LinkAndPublishContainer>
            <Permalink title="Permalink for the website.">
              <label>Permalink:</label>
              <span>companyurl.com</span>
              <input
                onChange={e => setPath(e.target.value)}
                value={path}
                name="path"
              />
            </Permalink>
            <FormControlLabel
              control={
                <Switch
                  checked={isPublished}
                  onChange={e => {
                    setIsPublished(e.target.checked)
                  }}
                  color={isPublished ? 'primary' : 'secondary'}
                  inputProps={{
                    'aria-label': 'Page is Published Toggle',
                    title: 'Page is Published Toggle'
                  }}
                />
              }
              label="Is Published"
            />
          </LinkAndPublishContainer>
          <PageStatusContainer>
            <InputLabel htmlFor="status">Page Status</InputLabel>
            <Select
              value={pageStatus}
              onChange={e => setPageStatus(e.target.value)}
              inputProps={{
                name: 'status',
                id: 'status'
              }}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="sold">Sold</MenuItem>
              <MenuItem value="archive">Archive</MenuItem>
            </Select>
          </PageStatusContainer>
        </PageMeta>
        <LeftAndRightContainer>
          <LeftContainer>
            <TextField
              name="title"
              id="title"
              value={title}
              aria-label="Item Title"
              label="Title"
              title="Item Title"
              style={{ width: '100%' }}
              onChange={e => {
                setTitle(e.target.value),
                  setPath(`/inventory${slugify(e.target.value)}`)
              }}
            />
            <ExpansionPanelContainer>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="meta-content"
                  id="meta-header"
                >
                  <Typography>Meta</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <MetaFieldContainer>
                    <TextField
                      name="meta-title"
                      label="Meta Title"
                      title="Meta Title"
                      aria-label="Meta Title"
                      defaultValue={item.meta.title}
                    />
                    <TextField
                      multiline
                      name="meta-description"
                      label="Meta Description"
                      title="Meta Description"
                      aria-label="Meta Description"
                      defaultValue={item.meta.description}
                    />
                    <TextField
                      name="meta-keywords"
                      label="Keywords"
                      title="Keywords"
                      aria-label="Keywords"
                      helperText="Keywords will be a comma-separated list"
                      defaultValue={item.meta.keywords}
                    />
                  </MetaFieldContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </ExpansionPanelContainer>
            <ImagesContainer>
              {isClayton ? (
                <img src={image} />
              ) : (
                <ImageUpload name="image" onChange={setImage} image={image} />
              )}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  name="main-alt-text"
                  id="main-alt-text"
                  aria-label="Main Image Alt Text"
                  title="Main Image Alt Text"
                  label="Main Image Alt Text"
                  margin="normal"
                  variant="outlined"
                  onChange={e => setMainImgAltText(e.target.value)}
                  value={mainImgAltText}
                />
                <TextField
                  name="main-title-text"
                  id="main-title-text"
                  aria-label="Main Image Title Text"
                  title="Main Image Title Text"
                  label="Main Image Title Text"
                  margin="normal"
                  variant="outlined"
                  onChange={e => setMainImgTitleText(e.target.value)}
                  value={mainImgTitleText}
                />
                <Buttons>
                  <Button
                    color="secondary"
                    onClick={() => {
                      if (image) {
                        setImage(oldState =>
                          stringifyUrl({
                            url: `${oldState}`,
                            query: {
                              title: mainImgTitleText,
                              alt: mainImgAltText
                            }
                          })
                        )
                      }
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      setMainImgAltText()
                      setMainImgTitleText()
                    }}
                  >
                    Cancel
                  </Button>
                </Buttons>
              </div>
            </ImagesContainer>
            <ButtonAndImageListContainer>
            <IconButton style={{ height: '48px' }} onClick={scrollLeft}>
              <BackArrow />
            </IconButton>

  <ImageListOuterContainerNew id="ImageListOuterContainerNew">
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {images.map((url, index) => (
              <Draggable key={url} draggableId={url} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ImageListItemNew>
                      {isClayton ? (
                        <React.Fragment>
                          <div onClick={() => setImage(url)}>
                            <InventoryImageUpload
                              name={url}
                              onChange={setImage}
                              image={url}
                            />
                          </div>
                          <Button onClick={() => {
                            setCurrentEditIndex(index);
                            setAltText(parseUrl(images[index] || '').query.alt);
                            setTitleText(parseUrl(images[index] || '').query.title);
                            setEditModal(true);
                          }}>
                            Edit Tags
                          </Button>
                          <Button onClick={() => deleteImage(index)}>
                            Remove
                          </Button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <InventoryImageUpload
                            name={url}
                            onChange={setImage}
                            image={url}
                          />
                          <Button onClick={() => {
                            setCurrentEditIndex(index);
                            setAltText(parseUrl(images[index] || '').query.alt);
                            setTitleText(parseUrl(images[index] || '').query.title);
                            setEditModal(true);
                          }}>
                            Edit Tags
                          </Button>
                          <Button
                            disabled={isClayton}
                            onClick={() => deleteImageFromS3(images[index], index)}
                          >
                            Remove
                          </Button>
                        </React.Fragment>
                      )}
                    </ImageListItemNew>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </ImageListOuterContainerNew>

  <IconButton style={{ height: '48px' }} onClick={scrollRight}>
  <ForwardArrow />
</IconButton>
</ButtonAndImageListContainer>

            <DescriptionContainer>
              <InputRow>
                <LongInputContainer>
                  <TextField
                    fullWidth
                    defaultValue={item.manufacturer}
                    name="manufacturer"
                    id="manufacturer"
                    aria-label="Manufacturer"
                    title="Manufacturer"
                    label="Manufacturer"
                    margin="normal"
                    variant="outlined"
                  />
                </LongInputContainer>
                <ShortInputContainer>
                  <TextField
                    fullWidth
                    disabled={isClayton}
                    defaultValue={item.footage}
                    name="footage"
                    type="number"
                    id="footage"
                    aria-label="Square Footage"
                    title="Square Footage"
                    label="Square Footage"
                    margin="normal"
                    variant="outlined"
                  />
                </ShortInputContainer>
              </InputRow>
              <InputRow>
                <LongInputContainer>
                  <TextField
                    fullWidth
                    disabled={isClayton}
                    defaultValue={item.modelNumber}
                    name="model-number"
                    id="model-number"
                    aria-label="Model Number"
                    title="Model Number"
                    label="Model Number"
                    margin="normal"
                    variant="outlined"
                  />
                </LongInputContainer>
                <ShortInputContainer>
                  <FormContainer>
                    <InputLabel htmlFor="bedrooms">Bedrooms</InputLabel>
                    <Select
                      fullWidth
                      disabled={isClayton}
                      value={bedrooms}
                      onChange={e => setBedrooms(e.target.value)}
                      inputProps={{
                        name: 'bedrooms',
                        id: 'bedrooms'
                      }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                    </Select>
                  </FormContainer>
                </ShortInputContainer>
              </InputRow>
              <InputRow>
                <LongInputContainer>
                  <FormContainer>
                    <InputLabel htmlFor="location">Location</InputLabel>
                    <Select
                      fullWidth
                      value={location}
                      onChange={e => setLocation(e.target.value)}
                      inputProps={{
                        name: 'location',
                        id: 'location',
                        'aria-label': 'Location',
                        title: 'Location',
                        label: 'Location'
                      }}
                    >
                      {locations.map(location => (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormContainer>
                </LongInputContainer>
                <ShortInputContainer>
                  <FormContainer>
                    <InputLabel htmlFor="baths">Baths</InputLabel>
                    <Select
                      fullWidth
                      disabled={isClayton}
                      value={baths}
                      onChange={e => setBaths(e.target.value)}
                      inputProps={{
                        name: 'baths',
                        id: 'baths'
                      }}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={1.5}>1.5</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={2.5}>2.5</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={3.5}>3.5</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={4.5}>4.5</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                    </Select>
                  </FormContainer>
                </ShortInputContainer>
              </InputRow>
              <MessageBoxContainer>
                <MessageBox
                  defaultValue={item.description}
                  name="description"
                  id="description"
                  aria-label="Description"
                  title="Description"
                  label="Description"
                  placeholder="Description"
                />
              </MessageBoxContainer>
            </DescriptionContainer>
            <LineDivider />
            <FeatureContainer>
              <Typography>Features</Typography>
              <CheckboxContainer>
                {features.map(feature => (
                  <CheckboxItemContainer key={feature.id}>
                    <Checkbox
                      value={feature.id}
                      defaultChecked={R.includes(feature.id, itemFeatures)}
                      inputProps={{
                        name: 'feature-' + feature.id,
                        'aria-label': 'primary checkbox'
                      }}
                    />{' '}
                    <Typography>{feature.name}</Typography>
                  </CheckboxItemContainer>
                ))}
              </CheckboxContainer>
            </FeatureContainer>
            <LineDivider />
            <ImagesContainer>
              <Typography>Floor Plan Image</Typography>
              {isClayton ? (
                <img src={floorPlan} />
              ) : (
                <ImageUpload
                  name="floorPlan"
                  onChange={setFloorPlan}
                  image={floorPlan}
                />
              )}
            </ImagesContainer>
          </LeftContainer>
          <RightContainer>
            <PriceContainer>
              <Typography>Price ($)</Typography>
              <TextField
                fullWidth
                defaultValue={item.price}
                name="price"
                id="price"
                aria-label="Price"
                title="Price"
                label="Price"
                margin="normal"
                variant="outlined"
              />
            </PriceContainer>
            <CategoryContainer>
              <Typography>Categories</Typography>
              {categories.map(category => (
                <CheckboxItemContainer key={category.id}>
                  <Checkbox
                    value={category.id}
                    defaultChecked={R.includes(category.id, itemCategories)}
                    inputProps={{
                      name: 'category-' + category.id,
                      'aria-label': 'primary checkbox'
                    }}
                  />{' '}
                  <Typography>{category.name}</Typography>
                </CheckboxItemContainer>
              ))}
            </CategoryContainer>
            <LineDivider />
            <MultimediaContainer>
              <Typography>Multimedia</Typography>
              <TextField
                name="video-link"
                id="video-link"
                aria-label="Video Link"
                title="Video Link"
                label="Video Link"
                margin="normal"
                variant="outlined"
                onChange={e => setVideoLink(e.target.value)}
                value={videoLink}
              />
              <TextField
                name="tour-link"
                id="tour-link"
                aria-label="Tour Link"
                title="Tour Link"
                label="Tour Link"
                margin="normal"
                variant="outlined"
                disabled={isClayton}
                onChange={e => setTourLink(e.target.value)}
                value={tourLink}
              />
            </MultimediaContainer>
            {fileUpload &&
              fileUpload.map((file, i) => {
                return (
                  <InnerContainer key={i}>
                    <HeaderContainer>
                      <InputLabel>File {i + 1}</InputLabel>
                      <IconButton onClick={() => removeItem(i)}>
                        <DeleteIcon />
                      </IconButton>
                    </HeaderContainer>
                    <TextAndImageContainer>
                      <ImageUploadContainer>
                        <FileUpload
                          name={`file-${i}`}
                          onChange={e => updateItem(i, 'path', e)}
                          file={file.path}
                        />
                        <input hidden value={file.path} />
                      </ImageUploadContainer>
                      <TextField
                        name={`title-${i}`}
                        id={`title-${i}`}
                        aria-label="File Title"
                        title="File Title"
                        label="File Title"
                        margin="normal"
                        variant="outlined"
                        onChange={e => updateItem(i, 'title', e.target.value)}
                        defaultValue={file.title}
                      />
                    </TextAndImageContainer>
                  </InnerContainer>
                )
              })}
            <Button onClick={addItem}>Add File</Button>
          </RightContainer>
        </LeftAndRightContainer>
      </Main>
      <Modal onClose={() => setModalOpen(false)} open={modalOpen}>
        <DeleteModal>
          <Typography variant="h2" component="h4" align="center" gutterBottom>
            Are you sure you want to delete
          </Typography>
          <Typography variant="subtitle1" align="center">
            {item.title}
          </Typography>
          <ButtonGroup>
            <Button color="secondary" onClick={deleteItem}>
              Delete
            </Button>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          </ButtonGroup>
        </DeleteModal>
      </Modal>
      <Modal
        aria-labelledby="edit-meta-modal"
        aria-describedby="edit-meta-modal"
        open={editModal}
        onClose={() => {
          setEditModal(false)
          setAltText()
          setTitleText()
        }}
      >
        <RemoveModal>
          <TextField
            name="alt-text"
            id="alt-text"
            aria-label="Alt Text"
            title="Alt Text"
            label="Alt Text"
            margin="normal"
            variant="outlined"
            onChange={e => setAltText(e.target.value)}
            value={altText}
          />
          <TextField
            name="title-text"
            id="title-text"
            aria-label="Title Text"
            title="Title Text"
            label="Title Text"
            margin="normal"
            variant="outlined"
            onChange={e => setTitleText(e.target.value)}
            value={titleText}
          />
          <Buttons>
            <Button
              color="secondary"
              onClick={() => editImageItem(currentEditIndex)}
            >
              Save
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setAltText()
                setTitleText()
                setEditModal(false)
                setCurrentEditIndex()
              }}
            >
              Cancel
            </Button>
          </Buttons>
        </RemoveModal>
      </Modal>
    </Page>
  )
}

export default Edit
