import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Star from '@material-ui/icons/Star'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import * as R from 'ramda'
import Carousel from 'nuka-carousel'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const ReviewContainer = styled.section`
  width: 100%;
  padding: 3rem;
  background: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const HeaderContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const InnerContainer = styled.div`
  background-color: #eeeeee;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  flex-wrap: wrap;
  position: relative;
  border-bottom: ${({ ftColor }) =>
    `40px ${ftColor} solid` || `40px black solid`};

  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }

  /* https://github.com/FormidableLabs/nuka-carousel/issues/349
  The wrong width is a known issue that will likely not be fixed,
  we are using !important to override their styles */

  .slider-list {
    width: 100% !important;
  }
  .slider-frame {
    width: 100% !important;
  }

  .paging-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .slider-control-bottomcenter {
    bottom: -5.2rem !important;

    @media only screen and (max-width: 768px) {
      bottom: -3.2rem !important;
    }
  }
`

const PictureAndTextContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const HeaderText = styled(Typography)`
  margin-top: 1rem;
`

const Photo = styled.img`
  max-width: 320px;
  height: auto;
  margin: auto;
  margin-bottom: 1rem;
  margin-left: 0;
  box-shadow: ${({ boxShadow }) => boxShadow};
  padding: ${({ imagePadding }) => imagePadding};
  background: #ffffff;
`

const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 2rem;
  margin-left: 2rem;
  max-width: 320px;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`

const ReviewTextContainer = styled.div`
  margin-top: 2rem;
  margin-left: 5rem;

  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ color }) => `${color}` || `inherit`};
  line-height: 28px;
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const Subtitle = styled(Typography)`
  padding-top: 0.5rem;
`

const ReviewHeader = styled(Typography)``

const ReviewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    margin-top: 2rem;
    flex-direction: column;
  }
`

const RatingContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const Review = styled.p`
  font-style: italic;

  :before {
    content: '"';
  }
  :after {
    content: '"';
  }
`

const Reviews1 = ({
  reviews,
  background,
  text,
  feature,
  moduleBackground,
  header = 'Customer Testimonials',
  image,
  isPreview,
  img = 'https://web.archive.org/web/20200405072553if_/http://www.inmanmanufacturedhomes.com/assets/SEExterior_7A2DDA804D737CD78C7098CF91FE68D0_41PRF32643AH.jpg',
  name = 'Name of Person in City, State',
  rating = 12345,
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. '
}) => {
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const modBackgroundColor = getBasedOnTheme(
    moduleBackground,
    currentTheme,
    brand
  )

  const primary = theme.palette.primary.main

  if (isPreview) {
    return (
      <ReviewContainer>
        <InnerContainer color={bgColor} ftColor={ftColor || primary}>
          <HeaderContainer color={textColor}>
            <HeaderText variant="h2">{header}</HeaderText>
          </HeaderContainer>
          <Carousel
            autoplay
            wrapAround
            autoplayInterval={7000}
            slidesToShow={1}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            <div>
              <PictureAndTextContainer>
                <PhotoContainer color={textColor}>
                  <Photo
                    boxShadow={theme.boxShadow}
                    imagePadding={theme.imagePadding}
                    src={img}
                  />
                  <Subtitle variant="caption">{subtitle}</Subtitle>
                </PhotoContainer>

                <ReviewTextContainer
                  fontFamily={theme.typography.fontFamily}
                  color={textColor}
                >
                  <ReviewHeaderContainer>
                    <ReviewHeader variant="h3">{name}</ReviewHeader>
                    <RatingContainer color={primary}>
                      {Array.from(String(rating), Number).map((_, i) => (
                        <Star key={i} />
                      ))}
                    </RatingContainer>
                  </ReviewHeaderContainer>

                  <Review color="inherit" variant="subtitle2">
                    {content}
                  </Review>
                </ReviewTextContainer>
              </PictureAndTextContainer>
            </div>
            <div>
              <PictureAndTextContainer>
                <PhotoContainer color={textColor}>
                  <Photo
                    boxShadow={theme.boxShadow}
                    imagePadding={theme.imagePadding}
                    src={img}
                  />
                  <Subtitle variant="caption">{subtitle}</Subtitle>
                </PhotoContainer>

                <ReviewTextContainer
                  fontFamily={theme.typography.fontFamily}
                  color={textColor}
                >
                  <ReviewHeaderContainer>
                    <ReviewHeader variant="h3">{name}</ReviewHeader>
                    <RatingContainer color={primary}>
                      {Array.from(String(rating), Number).map((_, i) => (
                        <Star key={i} />
                      ))}
                    </RatingContainer>
                  </ReviewHeaderContainer>

                  <Review color="inherit" variant="subtitle2">
                    {content}
                  </Review>
                </ReviewTextContainer>
              </PictureAndTextContainer>
            </div>
          </Carousel>
        </InnerContainer>
      </ReviewContainer>
    )
  }

  return (
    <ReviewContainer color={modBackgroundColor}>
      <InnerContainer img={image} color={bgColor} ftColor={ftColor || primary}>
        <HeaderContainer color={textColor}>
          <HeaderText variant="h2">{header}</HeaderText>
        </HeaderContainer>
        {reviews && reviews.length > 1 ? (
          <Carousel
            autoplay
            wrapAround
            autoplayInterval={5000}
            slidesToShow={1}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
          >
            {reviews.map((review, i) => (
              <div key={i}>
                <PictureAndTextContainer>
                  <PhotoContainer color={textColor}>
                    <Photo
                      boxShadow={theme.boxShadow}
                      imagePadding={theme.imagePadding}
                      src={review.image}
                      alt={review.alt_text}
                      title={review.title_text}
                    ></Photo>
                    <Subtitle variant="caption">{review.subtitle}</Subtitle>
                  </PhotoContainer>

                  <ReviewTextContainer
                    color={textColor}
                    fontFamily={theme.typography.fontFamily}
                  >
                    <ReviewHeaderContainer>
                      <ReviewHeader variant="h3">{review.name}</ReviewHeader>
                      <RatingContainer>
                        {Array.from(String(review.rating), Number).map(
                          (_, i) => (
                            <Star key={i} />
                          )
                        )}
                      </RatingContainer>
                    </ReviewHeaderContainer>

                    <Review color="inherit" variant="subtitle2">
                      {review.content}
                    </Review>
                  </ReviewTextContainer>
                </PictureAndTextContainer>
              </div>
            ))}
          </Carousel>
        ) : (
          <div>
            {reviews &&
              reviews.map((review, i) => (
                <div key={i}>
                  <PictureAndTextContainer>
                    <PhotoContainer color={textColor}>
                      <Photo
                        boxShadow={theme.boxShadow}
                        imagePadding={theme.imagePadding}
                        src={img}
                        alt={review.alt_text}
                        title={review.title_text}
                      ></Photo>
                      <Subtitle variant="caption">{review.subtitle}</Subtitle>
                    </PhotoContainer>
                    <ReviewTextContainer
                      color={textColor}
                      fontFamily={theme.typography.fontFamily}
                    >
                      <ReviewHeaderContainer>
                        <ReviewHeader variant="h3">{review.name}</ReviewHeader>
                        <RatingContainer>
                          {Array.from(String(review.rating), Number).map(
                            (_, i) => (
                              <Star key={i} />
                            )
                          )}
                        </RatingContainer>
                      </ReviewHeaderContainer>

                      <Review color="inherit" variant="subtitle2">
                        {review.content}
                      </Review>
                    </ReviewTextContainer>
                  </PictureAndTextContainer>
                </div>
              ))}
          </div>
        )}
      </InnerContainer>
    </ReviewContainer>
  )
}

Reviews1.displayName = 'Reviews 1'
Reviews1.moduleName = 'Reviews1'

export default Reviews1
