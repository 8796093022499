import { createStore, applyMiddleware, compose } from 'redux'
import createEpicMiddleware, { rootEpic } from './middleware/epics'
import defaultState from './defaultState'
import reducer from './reducer'

export const create = (preloadedState = defaultState) => {
  const composeEnhancers =
    typeof window !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose
  const epics = createEpicMiddleware()
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(epics))
  )

  epics.run(rootEpic)

  if (
    typeof window !== 'undefined' &&
    !(preloadedState && preloadedState.token)
  ) {
    store.dispatch({
      type: 'SET_TOKEN',
      payload: localStorage.getItem('auth_token')
    })
  }

  return store
}

export default create
