import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import ImageUpload from '~/SharedComponents/ImageUpload'
import Typography from '@material-ui/core/Typography'

const Inputs = styled.div`
  flex-grow: 2;
  width: 50%;
`

const Input = styled(TextField)`
  width: 100%;
`

const SiteSettings = ({
  title,
  ss_domain,
  ss_account,
  google_analytics,
  google_tag,
  fb_pixel,
  ar_adv_id,
  ar_pix_id,
  captcha_client,
  captcha_server,
  favicon,
  handleImageChange
}) => {
  return (
    <Inputs>
      <Input
        name="title"
        id="title"
        label="Site Title"
        aria-label="Site Title"
        title="Site Title"
        margin="normal"
        variant="outlined"
        defaultValue={title}
      />
      <Input
        name="ss_domain"
        id="ss_domain"
        label="SharpSpring Tracking Domain"
        aria-label="SharpSpring Tracking Domain"
        title="SharpSpring Tracking Domain"
        margin="normal"
        variant="outlined"
        defaultValue={ss_domain}
        helperText="This should be everything after the 'https://' and before '.marketingautomation.services/net' in 
        your tracking code block. For example: 'koi-1A1A1A1A1A'"
      />
      <Input
        name="ss_account"
        id="ss_account"
        label="SharpSpring Tracking ID"
        aria-label="SharpSpring Tracking ID"
        title="SharpSpring Tracking ID"
        margin="normal"
        variant="outlined"
        defaultValue={ss_account}
        helperText="This should be your SharpSpring Tracking ID. For example: 'KOI-2B2B2B2B'"
      />
      <Input
        name="google_analytics"
        id="google_analytics"
        label="Google Analytics Tracking ID"
        aria-label="Google Analytics Tracking ID"
        title="Google Analytics Tracking ID"
        margin="normal"
        variant="outlined"
        defaultValue={google_analytics}
        helperText="This should be your Google Analytics Tracking ID. For example: 'UA-123456789-1'"
      />
      <Input
        name="google_tag"
        id="google_tag"
        label="Google Tag Manager ID"
        aria-label="Google Tag Manager ID"
        title="Google Tag Manager ID"
        margin="normal"
        variant="outlined"
        defaultValue={google_tag}
        helperText="This should be your Google Tag Manager ID. For example: 'GTM-123456'"
      />
      <Input
        name="fb_pixel"
        id="fb_pixel"
        label="Facebook Pixel Tracking ID"
        aria-label="Facebook Pixel Tracking ID"
        title="Facebook Pixel Tracking ID"
        margin="normal"
        variant="outlined"
        defaultValue={fb_pixel}
        helperText="This should be your Facebook Pixel Tracking ID. For example: '1234567890'"
        gutterBottom
      />
      <Input
        name="ar_adv_id"
        id="ar_adv_id"
        label="AdRoll Advertiser ID"
        aria-label="AdRoll Advertiser ID"
        title="AdRoll Advertiser ID"
        margin="normal"
        variant="outlined"
        defaultValue={ar_adv_id}
        helperText="This should be your AdRoll Advertister ID. For example: 1A1A1A1A1A1A1A1A"
        gutterBottom
      />
      <Input
        name="ar_pix_id"
        id="ar_pix_id"
        label="AdRoll Pixel ID"
        aria-label="AdRoll Pixel ID"
        title="AdRoll Pixel ID"
        margin="normal"
        variant="outlined"
        defaultValue={ar_pix_id}
        helperText="This should be your AdRoll Pixel ID. For example: 1A1A1A1A1A1A1A1A"
        gutterBottom
      />
      <Input
        name="captcha_client"
        id="captcha_client"
        label="reCAPTCHA Site Key"
        aria-label="reCAPTCHA Site Key"
        title="reCAPTCHA Site Key"
        margin="normal"
        variant="outlined"
        defaultValue={captcha_client}
        helperText="This should be your reCAPTCHA Site Key."
        gutterBottom
      />
      <Input
        name="captcha_server"
        id="captcha_server"
        label="reCAPTCHA Secret Key"
        aria-label="reCAPTCHA Secret Key"
        title="reCAPTCHA Secret Key"
        margin="normal"
        variant="outlined"
        defaultValue={captcha_server}
        helperText="This should be your reCAPTCHA Secret Key."
        gutterBottom
      />
      <Typography align="center">Upload Favicon</Typography>
      <ImageUpload
        name="image-favicon"
        image={favicon}
        onChange={handleImageChange}
      />

      <input hidden name="image-favicon" value={favicon} />
    </Inputs>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(SiteSettings)
