import URL from '~/Utils/api_base'

const prod = (item, token) =>
  fetch(`${URL}/inventory/${item.id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(x => x.json())

export default prod
