import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ButtonPicker from './ButtonPicker'
import ButtonStylePicker from './ButtonStylePicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DescriptionAndColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DescriptionContainer = styled.div`
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`

const HeaderHeroEdit = ({ data }) => {
  const {
    phone,
    phone2,
    header,
    feature,
    background = '#ffffff',
    text = '#eeeeee',
    menuText,
    subtitle,
    ctaText,
    ctaLink,
    btnColor = 'primary',
    btnStyle = 'contained',
    headerSize = 'h1',
    orText = 'Or'
  } = data

  const [image, setImage] = useState(data.image)
  const [headerFontSize, setHeaderFontSize] = useState(headerSize)

  return (
    <InputContainer>
      <InnerContainer>
        <DescriptionAndColorContainer>
          <DescriptionContainer>
            <Typography>Phone number:</Typography>
            <TextField
              name="phone"
              id="phone"
              aria-label="Phone"
              title="Phone"
              label="Phone"
              margin="normal"
              variant="outlined"
              defaultValue={phone}
            />
            <TextField
              name="orText"
              id="orText"
              aria-label="Middle Text"
              title="Middle Text"
              label="Middle Text"
              margin="normal"
              variant="outlined"
              defaultValue={orText}
            />
            <TextField
              name="phone2"
              id="phone2"
              aria-label="Other Phone"
              title="Other Phone"
              label="Other Phone"
              margin="normal"
              variant="outlined"
              defaultValue={phone2}
            />
            <TextField
              name="header"
              id="header"
              aria-label="Header"
              title="Header"
              label="Header"
              margin="normal"
              variant="outlined"
              defaultValue={header}
            />
            <FormControl>
              <InputLabel id="header-size">Header Size</InputLabel>
              <Select
                name="headerSize"
                labelId="header-size"
                id="header-size-select"
                value={headerFontSize}
                onChange={e => setHeaderFontSize(e.target.value)}
              >
                <MenuItem value="h1">H1</MenuItem>
                <MenuItem value="h2">H2</MenuItem>
                <MenuItem value="h3">H3</MenuItem>
                <MenuItem value="h4">H4</MenuItem>
                <MenuItem value="h5">H5</MenuItem>
                <MenuItem value="h6">H6</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="subtitle"
              id="subtitle"
              aria-label="Subtitle"
              title="Subtitle"
              label="Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={subtitle}
              multiline
            />
            <ButtonInputContainer>
              <Typography>Call to Action Button</Typography>
              <TextField
                name="ctaText"
                id="ctaText"
                aria-label="Call to Action Text"
                title="Call to Action Text"
                label="Call to Action Text"
                margin="normal"
                variant="outlined"
                defaultValue={ctaText}
              />
              <TextField
                name="ctaLink"
                id="ctaLink"
                aria-label="Call to Action Link"
                title="Call to Action Link"
                label="Call to Action Link"
                margin="normal"
                variant="outlined"
                defaultValue={ctaLink}
              />
            </ButtonInputContainer>
          </DescriptionContainer>
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ColorPicker color={feature} name="feature" title="Feature Color" />
            <ColorPicker
              color={menuText}
              name="menuText"
              title="Menu Text Color"
            />
            <ButtonPicker
              color={btnColor}
              name="btnColor"
              title="Button Color"
            />
            <ButtonStylePicker
              style={btnStyle}
              name="btnStyle"
              title="Button Style"
            />
          </ColorInputContainer>
        </DescriptionAndColorContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
    </InputContainer>
  )
}

export default HeaderHeroEdit
