import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Alert from 'react-s-alert'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import loadable from '@loadable/component'

import Theme from './Theme'
import Login from './Login'
import Logout from './Logout'
import Register from './Register'
import Admin from './Admin'
import ErrorPage from './ErrorPage'
import PageTheme from './PageTheme'
import InventoryItem from './InventoryItem'
import BlogItem from './BlogItem'
import Preview from './Preview'
import PasswordReset from './PasswordReset'
import Video from './Video'

import * as Components from '~/Components'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/genie.css'

const getBlogTemplate = templates => {
  const keys = R.keys(templates)
  const templateID = keys.find(key => templates[key].title === 'Blog Template')

  return templates[templateID]
}

const getInventoryTemplate = templates => {
  const keys = R.keys(templates)

  const templateID = keys.find(
    key => templates[key].title === 'Inventory Template'
  )

  return templates[templateID]
}

const renderMetaField = R.cond([
  [
    R.propEq('key', 'description'),
    ({ value }) => <meta name="description" content={value} />
  ],
  [
    R.propEq('key', 'keywords'),
    ({ value }) => <meta name="keywords" content={value} />
  ],
  [
    R.propEq('key', 'author'),
    ({ value }) => <meta name="author" content={value} />
  ],
  [
    R.propEq('key', 'facebook_domain_verification'),
    ({ value }) => <meta name="facebook-domain-verification" content={value} />
  ],
  [
    R.propEq('key', 'og:title'),
    ({ value }) => <meta property="og:title" content={value} />
  ],
  [
    R.propEq('key', 'og:description'),
    ({ value }) => <meta property="og:description" content={value} />
  ],
  [
    R.propEq('key', 'og:image'),
    ({ value }) => <meta property="og:image" content={value} />
  ],
  [R.T, R.always(null)]
])

const PageMeta = ({ meta, title }) => (
  <Helmet>
    <title>{title}</title>
    {R.keys(meta).map(key => renderMetaField({ key, value: meta[key] }))}
  </Helmet>
)

const App = () => {
  const pages = useSelector(state => state.pages)
  const inventory = useSelector(state => state.inventory)
  const blogs = useSelector(state => state.blogs)
  const templates = useSelector(state => state.templates.ids)
  const redirects = useSelector(state => state.redirects)
  const blogTemplate = getBlogTemplate(templates)
  const inventoryTemplate = getInventoryTemplate(templates)
  const favicon = useSelector(state => state.profile.settings.favicon)

  const location = useLocation()

  const win = typeof window === 'undefined' ? {} : window

  useEffect(() => {
    if (win._ss) {
      win._ss.push(['_trackPageView'])
    }
  }, [win._ss, location])

  return (
    <Theme>
      <Helmet>
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />
      </Helmet>
      <Switch>
        {redirects.map(({ original_path, redirect_path }) => (
          <Route
            key={original_path}
            exact
            path={original_path}
            render={() => <Redirect to={redirect_path} />}
          />
        ))}
        <Route
          exact
          path="/login"
          component={loadable(() => import('./Login'))}
        />
        <Route
          exact
          path="/logout"
          component={loadable(() => import('./Logout'))}
        />
        <Route
          exact
          path="/register"
          component={loadable(() => import('./Register'))}
        />
        <Route path="/admin" component={loadable(() => import('./Admin'))} />
        <Route
          exact
          path="/preview"
          component={loadable(() => import('./Preview'))}
        />
        <Route
          exact
          path="/video"
          component={loadable(() => import('./Video'))}
        />
        <Route
          path="/password-reset"
          component={loadable(() => import('./PasswordReset'))}
        />
        {Object.keys(pages.ids)
          .filter(pageID => pages.ids[pageID].published)
          .map(pageID => (
            <Route
              key={pageID}
              exact
              path={pages.ids[pageID].path}
              render={() => {
                const page = pages.ids[pageID]
                const meta = R.propOr({}, 'meta', page)
                return (
                  <PageTheme>
                    <PageMeta meta={meta} title={meta.title} />

                    {pages.ids[pageID].blocks.map(({ component, data, id }) => {
                      /* eslint-disable-next-line no-unused-vars */
                      const Component = Components[component]
                      const AsyncComponent = loadable(() =>
                        import(`~/Components/${component}`)
                      )
                      return <AsyncComponent isLive={true} isPreviewed={false} key={id} {...data} />
                    })}
                  </PageTheme>
                )
              }}
            />
          ))}
        {Object.keys(inventory.ids)
          .filter(id => inventory.ids[id].published)
          .map(id => (
            <Route
              key={id}
              exact
              path={inventory.ids[id].path}
              render={() => (
                <PageTheme>
                  <Helmet title={inventory.ids[id].meta.title}>
                    <meta
                      property="og:description"
                      content={inventory.ids[id].meta.description}
                    />
                    <meta
                      name="description"
                      content={inventory.ids[id].meta.description}
                    />
                    <meta
                      property="og:title"
                      content={inventory.ids[id].meta.title}
                    />
                    <meta
                      property="og:image"
                      content={inventory.ids[id].mainImage}
                    />
                  </Helmet>
                  {inventoryTemplate.blocks.map(
                    ({ component, data = {}, id: block_id }) => {
                      /* eslint-disable-next-line no-unused-vars */
                      const { menu, ...props } = data
                      const Component = Components[component]
                      const AsyncComponent = loadable(() =>
                        import(`~/Components/${component}`)
                      )

                      const InventoryItem = loadable(() =>
                        import('./InventoryItem')
                      )
                      if (component === 'CONTENT') {
                        return <InventoryItem {...inventory.ids[id]} />
                      }
                      return <AsyncComponent key={block_id} {...props} />
                    }
                  )}
                </PageTheme>
              )}
            />
          ))}
        {Object.keys(blogs.ids)
          .filter(id => blogs.ids[id].published)
          .map(id => (
            <Route
              key={id}
              exact
              path={blogs.ids[id].path}
              render={() => (
                <PageTheme>
                  <Helmet title={blogs.ids[id].meta_title}>
                    <meta
                      property="og:description"
                      content={blogs.ids[id].meta_description}
                    />
                    <meta
                      name="description"
                      content={blogs.ids[id].meta_description}
                    />
                    <meta name="author" content={blogs.ids[id].meta_author} />
                    <meta
                      property="og:title"
                      content={blogs.ids[id].meta_title}
                    />
                    <meta
                      property="og:image"
                      content={blogs.ids[id].og_image}
                    />
                  </Helmet>
                  {blogTemplate.blocks.map(
                    ({ component, data = {}, id: block_id }) => {
                      /* eslint-disable-next-line no-unused-vars */
                      const { menu, ...props } = data
                      const Component = Components[component]
                      if (component === 'CONTENT') {
                        return <BlogItem blog={blogs.ids[id]} />
                      }
                      return <Component key={block_id} {...props} />
                    }
                  )}
                </PageTheme>
              )}
            />
          ))}
        <Route component={ErrorPage} />
      </Switch>

      <Alert stack={{ limit: 10, html: true }} />
    </Theme>
  )
}

export default App
