import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

const Page = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #a8b7c6;
`

const NotFound = () => (
  <Page>
    <Typography variant="h2" gutterBottom>
      No Blog Posts found. 😞
    </Typography>
    <Typography>
      Why don't you create one using the menu to the side?
    </Typography>
  </Page>
)

export default NotFound
