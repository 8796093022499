import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/styles'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const PromoContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const PictureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    background: ${({ boxShadow = 'inherit' }) =>
      boxShadow == 'none' ? 'transparent' : '#ffffff'};
  }
`

const PromoLink = styled.a`
  text-decoration: none;
`

const PromoNonLink = styled.div``

const Promo1 = ({
  homes = [
    {
      img:
                'https://web.archive.org/web/20200405072553if_/http://www.inmanmanufacturedhomes.com/assets/SEExterior_7A2DDA804D737CD78C7098CF91FE68D0_41PRF32643AH.jpg'
    }
  ],
  isPreview,
  promoRows,
  background,
  image
}) => {
  const theme = useTheme()

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  if (isPreview) {
    return (
      <PromoContainer>
        {homes.map(({ img }, i) => (
          <PictureContainer boxShadow={theme.boxShadow} key={i}>
            <img src={img} />
          </PictureContainer>
        ))}
      </PromoContainer>
    )
  }

  return (
    <PromoContainer img={image} color={bgColor}>
      {promoRows &&
        promoRows.map(({ image1, link1, title1, alt1 }, i) => (
          <PictureContainer boxShadow={theme.boxShadow} key={i}>
            {link1 ? (
              <PromoLink
                href={link1}
                target={isRelative(link1) ? '_self' : '_blank'}
              >
                <img title={title1} alt={alt1} src={image1} />
              </PromoLink>
            ) : (
              <PromoNonLink>
                <img title={title1} alt={alt1} src={image1} />
              </PromoNonLink>
            )}
          </PictureContainer>
        ))}
    </PromoContainer>
  )
}

Promo1.displayName = 'Promo 1'
Promo1.moduleName = 'Promo1'

export default Promo1
