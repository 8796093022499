import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Typography, Button, ButtonGroup } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import URL from '~/Utils/api_base'
import AdminMenu from '~/Admin/AdminMenu'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`

const StateZipContainer = styled.div`
  display: flex;
`

const StateInput = styled(FormControl)`
  flex-grow: 2;
`

const ZipInput = styled(TextField)`
  flex-grow: 1;
  margin-left: 10px;
`

const PasswordContainer = styled.div`
  display: flex;
`

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
].reduce((a, [state, initial]) => ({ ...a, [initial]: state }))

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    margin-bottom: 2rem;
  }

  fieldset {
    padding: 0.5rem;
    border-radius: 4.5px;
    font-family: inherit;
  }
`

const CreateDomain = () => {
  const [password, setPassword] = useState()
  const [passwordConfirm, setPasswordConfirm] = useState()
  const [manufacturers, setManufacturers] = useState([])
  const [selectedManufacturers, setSelectedManufacturers] = useState([])

  const [currentState, setState] = useState()
  const [theme, setTheme] = useState('Theme1')
  const [error, setError] = useState()

  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    const data = new FormData(e.target)

    const domain = {
      host: data.get('host'),
      tld: data.get('tld')
    }

    const profile = {
      admin: {
        first: data.get('admin_first_name'),
        last: data.get('admin_last_name'),
        phone: data.get('admin_phone'),
        email: data.get('admin_email')
      },
      settings: {
        title: data.get('title')
      },
      company: {
        name: data.get('company'),

        clayton_id: data.get('clayton_id'),
        address: data.get('address'),
        address2: '',
        city: data.get('city'),
        state: data.get('state'),
        zip: data.get('zip')
      },
      brand: {
        theme,
        color1: '',
        color2: ''
      }
    }

    fetch(`${URL}/admin/createAdminDomain`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        domain,
        profile,
        user: {
          password: data.get('confirmPassword'),
          email: data.get('admin_email'),
          first_name: data.get('admin_first_name'),
          last_name: data.get('admin_last_name'),
          contact_email: data.get('admin_email')
        }
      })
    })
      .then(x => x.json())
      .then(res => {
        if (res.error) {
          setError(res.error.detail)
        } else if (res.data) {
          history.push(`/domains/${res.data.domain_id}/admin`)
        }
      })
  }

  useEffect(() => {
    fetch(`${URL}/manufacturers`)
      .then(x => x.json())
      .then(({ data, error }) => {
        if (data) {
          setManufacturers(data)
        } else if (error) {
          setError(error)
        }
      })
  }, [])

  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Create New Domain
        </Typography>
        <Form className="main-content" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            name="company"
            label="Company Name"
            helperText="The name of the company"
          />
          <TextField
            fullWidth
            required
            name="address"
            label="Company Address"
          />
          <TextField fullWidth required name="city" label="City" />
          <StateZipContainer>
            <StateInput margin="normal">
              <InputLabel htmlFor="state">State</InputLabel>
              <Select
                value={currentState}
                onChange={({ target }) => setState(target.value)}
                inputProps={{
                  name: 'state',
                  id: 'state',
                  'aria-label': 'State',
                  title: 'State'
                }}
                required
              >
                <MenuItem value="">Select State</MenuItem>
                {Object.keys(states).map(initial => (
                  <MenuItem key={initial} value={initial}>
                    {states[initial]}
                  </MenuItem>
                ))}
              </Select>
              <input hidden value={currentState} name="state" />
            </StateInput>
            <ZipInput
              type="tel"
              name="zip"
              id="zip"
              aria-label="Postal Code"
              title="Postal Code"
              label="Postal Code"
              margin="normal"
              required
            />
          </StateZipContainer>
          <TextField
            fullWidth
            required
            name="title"
            label="Domain Title"
            helperText="A human-readable title that will be used as a backup if a page does not set a title."
          />
          <FormControl fullWidth required>
            <InputLabel id="theme-select-label">Theme</InputLabel>
            <Select
              labelId="theme-select-label"
              id="theme-select"
              value={theme}
              onChange={e => setTheme(e.target.value)}
            >
              <MenuItem value="Theme1">Theme 1</MenuItem>
              <MenuItem value="Theme2">Theme 2</MenuItem>
              <MenuItem value="Theme3">Theme 3</MenuItem>
              <MenuItem value="Theme4">Theme 4</MenuItem>
            </Select>
            <FormHelperText>
              What style do you want to start with? We can always change this
              later
            </FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            required
            name="clayton_id"
            label="Clayton ID"
            helperText="What is the Clayton ID for this domain? We need this to allow reading from the Clayton API"
          />
          <TextField
            fullWidth
            required
            name="host"
            label="Domain Host"
            helperText="If the domain was ABC.com, this value would be ABC"
          />

          <TextField
            fullWidth
            required
            name="tld"
            label="Domain TLD"
            helperText="If the domain was ABC.com, this value would be .com"
            defaultValue=".com"
          />
          <FormControl fullWidth>
            <InputLabel id="manufacturer-select-label">
              Manufacturers
            </InputLabel>
            <Select
              labelId="manufacturer-select-label"
              id="manufacturer-select"
              value={selectedManufacturers}
              multiple
              onChange={e => setSelectedManufacturers(e.target.value)}
            >
              {manufacturers.map(man => (
                <MenuItem key={man.id} value={man.id}>
                  {man.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              What manufacturers does this user get homes from? You can select
              multiple.
            </FormHelperText>
          </FormControl>
          <fieldset>
            <legend>Admin User</legend>
            <TextField
              fullWidth
              required
              name="admin_first_name"
              label="Admin First Name"
            />
            <TextField
              fullWidth
              required
              name="admin_last_name"
              label="Admin Last Name"
            />
            <TextField
              fullWidth
              required
              name="admin_phone"
              label="Admin Phone"
            />

            <TextField
              fullWidth
              required
              type="email"
              name="admin_email"
              label="Admin Email"
            />
            <PasswordContainer>
              <TextField
                fullWidth
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
                name="password"
                label="Password"
              />
              <TextField
                fullWidth
                required
                name="confirmPassword"
                label="Confirm Password"
                value={passwordConfirm}
                onChange={e => setPasswordConfirm(e.target.value)}
              />
            </PasswordContainer>
            {password !== passwordConfirm && (
              <Typography variant="subtitle2">
                Your passwords do not match.
              </Typography>
            )}
          </fieldset>
          {error && <Typography>{error}</Typography>}
          <ButtonGroup style={{ marginTop: '4rem' }}>
            <Button
              type="submit"
              disabled={password !== passwordConfirm}
              color="primary"
            >
              Create Domain
            </Button>
            <Button onClick={() => history.goBack()}>Cancel</Button>
          </ButtonGroup>
        </Form>
      </Content>
    </Page>
  )
}

export default CreateDomain
