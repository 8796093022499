import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { create } from './State/create'
import { loadableReady } from '@loadable/component'
import 'react-app-polyfill/stable'

import App from './App'
import Admin from './Admin'

const preloadedState =
  typeof window === 'undefined' ? {} : window.preloadedState

const isAdminDeploy =
  typeof window === 'undefined'
    ? process.env.IS_ADMIN_APP
    : window.env.IS_ADMIN_APP

export const store = create(preloadedState)

function Main() {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  return <App />
}

if (isAdminDeploy) {
  render(
    <BrowserRouter>
      <Admin />
    </BrowserRouter>,
    document.getElementById('root')
  )
} else {
  loadableReady().then(() => {
    hydrate(
      <Provider store={store}>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    )
  })
}

if (module.hot) {
  module.hot.accept()
}
