import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import URL from '~/Utils/api_base'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'

import AdminMenu from '~/Admin/AdminMenu'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`

const Manufacturers = () => {
  const [manufacturers, setManufacturers] = useState()
  const [error, setError] = useState()
  useEffect(() => {
    fetch(`${URL}/manufacturers`)
      .then(x => x.json())
      .then(({ data, error }) => {
        if (error) {
          setError(error)
        } else {
          setManufacturers(data)
        }
      })
  }, [])

  if (!manufacturers) {
    return 'Loading...'
  }

  if (error) {
    return <p>Error Loading Page: {error.message}</p>
  }

  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Manufacturers List
        </Typography>
        <List className="main-content">
          {manufacturers.map(manufacturer => (
            <Tooltip title="Edit Manufacturer Data" key={manufacturer.id}>
              <ListItem
                button
                component={Link}
                to={`/manufacturers/${manufacturer.id}`}
              >
                <ListItemText>{manufacturer.name}</ListItemText>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Content>
    </Page>
  )
}

export default Manufacturers
