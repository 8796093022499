import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import FormControl from '@material-ui/core/FormControl'
import SubArrow from '@material-ui/icons/SubdirectoryArrowRight'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import * as R from 'ramda'

import { useMenu } from '~/Hooks/useMenu'
import { actions } from '~/State/ducks/menus'
import { idsLens } from '~/State/ducks/pages'

const ModalBody = styled.div`
  position: absolute;
  width: 50%;
  min-height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  flex-direction: column;
`

const ModalContent = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: flex-end;
  background: #e4e6e9;
  padding: 2rem;
`

const HeaderText = styled(Typography)``

const CloseButton = styled(IconButton)``

const HeaderTextContainer = styled.div`
  margin: auto;
`

const SaveButton = styled(Button)``

const SaveButtonContainer = styled.div`
  margin-top: auto;
  align-self: flex-end;
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
`

const PageFormContainer = styled.div`
  display: flex;
`
const PageItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ChildFormContainer = styled.div`
  display: flex;
`

const AddChildButton = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 2rem;
`

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
`

const InnerChildrenContainer = styled.div`
  display: flex;
  padding-left: 1rem;
  flex-direction: column;
  width: 100%;
`

const MenuSetup = React.forwardRef(({ open, onClose }, ref) => {
  const dispatch = useDispatch()
  const menu = useMenu()
  const originalMenu = useSelector(state => state.menus)
  const [currentPages, setCurrentPages] = useState(menu)

  const pages = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const addPage = pageId => {
    setCurrentPages(R.append({ id: pageId }))
  }

  const removePage = index => {
    setCurrentPages(pages => pages.filter((_, i) => i !== index))
  }

  const removeChildren = (parent, index) => {
    setCurrentPages(oldPages => [
      ...oldPages.slice(0, parent),
      {
        ...oldPages[parent],
        children: [
          ...oldPages[parent].children.slice(0, index),
          ...oldPages[parent].children.slice(index + 1)
        ]
      },
      ...oldPages.slice(parent + 1)
    ])
  }

  const addChild = index => {
    setCurrentPages(oldState => [
      ...oldState.slice(0, index),
      {
        ...oldState[index],
        children: [...(oldState[index].children || []), { id: pages[0].id }]
      },
      ...oldState.slice(index + 1)
    ])
  }

  const updatePage = (path, value) => {
    setCurrentPages(
      R.over(
        R.lensPath(path),
        R.compose(
          R.mergeDeepLeft(value),
          R.defaultTo({})
        )
      )
    )
  }

  const saveMenu = () => {
    dispatch({
      type: actions.UPDATE_MENU_REQUEST,
      payload: {
        ...originalMenu,
        pages: currentPages
      }
    })
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} ref={ref}>
      <ModalBody>
        <HeaderContainer>
          <HeaderTextContainer>
            <HeaderText variant="h5" align="center" gutterBottom>
              Menu Setup
            </HeaderText>
          </HeaderTextContainer>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </HeaderContainer>

        <ModalContent>
          {currentPages.map((page, i) => {
            return (
              <PageItemContainer key={i}>
                <PageFormContainer>
                  <FormControl fullWidth>
                    <InputLabel htmlFor={`${page.id}-name`}>
                      Page Name
                    </InputLabel>
                    <Select
                      value={page.id}
                      onChange={e => updatePage([i], { id: e.target.value })}
                      inputProps={{
                        name: `${page.id}-name`,
                        id: `${page.id}-name`,
                        'aria-label': `Menu Page ${i + 1}`,
                        title: `Menu Page ${i + 1}`,
                        label: `Menu Page ${i + 1}`
                      }}
                    >
                      {pages.map(a => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Tooltip title="Delete page from menu">
                    <IconButton onClick={() => removePage(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </PageFormContainer>
                <ChildrenContainer>
                  <AddChildButton>
                    <Tooltip title="Add a child page">
                      <IconButton onClick={() => addChild(i)}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </AddChildButton>
                  {page.children && page.children.length > 0 ? (
                    <SubArrow />
                  ) : null}
                  <InnerChildrenContainer>
                    {page.children &&
                      page.children.map((child, j) => (
                        <ChildFormContainer key={j}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor={`${child.id}-name`}>
                              Page Name
                            </InputLabel>
                            <Select
                              value={child.id}
                              onChange={e =>
                                updatePage([i, 'children', j], {
                                  id: e.target.value
                                })
                              }
                              inputProps={{
                                name: `${child.id}-name`,
                                id: `${child.id}-name`,
                                'aria-label': `Menu Page ${i + 1}`,
                                title: `Menu Page ${i + 1}`,
                                label: `Menu Page ${i + 1}`
                              }}
                            >
                              {pages.map(a => (
                                <MenuItem key={a.id} value={a.id}>
                                  {a.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Tooltip title="Delete page from menu">
                            <IconButton onClick={() => removeChildren(i, j)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ChildFormContainer>
                      ))}
                  </InnerChildrenContainer>
                </ChildrenContainer>
              </PageItemContainer>
            )
          })}
        </ModalContent>
        {pages.length > 0 ? (
          <Button onClick={() => addPage(pages[0].id)}>Add Page to Menu</Button>
        ) : (
          <Typography align="center">You have no created pages</Typography>
        )}
        <SaveButtonContainer>
          <SaveButton
            align="right"
            color="primary"
            disabled={!pages.length > 0}
            type="submit"
            variant="contained"
            onClick={saveMenu}
          >
            Save Menu
          </SaveButton>
        </SaveButtonContainer>
      </ModalBody>
    </Modal>
  )
})

export default MenuSetup
