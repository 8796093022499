import React, { useState } from 'react'
import styled from '@emotion/styled'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import DaySelector from './DaySelector'
import ImageUpload from '~/SharedComponents/ImageUpload'

const Location = styled(ListItem)`
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const CreateLocationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5rem;
`

const LocationFormItem = styled.div`
  flex: 1;
  padding: 0.5rem;

  .MuiFormControl-root {
    width: 100%;
  }
`
const FormContent = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddressContainer = styled.div``

const ImageUploadContainer = styled.div`
  padding-top: 2rem;
`

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
].reduce((a, [state, initial]) => ({ ...a, [initial]: state }), {})

const StateZipContainer = styled.div`
  display: flex;
`

const StateInput = styled(FormControl)`
  flex-grow: 2;
`

const ZipInput = styled(TextField)`
  flex-grow: 1;
  margin-left: 10px;
`

const LocationComponent = ({ location, updateLocation, deleteLocation }) => {
  const {
    id,
    name,
    description,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    hours,
    image
  } = location
  const [editMe, setEditMe] = useState()
  const [currentState, setState] = useState()
  const [currentImage, setCurrentImage] = useState(image)
  const [newHours, setHours] = useState({
    ...hours
  })

  const cancelAdd = () => {
    setEditMe()
    setState()
  }

  const handleLocationUpdate = e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const info = {
      id,
      name: data.get('name'),
      description: data.get('description'),
      address: data.get('address'),
      address2: data.get('address-2'),
      city: data.get('city'),
      state: data.get('state'),
      zip: Number(data.get('zip')),
      phone: data.get('phone'),
      hours: newHours,
      image: currentImage
    }

    updateLocation(info)
    cancelAdd()
  }

  const handleChange = (name, path, value) => {
    setHours(oldHours => ({
      ...oldHours,
      ...{
        [name]: {
          ...oldHours[name],
          [path]: value
        }
      }
    }))
  }

  return (
    <React.Fragment>
      <Location>
        <ListItemText primary={name} secondary={description} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="edit location"
            title="Edit Location"
            onClick={() => setEditMe(editMe ? null : id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete location"
            title="Delete Location"
            onClick={() => deleteLocation(id)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </Location>
      {editMe === id && (
        <CreateLocationForm onSubmit={handleLocationUpdate}>
          <FormContent>
            <LocationFormItem>
              <InnerContainer>
                <AddressContainer>
                  <Typography>Details</Typography>
                  <TextField
                    id="name"
                    name="name"
                    label="Location Name"
                    defaultValue={name}
                    required
                  />
                  <TextField
                    id="address"
                    name="address"
                    label="Address"
                    defaultValue={address}
                    required
                  />
                  <TextField
                    id="address-2"
                    name="address-2"
                    label="Address 2"
                    defaultValue={address2}
                  />
                  <TextField
                    id="city"
                    name="city"
                    label="City"
                    defaultValue={city}
                    required
                  />
                  <StateZipContainer>
                    <StateInput margin="normal">
                      <InputLabel htmlFor="state">State</InputLabel>
                      <Select
                        value={currentState || state}
                        onChange={({ target }) => setState(target.value)}
                        inputProps={{
                          name: 'state',
                          id: 'state',
                          'aria-label': 'State',
                          title: 'State'
                        }}
                        required
                      >
                        <MenuItem value="">Select State</MenuItem>
                        {Object.keys(states).map(initial => (
                          <MenuItem key={initial} value={initial}>
                            {states[initial]}
                          </MenuItem>
                        ))}
                      </Select>
                      <input
                        hidden
                        value={currentState || state}
                        name="state"
                      />
                    </StateInput>
                    <ZipInput
                      type="tel"
                      name="zip"
                      id="zip"
                      aria-label="Postal Code"
                      title="Postal Code"
                      label="Postal Code"
                      margin="normal"
                      defaultValue={zip}
                      required
                    />
                  </StateZipContainer>
                  <TextField
                    id="phone"
                    name="phone"
                    defaultValue={phone}
                    aria-label="Location Phone Number"
                    title="Location Phone Number"
                    label="Location Phone Number"
                  />
                </AddressContainer>
                <ImageUploadContainer>
                  <Typography align="center">
                    Upload Map Image for Location
                  </Typography>
                  <ImageUpload
                    name="image"
                    onChange={setCurrentImage}
                    image={currentImage}
                  />
                  <input hidden value={currentImage} name="image" />
                </ImageUploadContainer>
              </InnerContainer>
            </LocationFormItem>
            <LocationFormItem>
              <Typography>Store Hours</Typography>
              <DaySelector
                name="sunday"
                title="sunday"
                day="Sunday"
                isOpened={newHours.sunday.isOpened}
                openTime={newHours.sunday.openTime}
                closeTime={newHours.sunday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="monday"
                title="monday"
                day="Monday"
                isOpened={newHours.monday.isOpened}
                openTime={newHours.monday.openTime}
                closeTime={newHours.monday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="tuesday"
                title="tuesday"
                day="Tuesday"
                isOpened={newHours.tuesday.isOpened}
                openTime={newHours.tuesday.openTime}
                closeTime={newHours.tuesday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="wednesday"
                title="wednesday"
                day="Wednesday"
                isOpened={newHours.wednesday.isOpened}
                openTime={newHours.wednesday.openTime}
                closeTime={newHours.wednesday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="thursday"
                title="thursday"
                day="Thursday"
                isOpened={newHours.thursday.isOpened}
                openTime={newHours.thursday.openTime}
                closeTime={newHours.thursday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="friday"
                title="friday"
                day="Friday"
                isOpened={newHours.friday.isOpened}
                openTime={newHours.friday.openTime}
                closeTime={newHours.friday.closeTime}
                onChange={handleChange}
              />
              <DaySelector
                name="saturday"
                title="saturday"
                day="Saturday"
                isOpened={newHours.saturday.isOpened}
                openTime={newHours.saturday.openTime}
                closeTime={newHours.saturday.closeTime}
                onChange={handleChange}
              />
            </LocationFormItem>
          </FormContent>
          <Button type="submit" align="right" color="secondary">
            Update
          </Button>
        </CreateLocationForm>
      )}
    </React.Fragment>
  )
}

export default LocationComponent
