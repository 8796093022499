import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { useMenu } from '~/Hooks/useMenu'
import ListMenuItem from '~/SharedComponents/ListMenuItem'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 320px;
  height: 100px;
  align-items: center;
  justify-content: center;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const AddressAndMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  padding-right: 2rem;

  @media only screen and (max-width: 960px) {
    padding-right: 0;
    text-align: center;
  }
`

const MenuContainer = styled.nav`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 960px) {
    width: 100%;
    align-items: center;
  }
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;

  :hover {
    color: #8e8e8e;
  }
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
`

const AddressTextContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const LogoAndMenuContainer = styled.div`
  display: flex;
`

const ViewContainer = styled.div`
  width: 100%;
`

const Address = styled(Typography)``

const AddressTag = styled.address`
  font-style: normal;
`

const CustomMenu = styled(Menu)`
  ul {
    background: ${({ listColor }) => `${listColor}` || `inherit`};
  }
`

const HamburgerButton = styled(IconButton)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`} !important;
`

const MobileNavContainer = styled.div`
  margin-right: 1.5rem;
`

const Header3 = ({
  header = '1234 Anytown St, Cookeville, TN, 38501',
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  background,
  text,
  isPreview,
  menuText,
  image
}) => {
  const theme = useTheme()
  const [menuElement, setMenuElement] = useState(null)
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const menu = useMenu()

  const pageHash = useSelector(state => state.pages.ids)

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]

  return (
    <HeaderContainer img={image} color={bgColor}>
      {isDesktop ? (
        <ViewContainer>
          <LogoContainer to={'/'}>
            {brand.logo && (
              <img src={brand.logo} alt={`${company.name} Logo`} />
            )}
            {!brand.logo && isPreview && (
              <img src={brand.logo || logo} alt={`${company.name} Logo`} />
            )}
          </LogoContainer>
          <AddressAndMenuContainer>
            <AddressTextContainer color={textColor}>
              <AddressTag>
                <Address>{header}</Address>
              </AddressTag>
            </AddressTextContainer>
            <MenuContainer>
              <MenuItemContainer>
                {isPreview
                  ? previewData.map(({ label, path }) => (
                      <MenuLink to={path} key={path}>
                        <ListMenuItem
                          color={menuTextColor}
                          title={label}
                        ></ListMenuItem>
                      </MenuLink>
                    ))
                  : menu.map(({ id, children }) => (
                      <MenuLink to={pageHash[id].path} key={pageHash[id].path}>
                        <ListMenuItem
                          color={menuTextColor}
                          title={pageHash[id].title}
                          menuChildren={children}
                        ></ListMenuItem>
                      </MenuLink>
                    ))}
              </MenuItemContainer>
            </MenuContainer>
          </AddressAndMenuContainer>
        </ViewContainer>
      ) : (
        <ViewContainer>
          <LogoAndMenuContainer>
            <LogoContainer to={'/'}>
              {brand.logo && <img src={brand.logo} alt={company.name} />}
              {isPreview && <img src={brand.logo || logo} alt={company.name} />}
            </LogoContainer>
            <MenuContainer>
              <MobileNavContainer>
                <HamburgerButton
                  iconColor={menuTextColor}
                  aria-controls="header3-menu"
                  aria-haspopup="true"
                  onClick={e => setMenuElement(e.currentTarget)}
                >
                  <MenuIcon color="inherit" />
                </HamburgerButton>

                <CustomMenu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  getContentAnchorEl={null}
                  listColor={bgColor}
                  id="header3-menu"
                  anchorEl={menuElement}
                  keepMounted
                  open={Boolean(menuElement)}
                  onClose={() => setMenuElement(null)}
                >
                  <List>
                    {isPreview
                      ? previewData.map(({ path, label }, i) => (
                          <ListItem key={i}>
                            <MenuLink to={path}>
                              <ListMenuItem
                                color={menuTextColor}
                                title={label}
                              ></ListMenuItem>
                            </MenuLink>
                          </ListItem>
                        ))
                      : menu.map(({ id, children }, i) => (
                          <ListItem key={i}>
                            <MenuLink to={pageHash[id].path}>
                              <ListMenuItem
                                color={menuTextColor}
                                title={pageHash[id].title}
                                menuChildren={children}
                              ></ListMenuItem>
                            </MenuLink>
                          </ListItem>
                        ))}
                  </List>
                </CustomMenu>
              </MobileNavContainer>
            </MenuContainer>
          </LogoAndMenuContainer>
          <AddressAndMenuContainer></AddressAndMenuContainer>
        </ViewContainer>
      )}
    </HeaderContainer>
  )
}

Header3.displayName = 'Header 3'
Header3.moduleName = 'Header3'

export default Header3
