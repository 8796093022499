import { useSelector } from 'react-redux'

export const useMenu = () => {
  const menuItems = useSelector(state => state.menus.pages)

  const pageHash = useSelector(state => state.pages.ids)

  return menuItems
    .filter(({ id }) => pageHash[id])
    .map(({ children = [], ...rest }) => ({
      ...rest,
      children: children.filter(({ id }) => pageHash[id])
    }))
}
