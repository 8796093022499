import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const LogoContainer = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`

const Card = styled.a`
  text-decoration: none;
  color: inherit;
  max-width: 45px;
  max-height: 35px;
  margin: 0.5rem;
  margin-left: 0;
`

const LogosContainer = styled.div`
  display: flex;
  padding-left: 5rem;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5rem;
  align-items: flex-end;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    align-items: center;
    padding-right: 0;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 45px;
  max-height: 35px;
  align-items: center;
  justify-content: center;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const previewData = [
  {
    url:
      'https://image.shutterstock.com/image-vector/stock-exchange-statistic-icon-vector-600w-1601684254.jpg',
    alt: 'alt tag',
    title: 'title tag'
  },
  {
    url:
      'https://image.shutterstock.com/image-vector/stock-exchange-statistic-icon-vector-600w-1601684254.jpg',
    alt: 'alt tag',
    title: 'title tag'
  },
  {
    url:
      'https://image.shutterstock.com/image-vector/stock-exchange-statistic-icon-vector-600w-1601684254.jpg',
    alt: 'alt tag',
    title: 'title tag'
  }
]

const Affiliations = ({
  background,
  text,
  affiliationLogos,
  isPreview,
  text1,
  text2,
  bgImage
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <LogoContainer img={bgImage} color={bgColor}>
        <LogosContainer>
          {previewData &&
            previewData.map((logo, i) => (
              <Card href={logo.url} target="_blank" key={i}>
                <ImageContainer>
                  <img src={logo.url} alt={logo.alt} title={logo.title} />
                </ImageContainer>
              </Card>
            ))}
        </LogosContainer>
        <TextContainer color={textColor}>
          <Typography gutterBottom variant="caption">
            License Number
          </Typography>
          <Typography variant="caption">{text2}</Typography>
        </TextContainer>
      </LogoContainer>
    )
  }
  return (
    <LogoContainer img={bgImage} color={bgColor}>
      <LogosContainer>
        {affiliationLogos &&
          affiliationLogos.map((logo, i) => (
            <Card href={logo.url} target="_blank" key={i}>
              <ImageContainer>
                <img src={logo.img} alt={logo.alt} title={logo.title} />
              </ImageContainer>
            </Card>
          ))}
      </LogosContainer>
      <TextContainer color={textColor}>
        <Typography gutterBottom variant="caption">
          {text1}
        </Typography>
        <Typography variant="caption">{text2}</Typography>
      </TextContainer>
    </LogoContainer>
  )
}

Affiliations.displayName = 'Affiliations'
Affiliations.moduleName = 'Affiliations'

export default Affiliations
