import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { useMenu } from '~/Hooks/useMenu'
import ListMenuItem from '~/SharedComponents/ListMenuItem'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 85%;
  width: 320px;
  height: 100px;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  padding-top: 1rem;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    height: 100px;
    width: 160px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const AddressContainer = styled(Typography)``

const MenuContainer = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ color }) => `${color}` || `inherit`};
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50px;

  :hover {
    background: #e6e6e6;
    color: #8e8e8e;
  }
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
`

const AddressTag = styled.address`
  font-style: normal;
  color: ${({ color }) => color || 'inherit'};
`

const CustomMenu = styled(Menu)`
  ul {
    background: ${({ listColor }) => `${listColor}` || `inherit`};
  }
`

const HamburgerButton = styled(IconButton)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`} !important;
`

const Header1 = ({
  background,
  feature = 'primary',
  text = 'secondary',
  menuText,
  header = '1234 Any Street, Cookeville, TN 38501 | 123-456-7890',
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  isPreview,
  image
}) => {
  const menu = useMenu()
  const [menuElement, setMenuElement] = useState(null)
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const pageHash = useSelector(state => state.pages.ids)

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const primary = theme.palette.primary.main

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]

  return (
    <HeaderContainer img={image} color={bgColor}>
      <LogoContainer to={'/'}>
        {brand.logo && <img src={brand.logo} alt={`${company.name} Logo`} />}
        {!brand.logo && isPreview && (
          <img src={brand.logo || logo} alt={`${company.name} Logo`} />
        )}
      </LogoContainer>
      {isDesktop && (
        <AddressTag color={textColor}>
          <AddressContainer align="center" color="inherit" gutterBottom>
            {header}
          </AddressContainer>
        </AddressTag>
      )}
      <MenuContainer color={ftColor || primary}>
        {isDesktop ? (
          <MenuItemContainer>
            {isPreview
              ? previewData.map(({ path, label }) => (
                  <MenuLink to={path} key={path}>
                    <ListMenuItem color={menuTextColor} title={label} />
                  </MenuLink>
                ))
              : menu.map(({ id, children }) => (
                  <MenuLink to={pageHash[id].path} key={pageHash[id].path}>
                    <ListMenuItem
                      color={menuTextColor}
                      title={pageHash[id].title}
                      menuChildren={children}
                    />
                  </MenuLink>
                ))}
          </MenuItemContainer>
        ) : (
          <div>
            <HamburgerButton
              iconColor={menuTextColor}
              aria-controls="header1-menu"
              aria-haspopup="true"
              onClick={e => setMenuElement(e.currentTarget)}
            >
              <MenuIcon color="inherit" />
            </HamburgerButton>
            <CustomMenu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              getContentAnchorEl={null}
              listColor={ftColor}
              id="header1-menu"
              anchorEl={menuElement}
              keepMounted
              open={Boolean(menuElement)}
              onClose={() => setMenuElement(null)}
            >
              <List>
                {isPreview
                  ? previewData.map(({ path, label }, i) => (
                      <ListItem key={i}>
                        <MenuLink to={path} key={path}>
                          <ListMenuItem color={menuTextColor} title={label} />
                        </MenuLink>
                      </ListItem>
                    ))
                  : menu.map(({ id, children }, i) => (
                      <ListItem key={i}>
                        <MenuLink
                          to={pageHash[id].path}
                          key={pageHash[id].path}
                        >
                          <ListMenuItem
                            color={menuTextColor}
                            title={pageHash[id].title}
                            menuChildren={children}
                          />
                        </MenuLink>
                      </ListItem>
                    ))}
              </List>
            </CustomMenu>
          </div>
        )}
      </MenuContainer>
    </HeaderContainer>
  )
}

Header1.displayName = 'Header 1'
Header1.moduleName = 'Header1'

export default Header1
