import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { Link } from 'react-router-dom'

import { useTheme } from '@material-ui/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { useMenu } from '~/Hooks/useMenu'
import ListMenuItem from '~/SharedComponents/ListMenuItem'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const MainContainer = styled.div`
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeroContainer = styled.section`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 3rem;
  /* background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */

  @media only screen and (max-width: 768px) {
    align-items: center;
    padding-left: 0;
    padding: 2rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%;
  text-align: left;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const SubtitleTextContainer = styled.div`
  width: 80%;
  text-align: left;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const HeaderText = styled(Typography)`
  line-height: 1.2 !important;

  @media only screen and (max-width: 768px) {
    font-size: 32px !important;
  }
`

const SubtitleText = styled(Typography)``

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: stretch;
  height: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? '95px'
      : theme == 'Theme5'
      ? '146px'
      : theme == 'TimbTheme'
      ? '150px'
      : 'auto'};
  flex-direction: ${({ theme }) =>
    theme == 'Theme5' ? 'column-reverse' : `row`};

  @media only screen and (max-width: 1268px) {
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 320px;
  height: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '90px'
      : theme == 'TimbTheme'
      ? '150px'
      : `100px`};
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
  padding-top: 5px;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const AddressAndCallContainer = styled.div`
  display: flex;
  min-width: 280px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: ${({ theme }) => (theme == 'Theme5' ? 'none' : `auto`)};
  background: ${({ color }) => `${color}` || `inherit`};
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  padding: ${({ theme }) => (theme == 'Theme5' ? '1rem' : `2rem`)};
  @media only screen and (max-width: 1268px) {
    width: 100%;
  }
`

// Potentially used in theme
const OtherAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: 5%;
  background: ${({ color }) => `${color}` || `inherit`};
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem;

  @media only screen and (max-width: 1268px) {
    width: 100%;
    padding: 1rem;
  }
`

const MenuContainer = styled.nav`
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 960px) {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
`

const LogoAndMenuContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :hover {
    color: #8e8e8e;
  }
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
`

const Address = styled(Typography)``

const Phone = styled(Typography)`
  padding-left: ${({ theme }) => (theme == 'Theme5' ? '1rem' : `none`)};
  font-family: ${({ theme }) => theme == 'Theme5' && 'Gelasio'} !important;
  font-weight: ${({ theme }) => theme == 'Theme5' && 600} !important;
  white-space: nowrap;
  text-decoration: none;
`

const Phone2 = styled(Typography)`
  padding-left: ${({ theme }) => (theme == 'Theme5' ? '1rem' : `none`)};
  font-family: ${({ theme }) => theme == 'Theme5' && 'Gelasio'} !important;
  font-weight: ${({ theme }) => theme == 'Theme5' && 600} !important;
  white-space: nowrap;
  font-size: '25px' !important;
  text-decoration: none;
`

const Or = styled(Typography)`
  padding-left: ${({ theme }) => (theme == 'Theme5' ? '1rem' : `none`)};
`

const CallUs = styled(Typography)`
  text-transform: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8' ||
    theme == 'TimbTheme'
      ? 'uppercase'
      : `none`};
`

const AddressTag = styled.address`
  font-style: normal;
  display: flex;

  flex-direction: ${({ theme }) => (theme == 'Theme5' ? 'row' : `column`)};

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`

const CustomMenu = styled(Menu)`
  ul {
    background: ${({ listColor }) => `${listColor}` || `inherit`};
  }
`

const HamburgerButton = styled(IconButton)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`} !important;
`

const MobileNavContainer = styled.div`
  margin-right: 1.5rem;
`

const HeaderHero = ({
  background,
  text,
  image = 'https://i.stack.imgur.com/l60Hf.png',
  phone,
  phone2,
  header = 'Suspendisse maximus, est ac euismod aliqu am dui mi convallis nisi',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'I am the call to action button',
  ctaLink = '',
  btnColor = 'primary',
  btnStyle = 'contained',
  feature = 'primary',
  menuText,
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  isPreview,
  headerSize = 'h1',
  orText = 'Or'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const [menuElement, setMenuElement] = useState(null)

  const theme = useTheme()

  const menu = useMenu()

  const pageHash = useSelector(state => state.pages.ids)

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const primary = theme.palette.primary.main

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]

  const CTA = path => (
    <Button
      href={path}
      target={isRelative(path) ? '_self' : '_blank'}
      color={btnColor}
      variant={btnStyle}
    >
      {ctaText}
    </Button>
  )

  return (
    <MainContainer img={image} color={bgColor}>
      <HeaderContainer theme={currentThemeEnum} img={image} color={bgColor}>
        <LogoAndMenuContainer>
          <LogoContainer theme={currentThemeEnum} to={'/'}>
            {brand.logo && (
              <img src={brand.logo} alt={`${company.name} Logo`} />
            )}
            {!brand.logo && isPreview && (
              <img src={brand.logo || logo} alt={`${company.name} Logo`} />
            )}
          </LogoContainer>
          <MenuContainer>
            {isDesktop ? (
              <MenuItemContainer>
                {isPreview
                  ? previewData.map(({ path, label }) => (
                      <MenuLink to={path} key={path}>
                        <ListMenuItem color={menuTextColor} title={label} />
                      </MenuLink>
                    ))
                  : menu.map(({ id, children }) => (
                      <MenuLink to={pageHash[id].path} key={pageHash[id].path}>
                        <ListMenuItem
                          color={menuTextColor}
                          title={pageHash[id].title}
                          menuChildren={children}
                        />
                      </MenuLink>
                    ))}
              </MenuItemContainer>
            ) : (
              <MobileNavContainer>
                <HamburgerButton
                  iconColor={menuTextColor}
                  aria-controls="header1-menu"
                  aria-haspopup="true"
                  onClick={e => setMenuElement(e.currentTarget)}
                >
                  <MenuIcon color="inherit" />
                </HamburgerButton>
                <CustomMenu
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  listColor={bgColor}
                  getContentAnchorEl={null}
                  id="header1-menu"
                  anchorEl={menuElement}
                  keepMounted
                  open={Boolean(menuElement)}
                  onClose={() => setMenuElement(null)}
                >
                  <List>
                    {isPreview
                      ? previewData.map(({ path, label }, i) => (
                          <ListItem key={i}>
                            <MenuLink to={path} key={path}>
                              <ListMenuItem
                                color={menuTextColor}
                                title={label}
                              />
                            </MenuLink>
                          </ListItem>
                        ))
                      : menu.map(({ id, children }, i) => (
                          <ListItem key={i}>
                            <MenuLink
                              to={pageHash[id].path}
                              key={pageHash[id].path}
                            >
                              <ListMenuItem
                                color={menuTextColor}
                                title={pageHash[id].title}
                                menuChildren={children}
                              />
                            </MenuLink>
                          </ListItem>
                        ))}
                  </List>
                </CustomMenu>
              </MobileNavContainer>
            )}
          </MenuContainer>
        </LogoAndMenuContainer>
        {currentThemeEnum == 'Theme6' ||
        currentThemeEnum == 'Theme7' ||
        currentThemeEnum == 'Theme8' ||
        currentThemeEnum == 'TimbTheme' ? (
          <OtherAddressContainer
            textColor={textColor}
            color={ftColor || primary}
          >
            <AddressTag>
              <CallUs align="center" variant="body2">
                Call Us Today!
              </CallUs>
              <Phone
                align="center"
                component="a"
                href={`tel:${phone}`}
                color="inherit"
                variant="h3"
              >
                {phone}
              </Phone>
              {phone2 && (
                <React.Fragment>
                  <Or theme={currentThemeEnum} align="center">
                    {orText}
                  </Or>
                  <Phone2
                    theme={currentThemeEnum}
                    align="center"
                    component="a"
                    href={`tel:${phone2}`}
                    color="inherit"
                    variant="h3"
                  >
                    {phone2}
                  </Phone2>
                </React.Fragment>
              )}
            </AddressTag>
          </OtherAddressContainer>
        ) : (
          <AddressAndCallContainer
            theme={currentThemeEnum}
            textColor={textColor}
            color={ftColor || primary}
          >
            <AddressTag theme={currentThemeEnum}>
              <CallUs theme={currentThemeEnum} align="center">
                Call Us Today!
              </CallUs>
              <Phone
                theme={currentThemeEnum}
                align="center"
                component="a"
                href={`tel:${phone}`}
                color="inherit"
                variant={currentThemeEnum == 'Theme5' ? 'h3' : 'h2'}
              >
                {phone}
              </Phone>
              {phone2 && (
                <React.Fragment>
                  <Or theme={currentThemeEnum} align="center">
                    {orText}
                  </Or>
                  <Phone2
                    theme={currentThemeEnum}
                    align="center"
                    component="a"
                    href={`tel:${phone}`}
                    color="inherit"
                    variant="h3"
                  >
                    {phone2}
                  </Phone2>
                </React.Fragment>
              )}
              <Address align="center"></Address>
            </AddressTag>
          </AddressAndCallContainer>
        )}
      </HeaderContainer>
      <HeroContainer img={image} color={bgColor}>
        <TextContainer color={textColor}>
          <HeaderText variant={headerSize} gutterBottom>
            {header}
          </HeaderText>
          <SubtitleTextContainer>
            <SubtitleText variant="body1" gutterBottom>
              {subtitle}
            </SubtitleText>
          </SubtitleTextContainer>
          <ButtonContainer>{ctaText && CTA(ctaLink)}</ButtonContainer>
        </TextContainer>
      </HeroContainer>
    </MainContainer>
  )
}

HeaderHero.displayName = 'Header Hero'
HeaderHero.moduleName = 'HeaderHero'

export default HeaderHero
