import React from 'react'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import CategoryManager from './CategoryManager'
import FeatureManager from './FeatureManager'
import LocationManager from './LocationManager'
import View from './View'
import Create from './Create'
import Edit from './Edit'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Footer = styled.footer`
  width: 100%;
  flex-grow: 0;
  padding: 1rem;
`

const Inventory = () => {
  const match = useRouteMatch()

  return (
    <Page>
      <Helmet title="Inventory Management" />
      <Switch>
        <Route exact path={match.path} component={View} />
        <Route path={`${match.path}/categories`} component={CategoryManager} />
        <Route path={`${match.path}/features`} component={FeatureManager} />
        <Route path={`${match.path}/create`} component={Create} />
        <Route path={`${match.path}/edit/:id`} component={Edit} />
        <Route path={`${match.path}/locations`} component={LocationManager} />
      </Switch>
      <Footer>
        <Typography variant="caption">
          Copyright &copy; 2019 PowerSlide
        </Typography>
      </Footer>
    </Page>
  )
}

export default Inventory
