import styled from '@emotion/styled'

const InputContainer = styled.div`
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`

export default InputContainer
