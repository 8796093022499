import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const HeroContainer = styled.section`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ color }) => `${color}` || `inherit`};
  width: 80%;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const SubtitleTextContainer = styled.div`
  width: 80%;
  text-align: center;
  margin-bottom: 3rem;
`

const HeaderText = styled(Typography)`
  line-height: 1.2;
  font-weight: ${({ theme }) =>
    (theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8') &&
    600} !important;

  @media only screen and (max-width: 768px) {
    font-size: 32px !important;
  }
`

const SubtitleText = styled(Typography)`
  font-size: 18px;
`

const Hero2 = ({
  background,
  text,
  image = 'https://i.stack.imgur.com/l60Hf.png',
  header = 'Suspendisse maximus, est ac euismod aliqu am dui mi convallis nisi',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'I am the call to action button',
  ctaLink = '',
  btnColor = 'primary',
  btnStyle = 'contained',
  headerSize = 'h1'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  const CTA = path => (
    <Button
      href={path}
      target={isRelative(path) ? '_self' : '_blank'}
      color={btnColor}
      variant={btnStyle}
    >
      {ctaText}
    </Button>
  )

  return (
    <HeroContainer img={image} color={bgColor}>
      <TextContainer color={textColor}>
        <HeaderText
          variant={headerSize}
          theme={currentThemeEnum}
          align="center"
          gutterBottom
        >
          {header}
        </HeaderText>
        <SubtitleTextContainer>
          <SubtitleText variant="body1" align="center" gutterBottom>
            {subtitle}
          </SubtitleText>
        </SubtitleTextContainer>
      </TextContainer>
      {ctaText && CTA(ctaLink)}
    </HeroContainer>
  )
}

Hero2.displayName = 'Hero 2'
Hero2.moduleName = 'Hero2'

export default Hero2
