import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { Button } from '@material-ui/core'

import { actions } from '~/State/ducks/users'

const UserItem = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const Inputs = styled.div`
  display: flex;
  width: 100%;

  .MuiFormControl-root {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const Input = styled(TextField)`
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddUsersContainer = styled.form`
  display: flex;
  flex-direction: column;
`

const AddUsersInputsContainer = styled.div`
  border: solid 1px;
  width: 100%;
  margin-top: 1rem;
`

const ItemWrapper = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`

const EditUser = ({ confirmDeleteUser, userInfo }) => {
  const dispatch = useDispatch()
  
  const [editMe, setEditMe] = useState()

  const roles = useSelector(state => {
    const rolesObject = R.view(R.lensProp('roles'), state)
    const ids = R.view(R.lensProp('ids'), rolesObject)

    return R.values(ids)
  })

  const userRole = roles.filter((role) => role.name.toLowerCase() == userInfo.role.toLowerCase())

  const [role, setRole] = useState(userRole[0].id)

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    // Grab Form Data
    const formdata = new FormData(e.target)
    // Create Object from it
    const data = {
      id: userInfo.id,
      password: formdata.get('userPassword'),
      email: formdata.get('userEmail'),
      first_name: formdata.get('userFirstName'),
      last_name: formdata.get('userLastName'),
      role_id: formdata.get('roleId'),
      contact_email: formdata.get('contactEmail')
    }

    dispatch({
      type: actions.UPDATE_USER_REQUEST,
      payload: data
    })

    setEditMe()
  }

  console.log(userInfo)
  console.log(roles)
  console.log(userRole)
  console.log(role)

  return (
    <React.Fragment>
      <UserItem>
        <ItemWrapper>
          <Typography>
            {userInfo.first_name} {userInfo.last_name}
          </Typography>
        </ItemWrapper>
        <ItemWrapper>
          <Typography>{userInfo.email}</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <Typography>{userInfo.contact_email}</Typography>
        </ItemWrapper>
        <ItemWrapper>
          <Typography>{userInfo.role}</Typography>
        </ItemWrapper>
        <ButtonWrapper>
          <IconButton
            edge="end"
            aria-label="Edit User"
            title="Edit User"
            onClick={() => setEditMe(editMe ? null : userInfo.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="Delete User"
            title="Delete User"
            onClick={() => confirmDeleteUser(userInfo.id)}
          >
            <DeleteIcon />
          </IconButton>
        </ButtonWrapper>
      </UserItem>
      {editMe === userInfo.id && (
        <AddUsersContainer onSubmit={handleSubmit}>
          <AddUsersInputsContainer>
            <InputsContainer>
              <Inputs>
                <Input
                  id="userFirstName"
                  name="userFirstName"
                  label="First Name"
                  aria-label="First Name"
                  title="First Name"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.first_name}
                />
                <Input
                  id="userLastName"
                  name="userLastName"
                  label="Last Name"
                  aria-label="Last Name"
                  title="Last Name"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.last_name}
                />
              </Inputs>
              <Inputs>
                <Input
                  id="userEmail"
                  name="userEmail"
                  label="User Email"
                  aria-label="User Email"
                  title="Email"
                  type="email"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.email}
                />
                <Input
                  id="contactEmail"
                  name="contactEmail"
                  label="Contact Email"
                  aria-label="Contact Email"
                  title="Contact Email"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.contact_email}
                />
              </Inputs>
              <Inputs>
                <Input
                  name="userPassword"
                  id="password"
                  type="password"
                  aria-label="Password"
                  title="Password"
                  label="Password"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.password}
                  required
                />
                <Input
                  name="userConfirmPassword"
                  id="password"
                  type="password"
                  aria-label="Confirm Password"
                  title="Confirm Password"
                  label="Confirm Password"
                  margin="normal"
                  variant="outlined"
                  defaultValue={userInfo.password}
                  required
                />
              </Inputs>
              <Inputs>
                <FormControl fullWidth>
                  <InputLabel htmlFor="roleId">User Role</InputLabel>
                  <Select
                    value={role}
                    onChange={e => {
                      setRole(e.target.value)
                    }}
                    inputProps={{
                      name: 'roleId',
                      id: 'roleId',
                      'aria-label': 'Role Name',
                      title: 'Role Name',
                      label: 'Role Name'
                    }}
                  >
                    {roles
                      .filter(a => a.name.toLowerCase() !== 'superadmin')
                      .map(a => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Inputs>
            </InputsContainer>
            <ButtonContainer>
              <Button variant="contained" type="submit">
                Update User
              </Button>
            </ButtonContainer>
          </AddUsersInputsContainer>
        </AddUsersContainer>
      )}
    </React.Fragment>
  )
}

export default EditUser
