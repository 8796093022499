import URL from '~/Utils/api_base'
const prod = (action, token) =>
  fetch(`${URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(action.payload)
  }).then(x => x.json())

export default prod
