import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Star from '@material-ui/icons/Star'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import Carousel from 'nuka-carousel'
import IconButton from '@material-ui/core/IconButton'
import PrevArrow from '@material-ui/icons/ArrowBackIos'
import NextArrow from '@material-ui/icons/ArrowForwardIos'
import { useTheme } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const ReviewContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  /* https://github.com/FormidableLabs/nuka-carousel/issues/349
  The wrong width is a known issue that will likely not be fixed,
  we are using !important to override their styles */

  .slider-list {
    width: 100% !important;
  }
  .slider-frame {
    width: 100% !important;
  }

  .paging-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 0;
    padding-top: 1rem;
  }
`

const HeaderContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const HeaderText = styled(Typography)`
  margin-top: 1rem;
`

const ReviewAndNameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 5rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }
`

const NameAndCityContainer = styled.div`
  min-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
  color: ${({ color }) => `${color}` || `inherit`};
  flex: 1;

  @media only screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: flex-start;
    padding-left: 0;
  }
`

const NameContainer = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
`

const Review = styled.p`
  padding-left: 2rem;
  font-size: 24px;
  line-height: 40px;
  color: inherit;

  text-indent: -0.9rem;

  :before {
    display: inline-block;
    content: '"';
    font-family: inherit;
    font-size: 5rem;
    line-height: 1px;
    position: relative;
    left: -20px;
    transform: rotate(180deg);
  }

  :after {
    display: inline-block;
    content: '"';
    font-family: inherit;
    font-size: 5rem;
    line-height: 1px;
    bottom: -40px;
    right: 10px;
    position: relative;
    float: right;
  }
`

const ReviewTextContainer = styled.div`
  flex: 3;
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ color }) => `${color}` || `inherit`};
  line-height: 40px;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const RatingContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
`

const Name = styled(Typography)``

const City = styled(Typography)``

const LineDivider = styled.hr`
  width: 100%;
  margin-bottom: 1rem;
`

const Reviews3 = ({
  reviews,
  background,
  text,
  isPreview,
  image,
  header = 'Customer Testimonials',
  name = 'Name of Person',
  location = 'City, State',
  rating = 12345,
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
}) => {
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up('sm'))

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const primary = theme.palette.primary.main

  if (isPreview) {
    return (
      <ReviewContainer color={bgColor}>
        <HeaderContainer color={textColor}>
          <HeaderText align="center" variant="h2" color="inherit">
            {header}
          </HeaderText>
        </HeaderContainer>
        <Carousel
          autoplay
          wrapAround
          autoplayInterval={5000}
          slidesToShow={1}
          renderBottomCenterControls={null}
          renderCenterLeftControls={({ previousSlide }) => (
            <IconButton onClick={previousSlide}>
              <PrevArrow />
            </IconButton>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <IconButton onClick={nextSlide}>
              <NextArrow />
            </IconButton>
          )}
        >
          <ReviewAndNameContainer>
            <NameAndCityContainer variant="h6" color={textColor}>
              <RatingContainer color={primary}>
                {Array.from(String(rating), Number).map((_, i) => (
                  <Star key={i} />
                ))}
              </RatingContainer>
              <NameContainer>
                <Name gutterBottom variant="h3" color="inherit">
                  {name}
                </Name>
                <br />
                <City variant="h5" color="inherit">
                  {location}
                </City>
              </NameContainer>
            </NameAndCityContainer>
            {mobileBreakpoint ? null : <LineDivider />}
            <ReviewTextContainer
              fontFamily={theme.typography.fontFamily}
              color={textColor}
            >
              <Review>{content}</Review>
            </ReviewTextContainer>
          </ReviewAndNameContainer>
          <ReviewAndNameContainer>
            <NameAndCityContainer variant="h6" color={textColor}>
              <RatingContainer color={primary}>
                {Array.from(String(rating), Number).map((_, i) => (
                  <Star key={i} />
                ))}
              </RatingContainer>
              <NameContainer>
                <Name gutterBottom variant="h3" color="inherit">
                  {name}
                </Name>
                <br />
                <City variant="h5" color="inherit">
                  {location}
                </City>
              </NameContainer>
            </NameAndCityContainer>
            {mobileBreakpoint ? null : <LineDivider />}
            <ReviewTextContainer
              fontFamily={theme.typography.fontFamily}
              color={textColor}
            >
              <Review>{content}</Review>
            </ReviewTextContainer>
          </ReviewAndNameContainer>
        </Carousel>
      </ReviewContainer>
    )
  }

  return (
    <ReviewContainer img={image} color={bgColor}>
      <HeaderContainer color={textColor}>
        <HeaderText align="center" variant="h2" color="inherit">
          {header}
        </HeaderText>
      </HeaderContainer>
      {reviews && reviews.length > 1 ? (
        <Carousel
          autoplay
          wrapAround
          autoplayInterval={7000}
          slidesToShow={1}
          renderBottomCenterControls={null}
          renderCenterLeftControls={({ previousSlide }) => (
            <IconButton onClick={previousSlide}>
              <PrevArrow />
            </IconButton>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <IconButton onClick={nextSlide}>
              <NextArrow />
            </IconButton>
          )}
        >
          {reviews.map((review, i) => (
            <ReviewAndNameContainer key={i}>
              <NameAndCityContainer variant="h6" color={textColor}>
                <RatingContainer>
                  {Array.from(String(review.rating), Number).map((_, i) => (
                    <Star key={i} />
                  ))}
                </RatingContainer>
                <NameContainer>
                  <Name gutterBottom variant="h3" color="inherit">
                    {review.name}
                  </Name>
                  <br />
                  <City variant="h5" color="inherit">
                    {review.location}
                  </City>
                </NameContainer>
              </NameAndCityContainer>
              <Divider
                orientation={mobileBreakpoint ? 'vertical' : 'horizontal'}
              />
              <ReviewTextContainer
                fontFamily={theme.typography.fontFamily}
                color={textColor}
              >
                <Review variant="h3" color="inherit">
                  {review.content}
                </Review>
              </ReviewTextContainer>
            </ReviewAndNameContainer>
          ))}
        </Carousel>
      ) : (
        <span>
          {reviews &&
            reviews.map((review, i) => (
              <ReviewAndNameContainer key={i}>
                <NameAndCityContainer variant="h6" color={textColor}>
                  <RatingContainer>
                    {Array.from(String(review.rating), Number).map((_, i) => (
                      <Star key={i} />
                    ))}
                  </RatingContainer>
                  <NameContainer>
                    <Name gutterBottom variant="h3" color="inherit">
                      {review.name}
                    </Name>
                    <br />
                    <City variant="h5" color="inherit">
                      {review.location}
                    </City>
                  </NameContainer>
                </NameAndCityContainer>
                <Divider
                  orientation={mobileBreakpoint ? 'vertical' : 'horizontal'}
                />
                <ReviewTextContainer
                  fontFamily={theme.typography.fontFamily}
                  color={textColor}
                >
                  <Review variant="h3" color="inherit">
                    {review.content}
                  </Review>
                </ReviewTextContainer>
              </ReviewAndNameContainer>
            ))}
        </span>
      )}
    </ReviewContainer>
  )
}

Reviews3.displayName = 'Reviews 3'
Reviews3.moduleName = 'Reviews3'

export default Reviews3
