export const createActions = (domain, list) => {
  const prefix = `@@${domain}`

  return list.reduce((a, c) => ({ ...a, [c]: `${prefix}/${c}` }), {})
}

export const createHandler = handler => (state, action) => {
  if (handler[action.type]) {
    return handler[action.type](state, action)
  }

  return state
}
