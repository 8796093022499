import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import VideocamIcon from '@material-ui/icons/Videocam'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { format, parseISO } from 'date-fns'

import styled from '@emotion/styled'
import * as R from 'ramda'
import { CSVLink } from 'react-csv'

import { actions, idsLens } from '~/State/ducks/leads'
import { idLens } from '~/State/ducks/domain'

const Inputs = styled.div`
  flex-grow: 2;
  width: 100%;
`

const ModalBody = styled.div`
  width: 70%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const ModalActions = styled.div`
  margin: 0 auto;
`

const TableContainer = styled.div`
  max-height: 440px;
  width: 100%;
  overflow: auto;
`

const HeaderAndButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const DropDown = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
  margin-right: 1rem;
`

const DropDownFormControl = styled(FormControl)``

const Leads = () => {
  const domain_id = useSelector(R.view(idLens))
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [confirmDelete, setConfirmDelete] = useState()
  const dispatch = useDispatch()
  const leads = useSelector(state => R.values(R.view(idsLens, state)))

  const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'email', label: 'Email Address', minWidth: 100 },
    {
      id: 'phone',
      label: 'Phone Number',
      minWidth: 170
    },
    {
      id: 'date',
      label: 'Date Submitted',
      minWidth: 170
    },
    {
      id: 'status',
      label: 'Lead Status',
      minWidth: 170,
      maxWidth: 240
    },
    {
      id: 'message',
      label: 'Message',
      minWidth: 170
    }
  ]

  const updateLead = (lead, status) => {
    const data = {
      ...lead,
      domain_id,
      status
    }

    dispatch({
      type: actions.UPDATE_LEAD_REQUEST,
      payload: data
    })
  }

  const csvData = leads.map(lead => {
    return [
      lead.form_data.name,
      lead.form_data.company,
      lead.form_data.email,
      lead.form_data.phone,
      lead.created_at,
      format(parseISO(lead.created_at), 'MM-dd-yyyy'),
      lead.status,
      lead.form_data.message
    ]
  })

  const exportData = [
    ['Name', 'Email Address', 'Phone', 'Date', 'Status', 'Message'],
    ...csvData
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Inputs>
      <HeaderAndButtonContainer>
        <Typography>Leads</Typography>
        <div>
          <Button
            style={{ marginRight: '1rem' }}
            component={Link}
            to="/video?dealer=true"
            variant="contained"
          >
            Schedule Video Tour
          </Button>
          <CSVLink
            filename={`${format(new Date(), 'yyyy-MM-dd')}-leads.csv`}
            data={exportData}
          >
            <Button variant="contained">Export to CSV</Button>
          </CSVLink>
        </div>
      </HeaderAndButtonContainer>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leads
              .sort((a, b) => {
                const aTime = new Date(a.created_at)
                const bTime = new Date(b.created_at)

                if (aTime > bTime) {
                  return -1
                }

                if (bTime > aTime) {
                  return 1
                }

                return 0
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(lead => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={lead.id}>
                    <TableCell>
                      <IconButton onClick={() => setConfirmDelete(lead.id)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={`/video?dealer=true&email=${lead.form_data.email}`}
                      >
                        <VideocamIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell key="name">{lead.form_data.name}</TableCell>
                    <TableCell key="email">{lead.form_data.email}</TableCell>
                    <TableCell key="phone">{lead.form_data.phone}</TableCell>
                    <TableCell key="message">
                      {format(parseISO(lead.created_at), 'MM-dd-yyyy')}
                    </TableCell>
                    <TableCell key="status">
                      <DropDownFormControl variant="outlined">
                        <DropDown
                          name="status"
                          value={lead.status}
                          onChange={e => updateLead(lead, e.target.value)}
                          inputProps={{
                            name: 'status',
                            id: 'status'
                          }}
                        >
                          <MenuItem value="contacted">Contacted</MenuItem>
                          <MenuItem value="not contacted">
                            Not Contacted
                          </MenuItem>
                          <MenuItem value="video tour requested">
                            Video Tour Requested
                          </MenuItem>
                          <MenuItem value="video tour scheduled">
                            Video Tour Scheduled
                          </MenuItem>
                        </DropDown>
                      </DropDownFormControl>
                    </TableCell>
                    <TableCell key="message">
                      {lead.form_data.message}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={leads.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Modal open={!!confirmDelete} onClose={() => setConfirmDelete(false)}>
        <ModalBody>
          <Typography align="center" variant="h5" gutterBottom>
            Are you sure you want to delete
          </Typography>
          <ModalActions>
            <ButtonGroup>
              <Button color="primary" onClick={() => setConfirmDelete(false)}>
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  dispatch({
                    type: actions.DELETE_LEAD_REQUEST,
                    payload: {
                      id: confirmDelete
                    }
                  })

                  setConfirmDelete()
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ModalActions>
        </ModalBody>
      </Modal>
    </Inputs>
  )
}

export default Leads
