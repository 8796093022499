import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import styled from '@emotion/styled'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { Button } from '@material-ui/core'

import { actions } from '~/State/ducks/users'

const Inputs = styled.div`
  display: flex;
  width: 100%;

  .MuiFormControl-root {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const Input = styled(TextField)`
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddUsersContainer = styled.form`
  display: flex;
  flex-direction: column;
`

const AddUsersInputsContainer = styled.div`
  border: solid 1px;
  width: 100%;
  margin-top: 1rem;
`

const PasswordMessageContainer = styled.div`
  margin: 1rem;
  display: flex;
  justify-content: center;
`

const AddUser = ({ removeForm, index }) => {
  const [password, setPassword] = useState()
  const [passwordConfirm, setPasswordConfirm] = useState()
  const dispatch = useDispatch()
  const domain_id = useSelector(state => state.domain.id)
  const [role, setRole] = useState()

  const roles = useSelector(state => {
    const rolesObject = R.view(R.lensProp('roles'), state)
    const ids = R.view(R.lensProp('ids'), rolesObject)

    return R.values(ids)
  })

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    // Grab Form Data
    const formdata = new FormData(e.target)
    // Create Object from it
    const data = {
      domain_id,
      password: formdata.get('userPassword'),
      email: formdata.get('userEmail'),
      first_name: formdata.get('userFirstName'),
      last_name: formdata.get('userLastName'),
      role_id: formdata.get('roleId'),
      contact_email: formdata.get('contactEmail')
    }

    dispatch({
      type: actions.REGISTER_USER_NO_LOGIN,
      payload: data
    })

    removeForm(index)
  }

  return (
    <AddUsersContainer onSubmit={handleSubmit}>
      <AddUsersInputsContainer>
        <InputsContainer>
          <Inputs>
            <Input
              id="userFirstName"
              name="userFirstName"
              label="First Name"
              aria-label="First Name"
              title="First Name"
              margin="normal"
              variant="outlined"
            />
            <Input
              id="userLastName"
              name="userLastName"
              label="Last Name"
              aria-label="Last Name"
              title="Last Name"
              margin="normal"
              variant="outlined"
            />
          </Inputs>
          <Inputs>
            <Input
              id="userEmail"
              name="userEmail"
              label="User Email"
              aria-label="User Email"
              title="Email"
              type="email"
              margin="normal"
              variant="outlined"
              required
            />
            <Input
              id="contactEmail"
              name="contactEmail"
              label="Contact Email"
              aria-label="Contact Email"
              title="Contact Email"
              margin="normal"
              variant="outlined"
              required
            />
          </Inputs>

          <Inputs>
            <Input
              name="userPassword"
              id="password"
              type="password"
              aria-label="Password"
              title="Password"
              label="Password"
              margin="normal"
              variant="outlined"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <Input
              name="userConfirmPassword"
              id="password"
              type="password"
              aria-label="Confirm Password"
              title="Confirm Password"
              label="Confirm Password"
              margin="normal"
              variant="outlined"
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.target.value)}
              required
            />
          </Inputs>
          <PasswordMessageContainer>
            {password !== passwordConfirm && (
              <Typography variant="subtitle2">
                Your passwords do not match.
              </Typography>
            )}
          </PasswordMessageContainer>
          <Inputs>
            <FormControl fullWidth>
              <InputLabel htmlFor="roleId">User Role</InputLabel>
              <NativeSelect
                value={role}
                required
                onChange={e => {
                  setRole(e.target.value)
                }}
                inputProps={{
                  name: 'roleId',
                  id: 'roleId',
                  'aria-label': 'Role Name',
                  title: 'Role Name',
                  label: 'Role Name'
                }}
              >
                {roles
                  .filter(a => a.name.toLowerCase() !== 'superadmin')
                  .map(a => (
                    <option key={a.id} value={a.id}>
                      {a.name}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </Inputs>
        </InputsContainer>
        <ButtonContainer>
          <Button variant="contained" onClick={() => removeForm(index)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={password !== passwordConfirm}
            type="submit"
          >
            Add User
          </Button>
        </ButtonContainer>
      </AddUsersInputsContainer>
    </AddUsersContainer>
  )
}

export default AddUser
