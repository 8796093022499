import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { useSelector, useDispatch } from 'react-redux'
import TimePicker from 'rc-time-picker'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CancelIcon from '@material-ui/icons/HighlightOff'
import 'rc-time-picker/assets/index.css'
import moment from 'moment-timezone'

import * as R from 'ramda'

import URL from '~/Utils/api_base'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { actions } from '~/State/ducks/leads'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const ContactContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  color: ${({ color }) => `${color}` || `inherit`};
  flex: 1;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  flex-direction: column;
  flex: 1;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 0;
    padding-right: 1rem;
  }
`

const MessageBox = styled(TextField)`
  width: 100%;
  margin-top: 2rem;
  background: white;
  flex: 1;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};
  margin-top: 16px;
`

const MessageContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  flex: 1;

  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1rem;
    margin-top: 16px;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 1rem;
    padding-right: 0;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding-right: 4rem;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const HeaderText = styled(Typography)`
  font-weight: ${({ theme }) =>
    (theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8') &&
    600} !important;
`

const SubtitleText = styled(Typography)`
  padding-top: 2rem;

  @media only screen and (max-width: 768px) {
    padding-bottom: 2rem;
  }
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Input = styled(TextField)`
  background: white;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};

  label {
    color: #7b7c7c;
  }
`

const ResetButton = styled(Button)`
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const MessageTextContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
  padding-bottom: 2rem;
  text-align: center;
`

const InnerFormContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const TimeSelector = styled(TimePicker)`
  margin-top: 16px;
  margin-bottom: 8px;

  .rc-time-picker-input {
    font-family: ${({ theme }) =>
      `${theme.typography.body1.fontFamily}` || `inherit`};
    height: 56px;
    font-size: 1rem;
    padding: 18.5px 14px;
  }
  .rc-time-picker-clear {
    right: 16px;
    top: 16px;
  }
`

const VideoRequest = ({
  background,
  text,
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  image,
  btnColor = 'primary',
  btnStyle = 'contained',
  header = 'Schedule a Video Tour',
  ctaText = 'Schedule a Video Tour'
}) => {
  const [showMessage, setShowMessage] = useState(false)
  const [appTime, setAppTime] = useState(moment())
  const [appDate, setAppDate] = useState(moment())
  const [showForm, setShowForm] = useState(false)

  const myRef = useRef(null)

  const dispatch = useDispatch()

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)

    //code for sending API call to send email to dealer

    setAppDate(oldState =>
      oldState.set({
        hour: appTime.get('hour'),
        minute: appTime.get('minute'),
        second: 0,
        millisecond: 0
      })
    )

    const data = {
      dateTime: appDate.utc().format('YYYY-MM-DDhh:mmA'),
      timeZone: moment.tz.guess(),
      name: formdata.get('name'),
      email: formdata.get('email'),
      message: formdata.get('message'),
      phone: formdata.get('phone'),
      host,
      tld,
      domain_id
    }

    fetch(`${URL}/video/sendRequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(data)
    }).then(x => x.json())

    const leadData = {
      domain_id,
      status: 'video tour requested',
      form_data: {
        name: formdata.get('name'),
        company: 'none',
        email: formdata.get('email'),
        phone: formdata.get('phone'),
        message: formdata.get('message')
      }
    }

    dispatch({
      type: actions.CREATE_LEAD_REQUEST,
      payload: leadData
    })

    window.scrollTo(0, myRef.current.offsetTop)

    setShowMessage(true)
  }

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  const host = useSelector(state => state.domain.host)
  const tld = useSelector(state => state.domain.tld)
  const domain_id = useSelector(state => state.domain.id)

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const format = 'h:mm A'

  const now = moment()
    .hour(0)
    .minute(0)

  return (
    <ContactContainer
      ref={myRef}
      onSubmit={handleSubmit}
      img={image}
      color={bgColor}
    >
      <TextContainer color={textColor}>
        <HeaderText
          theme={currentThemeEnum}
          color="inherit"
          component="p"
          variant="h2"
          align="center"
        >
          {header}
        </HeaderText>
        {!showMessage && (
          <SubtitleText align="center" color="inherit" variant="body1">
            {subtitle}
          </SubtitleText>
        )}
      </TextContainer>
      {!showForm && (
        <Button
          onClick={() => setShowForm(true)}
          color={btnColor}
          variant={btnStyle}
        >
          {ctaText}
        </Button>
      )}
      {showForm && (
        <React.Fragment>
          {showMessage ? (
            <MessageTextContainer color={textColor}>
              <Typography component="p" variant="h3" color="inherit">
                Thank you for your submission! You will receive an email with
                further instructions.
              </Typography>
            </MessageTextContainer>
          ) : (
            <React.Fragment>
              <FormContainer>
                <CloseButtonContainer>
                  <CancelIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowForm(false)}
                  />
                </CloseButtonContainer>
                <InnerFormContainer>
                  <InformationContainer>
                    <React.Fragment>
                      <Input
                        theme={currentThemeEnum}
                        name="date"
                        id="date"
                        type="date"
                        aria-label="Date"
                        title="Date"
                        margin="normal"
                        variant="outlined"
                        required
                        onChange={e => setAppDate(moment(e.target.value))}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TimeSelector
                        theme={currentTheme}
                        showSecond={false}
                        defaultValue={now}
                        className="xxx"
                        onChange={e => setAppTime(e)}
                        format={format}
                        use12Hours
                        inputReadOnly
                      />
                    </React.Fragment>
                    <Input
                      theme={currentThemeEnum}
                      name="name"
                      id="name"
                      required
                      aria-label="Name"
                      title="Name"
                      label="Name"
                      margin="normal"
                      variant="outlined"
                    />
                    <Input
                      theme={currentThemeEnum}
                      name="email"
                      id="email"
                      aria-label="Email"
                      title="Email"
                      required
                      label="Email"
                      margin="normal"
                      variant="outlined"
                    />
                    <Input
                      theme={currentThemeEnum}
                      name="phone"
                      id="phone"
                      aria-label="Phone Number"
                      title="Phone"
                      label="Phone"
                      margin="normal"
                      variant="outlined"
                    />
                  </InformationContainer>
                  <MessageContainer>
                    <MessageBox
                      theme={currentThemeEnum}
                      name="message"
                      id="message"
                      aria-label="Message"
                      title="Message"
                      label="Message"
                      multiline
                      variant="outlined"
                      rows={isDesktop ? 18 : 12}
                    />
                  </MessageContainer>
                </InnerFormContainer>
              </FormContainer>
              <ButtonContainer>
                <ResetButton textColor={textColor} type="reset">
                  Reset
                </ResetButton>
                <SubmitButton
                  color={btnColor}
                  disabled={
                    showMessage ||
                    (appTime &&
                      moment().isAfter(
                        moment(appDate).set({
                          hour: appTime.get('hour'),
                          minute: appTime.get('minute'),
                          second: 0,
                          millisecond: 0
                        })
                      ))
                  }
                  type="submit"
                  variant={btnStyle}
                >
                  Submit
                </SubmitButton>
              </ButtonContainer>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </ContactContainer>
  )
}

VideoRequest.displayName = 'Video Request'
VideoRequest.moduleName = 'VideoRequest'

export default VideoRequest
