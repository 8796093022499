import React, { useState } from 'react'
import List from '@material-ui/core/List'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const MenuLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  color: ${({ color }) => `${color}` || `inherit`};
`

const MenuListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => `${color}` || `inherit`};
`

const ExpandDown = styled(ExpandMore)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`};
`

const IconContainer = styled(IconButton)`
  margin-right: -1rem !important;
`

const ExpandUp = styled(ExpandLess)`
  color: ${({ iconColor }) => `${iconColor}` || `inherit`};
`

const CustomListItem = styled(ListItem)`
  color: ${({ color }) => `${color}` || `inherit`};
`

const ListMenuItem = ({ title, menuChildren, color }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [menuElement, setMenuElement] = useState(null)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const pageHash = useSelector(state => state.pages.ids)

  const handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(isOpen => !isOpen)
  }

  const handleDesktopClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setMenuElement(e.currentTarget)
  }

  const handleClickOff = e => {
    e.preventDefault()
    e.stopPropagation()
    setMenuElement(null)
  }

  return (
    <React.Fragment>
      {isDesktop ? (
        <React.Fragment>
          <MenuListItem color={color}>
            <Typography>{title}</Typography>
            {menuChildren && menuChildren.length > 0 ? (
              <React.Fragment>
                <IconContainer onClick={e => handleDesktopClick(e)}>
                  {menuElement ? (
                    <ExpandUp iconColor={color} />
                  ) : (
                    <ExpandDown iconColor={color} />
                  )}
                </IconContainer>
              </React.Fragment>
            ) : null}
          </MenuListItem>
          {menuChildren && menuChildren.length > 0 ? (
            <React.Fragment>
              <Menu
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                getContentAnchorEl={null}
                id={`${title}-id`}
                anchorEl={menuElement}
                keepMounted
                open={Boolean(menuElement)}
                onClose={e => handleClickOff(e)}
              >
                {menuChildren.map((child, i) => {
                  return (
                    <MenuItem key={i}>
                      <MenuLink to={pageHash[child.id].path}>
                        <Typography>{pageHash[child.id].title}</Typography>
                      </MenuLink>
                    </MenuItem>
                  )
                })}
              </Menu>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CustomListItem color={color} style={{ justifyContent: 'center' }}>
            <Typography>{title}</Typography>
            {menuChildren && menuChildren.length > 0 ? (
              <IconContainer onClick={e => handleClick(e)}>
                {open ? (
                  <ExpandUp iconColor={color} />
                ) : (
                  <ExpandDown iconColor={color} />
                )}
              </IconContainer>
            ) : null}
          </CustomListItem>
          {menuChildren && menuChildren.length > 0 ? (
            <Collapse in={open} unmountOnExit>
              <List component="div" disablePadding>
                {menuChildren.map((child, i) => (
                  <CustomListItem
                    style={{ justifyContent: 'center' }}
                    button
                    key={i}
                  >
                    <MenuLink color={color} to={pageHash[child.id].path}>
                      <Typography>{pageHash[child.id].title}</Typography>
                    </MenuLink>
                  </CustomListItem>
                ))}
              </List>
            </Collapse>
          ) : null}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ListMenuItem
