import * as R from 'ramda'
import { flatMap, map, withLatestFrom } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { createActions, createHandler } from '~/State/utils'
import addRedirect from '~/Requests/domains/addRedirect'
import removeRedirect from '~/Requests/domains/removeRedirect'

export const defaultState = {
  domain: {
    tld: '.com',
    host: 'abc',
    id: '724b98fe-ad28-4735-b075-73a0a638f39c'
  }
}

export const rootLens = R.lensProp('domain')
export const idLens = R.compose(
  rootLens,
  R.lensProp('id')
)

export const redirectLens = R.lensProp('redirects')

export const actions = createActions('DOMAIN', [
  'ADD_REDIRECT_REQUESTED',
  'ADD_REDIRECT_FULFILLED',
  'ADD_REDIRECT_ERROR',
  'REMOVE_REDIRECT_REQUESTED',
  'REMOVE_REDIRECT_FULFILLED',
  'REMOVE_REDIRECT_ERROR'
])

export const reducer = createHandler({
  [actions.ADD_REDIRECT_FULFILLED]: (state, action) =>
    R.over(redirectLens, R.append(action.payload), state),
  [actions.REMOVE_REDIRECT_FULFILLED]: (state, action) =>
    R.over(
      redirectLens,
      R.filter(
        ({ original_path }) => original_path !== action.payload.original_path
      ),
      state
    )
})

export const handleRedirectRequest = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.ADD_REDIRECT_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      addRedirect(
        {
          ...action.payload,
          domain_id: state.domain.id
        },
        state.token
      )
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.ADD_REDIRECT_ERROR,
          payload: error,
          meta
        }
      }

      return {
        type: actions.ADD_REDIRECT_FULFILLED,
        payload: data,
        meta
      }
    })
  )

export const handleRemoveRedirectRequest = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.REMOVE_REDIRECT_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      removeRedirect(
        {
          ...action.payload,
          domain_id: state.domain.id
        },
        state.token
      )
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.REMOVE_REDIRECT_ERROR,
          payload: error,
          meta
        }
      }

      return {
        type: actions.REMOVE_REDIRECT_FULFILLED,
        payload: data,
        meta
      }
    })
  )

export const epics = [handleRedirectRequest, handleRemoveRedirectRequest]
