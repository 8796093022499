import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import { useSelector } from 'react-redux'
import * as R from 'ramda'

import InputContainer from './Container'
import ColorPicker from './ColorPicker'
import ImageUpload from '~/SharedComponents/ImageUpload'

import { idsLens } from '~/State/ducks/locations'

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderContainer = styled.div`
  display: flex;
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
`

const LeftContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RightContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const Locations2Edit = ({ data, handleEdit }) => {
  const {
    background = '#0c0c0c',
    text = '#ffffff',
    feature = '#0c0c0c',
    showLogo = false
  } = data

  const [image, setImage] = useState(data.image)

  const locations = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const addItem = () =>
    handleEdit(oldState => ({
      ...oldState,
      ids: [...oldState.ids, R.pathOr(null, [0, 'id'], locations)]
    }))

  const updateItem = (index, value) =>
    handleEdit(oldState => ({
      ...oldState,
      ids: R.adjust(index, R.always(value), oldState.ids)
    }))

  const removeItem = index =>
    handleEdit(oldState => ({
      ...oldState,
      ids: R.remove(index, 1, oldState.ids)
    }))

  return (
    <InputContainer>
      <MainContainer>
        <LeftContainer>
          <TextField
            name="header"
            id="header"
            aria-label="Header"
            title="Header"
            label="Header"
            margin="normal"
            variant="outlined"
            defaultValue={data.header}
          />
          <TextField
            name="subtitle"
            id="subtitle"
            aria-label="Subtitle"
            title="Subtitle"
            label="Subtitle"
            margin="normal"
            variant="outlined"
            defaultValue={data.subtitle}
            multiline
          />
          <FormControlLabel
            control={
              <Switch
                checked={showLogo}
                onChange={e =>
                  handleEdit(oldState => ({
                    ...oldState,
                    showLogo: e.target.checked
                  }))
                }
                inputProps={{
                  'aria-label': 'Logo Switch',
                  name: 'showLogo',
                  id: 'showLogo'
                }}
              />
            }
            label="Show Logo instead of Location Name"
          />
          <ColorInputContainer>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
            <ColorPicker color={text} name="text" title="Text Color" />
            <ColorPicker color={feature} name="feature" title="Feature Color" />
          </ColorInputContainer>
          <Typography align="center">Background Image</Typography>
          <ImageUpload name="image-upload" onChange={setImage} image={image} />
          <input hidden value={image} name="image" />
        </LeftContainer>
        <RightContainer>
          <Typography>Select Locations to Display</Typography>
          {data.ids.map((dropdown, i) => {
            return (
              <FormContainer key={i}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={`${dropdown}-name`}>
                    Location {i + 1}
                  </InputLabel>
                  <Select
                    value={dropdown}
                    onChange={e => {
                      updateItem(i, e.target.value)
                    }}
                    inputProps={{
                      name: `${dropdown}-name`,
                      id: `${dropdown}-name`,
                      'aria-label': `Location ${i + 1}`,
                      title: `Location ${i + 1}`,
                      label: `Location ${i + 1}`
                    }}
                  >
                    {locations.map(location => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <HeaderContainer>
                  <IconButton onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </HeaderContainer>
              </FormContainer>
            )
          })}
          <Button onClick={addItem}>Add Location</Button>
        </RightContainer>
      </MainContainer>
    </InputContainer>
  )
}

export default Locations2Edit
