import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const HeroContainer = styled.section`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 5rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
    align-items: center;
    padding-left: 0;
    padding: 2rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%;
  text-align: left;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const SubtitleTextContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const HeaderText = styled(Typography)`
  line-height: 1.2 !important;

  @media only screen and (max-width: 768px) {
    font-size: 32px !important;
  }
`

const SubtitleText = styled(Typography)``

const Hero3 = ({
  background,
  text,
  image = 'https://i.stack.imgur.com/l60Hf.png',
  header = 'Suspendisse maximus, est ac euismod aliqu am dui mi convallis nisi',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'I am the call to action button',
  ctaLink = '',
  btnColor = 'primary',
  btnStyle = 'contained',
  headerSize = 'h1'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  const CTA = path => (
    <Button
      href={path}
      target={isRelative(path) ? '_self' : '_blank'}
      color={btnColor}
      variant={btnStyle}
    >
      {ctaText}
    </Button>
  )

  return (
    <HeroContainer img={image} color={bgColor}>
      <TextContainer color={textColor}>
        <HeaderText variant={headerSize} gutterBottom>
          {header}
        </HeaderText>
        <SubtitleTextContainer>
          <SubtitleText variant="body1" gutterBottom>
            {subtitle}
          </SubtitleText>
        </SubtitleTextContainer>
        <ButtonContainer>{ctaText && CTA(ctaLink)}</ButtonContainer>
      </TextContainer>
    </HeroContainer>
  )
}

Hero3.displayName = 'Hero 3'
Hero3.moduleName = 'Hero3'

export default Hero3
