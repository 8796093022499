import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'

import URL from '~/Utils/api_base'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Form = styled(Paper)`
  width: 95%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Login = () => {
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    const data = {
      email: formData.get('email'),
      password: formData.get('password')
    }

    fetch(`${URL}/users/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(x => x.json())
      .then(({ data, meta }) => {
        if (data && data.role.toLowerCase() === 'superadmin') {
          localStorage.setItem('token', meta.token)
          localStorage.setItem('currentUser', data.id)
          history.push('/')
        }
      })
  }

  return (
    <Page>
      <Typography variant="h3" gutterBottom align="center">
        SuperAdmin Login
      </Typography>
      <Form component="form" onSubmit={handleSubmit}>
        <TextField
          name="email"
          id="email"
          type="email"
          aria-label="Email"
          title="Email"
          label="Email"
          margin="normal"
          variant="outlined"
        />
        <TextField
          name="password"
          id="password"
          type="password"
          aria-label="Password"
          title="Password"
          label="Password"
          margin="normal"
          variant="outlined"
          required
        />
        <Buttons>
          <Button color="primary" type="submit">
            Sign In
          </Button>
          <Button color="secondary" type="reset">
            Clear Form
          </Button>
        </Buttons>
      </Form>
    </Page>
  )
}

export default Login
