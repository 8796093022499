import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'

import * as R from 'ramda'

import URL from '~/Utils/api_base'
import { idLens } from '~/State/ducks/domain'

const InputsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px solid gray;
  border-radius: 4.5px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImageInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const InventoryImageUpload = ({ onChange, image: img, name }) => {
  const [image, setImage] = useState(img)
  const domain_id = useSelector(R.view(idLens))

  //This is getting the end of the S3 bucket url

  const getEndFromIMG = url => {
    const encoded = new URL(url)

    return encoded.pathname
  }

  const deleteImage = () => {
    const end = getEndFromIMG(img)
    const url = `${URL}/files${end}`
    return fetch(url, {
      method: 'DELETE'
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          onChange('')
          setImage('')
        }
      })
  }

  const reset = () => deleteImage().then(() => onChange())

  useEffect(() => {
    //We are checking to see if the current image is different than the image that was passed in

    if (image && image !== img) {
      const data = new FormData()

      Array.from(image).forEach(image => data.append('files', image))

      fetch(`${URL}/files/${domain_id}/multiple`, {
        method: 'POST',
        body: data
      })
        .then(x => x.json())
        .then(({ data }) => {
          onChange(oldState => [
            ...oldState,
            ...data.link.map(img => img.location)
          ])
        })
        .then(setImage())
    }
  }, [image])

  return (
    <UploadImageContainer>
      <InputsContainer>
        {!image && !img && (
          <IconButton component={'label'} htmlFor={name}>
            <AddCircleIcon />
          </IconButton>
        )}

        <ImageInput
          id={name}
          type="file"
          name={name}
          aria-label={name}
          title={name}
          multiple
          onChange={e => {
            const { target } = e
            if (img) {
              reset().then(() => setImage(target.files))
            } else {
              setImage([image, ...e.target.files])
            }
          }}
          accept="image/*"
        />
        <img src={img} />
      </InputsContainer>
    </UploadImageContainer>
  )
}

export default InventoryImageUpload
