import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Modal from '@material-ui/core/Modal'

import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { parse } from 'query-string'
import * as R from 'ramda'

import BlogForm from './BlogForm'
import CreateBlogModal from './CreateBlogModal'
import NotFound from './NotFound'
import { idsLens, actions } from '~/State/ducks/blogs'
import MenuSetup from '~/App/Admin/Builder/MenuSetup'

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  background: #768390;
  align-items: center;
`

const SiteName = styled(Typography)`
  flex-grow: 1;
  color: #fff;
  padding-left: 1rem;
`

const PageOptions = styled.nav`
  flex-grow: 1;
  max-width: 33%;
  display: flex;
  align-items: stretch;
  color: #fff;
  justify-content: space-between;
`

const PageOption = styled.button`
  flex: 1 1 auto;
  border: none;
  border-left: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  font-family: 'Roboto';
  padding: 2rem;
  transition: all 0.25s ease-in-out;

  :hover {
    color: #768390;
    background: #fff;
  }
`

const Main = styled.div`
  width: 100%;
  flex-grow: 2;
  display: flex;
`

const SideMenu = styled.div`
  display: flex;
  background: #f1f2f4;
  flex-direction: column;
  width: 290px;
`

const TabsContainer = styled.div`
  width: 290px;
  background: gray;
  display: flex;
`

const BlogsTab = styled(Link)`
  text-decoration: none;
  color: black;
  flex: 1;
  display: flex;
  background: #f1f2f4;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const PageLink = styled(Link)`
  background: ${({ selected }) => (selected ? '#c9cdd2' : '#e4e6e9')};
  color: black;
  border: none;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5px;

  :hover {
    background: #c9cdd2;
  }

  & {
    width: 100%;
  }
`

const PagesTab = styled(Link)`
  text-decoration: none;
  flex: 1;
  display: flex;
  color: black;
  background: #c9cdd2;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  :hover {
    background: #f1f2f4;
  }
`

const MenuButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #939ca6;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  :hover {
    background: #f1f2f4;
  }
`

const DeleteModal = styled.div`
  width: 80%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Blog = () => {
  const [addBlog, setAddBlog] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [menuModalOpen, setMenuModalOpen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const blogHash = useSelector(state => {
    return R.view(idsLens, state)
  })
  const company_name = useSelector(state => state.profile.company.name)

  const domain_id = useSelector(state => state.domain.id)
  const baseName = useSelector(state => state.baseName || '')

  const blogList = R.values(blogHash)
  const [blogEdits, setBlogEdits] = useState({})
  const query = parse(location.search)

  // If we do not have a query.page and we have
  // some known pages
  if (!query.page && blogList.length) {
    // Let's ask React to go update our query to say that the
    // current page to build is whatever is the first key in
    // the pages hash

    return <Redirect to={`/admin/blog?page=${blogList[0].id}`} />
  }

  if (query.page && !blogList.length) {
    return <Redirect to="/admin/blog" />
  }

  const editBlog = update => {
    setBlogEdits(oldEdits => ({
      ...oldEdits,
      ...update
    }))
  }

  const currentBlog = blogHash[query.page] || {}

  const updatedBlog = {
    ...currentBlog,
    ...blogEdits
  }

  const saveBlog = () => {
    dispatch({
      type: actions.UPDATE_BLOG_REQUEST,
      payload: updatedBlog
    })
  }

  const deleteBlog = () => {
    dispatch({
      type: actions.DELETE_BLOG_REQUEST,
      payload: { id: currentBlog.id }
    })

    setShowDeleteModal(false)
  }

  const setPreview = () => {
    localStorage.setItem(
      'currentBlog',
      JSON.stringify({ ...currentBlog, ...blogEdits })
    )

    window.open(`${baseName}/preview`)
  }

  return (
    <Page>
      <Header>
        <SiteName variant="h5">{company_name}</SiteName>
        <PageOptions>
          <PageOption color="inherit" onClick={() => setShowDeleteModal(true)}>
            Delete Blog Post
          </PageOption>
          <PageOption
            color="inherit"
            onClick={() => (window.location = window.location)}
          >
            Revert to Previous Version
          </PageOption>
          <PageOption color="inherit" onClick={setPreview}>
            Preview Blog Post
          </PageOption>
          <PageOption color="inherit" onClick={saveBlog}>
            Save Blog Post
          </PageOption>
        </PageOptions>
      </Header>
      <Helmet title="Create a page" />
      <Main>
        <SideMenu>
          <MenuButtonContainer onClick={() => setMenuModalOpen(true)}>
            <Typography>Menu Setup</Typography>
          </MenuButtonContainer>
          <TabsContainer>
            <PagesTab to={`/admin/builder`}>
              <Typography>Pages</Typography>
            </PagesTab>
            <BlogsTab to={`/admin/blog`}>
              <Typography>Blogs</Typography>
            </BlogsTab>
          </TabsContainer>
          <Button
            style={{ display: 'flex' }}
            onClick={() => setAddBlog(true)}
            aria-label="create blog post"
          >
            <AddCircleIcon />
            &nbsp;Create New Blog Post
          </Button>
          {blogList.map(blog => (
            <PageLink
              key={blog.id}
              selected={query.page === blog.id}
              to={`/admin/blog?page=${blog.id}`}
            >
              <Typography>{blog.title}</Typography>
            </PageLink>
          ))}
        </SideMenu>
        {currentBlog.id ? (
          <BlogForm
            key={currentBlog.id}
            currentBlog={currentBlog}
            onChange={editBlog}
          />
        ) : (
          <NotFound />
        )}
        <CreateBlogModal
          open={addBlog}
          onClose={() => setAddBlog(false)}
          onSelect={newBlogTitle => {
            dispatch({
              type: actions.CREATE_BLOG_REQUEST,
              payload: {
                title: newBlogTitle,
                path: '',
                published: false,
                domain_id
              },
              meta: history
            })

            setAddBlog(false)
          }}
        />
      </Main>
      <Modal onClose={() => setShowDeleteModal(false)} open={showDeleteModal}>
        <DeleteModal>
          <Typography variant="h2" component="h4" align="center" gutterBottom>
            Are you sure you want to delete this blog post?
          </Typography>
          <ButtonGroup>
            <Button
              color="secondary"
              onClick={deleteBlog}
              aria-label="delete blog post"
            >
              Delete
            </Button>
            <Button
              onClick={() => setShowDeleteModal(false)}
              aria-label="cancel delete"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </DeleteModal>
      </Modal>
      <Modal
        aria-labelledby="menu-setup-title"
        aria-describedby="menu-setup-description"
        open={menuModalOpen}
        onClose={() => setMenuModalOpen(false)}
      >
        <MenuSetup
          open={menuModalOpen}
          onClose={() => setMenuModalOpen(false)}
        />
      </Modal>
    </Page>
  )
}

export default Blog
