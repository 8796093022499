import * as R from 'ramda'
import { defaultState as usersState } from './ducks/users'
import { defaultState as pagesState } from './ducks/pages'
import { defaultState as companyState } from './ducks/companies'
import { defaultState as profileState } from './ducks/profile'
import { defaultState as tokenState } from './ducks/token'
import { defaultState as menusState } from './ducks/menus'
import { defaultState as inventoryState } from './ducks/inventory'
import { defaultState as categoryState } from './ducks/categories'
import { defaultState as featureState } from './ducks/features'
import { defaultState as locationState } from './ducks/locations'
import { defaultState as blogState } from './ducks/blogs'
import { defaultState as domainState } from './ducks/domain'
import { defaultState as templateState } from './ducks/templates'
import { defaultState as leadState } from './ducks/leads'

const defaultState = R.mergeAll([
  usersState,
  pagesState,
  companyState,
  profileState,
  tokenState,
  menusState,
  inventoryState,
  categoryState,
  featureState,
  locationState,
  blogState,
  domainState,
  templateState,
  leadState
])

export default defaultState
