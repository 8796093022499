import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import { useLocation, useHistory } from 'react-router-dom'
import SwitchCamera from '@material-ui/icons/FlipCameraIos'
import IconButton from '@material-ui/core/IconButton'
import CallEnd from '@material-ui/icons/CallEnd'
import Typography from '@material-ui/core/Typography'
import TimePicker from 'rc-time-picker'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import VideocamOff from '@material-ui/icons/VideocamOff'
import VideocamOn from '@material-ui/icons/Videocam'
import MicOn from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import 'rc-time-picker/assets/index.css'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'

import URL from '~/Utils/api_base'

const Publisher = styled.div`
  width: 200px;
  height: 30vh;
  position: relative;
  max-width: 50%;
`

const Subscriber = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #bebebe;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SwitchCameraButton = styled(IconButton)`
  background-color: white !important;

  :not(:last-child) {
    margin-right: 16px !important;
  }
`

const SwitchCameraButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 25px;
  right: 1px;
  width: 100%;
  display: flex;
  justify-content: center;

  .MuiSvgIcon-root {
    font-size: 2rem;
  }
`

const TimeSelector = styled(TimePicker)`
  margin-top: 16px;
  margin-bottom: 8px;

  .rc-time-picker-input {
    height: 56px;
    font-size: 1rem;
    padding: 18.5px 14px;
  }
  .rc-time-picker-clear {
    right: 16px;
    top: 16px;
  }
`

const Input = styled(TextField)`
  background: white;
  border-radius: ${({ theme }) =>
    theme == 'Theme5' ||
    theme == 'Theme6' ||
    theme == 'Theme7' ||
    theme == 'Theme8'
      ? '0px'
      : `4.5px`};

  label {
    color: #7b7c7c;
  }
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WaitingTextContainer = styled.div`
  position: absolute;
  right: 1px;
  top: 50%;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  .pulsate {
    animation: opacityPulse 3s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
  }

  @keyframes opacityPulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const LogoContainer = styled.div`
  width: 320px;
  height: 200px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    height: 100px;
    width: 160px;
    margin-bottom: none;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const ApptPaper = styled(Paper)`
  width: 250px;
  padding: 1rem;
`

const Video = () => {
  const location = useLocation()
  const query = parse(location.search)
  const isDealer = query.dealer

  const history = useHistory()

  const apiKey = '46702982'
  const sessionId = query.sessionId
  const time = query.time
  const customerEmail = query.email
  const token = query.token

  const utcDate = moment.utc(query.time, 'YYYY-MM-DDHH:mmA')

  const localDate = moment(utcDate).local()

  const domain_id = useSelector(state => state.domain.id)
  const brand = useSelector(state => state.profile.brand)
  const company = useSelector(state => state.profile.company)
  const host = useSelector(state => state.domain.host)
  const tld = useSelector(state => state.domain.tld)

  const [appTime, setAppTime] = useState(time ? localDate : moment())
  const [appDate, setAppDate] = useState(
    time ? localDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  )

  const [publisherObj, setPublisherObj] = useState()
  const [cameraOn, setCameraOn] = useState()
  const [micOn, setMicOn] = useState()
  const [showMessage, setShowMessage] = useState(false)
  const [authorized, setAuthorized] = useState(false)
  const [subscriber, setSubscriber] = useState(false)

  const format = 'h:mm A'

  const [scriptLoaded, setScriptLoaded] = useState(
    typeof window !== 'undefined' && typeof myScript !== 'undefined'
  )

  const handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }) => src === 'https://static.opentok.com/v2/js/opentok.min.js'
      )
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true
        })
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)
    //code for sending API call to send email to dealer

    const dateTime = moment(appDate).set({
      hour: appTime.get('hour'),
      minute: appTime.get('minute'),
      second: 0,
      millisecond: 0
    })

    fetch(`${URL}/video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        host,
        tld,
        dateTime: dateTime.utc().format('YYYY-MM-DDhh:mm:ssA'),
        customerEmail: formdata.get('customer-email'),
        salesEmail: formdata.get('sales-email'),
        domain_id,
        timeZone: moment.tz.guess()
      })
    }).then(x => x.json())

    setShowMessage(true)
  }

  useEffect(() => {
    if (!localStorage.getItem('auth_token') && isDealer) {
      history.push('/login')
    } else if (localStorage.getItem('auth_token') && isDealer) {
      setAuthorized(true)
    }

    if (scriptLoaded) {
      function handleError(error) {
        if (error) {
          alert(error.message)
        }
      }

      initializeSession()

      function initializeSession() {
        var session = OT.initSession(apiKey, sessionId)

        session.on('streamCreated', function(event) {
          session.subscribe(
            event.stream,
            'subscriber-vid',
            {
              insertMode: 'append',
              width: '100%',
              height: '100%'
            },
            handleError
          )

          setSubscriber(true)
        })

        var publisher = OT.initPublisher(
          'publisher-vid',
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
            mirror: false,
            style: {
              buttonDisplayMode: 'off'
            }
          },
          handleError
        )

        setPublisherObj(publisher)

        session.connect(token, function(error) {
          if (error) {
            handleError(error)
          } else {
            session.publish(publisher, handleError)
            setCameraOn(true)
            setMicOn(true)
            const btn = document.getElementById('switch-camera')

            btn.onclick = function() {
              publisher.cycleVideo()
            }
          }
        })

        session.on('streamPropertyChanged', function(event) {
          if (
            event.changedProperty == 'hasVideo' &&
            event.stream.streamId == publisher.streamId
          ) {
            if (event.newValue == false) {
              setCameraOn(false)
            } else if (event.newValue == true) {
              setCameraOn(true)
            }
          }

          if (
            event.changedProperty == 'hasAudio' &&
            event.stream.streamId == publisher.streamId
          ) {
            if (event.newValue == false) {
              setMicOn(false)
            } else if (event.newValue == true) {
              setMicOn(true)
            }
          }
        })
      }
    }
  }, [scriptLoaded])

  return (
    <React.Fragment>
      {!isDealer &&
        !moment().isAfter(moment(localDate).subtract(5, 'minutes')) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'gray',
              width: '100%',
              height: '100vh'
            }}
          >
            <ApptPaper elevation={3}>
              <Typography align="center">
                Your appointment is scheduled for
                <br />
                <strong>{localDate.format('MMMM Do YYYY, h:mm A')}</strong>
              </Typography>
            </ApptPaper>
          </div>
        )}
      {!isDealer && moment().isAfter(moment(localDate).subtract(5, 'minutes')) && (
        <React.Fragment>
          <Helmet onChangeClientState={handleChangeClientState}>
            {typeof window !== 'undefined' &&
              typeof myScript === 'undefined' && (
                <script
                  async
                  defer
                  src={'https://static.opentok.com/v2/js/opentok.min.js'}
                />
              )}
          </Helmet>
          <VideoContainer>
            <Subscriber id="subscriber-vid">
              <div
                style={{
                  position: 'absolute',
                  right: '1px',
                  top: '1px',
                  zIndex: '10',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Publisher id="publisher-vid"></Publisher>
              </div>
              {!subscriber && (
                <WaitingTextContainer>
                  <Typography align="center" variant="h4" className="pulsate">
                    Waiting for the Other Party to Join
                  </Typography>
                </WaitingTextContainer>
              )}

              <SwitchCameraButtonContainer>
                <SwitchCameraButton size="large" id="switch-camera">
                  <SwitchCamera />
                </SwitchCameraButton>

                {publisherObj && (
                  <React.Fragment>
                    <SwitchCameraButton
                      size="large"
                      onClick={() => {
                        publisherObj.publishVideo(!cameraOn)
                      }}
                      id="toggle-camera"
                    >
                      {cameraOn ? <VideocamOn /> : <VideocamOff />}
                    </SwitchCameraButton>
                    <SwitchCameraButton
                      size="large"
                      onClick={() => {
                        publisherObj.publishAudio(!micOn)
                      }}
                      id="toggle-mic"
                    >
                      {micOn ? <MicOn /> : <MicOff />}
                    </SwitchCameraButton>
                  </React.Fragment>
                )}
                <SwitchCameraButton
                  size="large"
                  onClick={() => window.close('', '_parent', '')}
                  id="end-call"
                >
                  <CallEnd style={{ fill: 'red' }} />
                </SwitchCameraButton>
              </SwitchCameraButtonContainer>
            </Subscriber>
          </VideoContainer>
        </React.Fragment>
      )}

      {isDealer && authorized && (
        <MainContainer>
          <LogoContainer>
            {brand.logo && (
              <img src={brand.logo} alt={`${company.name} Logo`} />
            )}
          </LogoContainer>
          <FormContainer onSubmit={handleSubmit}>
            {time ? (
              <Typography gutterBottom align="center">
                A customer has requested an appointment for{' '}
                <strong>{localDate.format('MMMM Do YYYY, h:mm A')}</strong>.
                Please confirm this time or select a new appointment time.
              </Typography>
            ) : (
              <Typography gutterBottom align="center">
                Select a date and time for this appointment.
              </Typography>
            )}

            <Input
              name="date"
              id="date"
              type="date"
              aria-label="Date"
              title="Date"
              label="Date"
              margin="normal"
              variant="outlined"
              required
              value={appDate}
              onChange={e => setAppDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TimeSelector
              showSecond={false}
              defaultValue={appTime}
              onChange={e => setAppTime(e)}
              format={format}
              use12Hours
              inputReadOnly
            />
            <Typography gutterBottom align="center">
              Please enter the email addresses of the participants and click
              "Create Appointment" to send email invitations.
            </Typography>
            <Input
              name="sales-email"
              id="sales-email"
              aria-label="Sales Email"
              title="Sales Email"
              label="Sales Email"
              margin="normal"
              variant="outlined"
              required
              InputLabelProps={{ shrink: true }}
            />
            <Input
              name="customer-email"
              id="customer-email"
              aria-label="Customer Email"
              title="Customer Email"
              label="Customer Email"
              margin="normal"
              variant="outlined"
              required
              defaultValue={customerEmail}
              InputLabelProps={{ shrink: true }}
            />
            <SubmitButton
              variant="contained"
              disabled={
                showMessage ||
                (appTime &&
                  moment().isAfter(
                    moment(appDate, 'YYYY-MM-DD').set({
                      hour: appTime.get('hour'),
                      minute: appTime.get('minute'),
                      second: 0,
                      millisecond: 0
                    })
                  ))
              }
              color="primary"
              type="submit"
            >
              Create Appointment
            </SubmitButton>
            {showMessage && (
              <Typography>
                This appointment has been successfully created. You can safely
                close this window.
              </Typography>
            )}
            {appTime &&
              moment().isAfter(
                moment(appDate, 'YYYY-MM-DD').set({
                  hour: appTime.get('hour'),
                  minute: appTime.get('minute'),
                  second: 0,
                  millisecond: 0
                })
              ) && (
                <Typography color="secondary">
                  Appointment must be set for a future time
                </Typography>
              )}
          </FormContainer>
        </MainContainer>
      )}
    </React.Fragment>
  )
}

export default Video
