import { createHandler } from '~/State/utils'
import { map, tap } from 'rxjs/operators'
import { ofType } from 'redux-observable'

import { actions as userActions } from './users'

export const defaultState = {
  token:
    typeof window !== 'undefined' ? localStorage.getItem('auth_token') : null
}

export const reducer = createHandler({
  [userActions.LOGIN_USER_SUCCESS]: (state, action) => ({
    ...state,
    token: action.meta.token
  }),
  [userActions.LOGOUT]: state => ({
    ...state,
    token: null
  }),
  SET_TOKEN: (state, action) => ({
    ...state,
    token: action.payload
  })
})

export const setToken$ = action$ =>
  action$.pipe(
    ofType(userActions.LOGIN_USER_SUCCESS),
    tap(action => {
      localStorage.setItem('auth_token', action.meta.token)
    }),
    map(() => ({
      type: '@@MISC/DO_NOT_LISTEN'
    }))
  )

export const removeToken$ = actions$ =>
  actions$.pipe(
    ofType(userActions.LOGOUT),
    tap(() => {
      localStorage.removeItem('auth_token')
    }),
    map(() => ({
      type: '@@MISC/DO_NOT_LISTEN'
    }))
  )
export const epics = [setToken$, removeToken$]
