import React, { useState } from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import styled from '@emotion/styled'

const Inputs = styled.div`
  flex-grow: 2;
  width: 50%;
  padding: 0.5rem;
`

const StateZipContainer = styled.div`
  display: flex;
`

const StateInput = styled(FormControl)`
  flex-grow: 2;
`

const ZipInput = styled(TextField)`
  flex-grow: 1;
  margin-left: 10px;
`

const Input = styled(TextField)`
  width: 100%;
`

const InputsContainer = styled.div`
  display: flex;
`

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
].reduce((a, [state, initial]) => ({ ...a, [initial]: state }))

const SiteSettings = ({
  name,
  address,
  address2,
  city,
  state,
  zip,
  clayton_id
}) => {
  const [currentState, setState] = useState(state)

  return (
    <InputsContainer>
      <Inputs>
        <Input
          name="company-name"
          id="company-name"
          label="Company Name"
          aria-label="Company Name"
          title="Company Name"
          margin="normal"
          variant="outlined"
          defaultValue={name}
          required
        />
        <Input
          name="address1"
          id="address1"
          label="Address"
          aria-label="Address"
          title="Address"
          margin="normal"
          variant="outlined"
          defaultValue={address}
          required
        />
        <Input
          name="address2"
          id="address2"
          label="Address Line 2"
          aria-label="Address Line 2"
          title="Address Line 2"
          margin="normal"
          variant="outlined"
          defaultValue={address2}
        />
        <Input
          name="city"
          id="city"
          label="City"
          aria-label="City"
          title="City"
          margin="normal"
          variant="outlined"
          defaultValue={city}
          required
        />
        <StateZipContainer>
          <StateInput margin="normal">
            <InputLabel htmlFor="state">State</InputLabel>
            <Select
              value={currentState}
              onChange={({ target }) => setState(target.value)}
              inputProps={{
                name: 'state',
                id: 'state',
                'aria-label': 'State',
                title: 'State'
              }}
              required
            >
              <MenuItem value="">Select State</MenuItem>
              {Object.keys(states).map(initial => (
                <MenuItem key={initial} value={initial}>
                  {states[initial]}
                </MenuItem>
              ))}
            </Select>
            <input hidden value={currentState} name="state" />
          </StateInput>
          <ZipInput
            type="tel"
            name="zip"
            id="zip"
            aria-label="Postal Code"
            title="Postal Code"
            label="Postal Code"
            margin="normal"
            variant="outlined"
            defaultValue={zip}
            required
          />
        </StateZipContainer>
      </Inputs>
      <Inputs>
        <Input
          name="clayton-id"
          id="clayton-id"
          label="Clayton Built® ID"
          aria-label="Clayton Built® ID"
          title="Clayton Built® ID"
          margin="normal"
          variant="filled"
          defaultValue={clayton_id}
          required
        />
      </Inputs>
    </InputsContainer>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(SiteSettings)
