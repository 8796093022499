import React, { useState } from 'react';
import styled from '@emotion/styled';
import CookieStorage from '~/App/CookieStorage';
import '~/App/global.css';

const FooterContainer = styled.div`
  background-color: rgb(27, 52, 83);
  text-align: center;
  padding: 10px 0;
  width: 100vw;
`;

const FooterInside = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  max-width: 600px;
  margin: 0 auto;
`;

const FooterText = styled.p`
  color: rgb(255, 255, 255);
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FooterLink = styled.a`
  color: rgb(161, 163, 166);
  text-decoration: none;
  padding: 0 10px;
  border-right: 1px solid rgb(161, 163, 166);
  transition: color 400ms ease;

  &:last-child {
    border-right: none;
    padding-right: 0;
  }
`;

const HiddenContent = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20em;
  background-color: white;
  max-width: 20em;
  overflow-y: auto;
  padding: 1em;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const CloseButton = styled.button`
  top: 0px;
  right: 10px !important;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  text-align: right;
`;

const SaveButton = styled.button`
background-color: #f1d600;
border-color: #000;
color: #000;
font-size: .875em;
margin: 1em 0 0;
width: 100%;
padding-top: 6px;
padding-bottom: 6px;
z-index: 100;
cursor: pointer;
`;


const BottomFooter = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <FooterContainer>
      <FooterInside>
        <FooterText className="xsmall">© 1998-2023 Clayton.</FooterText>
        <FooterLinks className="xsmall">
          <FooterLink target="_blank" href="https://www.claytonhomes.com/legal">
            Legal
          </FooterLink>
          <FooterLink target="_blank" href="https://www.claytonhomes.com/privacy">
            Privacy
          </FooterLink>
          <FooterLink onClick={toggleVisibility}>Do Not Sell or Share My Personal Information</FooterLink>
        </FooterLinks>
      </FooterInside>
      <HiddenContent isVisible={isVisible}>
        <CloseButton onClick={toggleVisibility}>X</CloseButton>
        <CookieStorage />
        <SaveButton onClick={toggleVisibility}>Save</SaveButton>
      </HiddenContent>
    </FooterContainer>
  );
};

export default BottomFooter;
