import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import { parse } from 'query-string'

import { actions } from '~/State/ducks/users'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Form = styled(Paper)`
  width: 95%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin-top: 1rem;
`

const PasswordReset = () => {
  const [password, setPassword] = useState()
  const [passwordConfirm, setPasswordConfirm] = useState()
  const [emailSent, setEmailSent] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const query = parse(location.search)

  const handleSubmit = e => {
    e.preventDefault()

    if (!query.token) {
      const formdata = new FormData(e.target)

      dispatch({
        type: actions.RESET_PASSWORD_REQUEST,
        payload: {
          email: formdata.get('email')
        }
      })

      setEmailSent(true)
    } else {
      const formdata = new FormData(e.target)
      const data = {
        email: formdata.get('email'),
        password: formdata.get('password'),
        token: query.token
      }

      dispatch({
        type: actions.CONFIRM_PASSWORD_REQUEST,
        payload: data,
        meta: history
      })
      setEmailSent(false)
    }
  }

  return (
    <React.Fragment>
      {!query.token ? (
        <Page>
          <Typography variant="body1">
            Enter your email address below to reset your password. You will
            receive an email with further instructions.
          </Typography>
          <Form component="form" onSubmit={handleSubmit}>
            <TextField
              name="email"
              id="email"
              type="email"
              aria-label="Email"
              title="Email"
              label="Email"
              margin="normal"
              variant="outlined"
            />
            <Button disabled={emailSent} type="submit">
              Reset Password
            </Button>
            {emailSent && (
              <Typography align="center" variant="subtitle2">
                Please check your email and follow the instructions. If you have
                not received an email, please refresh this page and try again.
              </Typography>
            )}
          </Form>
        </Page>
      ) : (
        <Page>
          <Typography variant="body1">
            Please enter your email address along with your new password.
          </Typography>
          <Form component="form" onSubmit={handleSubmit}>
            <TextField
              name="email"
              id="email"
              type="email"
              aria-label="Email"
              title="Email"
              label="Email"
              margin="normal"
              variant="outlined"
            />
            <TextField
              name="password"
              id="password"
              type="password"
              aria-label="Password"
              title="Password"
              label="Password"
              margin="normal"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              required
            />
            <TextField
              name="passwordConfirm"
              id="passwordConfirm"
              type="password"
              aria-label="Confirm Password"
              title="Confirm Password"
              label="Confirm Password"
              margin="normal"
              variant="outlined"
              onChange={e => setPasswordConfirm(e.target.value)}
              required
            />
            {password !== passwordConfirm && (
              <Typography variant="subtitle2">
                Your passwords do not match.
              </Typography>
            )}
            <Button disabled={password !== passwordConfirm} type="submit">
              Reset Password
            </Button>
          </Form>
        </Page>
      )}
    </React.Fragment>
  )
}

export default PasswordReset
