import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import * as R from 'ramda'

import { byIdLens } from '~/State/ducks/blogs'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const FeaturedBlogContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ color }) =>
    ` linear-gradient(to right, ${color} 75%, transparent 25%);` ||
    ` linear-gradient(to right, black 75%, transparent 25%);`};
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background: #eeeeee;
  }
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8rem;
  padding-left: 8rem;
  padding-bottom: 8rem;
  flex: 1;
  color: ${({ color }) => `${color}`};

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const PhotoContainer = styled.div`
  padding: 3rem;
  padding-right: 0px;
  flex: 1;

  img {
    width: 100%;
    height: auto;
    margin: auto;
  }
`

const ButtonContainer = styled.div`
  display: flex;

  margin-top: 2rem;
`

const SubtitleTextContainer = styled.div`
  width: 100%;
  text-align: center;
`

const HeaderText = styled(Typography)`
  padding-top: 2rem;
`

const BlogText = styled(Typography)``

const SubtitleText = styled(Typography)``

const FeaturedBlog3 = ({
  id = '1',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor. Maecenas porttitor ante turpis, et eleifend justo tempor at. Nullam imperdiet, arcu eu vehicula vulputate, augue nisi ultrices nulla.',
  ctaText = 'Click here',
  isPreview,
  background,
  text,
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const blog = useSelector(state => {
    const item = R.view(byIdLens(id), state)

    return {
      title: 'My Default Title',
      image: 'https://i.stack.imgur.com/l60Hf.png',
      ...item
    }
  })

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)
  return (
    <FeaturedBlogContainer color={bgColor}>
      <TextContainer color={textColor}>
        <BlogText variant="h4">FEATURED BLOG</BlogText>
        <HeaderText variant="h1" align="left" gutterBottom>
          {blog.title}
        </HeaderText>
        <SubtitleTextContainer>
          <SubtitleText variant="body1" align="left" gutterBottom>
            {subtitle}
          </SubtitleText>
        </SubtitleTextContainer>
        <ButtonContainer>
          {ctaText && (
            <Button
              component={Link}
              variant={btnStyle}
              to={blog.path}
              color={btnColor}
            >
              {ctaText}
            </Button>
          )}
        </ButtonContainer>
      </TextContainer>
      <PhotoContainer>
        <img
          src={isPreview ? 'https://i.stack.imgur.com/l60Hf.png' : blog.image}
        ></img>
      </PhotoContainer>
    </FeaturedBlogContainer>
  )
}

FeaturedBlog3.displayName = 'Featured Blog 3'
FeaturedBlog3.moduleName = 'FeaturedBlog3'

export default FeaturedBlog3
