import React, { useState } from 'react'
import styled from '@emotion/styled'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'

import { useSelector } from 'react-redux'
import * as R from 'ramda'

import ButtonStylePicker from './ButtonStylePicker'
import ButtonPicker from './ButtonPicker'
import ColorPicker from './ColorPicker'
import InputContainer from './Container'

import { idsLens } from '~/State/ducks/blogs'

const FormContainer = styled.div`
  min-width: 120px;
`

const HeaderContainer = styled.div`
  display: flex;
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
`

const LeftContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RightContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
  flex-direction: column;
`

const ColorInputWrapper = styled.div`
  padding-right: 2rem;
`

const FeaturedBlog2Edit = ({ data }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    btnColor = 'primary',
    btnStyle = 'contained'
  } = data

  const blogs = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids)
  })

  const [featuredBlog, setFeaturedBlog] = useState(
    data.id || blogs[0] ? blogs[0].id : null
  )

  return (
    <InputContainer>
      <MainContainer>
        <LeftContainer>
          <TextField
            name="subtitle"
            id="subtitle"
            aria-label="Subtitle"
            title="Subtitle"
            label="Subtitle"
            margin="normal"
            variant="outlined"
            defaultValue={data.subtitle}
            multiline
          />
          <TextField
            name="ctaText"
            id="ctaText"
            aria-label="Call To Action Text"
            title="Call To Action Text"
            label="Call To Action Text"
            margin="normal"
            variant="outlined"
            defaultValue={data.ctaText}
          />
          <Typography>Don't have a background image?</Typography>
          <ColorInputContainer>
            <ColorInputWrapper>
              <ColorPicker
                color={background}
                name="background"
                title="Background Color"
              />
            </ColorInputWrapper>
            <ColorInputWrapper>
              <ColorPicker color={text} name="text" title="Text Color" />
            </ColorInputWrapper>
            <ColorInputWrapper>
              <ButtonPicker
                color={btnColor}
                name="btnColor"
                title="Button Color"
              />
            </ColorInputWrapper>
            <ColorInputWrapper>
              <ButtonStylePicker
                color={btnStyle}
                name="btnStyle"
                title="Button Style"
              />
            </ColorInputWrapper>
          </ColorInputContainer>
        </LeftContainer>
        <RightContainer>
          <Typography>Select Blog Post to Display</Typography>
          <FormContainer>
            <HeaderContainer></HeaderContainer>
            <FormControl fullWidth>
              <InputLabel htmlFor="id">Blog Post</InputLabel>
              <Select
                value={featuredBlog}
                onChange={e => setFeaturedBlog(e.target.value)}
                inputProps={{
                  name: 'id',
                  id: 'id',
                  'aria-label': 'Featured Blog',
                  title: 'Featured Blog',
                  label: 'Featured Blog'
                }}
              >
                {blogs.length &&
                  blogs.map(blog => (
                    <MenuItem key={blog.id} value={blog.id}>
                      {blog.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </FormContainer>
        </RightContainer>
      </MainContainer>
    </InputContainer>
  )
}

export default FeaturedBlog2Edit
