import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Modal from '@material-ui/core/Modal'
import Buttons from '@material-ui/core/ButtonGroup'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { parse } from 'query-string'
import * as R from 'ramda'

import CreatePageModal from './CreatePageModal'
import TemplateForm from './TemplateForm'
import PageForm from './PageForm'
import PageMenu from './PageMenu'
import NotFound from './NotFound'
import MenuSetup from './MenuSetup'

import { actions } from '~/State/ducks/pages'
import { actions as templateActions } from '~/State/ducks/templates'

const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  background: #768390;
  align-items: center;
`

const SiteName = styled(Typography)`
  flex-grow: 1;
  color: #fff;
  padding-left: 1rem;
`

const PageOptions = styled.nav`
  flex-grow: 1;
  max-width: 40%;
  display: flex;
  align-items: stretch;
  color: #fff;
  justify-content: space-between;
`

const PageOption = styled.button`
  flex: 1 1 auto;
  border: none;
  border-left: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  font-family: 'Roboto';
  padding: 2rem;
  transition: all 0.25s ease-in-out;

  :hover {
    color: #768390;
    background: #fff;
  }
`

const ImportPageOption = styled.label`
  flex: 1 1 auto;
  border: none;
  border-left: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  font-family: 'Roboto';
  padding: 2rem;
  transition: all 0.25s ease-in-out;
  text-align: center;

  :hover {
    color: #768390;
    background: #fff;
  }
`

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 2;
`

const SideMenu = styled.div`
  display: flex;
  background: #f1f2f4;
  flex-direction: column;
  width: 290px;
`

const MenuDivider = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  .MuiTypography-root {
    width: 80%;
    margin-top: 1rem;
  }
`

const DeleteModal = styled.div`
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const ExportModal = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const TabsContainer = styled.div`
  width: inherit;
  background: gray;
  display: flex;
`

const PagesTab = styled(Link)`
  text-decoration: none;
  color: black;
  flex: 1;
  display: flex;
  background: #f1f2f4;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const MenuButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #939ca6;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  :hover {
    background: #f1f2f4;
  }
`

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const BlogsTab = styled(Link)`
  text-decoration: none;
  flex: 1;
  display: flex;
  color: black;
  background: #c9cdd2;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  :hover {
    background: #f1f2f4;
  }
`

/**
 * CODE OF INTEREST:
 *
 *  We are hard-coding this to only look 1 level deep. If
 *  you wanted to add more nested levels, we would add them
 *  here.
 *
 *  THIS IS FINICKY AND WILL BREAK IF YOU TOUCH IT. I AM SORRY
 */
const getAllNonMenuPages = (menu, pages) => {
  const ids = R.flatten(
    menu.pages.map(({ id, children = [] }) => [
      String(id),
      children.map(({ id }) => String(id))
    ])
  )

  const idList = Object.keys(pages.ids).map(String)

  return idList.filter(id => !R.includes(id, ids)).map(id => ({ id }))
}

const Builder = () => {
  const pages = useSelector(state => state.pages)
  const templates = useSelector(state => state.templates)
  const domainHost = useSelector(state => state.domain.host)
  const company_name = useSelector(state => state.profile.company.name)
  const menu = useSelector(state => state.menus)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [menuModalOpen, setMenuModalOpen] = useState(false)
  const [addPageOpen, setAddPage] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [pageChanges, setPageChanges] = useState({})
  const [showDeletePageModal, setShowDeletePageModal] = useState(false)
  const [pageExport, setPageExport] = useState()
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const pageList = Object.keys(pages.ids)
  const query = parse(location.search)

  const baseName = useSelector(state => state.baseName || '')

  // If we do not have a query.page and we have
  // some known pages
  if (!query.page && !query.template && pageList.length) {
    // Let's ask React to go update our query to say that the
    // current page to build is whatever is the first key in
    // the pages hash

    return <Redirect to={`/admin/builder?page=${pageList[0]}`} />
  }

  if (query.page && !pageList.length) {
    return <Redirect to="/admin/builder" />
  }

  const currentPage = pages.ids[query.page] || {}
  const currentTemplate = templates.ids[query.template] || {}

  const notMenuPages = getAllNonMenuPages(menu, pages)
  const clearState = () => {
    setHasChanged(false)
    setPageChanges({})
    setShowDeletePageModal(false)
  }

  const savePage = () => {
    if (query.template) {
      dispatch({
        type: templateActions.UPDATE_TEMPLATE_REQUEST,
        payload: {
          ...currentTemplate,
          ...pageChanges
        }
      })
    } else {
      dispatch({
        type: actions.UPDATE_PAGE_REQUESTED,
        payload: {
          ...currentPage,
          ...pageChanges
        }
      })
    }
    clearState()
  }

  const deletePage = () => {
    dispatch({
      type: actions.DELETE_PAGE_REQUESTED,
      payload: {
        ...currentPage
      },
      meta: history
    })
    clearState()
  }

  const handleMenuItemClick = e => {
    e.stopPropagation()
    if (hasChanged && !confirm('Do you want to undo all changes?')) {
      e.preventDefault()
    } else if (hasChanged) {
      clearState()
    }
  }

  const setPreview = () => {
    localStorage.setItem(
      'currentPage',
      JSON.stringify({ ...currentPage, ...pageChanges })
    )
    window.open(`${baseName}/preview`)
  }

  const exportPage = async () => {
    const json = JSON.stringify(currentPage)
    const blob = new Blob([json], { type: 'application/json' })
    const file = await URL.createObjectURL(blob)
    setPageExport(file)
    setExportModalOpen(true)
  }

  const uploadPage = page => {
    const fr = new FileReader()

    fr.onload = e => {
      let result = JSON.parse(e.target.result)
      delete result['id']
      result.meta['og:image'] = ''

      const newBlocks = result.blocks.map(block => {
        delete block['id']
        block.data.image = ''
        block.data.bgImage = ''
        block.data.img = ''
        block.data.promoRows = []
        block.data.reviews = []
        return block
      })

      result = {
        ...result,
        blocks: newBlocks
      }

      dispatch({
        type: actions.CREATE_PAGE_REQUESTED,
        payload: result,
        meta: history
      })
    }

    fr.readAsText(page)
  }

  const slugify = str => {
    if (str) {
      str = str.replace(/^\s+|\s+$/g, '')

      // Make the string lowercase
      str = str.toLowerCase()

      // Remove accents, swap ñ for n, etc
      var from =
        'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
      var to =
        'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      // Remove invalid chars
      str = str
        .replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-')

      return str
    }
  }

  return (
    <Page>
      <Header>
        <SiteName variant="h5">{company_name}</SiteName>
        <PageOptions>
          <FileInput
            id="file"
            type="file"
            name="file"
            aria-label="File Upload"
            title="File Upload"
            onChange={e => {
              uploadPage(e.target.files[0])
            }}
          />
          <ImportPageOption component={'label'} for="file" color="inherit">
            Import Page
          </ImportPageOption>
          <PageOption onClick={() => exportPage()} color="inherit">
            Export Page
          </PageOption>
          <PageOption
            color="inherit"
            onClick={() => setShowDeletePageModal(true)}
          >
            Delete Page
          </PageOption>
          <PageOption
            color="inherit"
            onClick={() => (window.location = window.location)}
          >
            Revert to Previous Version
          </PageOption>
          {!query.template && (
            <PageOption color="inherit" onClick={setPreview}>
              Preview Page
            </PageOption>
          )}
          <PageOption color="inherit" onClick={savePage} aria-label="Save Page">
            Save Page
          </PageOption>
        </PageOptions>
      </Header>
      <Helmet title="Create a page" />
      <Main>
        <SideMenu>
          <MenuButtonContainer onClick={() => setMenuModalOpen(true)}>
            <Typography>Menu Setup</Typography>
          </MenuButtonContainer>
          <TabsContainer>
            <PagesTab to={`/admin/builder`}>
              <Typography>Pages</Typography>
            </PagesTab>
            <BlogsTab to={`/admin/blog`}>
              <Typography>Blogs</Typography>
            </BlogsTab>
          </TabsContainer>
          <Button
            style={{ display: 'flex' }}
            onClick={() => setAddPage(true)}
            aria-label="create new page"
          >
            <AddCircleIcon />
            &nbsp;Create A New Page
          </Button>
          <Typography variant="caption" align="center" gutterBottom>
            Menu Pages
          </Typography>
          <PageMenu
            menu={menu}
            pages={pages.ids}
            selectedPageId={query.page}
            onClick={handleMenuItemClick}
          />
          <MenuDivider>
            <Divider />
            <Typography variant="caption" align="center" gutterBottom>
              Non-Menu Pages
            </Typography>
          </MenuDivider>
          <PageMenu
            menu={{ pages: notMenuPages }}
            pages={pages.ids}
            selectedPageId={query.page}
            onClick={handleMenuItemClick}
          />
          <MenuDivider>
            <Divider />
            <Typography variant="caption" align="center" gutterBottom>
              Template Pages
            </Typography>
          </MenuDivider>
          <PageMenu
            menu={{ pages: Object.values(templates.ids) }}
            pages={templates.ids}
            selectedPageId={query.template}
            onClick={handleMenuItemClick}
            queryKey="template"
          />
        </SideMenu>

        {query.template ? (
          <TemplateForm
            {...currentTemplate}
            setPageChanges={setPageChanges}
            key={currentTemplate.id}
            onChange={() => setHasChanged(true)}
          />
        ) : currentPage.id ? (
          <PageForm
            {...currentPage}
            key={currentPage.id}
            setPageChanges={setPageChanges}
            hasChanged={hasChanged}
            onChange={() => setHasChanged(true)}
          />
        ) : (
          <NotFound />
        )}
        <CreatePageModal
          open={addPageOpen}
          onClose={() => setAddPage(false)}
          onSelect={newPagetitle => {
            dispatch({
              type: actions.CREATE_PAGE_REQUESTED,
              payload: {
                title: newPagetitle,
                blocks: []
              },
              meta: history
            })

            setAddPage(false)
          }}
        />
      </Main>
      <Modal
        aria-labelledby="delete-page-title"
        aria-describedby="delete-page-description"
        open={showDeletePageModal}
        onClose={() => setShowDeletePageModal(false)}
      >
        <DeleteModal>
          <Typography variant="h2" gutterBottom align="center">
            Are you sure you want to delete this page?
          </Typography>
          <Buttons>
            <Button color="secondary" onClick={deletePage}>
              Yes, delete this page.
            </Button>
            <Button
              color="primary"
              onClick={() => setShowDeletePageModal(false)}
            >
              No, keep this page.
            </Button>
          </Buttons>
        </DeleteModal>
      </Modal>
      <Modal
        aria-labelledby="menu-setup-title"
        aria-describedby="menu-setup-description"
        open={menuModalOpen}
        onClose={() => setMenuModalOpen(false)}
      >
        <MenuSetup
          open={menuModalOpen}
          onClose={() => setMenuModalOpen(false)}
        />
      </Modal>
      <Modal
        aria-labelledby="export-page-title"
        aria-describedby="export-page-description"
        open={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
      >
        <ExportModal>
          <Typography gutterBottom align="center">
            Exported pages will not include images or any other domain-specific
            data (inventory items, blog posts, etc)
          </Typography>
          <br />
          <Typography>
            {currentPage && `${domainHost}-${slugify(currentPage.title)}.json`}
          </Typography>
          <Buttons>
            <Button
              component={'a'}
              href={pageExport}
              download={
                currentPage &&
                `${domainHost}-${slugify(currentPage.title)}.json`
              }
              color="secondary"
              onClick={() => setExportModalOpen(false)}
            >
              Download Page
            </Button>
            <Button color="primary" onClick={() => setExportModalOpen(false)}>
              Cancel
            </Button>
          </Buttons>
        </ExportModal>
      </Modal>
    </Page>
  )
}

export default Builder
