import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import Instagram from '@material-ui/icons/Instagram'
import YouTube from '@material-ui/icons/YouTube'
import { useTheme } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { useMenu } from '~/Hooks/useMenu'
import ListMenuItem from '~/SharedComponents/ListMenuItem'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

import { getCookie, setCookie } from '~/App/CookieStorage'; 
import BottomFooter from '~/App/BottomFooter'
import PrivacyPolicyPopup from '~/App/PrivacyPolicyPopup'


const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;
`

const CallUsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ color }) => `${color}` || `inherit`};
  color: ${({ txtColor }) => `${txtColor}` || 'inherit'};
  padding: 2rem;
`

const AddressContainer = styled.div`
  margin-top: 2rem;
  padding-left: 5rem;

  @media only screen and (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const MainContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  width: 320px;
  height: 200px;
  align-items: center;
  justify-content: center;
  padding-left: 5rem;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 160px;
    height: 100px;
    padding-left: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const AddressAndMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  padding-right: 2rem;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
    width: 100%;
  }
`

const MenuContainer = styled.nav`
  display: flex;
  justify-content: flex-end;
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    color: #8e8e8e;
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

const LowerFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ color }) => color || 'inherit'};
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

const SocialAndCopyrightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const SocialContainer = styled.div`
  width: 220px;
  padding-left: 5rem;
  display: flex;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
    width: 120px;
  }
`

const CopyrightContainer = styled.div`
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
    align-items: center;
  }
`

const Address = styled(Typography)``

const CallUsText = styled(Typography)``

const ScheduleText = styled(Typography)`
  font-style: italic;
`

const PoweredByText = styled(Typography)`
  padding-bottom: 2rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 2rem;
  }
`

const CopyrightText = styled(Typography)`
  display: flex;

  a {
    text-decoration: none;
    color: inherit;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 2rem;
    text-align: center;
  }
`

const LegalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const AddressTag = styled.address`
  font-style: normal;
`

const SocialLink = styled.a`
  flex: 0.25;
`

const Footer1 = ({
  address = '1234 Main St',
  city = 'Anytown',
  state = 'CA',
  zip = 90210,
  phone = '808-555-1234',
  facebook = 'http://www.facebook.com',
  twitter = 'http://www.twitter.com',
  instagram = 'http://www.instagram.com',
  youtube = 'http://www.youtube.com',
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  legal,
  terms,
  background,
  text = 'black',
  feature,
  menuText,
  featureText,
  line1 = 'Schedule a home tour today!',
  line2 = 'Give us a call at 808-555-1234',
  image,
  isPreview,
  includePrivacyPolicyLink,
  includeClaytonHomesFooter
}) => {
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const menu = useMenu()

  const pageHash = useSelector(state => state.pages.ids)

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const ftTextColor = getBasedOnTheme(featureText, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const primary = theme.palette.primary.main

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]

  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  useEffect(() => {
    // Assuming "privacyPolicyAccepted" is the cookie name
    const privacyAccepted = getCookie('privacyPolicyAccepted') === 'true';
    setIsPrivacyPolicyAccepted(privacyAccepted);
  }, []);
  
  const handleClosePrivacyPolicy = () => {
    setCookie('privacyPolicyAccepted', 'true', 365); // Set cookie for 365 days
    setIsPrivacyPolicyAccepted(true);
  };

  return (
    <FooterContainer img={image} color={bgColor}>
      <CallUsContainer color={ftColor || primary} txtColor={ftTextColor}>
        <ScheduleText gutterBottom align="center" variant="body2">
          {line1}
        </ScheduleText>
        <CallUsText align="center" variant="h3">
          {line2}
        </CallUsText>
      </CallUsContainer>
      <MainContainer>
        <LogoContainer to={'/'}>
          {brand.logo && <img src={brand.logo} alt={`${company.name} Logo`} />}
          {!brand.logo && isPreview && (
            <img src={brand.logo || logo} alt={`${company.name} Logo`} />
          )}
        </LogoContainer>
        <AddressAndMenuContainer>
          <MenuContainer>
            <MenuItemContainer>
              {isPreview
                ? previewData.map(({ path, label }) => (
                    <MenuLink to={path} key={path}>
                      <ListMenuItem color={menuTextColor} title={label} />
                    </MenuLink>
                  ))
                : menu.map(({ id, children }) => (
                    <MenuLink to={pageHash[id].path} key={pageHash[id].path}>
                      <ListMenuItem
                        color={menuTextColor}
                        title={pageHash[id].title}
                        menuChildren={children}
                      />
                    </MenuLink>
                  ))}
            </MenuItemContainer>
          </MenuContainer>
        </AddressAndMenuContainer>
      </MainContainer>
      <LowerFooterContainer color={textColor}>
        <AddressContainer>
          <AddressTag>
            <Address color="inherit">
              {address} {city} {state} {zip} | {phone}
            </Address>
          </AddressTag>
        </AddressContainer>
        <SocialAndCopyrightContainer>
          <SocialContainer>
            {facebook && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={facebook}
              >
                <Facebook color="primary" />
              </SocialLink>
            )}
            {twitter && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={twitter}
              >
                <Twitter color="primary" />
              </SocialLink>
            )}
            {instagram && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={instagram}
              >
                <Instagram color="primary" />
              </SocialLink>
            )}
            {youtube && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={youtube}
              >
                <YouTube color="primary" />
              </SocialLink>
            )}
          </SocialContainer>
          <CopyrightContainer>
            <CopyrightText variant="caption" color="inherit">
              Copyright © {new Date().getFullYear()}{' '}
              {legal && <LegalLink href={legal}>&nbsp;Legal</LegalLink>}
              {legal && terms && <p>&nbsp;| </p>}{' '}
              {terms && (
                <LegalLink href={terms}>&nbsp;Terms & Conditions</LegalLink>
              )}
            </CopyrightText>
            <PoweredByText variant="caption" color="inherit">
              <a
                href="https://www.gopowerslide.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Powered by PowerSlide
              </a>
            </PoweredByText>
          </CopyrightContainer>
        </SocialAndCopyrightContainer>
      </LowerFooterContainer>
      <>
        {includeClaytonHomesFooter && <BottomFooter />}
        {includePrivacyPolicyLink === 'true' && !isPrivacyPolicyAccepted ? <PrivacyPolicyPopup onClose={handleClosePrivacyPolicy} /> : null}
      </>

    </FooterContainer>
  )
}

Footer1.displayName = 'Footer 1'
Footer1.moduleName = 'Footer1'

export default Footer1
