import { createActions, createHandler } from '~/State/utils'
import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap } from 'rxjs/operators'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_COMPANY_REQUESTED
 * @prop {string} CREATE_COMPANY_SUCCESS
 * @prop {string} CREATE_COMPANY_FAILURE
 * @prop {string} ADD_COMPANY
 * @prop {string} REMOVE_COMPANY
 * @prop {string} UPDATE_COMPANY
 */

/**
 * @type {Actions}
 */
export const actions = createActions('COMPANIES', [
  'CREATE_COMPANY_REQUESTED',
  'CREATE_COMPANY_SUCCESS',
  'CREATE_COMPANY_FAILURE',
  'ADD_COMPANY',
  'REMOVE_COMPANY',
  'UPDATE_COMPANY'
])

export const defaultState = {
  companies: {
    ids: {
      1: {
        name: "Billy's Bobbin For Apples",
        siteName: 'My Dank Site',
        avatar:
          'https://media3.s-nbcnews.com/j/newscms/2017_06/1894781/170207-elizabeth-warren-ok-2333_1977793352baba889818e63db3348a7b.nbcnews-fp-1200-800.jpg'
      }
    },
    current: 1
  }
}

export const reducer = createHandler({
  [actions.ADD_COMPANY]: (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      ids: {
        ...state.companies.ids,
        [action.payload.id]: action.payload
      }
    }
  }),
  [actions.UPDATE_COMPANY]: (state, action) => ({
    ...state,
    companies: {
      ...state.companies,
      ids: {
        ...state.companies.ids,
        [action.payload.id]: {
          ...state.companies.ids[action.payload.id],
          ...action.payload
        }
      }
    }
  })
})

const addCompany$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_COMPANY_REQUESTED),
    flatMap(() =>
      of$(
        new Promise(res => {
          setTimeout(() => res({ id: 1, name: 'Joe Shmoe' }), 1000)
        })
      )
    ),
    map(apiResult => ({
      type: actions.UPDATE_COMPANY,
      payload: apiResult
    }))
  )

export const epics = [addCompany$]
