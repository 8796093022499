import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import URL from '~/Utils/api_base'
import { Link } from 'react-router-dom'
import AdminMenu from '~/Admin/AdminMenu'
import * as R from 'ramda'

import List from '@material-ui/core/List'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItem } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Typography from '@material-ui/core/Typography'
import Buttons from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import LaunchIcon from '@material-ui/icons/Launch';

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const RemoveModal = styled.div`
  width: 80%;
  min-height: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DomainItem = styled.div`
  padding: 1rem;
  display: flex;
  width: 50%;
  align-items: center;
`

const DomainListContainer = styled.div`
  padding: 0 2rem 2rem 2rem;
  margin-bottom: 2rem;
`

const DomainList = ({ domains, title, setDeleteModal, setDomainToDelete }) => (
  <DomainListContainer>
    <div style={{ marginBottom: '1rem', textTransform: 'capitalize' }}>
      <Typography variant="h4">{title} sites</Typography>
    </div>
    <div className="main-content">
      {domains
        .sort(function(a, b) {
          if (a.host.toLowerCase() < b.host.toLowerCase()) return -1
          if (a.host.toLowerCase() > b.host.toLowerCase()) return 1
        })
        .map(domain => (
          <DomainItem key={domain.id}>
            <ListItemText>
              <ListItem
                style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}
                component={Link}
                to={`/domains/${domain.id}/admin`}
              >
                {domain.host}
                {domain.tld}
              </ListItem>
            </ListItemText>

            <Tooltip title="Delete Domain">
              <ListItemAvatar>
                <Avatar>
                  <IconButton
                    onClick={() => {
                      setDeleteModal(true);
                      setDomainToDelete(domain);
                    }}
                  >
                    <DeleteForeverIcon style={{ color: 'rgb(255 64 64)' }} />
                  </IconButton>
                </Avatar>
              </ListItemAvatar>
            </Tooltip>


            <Tooltip title="Edit Domain Status">
              <ListItemAvatar>
                <Avatar component={Link} to={`/statuses/${domain.id}`}>
                  <EditIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>

            <Tooltip title="Open Domain Portal">
              <ListItemAvatar>
                <Avatar component={Link} to={`/domains/${domain.id}/admin`}>
                  <OpenInBrowserIcon />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            
            {title === 'live' && (
              <Tooltip title="Open Domain in New Tab">
                <ListItemAvatar>
                  <Avatar>
                    <IconButton
                      onClick={() => window.open(`http://${domain.host}${domain.tld}`, '_blank')}
                    >
                      <LaunchIcon style={{ color: 'rgb(185 255 185)' }} />
                    </IconButton>
                  </Avatar>
                </ListItemAvatar>
              </Tooltip>
            )}

          </DomainItem>
        ))}
    </div>
  </DomainListContainer>
)

const DomainGroups = ({ domains, setDeleteModal, setDomainToDelete }) => {
  const domain_titles = Object.keys(domains)

  return (
    <div>
      {domain_titles.map(title => (
        <DomainList
          key={title}
          setDeleteModal={setDeleteModal}
          setDomainToDelete={setDomainToDelete}
          title={title}
          domains={domains[title]}
        />
      ))}
    </div>
  )
}

const Domains = () => {
  const [domains, setDomains] = useState()
  const [error, setError] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [domainToDelete, setDomainToDelete] = useState()
  useEffect(() => {
    fetch(`${URL}/domains/?limit=100`)
      .then(x => x.json())
      .then(({ data, error }) => {
        if (error) {
          setError(error)
        } else {
          setDomains(
            R.groupBy(domain => {
              const status = domain.status
              return status === 'dev'
                ? 'dev'
                : status === 'staging'
                ? 'staging'
                : status === 'live'
                ? 'live'
                : 'dev'
            })(data)
          )
        }
      })
  }, [])

  if (!domains) {
    return 'Loading...'
  }

  if (error) {
    return <p>Error Loading Page: {error.message}</p>
  }

  const deleteDomain = domainId => {
    fetch(`${URL}/domains/${domainId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(x => x.json())
      .then(() => {
        setDeleteModal(false)
        window.location = window.location
      })
  }

  return (
    <Page>
      <AdminMenu />
      <List>
        <DomainGroups
          setDeleteModal={setDeleteModal}
          setDomainToDelete={setDomainToDelete}
          domains={domains}
        />
      </List>
      <Modal
        aria-labelledby="remove-domain-title"
        aria-describedby="remove-domain-description"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false)
          setDomainToDelete()
        }}
      >
        <RemoveModal>
          <Typography variant="h2" gutterBottom align="center">
            Are you sure you want to delete this Domain?
          </Typography>
          <Typography variant="h3" gutterBottom align="center">
            {domainToDelete && domainToDelete.host}
            {domainToDelete && domainToDelete.tld}
          </Typography>
          <Buttons>
            <Button
              color="secondary"
              onClick={() => deleteDomain(domainToDelete.id)}
            >
              Yes, delete this domain.
            </Button>
            <Button color="primary" onClick={() => setDeleteModal(false)}>
              No, keep this domain.
            </Button>
          </Buttons>
        </RemoveModal>
      </Modal>
    </Page>
  )
}

export default Domains
