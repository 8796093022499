import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const CalcContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})` || 'none'};
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  margin-left: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  color: ${({ color }) => `${color}` || `inherit`};
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-left: 0;
  }
`

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding: 0;
    padding-top: 1rem;
  }
`

const InformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  @media only screen and (max-width: 868px) and (min-width: 769px) {
    padding-left: 0;
    padding-right: 1rem;
  }
`

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${({ color }) => `${color}` || `inherit`};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
  align-items: center;
`

const HeaderText = styled(Typography)``

const SubtitleText = styled(Typography)`
  padding-top: 2rem;
`

const SubmitButton = styled(Button)`
  margin-left: 2rem;
`

const Input = styled(TextField)`
  background: white;
  border-radius: 4.5px;

  label {
    color: #7b7c7c;
  }
`

const HeaderAndTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`

const DisclaimerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
`

const MortgageCalc = ({
  background,
  text,
  image,
  header = 'Mortgage Calculator',
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
}) => {
  const [monthlyPayment, setMonthlyPayment] = useState()

  useEffect(() => {
    setMonthlyPayment()
  }, [])

  const calculatePayment = (a, p, n, apr) => {
    var r = apr / 1200
    var d = (r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1)

    return (a - p) * d
  }

  const handleSubmit = e => {
    e.preventDefault()

    const formdata = new FormData(e.target)

    const loanAmt = Number(formdata.get('loan'))
    const downPmt = Number(formdata.get('down'))
    const termLength = Number(formdata.get('term'))
    const aprPct = Number(formdata.get('apr'))

    setMonthlyPayment(calculatePayment(loanAmt, downPmt, termLength, aprPct))
  }

  const currentThemeEnum = useSelector(R.view(themeLens))
  const theme = useTheme()
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <CalcContainer onSubmit={handleSubmit} img={image} color={bgColor}>
      <TextContainer color={textColor}>
        <HeaderAndTextContainer>
          <HeaderText color="inherit" variant="h2">
            {header}
          </HeaderText>
          <SubtitleText color="inherit" variant="body1">
            {subtitle}
          </SubtitleText>
        </HeaderAndTextContainer>
        {isDesktop ? (
          <DisclaimerContainer>
            <Typography variant="caption">
              This calculator is intended solely for general informational
              purposes and to provide a rough estimate based on the information
              you provide. You should not base your decision on this estimate
              alone. Please note that title, registration, tax and other fees,
              and personal circumstances such as employment status and personal
              credit history, were not considered in the calculations. Personal
              circumstances may have a significant effect on interest rates for
              which an individual might qualify. Accordingly, we cannot and do
              not guarantee the accuracy or applicability of the estimates
              produced by this calculation, and the results from its use should
              be viewed as hypothetical, for illustrative purposes. At the
              appropriate time, we recommend that you rely upon actual interest
              rate and payment information provided to you by your dealer or
              lender.
            </Typography>
          </DisclaimerContainer>
        ) : null}
      </TextContainer>
      <FormContainer>
        <InformationContainer>
          <Input
            name="loan"
            id="loan"
            aria-label="Loan"
            title="Loan"
            label="Loan Amount"
            margin="normal"
            variant="outlined"
            type="number"
          />
          <Input
            name="down"
            id="down"
            aria-label="Down Payment"
            title="Down Payment"
            label="Down Payment"
            margin="normal"
            variant="outlined"
            type="number"
          />
          <Input
            name="term"
            id="term"
            aria-label="Loan Term (Months)"
            title="Loan Term (Months)"
            label="Loan Term (Months)"
            margin="normal"
            variant="outlined"
            type="number"
          />
          <Input
            name="apr"
            id="apr"
            aria-label="APR (%)"
            title="APR (%)"
            label="APR (%)"
            type="number"
            margin="normal"
            variant="outlined"
          />

          <ButtonContainer>
            <PriceContainer color={textColor}>
              <Typography variant="h3">
                {monthlyPayment
                  ? `$${monthlyPayment.toFixed(2).toLocaleString()}`
                  : '$ --.--'}
              </Typography>
            </PriceContainer>

            <SubmitButton color="primary" type="submit" variant="contained">
              Calculate Payment
            </SubmitButton>
          </ButtonContainer>
        </InformationContainer>
      </FormContainer>
      {isDesktop ? null : (
        <DisclaimerContainer>
          <Typography variant="caption">
            This calculator is intended solely for general informational
            purposes and to provide a rough estimate based on the information
            you provide. You should not base your decision on this estimate
            alone. Please note that title, registration, tax and other fees, and
            personal circumstances such as employment status and personal credit
            history, were not considered in the calculations. Personal
            circumstances may have a significant effect on interest rates for
            which an individual might qualify. Accordingly, we cannot and do not
            guarantee the accuracy or applicability of the estimates produced by
            this calculation, and the results from its use should be viewed as
            hypothetical, for illustrative purposes. At the appropriate time, we
            recommend that you rely upon actual interest rate and payment
            information provided to you by your dealer or lender.
          </Typography>
        </DisclaimerContainer>
      )}
    </CalcContainer>
  )
}

MortgageCalc.displayName = 'Mortgage Calculator'
MortgageCalc.moduleName = 'MortgageCalc'

export default MortgageCalc
