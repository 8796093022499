import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import { format, parseISO } from 'date-fns'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/blogs'
import { idsLens as userIds } from '~/State/ducks/users'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const BlogContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const Card = styled.div`
  max-width: 300px;
  width: 40%;
  margin: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: #ffffff;
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ theme, feature }) =>
    theme == 'Theme6' || theme == 'Theme8'
      ? `6px solid ${feature}`
      : `1px solid ${feature}`};
  color: ${({ color }) => `${color}` || `inherit`};
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  color: ${({ color }) => `${color}` || `inherit`};
`

const BlogTitle = styled(Typography)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 1rem;
`

const AuthorName = styled(Typography)`
  font-family: ${({ theme }) => theme == 'Theme6' && 'DM Sans'};
`

const previewData = [
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur phareta augue eget ex ultrices fringilla. Sed consequat vitae elit at rhoncus. Curabitur euismod neque a tristique convallis. Nunc cursus tellus eget gravida varius. Proin nec turpis eget nunc fermentum rutrum.',
    path: '/my-blog-path'
  }
]

const Blog3 = ({
  blogPosts = [],
  isPreview,
  background,
  text,
  image,
  feature
}) => {
  const blogs = useSelector(state => {
    const blogsById = R.view(idsLens, state)

    return blogPosts
      .filter(({ id }) => blogsById[id])
      .map(({ id, ...rest }) => ({
        title: 'My Default Title',
        ...rest,
        ...blogsById[id],
        id
      }))
  })

  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const userHash = useSelector(state => {
    return R.view(userIds, state)
  })

  if (isPreview) {
    return (
      <BlogContainer>
        {previewData.map((blog, i) => (
          <Card key={i}>
            <PictureContainer
              imagePadding={theme.imagePadding}
              boxShadow={theme.boxShadow}
            >
              <img src={blog.image} />
            </PictureContainer>
            <HeaderContainer color={theme.palette.primary.main}>
              <Typography variant="h5" gutterBottom>
                Month, day, year
              </Typography>
              <Typography variant="h6" gutterBottom>
                Author
              </Typography>
            </HeaderContainer>
            <CardFooter>
              <BlogTitle variant="h3" align="left">
                {blog.title}
              </BlogTitle>
              <Subtitle color="textPrimary" variant="body2" align="left">
                {blog.subtitle}
              </Subtitle>
            </CardFooter>
          </Card>
        ))}
      </BlogContainer>
    )
  }

  return (
    <BlogContainer img={image} color={bgColor}>
      {blogs.map((blog, i) => (
        <Card key={i}>
          <PictureContainer
            imagePadding={theme.imagePadding}
            boxShadow={theme.boxShadow}
          >
            <img src={blog.image} />
          </PictureContainer>
          <HeaderContainer
            theme={currentThemeEnum}
            feature={ftColor}
            color={textColor}
          >
            <Typography variant="h5" gutterBottom>
              {blog.last_updated &&
                format(parseISO(blog.last_updated), 'MMMM do yyyy')}
            </Typography>
            <AuthorName theme={currentThemeEnum} variant="h6" gutterBottom>
              {userHash[blog.author_id] && userHash[blog.author_id].first_name}{' '}
              {userHash[blog.author_id] && userHash[blog.author_id].last_name}
            </AuthorName>
          </HeaderContainer>
          <CardFooter color={textColor}>
            <BlogTitle variant="h3" align="left">
              {blog.title}
            </BlogTitle>
            <Subtitle color="inherit" variant="body2" align="left">
              {blog.subtitle}
            </Subtitle>
          </CardFooter>
        </Card>
      ))}
    </BlogContainer>
  )
}

Blog3.displayName = 'Blog 3'
Blog3.moduleName = 'Blog3'

export default Blog3
