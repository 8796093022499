import URL from '~/Utils/api_base'

const prod = user =>
  fetch(`${URL}/users/password_reset/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  }).then(x => x.json())

export default prod
