import React from 'react'
import * as Components from '~/Components'
import Typography from '@material-ui/core/Typography'
import styled from '@emotion/styled'

const ModuleInfo = styled.div``

export const contactInfo = [
  {
    displayName: Components.Contact1.displayName,
    Component: Components.Contact1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Contact 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Contact2.displayName,
    Component: Components.Contact2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Contact 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Contact3.displayName,
    Component: Components.Contact3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Contact 3</Typography>
      </ModuleInfo>
    )
  }
]

export const popupInfo = [
  {
    displayName: Components.Popup.displayName,
    Component: Components.Popup,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Popup</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.PopupWithForm.displayName,
    Component: Components.PopupWithForm,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Popup with Form</Typography>
      </ModuleInfo>
    )
  }
]

export const featuredBlogInfo = [
  {
    displayName: Components.FeaturedBlog1.displayName,
    Component: Components.FeaturedBlog1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Featured Blog 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.FeaturedBlog2.displayName,
    Component: Components.FeaturedBlog2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Featured Blog 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.FeaturedBlog3.displayName,
    Component: Components.FeaturedBlog3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Featured Blog 3</Typography>
      </ModuleInfo>
    )
  }
]

export const footerInfo = [
  {
    displayName: Components.Footer1.displayName,
    Component: Components.Footer1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Footer 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Footer2.displayName,
    Component: Components.Footer2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Footer 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Footer3.displayName,
    Component: Components.Footer3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Footer 3</Typography>
      </ModuleInfo>
    )
  }
]

export const headerInfo = [
  {
    displayName: Components.Header1.displayName,
    Component: Components.Header1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Header2.displayName,
    Component: Components.Header2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Header3.displayName,
    Component: Components.Header3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header 3</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Header4.displayName,
    Component: Components.Header4,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header 4</Typography>
      </ModuleInfo>
    )
  }
]

export const heroInfo = [
  {
    displayName: Components.Hero1.displayName,
    Component: Components.Hero1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Hero 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Hero2.displayName,
    Component: Components.Hero2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Hero 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Hero3.displayName,
    Component: Components.Hero3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Hero 3</Typography>
      </ModuleInfo>
    )
  }
]

export const introInfo = [
  {
    displayName: Components.Intro1.displayName,
    Component: Components.Intro1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Intro2.displayName,
    Component: Components.Intro2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Intro3.displayName,
    Component: Components.Intro3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro 3</Typography>
      </ModuleInfo>
    )
  }
]

export const introPhotoInfo = [
  {
    displayName: Components.IntroPhoto1.displayName,
    Component: Components.IntroPhoto1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro Photo 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.IntroPhoto2.displayName,
    Component: Components.IntroPhoto2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro Photo 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.IntroPhoto3.displayName,
    Component: Components.IntroPhoto3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Intro Photo 3</Typography>
      </ModuleInfo>
    )
  }
]

export const inventoryInfo = [
  {
    displayName: Components.Inventory1.displayName,
    Component: Components.Inventory1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Inventory 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Inventory2.displayName,
    Component: Components.Inventory2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Inventory 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Inventory3.displayName,
    Component: Components.Inventory3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Inventory 3</Typography>
      </ModuleInfo>
    )
  }
]

export const locationInfo = [
  {
    displayName: Components.Locations1.displayName,
    Component: Components.Locations1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Locations 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Locations2.displayName,
    Component: Components.Locations2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Locations 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Locations3.displayName,
    Component: Components.Locations3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Locations 3</Typography>
      </ModuleInfo>
    )
  }
]

export const newInventoryInfo = [
  {
    displayName: Components.NewInventory1.displayName,
    Component: Components.NewInventory1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">New Inventory 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.NewInventory2.displayName,
    Component: Components.NewInventory2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">New Inventory 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.NewInventory3.displayName,
    Component: Components.NewInventory3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">New Inventory 3</Typography>
      </ModuleInfo>
    )
  }
]

export const reviewInfo = [
  {
    displayName: Components.Reviews1.displayName,
    Component: Components.Reviews1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Reviews 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Reviews2.displayName,
    Component: Components.Reviews2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Reviews 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Reviews3.displayName,
    Component: Components.Reviews3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Reviews 3</Typography>
      </ModuleInfo>
    )
  }
]

export const blogInfo = [
  {
    displayName: Components.Blog1.displayName,
    Component: Components.Blog1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Blog2.displayName,
    Component: Components.Blog2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Blog3.displayName,
    Component: Components.Blog3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 3</Typography>
      </ModuleInfo>
    )
  }
]

export const altBlogInfo = [
  {
    displayName: Components.AltBlog1.displayName,
    Component: Components.AltBlog1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 1 (No Image)</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.AltBlog2.displayName,
    Component: Components.AltBlog2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 2 (No Image)</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.AltBlog3.displayName,
    Component: Components.AltBlog3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Blog 3 (No Image)</Typography>
      </ModuleInfo>
    )
  }
]

export const filteredInventoryInfo = [
  {
    displayName: Components.FilteredInventory1.displayName,
    Component: Components.FilteredInventory1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Filtered Inventory 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.FilteredInventory2.displayName,
    Component: Components.FilteredInventory2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Filtered Inventory 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.FilteredInventory3.displayName,
    Component: Components.FilteredInventory3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Filtered Inventory 3</Typography>
      </ModuleInfo>
    )
  }
]

export const promoImageInfo = [
  {
    displayName: Components.Promo1.displayName,
    Component: Components.Promo1,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Promo Images 1</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Promo2.displayName,
    Component: Components.Promo2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Promo Images 2</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.Promo3.displayName,
    Component: Components.Promo3,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Promo Images 3</Typography>
      </ModuleInfo>
    )
  }
]

export const textInfo = [
  {
    displayName: Components.Text.displayName,
    Component: Components.Text,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Text</Typography>
      </ModuleInfo>
    )
  }
]

export const fileListInfo = [
  {
    displayName: Components.FileList.displayName,
    Component: Components.FileList,
    info: (
      <ModuleInfo>
        <Typography variant="h4">File List</Typography>
      </ModuleInfo>
    )
  }
]

export const headerHeroInfo = [
  {
    displayName: Components.HeaderHero.displayName,
    Component: Components.HeaderHero,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header Hero</Typography>
      </ModuleInfo>
    )
  },
  {
    displayName: Components.HeaderHero2.displayName,
    Component: Components.HeaderHero2,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Header Hero 2</Typography>
      </ModuleInfo>
    )
  }
]

export const affiliationsInfo = [
  {
    displayName: Components.Affiliations.displayName,
    Component: Components.Affiliations,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Affiliations</Typography>
      </ModuleInfo>
    )
  }
]

export const mortgageCalcInfo = [
  {
    displayName: Components.MortgageCalc.displayName,
    Component: Components.MortgageCalc,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Mortgage Calculator</Typography>
      </ModuleInfo>
    )
  }
]

export const videoRequestInfo = [
  {
    displayName: Components.VideoRequest.displayName,
    Component: Components.VideoRequest,
    info: (
      <ModuleInfo>
        <Typography variant="h4">Video Chat Request</Typography>
      </ModuleInfo>
    )
  }
]
