import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/styles'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const PromoContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
    padding-top: 1rem;
  }
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 480px;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    margin: 0;
    margin-left: 1rem;

    :last-of-type {
      margin-right: 1rem;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    background: ${({ boxShadow = 'inherit' }) =>
      boxShadow == 'none' ? 'transparent' : '#ffffff'};
  }
`

const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const PromoLink = styled.a`
  text-decoration: none;
`

const PromoNonLink = styled.div``

const Promo3 = ({
  homes = {
    name: 'Name of Home',
    price: '$100,000',
    img:
      'https://web.archive.org/web/20200405072553if_/http://www.inmanmanufacturedhomes.com/assets/SEExterior_7A2DDA804D737CD78C7098CF91FE68D0_41PRF32643AH.jpg'
  },
  isPreview,
  promoRows,
  background,
  image
}) => {
  const theme = useTheme()

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const isRelative = path => path.charAt(0) === '/'

  if (isPreview) {
    return (
      <PromoContainer>
        <RowContainer>
          <PictureContainer>
            <img src={homes.img} />
          </PictureContainer>
          <PictureContainer>
            <img src={homes.img} />
          </PictureContainer>
          <PictureContainer>
            <img src={homes.img} />
          </PictureContainer>
        </RowContainer>
      </PromoContainer>
    )
  }

  return (
    <PromoContainer img={image} color={bgColor}>
      {promoRows &&
        promoRows.map((promo, i) => (
          <RowContainer key={i}>
            <PictureContainer boxShadow={theme.boxShadow}>
              {promo.link1 ? (
                <PromoLink
                  href={promo.link1}
                  target={isRelative(promo.link1) ? '_self' : '_blank'}
                >
                  <img
                    title={promo.title1}
                    alt={promo.alt1}
                    src={promo.image1}
                  />
                </PromoLink>
              ) : (
                <PromoNonLink>
                  <img
                    title={promo.title1}
                    alt={promo.alt1}
                    src={promo.image1}
                  />
                </PromoNonLink>
              )}
            </PictureContainer>
            <PictureContainer boxShadow={theme.boxShadow}>
              {promo.link2 ? (
                <PromoLink
                  href={promo.link2}
                  target={isRelative(promo.link2) ? '_self' : '_blank'}
                >
                  <img
                    title={promo.title2}
                    alt={promo.alt2}
                    src={promo.image2}
                  />
                </PromoLink>
              ) : (
                <PromoNonLink>
                  <img
                    title={promo.title2}
                    alt={promo.alt2}
                    src={promo.image2}
                  />
                </PromoNonLink>
              )}
            </PictureContainer>
            <PictureContainer boxShadow={theme.boxShadow}>
              {promo.link3 ? (
                <PromoLink
                  href={promo.link3}
                  target={isRelative(promo.link3) ? '_self' : '_blank'}
                >
                  <img
                    title={promo.title3}
                    alt={promo.alt3}
                    src={promo.image3}
                  />
                </PromoLink>
              ) : (
                <PromoNonLink>
                  <img
                    title={promo.title3}
                    alt={promo.alt3}
                    src={promo.image3}
                  />
                </PromoNonLink>
              )}
            </PictureContainer>
          </RowContainer>
        ))}
    </PromoContainer>
  )
}

Promo3.displayName = 'Promo 3'
Promo3.moduleName = 'Promo3'

export default Promo3
