import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'
import DescriptionIcon from '@material-ui/icons/Description'

import * as R from 'ramda'

import API_URL from '~/Utils/api_base'
import { idLens } from '~/State/ducks/domain'

const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;

  button:not(:first-of-type) {
    margin-left: 1rem;
  }
`

const InputsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  border: 1px solid black;
  border-radius: 4.5px;
`

const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImageInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const PreviewLink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
`

const RemoveButton = styled(Button)`
  padding-left: 1rem;
`
//For some reason the add button won't center on certain pages, adding this empty div fixes it?
const InnerContainer = styled.div``

const FileUpload = ({ onChange, file, name }) => {
  const [currentFile, setCurrentFile] = useState(file)
  const domain_id = useSelector(R.view(idLens))

  //This is getting the end of the S3 bucket url
  const getEndFromIMG = url => {
    const encoded = new URL(url)

    return encoded.pathname
  }

  const deleteImage = () => {
    const end = getEndFromIMG(file)
    const url = `${API_URL}/files${end}`
    return fetch(url, {
      method: 'DELETE'
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          onChange('')
          setCurrentFile('')
        }
      })
  }
  const reset = () => deleteImage().then(() => onChange())

  useEffect(() => {
    //We are checking to see if the current image is different than the image that was passed in
    if (currentFile && currentFile !== file) {
      const data = new FormData()

      data.set('file', currentFile)

      fetch(`${API_URL}/files/${domain_id}/pdf`, {
        method: 'POST',
        body: data
      })
        .then(x => x.json())
        .then(({ data }) => {
          onChange(data.link)
        })
    }
  }, [currentFile])

  return (
    <UploadFileContainer>
      <InputsContainer>
        <InnerContainer>
          {currentFile && <Typography>{currentFile.name}</Typography>}

          {!file && !currentFile && (
            <IconButton component={'label'} htmlFor={name}>
              <AddCircleIcon />
            </IconButton>
          )}

          {file && (
            <PreviewLink target="_blank" href={file}>
              <DescriptionIcon fontSize="large" />
              <Typography>Click to preview file</Typography>
            </PreviewLink>
          )}

          <ImageInput
            id={name}
            type="file"
            name={name}
            aria-label={name}
            title={name}
            onChange={e => {
              setCurrentFile(e.target.files[0])
            }}
          />
        </InnerContainer>
      </InputsContainer>

      <ButtonContainer>
        {file && (
          <RemoveButton
            variant="contained"
            color="primary"
            onClick={() => reset().then(() => onChange(''))}
          >
            Remove
          </RemoveButton>
        )}
      </ButtonContainer>
    </UploadFileContainer>
  )
}

export default FileUpload
