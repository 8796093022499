import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterListIcon from '@material-ui/icons/FilterList'
import { parseUrl } from 'query-string'

import * as R from 'ramda'

import { idsLens } from '~/State/ducks/inventory'
import { idsLens as categoryLens } from '~/State/ducks/categories'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { idsLens as locationLens } from '~/State/ducks/locations'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const InventoryContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`

const Card = styled(Link)`
  max-width: 320px;
  margin: 1rem;
  margin-bottom: 2rem;
  text-decoration: none;
  color: inherit;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const CardHeader = styled.div`
  display: flex;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    padding-bottom: 1rem;
  }
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
`

const PriceAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: #ffffff;
  }
`

const NameOfHome = styled(Typography)``

const Price = styled(Typography)`
  @media only screen and (max-width: 768px) {
    padding-right: 1rem;
  }
`

// const Subtitle = styled(Typography)`
//   @media only screen and (max-width: 768px) {
//     text-align: center;
//   }
// `

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 1rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`

const DropDown = styled(Select)`
  width: 240px;
  margin-bottom: 1rem;
  margin-right: 1rem;
`

const DropDownFormControl = styled(FormControl)``

const PriceSliderContainer = styled.div`
  padding-left: 5rem;
  padding-right: 5rem;

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const CategorySelect = styled(Select)`
  width: 240px;
  max-width: 240px;
`

const FilterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  label {
    background: white;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const ExpansionContainer = styled.div`
  padding: 2rem;
`

const LearnMore = styled.div`
  text-decoration: none;
  color: inherit;
`

const LearnMoreText = styled(Typography)`
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  font-size: 0.875rem !important;
`

const Status = styled(Button)`
  position: absolute !important;
  top: 5%;
  right: 5%;
`

const ClearButton = styled(Button)`
  height: auto;
`

//THIS DIV IS VERY IMPORTANT
const SliderWrapper = styled.div``

const previewData = [
  {
    title: 'My Default Title',
    path: '/inventory/1',
    manufacturer: 'ABC Corp',
    modelID: '1234',
    location: 1,
    footage: 1234,
    bedrooms: 2,
    bathrooms: 1.5,
    description:
      'I am the description that was entered into this system. I am a basic description but a description none the less.',
    features: [
      'acb17c51-6cc7-47ea-b11b-6c2db147321d',
      '28296ca4-87f1-4c30-855f-9729013a5144',
      '9eec3490-9345-41f0-af73-97c1ef2343ea'
    ],
    categories: [
      '0191f26d-294a-42d8-a907-73012d3598f7',
      '308bb1a4-f244-40f1-ab2e-bb75833eec80',
      'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
    ],
    price: 12345.67,
    multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
    published: true,
    status: 'sold',
    mainImage:
      'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
    images: [],
    isClayton: true
  },
  {
    title: 'My Default Title',
    path: '/inventory/1',
    manufacturer: 'ABC Corp',
    modelID: '1234',
    location: 1,
    footage: 1234,
    bedrooms: 2,
    bathrooms: 1.5,
    description:
      'I am the description that was entered into this system. I am a basic description but a description none the less.',
    features: [
      'acb17c51-6cc7-47ea-b11b-6c2db147321d',
      '28296ca4-87f1-4c30-855f-9729013a5144',
      '9eec3490-9345-41f0-af73-97c1ef2343ea'
    ],
    categories: [
      '0191f26d-294a-42d8-a907-73012d3598f7',
      '308bb1a4-f244-40f1-ab2e-bb75833eec80',
      'be05935f-78e9-4f41-8ab8-cefbc2e8d3e8'
    ],
    price: 12345.67,
    multimedia: ['https://youtube.com', 'https://reddit.com/r/all'],
    published: true,
    status: 'sold',
    mainImage:
      'https://i.pinimg.com/originals/6d/50/31/6d50317bd5276f2031e906bba42a63b6.jpg',
    images: [],
    isClayton: true
  }
]

const FilteredInventory3 = ({
  priceFilterMin: compPriceMin,
  priceFilterMax: compPriceMax,
  footageFilter: compFootage,
  bedsFilter: compBeds,
  bathsFilter: compBaths,
  categoryFilter: compCategory = [],
  locationFilter: compLocation,
  sectionFilter: compSection,
  manuFilter: compManu,
  isPreview,
  background,
  text,
  image,
  expanded
}) => {
  const inventory = useSelector(state => {
    const idHash = R.view(idsLens, state)

    return R.values(idHash)
  })

  const getMaxPrice = R.reduce((a, c) => Math.max(a, c.price), 0)
  const maxPrice = getMaxPrice(inventory)

  const [priceFilterMin, setPriceFilterMin] = useState(compPriceMin)
  const [priceFilterMax, setPriceFilterMax] = useState(compPriceMax)
  const [footageFilter, setFootageFilter] = useState(compFootage)
  const [bedsFilter, setBedsFilter] = useState(compBeds)
  const [bathsFilter, setBathsFilter] = useState(compBaths)
  const [locationFilter, setLocationFilter] = useState(compLocation)
  const [categoryFilter, setCategoryFilter] = useState(compCategory || [])
  const [sectionFilter, setSectionFilter] = useState(compSection)
  const [manuFilter, setManuFilter] = useState(compManu)

  const categoryHash = useSelector(R.view(categoryLens))
  const locationHash = useSelector(R.view(locationLens))

  const categoryList = R.values(categoryHash)
  const locationList = R.values(locationHash)

  const clearFilters = () => {
    setPriceFilterMax()
    setPriceFilterMin()
    setBathsFilter('')
    setBedsFilter('')
    setLocationFilter('')
    setFootageFilter('')
    setCategoryFilter([])
    setSectionFilter('')
    setManuFilter('')
  }

  useEffect(() => {
    if (compPriceMax) {
      setPriceFilterMax(compPriceMax)
    }

    if (compPriceMin) {
      setPriceFilterMin(compPriceMin)
    }

    if (compBaths) {
      setBathsFilter(compBaths)
    }

    if (compBeds) {
      setBedsFilter(compBeds)
    }

    if (compLocation) {
      setLocationFilter(compLocation)
    }

    if (compFootage) {
      setFootageFilter(compFootage)
    }

    if (compCategory.length > 0) {
      setCategoryFilter(compCategory)
    }

    if (compSection) {
      setSectionFilter(compSection)
    }

    if (compManu) {
      setManuFilter(compManu)
    }
  }, [
    compPriceMax,
    compPriceMin,
    compCategory,
    compBaths,
    compBeds,
    compFootage,
    compLocation,
    compSection,
    compManu
  ])

  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const singleSection = R.find(R.propEq('name', 'Single Section'))(categoryList)

  const multiSection = R.find(R.propEq('name', 'Multi Section'))(categoryList)

  const newCategoryList = categoryList.filter(cat => {
    if (singleSection && multiSection) {
      return cat.id !== singleSection.id && cat.id !== multiSection.id
    } else {
      return cat
    }
  })

  const manuList = [...new Set(inventory.map((item) => item.manufacturer))].sort()

  if (isPreview) {
    return (
      <InventoryContainer color={bgColor}>
        <ExpansionContainer>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <FilterListIcon /> <Typography>Filters</Typography>
            </ExpansionPanelSummary>
          </ExpansionPanel>
        </ExpansionContainer>
        <CardContainer>
          {previewData.map((inventory, i) => (
            <Card key={i}>
              <CardHeader color={textColor}>
                <NameOfHome component="p" variant="h3" gutterBottom>
                  {inventory.title}
                </NameOfHome>
              </CardHeader>
              <PictureContainer
                imagePadding={theme.imagePadding}
                boxShadow={theme.boxShadow}
              >
                <img
                  src={inventory.mainImage}
                  alt={inventory.title}
                  title={inventory.title}
                />
              </PictureContainer>
              <CardFooter color={textColor}>
                {/* <Subtitle color="textPrimary" variant="body2">
                  {inventory.description}
                </Subtitle> */}
                <PriceAndButtonContainer>
                  <Price color="primary" component="p" variant="h4">
                    {inventory.price && inventory.price == 0
                      ? 'Contact Us for Pricing'
                      : `$${inventory.price.toLocaleString()}`}
                  </Price>
                  <Button color="primary" component={Link} to={inventory.path}>
                    Learn More
                  </Button>
                </PriceAndButtonContainer>
              </CardFooter>
            </Card>
          ))}
        </CardContainer>
      </InventoryContainer>
    )
  }

  return (
    <InventoryContainer img={image} color={bgColor}>
      <ExpansionContainer>
        <ExpansionPanel defaultExpanded={expanded}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <FilterListIcon /> <Typography>Filters</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FilterContainer>
              {!compPriceMax && !compPriceMin && (
                <PriceSliderContainer>
                  <Typography id="price-slider" gutterBottom>
                    Price Range
                  </Typography>
                  <SliderWrapper>
                    <Slider
                      defaultValue={
                        maxPrice === 0 ? [0, 10000] : [0, maxPrice + 10000]
                      }
                      aria-labelledby="price-slider"
                      valueLabelDisplay="auto"
                      onChange={(_, v) => {
                        const [priceMin, priceMax] = v
                        setPriceFilterMax(priceMax)
                        setPriceFilterMin(priceMin)
                      }}
                      step={10000}
                      marks={
                        maxPrice === 0
                          ? [
                              { value: 0, label: '$0' },
                              { value: 10000, label: '$10,000' }
                            ]
                          : [
                              { value: 0, label: '$0' },
                              {
                                value: maxPrice,
                                label: `$${maxPrice.toLocaleString()}`
                              }
                            ]
                      }
                      min={0}
                      max={maxPrice === 0 ? 10000 : maxPrice + 10000}
                    />
                  </SliderWrapper>
                </PriceSliderContainer>
              )}

              <FilterRow>
                {!compManu && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="manufacturer">Manufacturer</InputLabel>
                    <DropDown
                      name="manufacturer"
                      value={manuFilter || ''}
                      onChange={e => setManuFilter(e.target.value)}
                    >
                      <MenuItem value="">All Manufacturers</MenuItem>

                        {manuList.map((manu) => (
                          <MenuItem key={manu} value={manu}>
                            {manu}
                          </MenuItem>
                        ))}
                    </DropDown>
                  </DropDownFormControl>
                )}
                {compCategory.length < 1 && (
                  <CategoryContainer>
                    <FormControl variant="outlined">
                      <InputLabel id="categoy-label">Categories</InputLabel>
                      <CategorySelect
                        labelId="category-label"
                        name="category"
                        id="category"
                        fullWidth
                        value={categoryFilter || ''}
                        multiple
                        onChange={e => setCategoryFilter(e.target.value)}
                      >
                        {newCategoryList.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </CategorySelect>
                      <FormHelperText>
                        Leave blank to show all categories
                      </FormHelperText>
                    </FormControl>
                  </CategoryContainer>
                )}
                {!compLocation && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="footage">Location</InputLabel>
                    <DropDown
                      name="location"
                      value={locationFilter || ''}
                      onChange={e => setLocationFilter(e.target.value)}
                    >
                      <MenuItem value="">All Locations</MenuItem>

                      {locationList.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </DropDown>
                  </DropDownFormControl>
                )}
                {singleSection && multiSection && !compSection && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="footage">Sections</InputLabel>
                    <DropDown
                      name="location"
                      value={sectionFilter || ''}
                      onChange={e => setSectionFilter(e.target.value)}
                    >
                      <MenuItem value="">All Sections</MenuItem>

                      <MenuItem value={singleSection.id}>
                        Single Section
                      </MenuItem>
                      <MenuItem value={multiSection.id}>Multi Section</MenuItem>
                    </DropDown>
                  </DropDownFormControl>
                )}
                {!compBeds && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="footage">Bedrooms</InputLabel>
                    <DropDown
                      name="bedrooms"
                      value={bedsFilter || ''}
                      onChange={e => setBedsFilter(e.target.value)}
                    >
                      <MenuItem value="">All Bedrooms</MenuItem>
                      <MenuItem value="1">1 Bedroom</MenuItem>
                      <MenuItem value="2">2 Bedrooms</MenuItem>
                      <MenuItem value="3">3 Bedrooms</MenuItem>
                      <MenuItem value="4">4 Bedrooms</MenuItem>
                      <MenuItem value="5">5+ Bedrooms</MenuItem>
                    </DropDown>
                  </DropDownFormControl>
                )}
                {!compBaths && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="footage">Baths</InputLabel>
                    <DropDown
                      name="footage"
                      value={bathsFilter || ''}
                      onChange={e => setBathsFilter(e.target.value)}
                      inputProps={{
                        name: 'footage',
                        id: 'footage'
                      }}
                    >
                      <MenuItem value="">All Baths</MenuItem>
                      <MenuItem value="1">1 Bath</MenuItem>
                      <MenuItem value="2">2 Baths</MenuItem>
                      <MenuItem value="3">3 Baths</MenuItem>
                      <MenuItem value="4">4 Baths</MenuItem>
                      <MenuItem value="5">5+ Baths</MenuItem>
                    </DropDown>
                  </DropDownFormControl>
                )}
                {!compFootage && (
                  <DropDownFormControl variant="outlined">
                    <InputLabel htmlFor="footage">Sq Footage</InputLabel>
                    <DropDown
                      name="footage"
                      value={footageFilter || ''}
                      onChange={e => setFootageFilter(e.target.value)}
                      inputProps={{
                        name: 'footage',
                        id: 'footage'
                      }}
                    >
                      <MenuItem value="">All Sq Footage</MenuItem>
                      <MenuItem value="500">&gt; 500 ft</MenuItem>
                      <MenuItem value="750">&gt; 750 ft</MenuItem>
                      <MenuItem value="1000">&gt; 1,000 ft</MenuItem>
                      <MenuItem value="1500">&gt; 1,500 ft</MenuItem>
                      <MenuItem value="2000">&gt; 2,000 ft</MenuItem>
                    </DropDown>
                  </DropDownFormControl>
                )}
                <div
                  style={{
                    height: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <ClearButton
                    color="secondary"
                    variant="contained"
                    onClick={clearFilters}
                  >
                    Clear
                  </ClearButton>
                </div>
              </FilterRow>
            </FilterContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </ExpansionContainer>
      <CardContainer>
        {inventory
          .filter(inv => inv.published)
          .filter(item => {
            const priceMin = priceFilterMin ? Number(priceFilterMin) : 0
            const priceMax = priceFilterMax
              ? Number(priceFilterMax)
              : Number.MAX_SAFE_INTEGER
            const footage = footageFilter ? Number(footageFilter) : 0
            const baths = bathsFilter ? Number(bathsFilter) : 0
            const beds = bedsFilter ? Number(bedsFilter) : 0
            const category =
              categoryFilter.length > 0 ? categoryFilter : item.categories
            const location = locationFilter ? locationFilter : item.location
            const section = sectionFilter ? sectionFilter : ''
            const manu = manuFilter ? manuFilter : ''

            return (
              item.price >= priceMin &&
              item.price <= priceMax &&
              item.footage >= footage &&
              (baths == 5 || baths == 0
                ? item.bathrooms >= baths
                : item.bathrooms == baths) &&
              (beds == 5 || beds == 0
                ? item.bedrooms >= beds
                : item.bedrooms == beds) &&
              (category.length > 0
                ? category.every(v => item.categories.includes(v))
                : true) &&
              item.location === location &&
              (section !== '' ? item.categories.includes(section) : true) &&
              (manu !== '' ? item.manufacturer == manu : true)
            )
          })
          .map((inventory, i) => (
            <Card key={i} to={inventory.path}>
              <CardHeader color={textColor}>
                <NameOfHome component="p" variant="h3" gutterBottom>
                  {inventory.title}
                </NameOfHome>
              </CardHeader>
              <PictureContainer
                imagePadding={theme.imagePadding}
                boxShadow={theme.boxShadow}
              >
                <img
                  loading="lazy"
                  title={parseUrl(inventory.mainImage).query.title}
                  alt={parseUrl(inventory.mainImage).query.alt}
                  src={inventory.mainImage}
                />
                {inventory.status === 'sold' && (
                  <Status variant="contained" color="primary">
                    SOLD
                  </Status>
                )}
              </PictureContainer>
              <CardFooter color={textColor}>
                {/* <Subtitle variant="body2">{inventory.description}</Subtitle> */}
                <PriceAndButtonContainer>
                  <Price color="primary" component="p" variant="h4">
                    {inventory.price == 0
                      ? 'Contact Us for Pricing'
                      : `$${inventory.price.toLocaleString()}`}
                  </Price>
                  <LearnMore color={textColor}>
                    <LearnMoreText
                      component="p"
                      variant={'h4'}
                      textColor={textColor}
                    >
                      LEARN MORE
                    </LearnMoreText>
                  </LearnMore>
                </PriceAndButtonContainer>
              </CardFooter>
            </Card>
          ))}
      </CardContainer>
    </InventoryContainer>
  )
}
FilteredInventory3.displayName = 'Filtered Inventory 3'
FilteredInventory3.moduleName = 'FilteredInventory3'

export default FilteredInventory3
