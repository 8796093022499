import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Facebook from '@material-ui/icons/Facebook'
import Twitter from '@material-ui/icons/Twitter'
import Instagram from '@material-ui/icons/Instagram'
import YouTube from '@material-ui/icons/YouTube'
import { useTheme } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { useMenu } from '~/Hooks/useMenu'
import ListMenuItem from '~/SharedComponents/ListMenuItem'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

import { getCookie, setCookie } from '~/App/CookieStorage'; 
import BottomFooter from '~/App/BottomFooter'
import PrivacyPolicyPopup from '~/App/PrivacyPolicyPopup'

const FooterContainer = styled.footer`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: ${({ primaryColor }) => `${primaryColor}` || `inherit`};
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;
`

const AddressContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};
  margin-top: 2rem;
  padding-left: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
`

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const LogoContainer = styled(Link)`
  margin-left: 5rem;
  display: flex;
  width: 320px;
  height: 200px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 160px;
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const AddressAndSocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  margin-right: 5rem;

  @media only screen and (max-width: 768px) {
    text-align: center;
    margin-right: 0;
    align-items: center;
    width: 100%;
  }
`

const MenuContainer = styled.nav`
  padding-left: 5rem;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
  }
`

const MenuLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover {
    color: #8e8e8e;
  }
`

const MenuItemContainer = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const MenuAndCopyrightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const SocialContainer = styled.div`
  padding-top: 4rem;
  padding-left: 5rem;
  width: 220px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 120px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1rem;
  }
`

const CopyrightContainer = styled.div`
  padding-right: 5rem;
  color: ${({ color }) => `${color}` || `inherit`};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    padding-right: 0rem;
    align-items: center;
  }
`

const Address = styled(Typography)``

const PoweredByText = styled(Typography)`
  a {
    text-decoration: none;
    color: inherit;
  }
`

const CopyrightText = styled(Typography)`
  display: flex;
  a {
    text-decoration: none;
    color: inherit;
  }
`

const Divider = styled.hr`
  width: 90%;
  height: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? '5px'
      : `1px`};
  background-color: ${({ color }) => `${color}` || `inherit`};
`

const AddressTag = styled.address`
  font-style: normal;
`

const SocialLink = styled.a`
  flex: 0.25;
`

const LegalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Footer3 = ({
  background,
  text = 'black',
  feature,
  menuText,
  address = '1234 Main St',
  city = 'Anytown',
  state = 'CA',
  zip = 90210,
  phone = '808-555-1234',
  facebook = 'myfacebooklink.com',
  twitter = 'mytwitterlink.com',
  instagram = 'myinstagramlink.com',
  youtube = 'youtube.com',
  logo = 'https://www.edmition.com/wp-content/uploads/2015/10/generalLogoMEA_175056_300dpi_1772H_1772W.png',
  isPreview,
  image,
  legal,
  terms,
  includePrivacyPolicyLink,
  includeClaytonHomesFooter
}) => {
  const showClaytonHomesFooter = includeClaytonHomesFooter === 'true';

  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const menu = useMenu()

  const pageHash = useSelector(state => state.pages.ids)

  const company = useSelector(state => state.profile.company)

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const menuTextColor = getBasedOnTheme(menuText, currentTheme, brand)

  const primary = theme.palette.primary.main

  const previewData = [
    {
      label: 'Page ABC',
      path: '/abc'
    },
    {
      label: 'Page DEF',
      path: '/def'
    },
    {
      label: 'Page XYZ',
      path: '/xyz'
    },
    {
      label: 'That Other Page That Has a Weirdly Long Title',
      path: '/really/nested/path'
    }
  ]


  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  useEffect(() => {
    // Assuming "privacyPolicyAccepted" is the cookie name
    const privacyAccepted = getCookie('privacyPolicyAccepted') === 'true';
    setIsPrivacyPolicyAccepted(privacyAccepted);
  }, []);
  
  const handleClosePrivacyPolicy = () => {
    setCookie('privacyPolicyAccepted', 'true', 365); // Set cookie for 365 days
    setIsPrivacyPolicyAccepted(true);
  };

  return (
    <FooterContainer
      img={image}
      color={bgColor}
      primaryColor={ftColor || primary}
    >
      <MainContainer>
        <LogoContainer to={'/'}>
          {brand.logo && <img src={brand.logo} alt={`${company.name} Logo`} />}
          {!brand.logo && isPreview && (
            <img src={brand.logo || logo} alt={`${company.name} Logo`} />
          )}
        </LogoContainer>
        <AddressAndSocialContainer>
          <AddressContainer color={textColor}>
            <AddressTag>
              <Address color="inherit">
                {address} {city} {state} {zip}
              </Address>
              <Address color="inherit">{phone}</Address>
            </AddressTag>
          </AddressContainer>

          <SocialContainer>
            {facebook && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={facebook}
              >
                <Facebook color="primary" />
              </SocialLink>
            )}
            {twitter && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={twitter}
              >
                <Twitter color="primary" />
              </SocialLink>
            )}
            {instagram && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={instagram}
              >
                <Instagram color="primary" />
              </SocialLink>
            )}
            {youtube && (
              <SocialLink
                target="_blank"
                rel="noopener noreferrer"
                href={youtube}
              >
                <YouTube color="primary" />
              </SocialLink>
            )}
          </SocialContainer>
        </AddressAndSocialContainer>
      </MainContainer>
      <Divider theme={currentThemeEnum} color={textColor} />
      <MenuAndCopyrightContainer>
        <MenuContainer>
          <MenuItemContainer>
            {isPreview
              ? previewData.map(({ path, label }) => (
                  <MenuLink to={path} key={path}>
                    <ListMenuItem color={menuTextColor} title={label} />
                  </MenuLink>
                ))
              : menu.map(({ id, children }) => (
                  <MenuLink to={pageHash[id].path} key={pageHash[id].path}>
                    <ListMenuItem
                      color={menuTextColor}
                      title={pageHash[id].title}
                      menuChildren={children}
                    />
                  </MenuLink>
                ))}
          </MenuItemContainer>
        </MenuContainer>

        <CopyrightContainer color={textColor}>
          <CopyrightText variant="caption" color="inherit">
            Copyright © {new Date().getFullYear()}{' '}
            {legal && <LegalLink href={legal}>&nbsp;Legal</LegalLink>}
            {legal && terms && <p>&nbsp;| </p>}{' '}
            {terms && (
              <LegalLink href={terms}>&nbsp;Terms & Conditions</LegalLink>
            )}
          </CopyrightText>
          <PoweredByText variant="caption" color="inherit">
            <a
              rel="noopener noreferrer"
              href="https://www.gopowerslide.com"
              target="_blank"
            >
              Powered by PowerSlide
            </a>
          </PoweredByText>
        </CopyrightContainer>
      </MenuAndCopyrightContainer>
      <>
      {showClaytonHomesFooter && <BottomFooter />}
        {includePrivacyPolicyLink === 'true' && !isPrivacyPolicyAccepted ? <PrivacyPolicyPopup onClose={handleClosePrivacyPolicy} /> : null}
      </>
    </FooterContainer>
  )
}

Footer3.displayName = 'Footer 3'
Footer3.moduleName = 'Footer3'

export default Footer3
