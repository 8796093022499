import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import PageTheme from './PageTheme'
import InventoryItem from './InventoryItem'
import BlogItem from './BlogItem'

import * as Components from '~/Components'

const Preview = () => {
  const [inventory, setInventory] = useState()
  const [currentPage, setCurrentPage] = useState()
  const [currentInventory, setCurrentInventory] = useState()
  const [currentUnsavedInventory, setCurrentUnsavedInventory] = useState()
  const [currentBlog, setCurrentBlog] = useState()
  const getInventory = useSelector(state => state.inventory)

  useEffect(() => {
    window.onbeforeunload = function() {
      localStorage.removeItem('currentPage')
      localStorage.removeItem('currentBlog')
      localStorage.removeItem('currentInventory')
      localStorage.removeItem('currentUnsavedInventory')
    }

    setInventory(getInventory)
    setCurrentPage(JSON.parse(localStorage.getItem('currentPage')))
    setCurrentInventory(JSON.parse(localStorage.getItem('currentInventory')))
    setCurrentBlog(JSON.parse(localStorage.getItem('currentBlog')))

    setCurrentUnsavedInventory(
      JSON.parse(localStorage.getItem('currentUnsavedInventory'))
    )

    return () => {
      setCurrentPage()
      setCurrentInventory()
      setCurrentBlog()
      setCurrentUnsavedInventory()
      setInventory()

      localStorage.removeItem('currentPage')
      localStorage.removeItem('currentInventory')
      localStorage.removeItem('currentBlog')
      localStorage.removeItem('currentUnsavedInventory')
    }
  }, [])

  {console.log("currentPage: "+JSON.stringify(currentPage))}

  return (
    <React.Fragment>
      <PageTheme>
        {currentPage ? (
          <React.Fragment>
            {currentPage.blocks.map(({ component, data, id }) => {
              /* eslint-disable-next-line no-unused-vars */
              const { menu, ...props } = data
              const Component = Components[component]
              return <Component isPreviewed={true} isLive={false} key={id} {...props} />
            })}
          </React.Fragment>
        ) : null}
        {currentInventory && (
          <InventoryItem {...inventory.ids[currentInventory.id]} />
        )}
        {currentUnsavedInventory && (
          <InventoryItem {...currentUnsavedInventory} />
        )}
        {currentBlog && <BlogItem blog={currentBlog} />}
      </PageTheme>
    </React.Fragment>
  )
}

export default Preview
