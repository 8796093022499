import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import * as R from 'ramda'

import InputContainer from './Container'
import ColorPicker from './ColorPicker'
import { idsLens } from '~/State/ducks/categories'
import { idsLens as inventoryLens } from '~/State/ducks/inventory'
import { idsLens as locationLens } from '~/State/ducks/locations'
import ImageUpload from '~/SharedComponents/ImageUpload'

const Filters = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  select:not(:first-of-type) {
    margin-left: 1rem;
  }
`

const ColorInputContainer = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  background-color: #f1f2f4;
  align-items: center;
  flex: 1;
`

const TextContainer = styled.div`
  padding: 1rem;
`

const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`

const PriceAndCategory = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const OtherFilters = styled.div`
  display: flex;
  justify-content: center;
`

const CategorySelect = styled(Select)`
  min-width: 120px;
`

const FilteredInventoryEdit = ({ data, handleEdit }) => {
  const { background = '#0c0c0c', text = '#ffffff', feature } = data
  const [priceFilterMin, setPriceFilterMin] = useState(data.priceFilterMin)
  const [priceFilterMax, setPriceFilterMax] = useState(data.priceFilterMax)
  const [footageFilter, setFootageFilter] = useState(data.footageFilter)
  const [bedsFilter, setBedsFilter] = useState(data.bedsFilter)
  const [bathsFilter, setBathsFilter] = useState(data.bathsFilter)
  const [locationFilter, setLocationFilter] = useState(data.locationFilter)
  const [sectionFilter, setSectionFilter] = useState(data.sectionFilter)
  const [categoryFilter, setCategoryFilter] = useState(
    data.categoryFilter || []
  )
  const [manuFilter, setManuFilter] = useState(data.manuFilter)
  const [image, setImage] = useState(data.image)
  const [expanded, setExpanded] = useState(data.expanded)

  const categoryHash = useSelector(R.view(idsLens))
  const locationHash = useSelector(R.view(locationLens))

  const categoryList = R.values(categoryHash)
  const locationList = R.values(locationHash)

  const singleSection = R.find(R.propEq('name', 'Single Section'))(categoryList)

  const multiSection = R.find(R.propEq('name', 'Multi Section'))(categoryList)

  const inventory = useSelector(state => {
  const idHash = R.view(inventoryLens, state)

    return R.values(idHash)
  })

  

  useEffect(() => {
    handleEdit(oldState => ({
      ...oldState,
      priceFilterMin,
      priceFilterMax,
      footageFilter,
      bedsFilter,
      bathsFilter,
      locationFilter,
      categoryFilter,
      sectionFilter,
      manuFilter,
      expanded
    }))
  }, [
    priceFilterMin,
    priceFilterMax,
    footageFilter,
    bedsFilter,
    bathsFilter,
    locationFilter,
    categoryFilter,
    sectionFilter,
    manuFilter,
    expanded
  ])

  const manuList = [...new Set(inventory.map((item) => item.manufacturer))].sort()

  return (
    <InputContainer>
      <ColorInputContainer>
        <ColorPicker
          color={background}
          name="background"
          title="Background Color"
        />
        <ColorPicker color={text} name="text" title="Text Color" />
        <ColorPicker color={feature} name="feature" title="Feature Color" />
      </ColorInputContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload name="image-upload" onChange={setImage} image={image} />
      <input hidden value={image} name="image" />
      <Typography>Filter Inventory to Display</Typography>
      <Filters>
        <FormControlLabel
          control={
            <Switch
              checked={expanded}
              onChange={e => setExpanded(e.target.checked)}
              inputProps={{
                'aria-label': 'Filter Expanded by Default Switch',
                name: 'expanded',
                id: 'expanded'
              }}
            />
          }
          label="Filter Expanded by Default"
        />
        <PriceAndCategory>
          <TextContainer>
            <TextField
              value={data.priceFilterMin}
              onChange={e => setPriceFilterMin(e.target.value)}
              label="Minimum price to show"
            />
          </TextContainer>
          <TextContainer>
            <TextField
              value={data.priceFilterMax}
              onChange={e => setPriceFilterMax(e.target.value)}
              label="Maximum price to show"
            />
          </TextContainer>
        </PriceAndCategory>
        <CategoryContainer>
          <FormControl>
            <InputLabel id="categoy-label">Categories</InputLabel>
            <CategorySelect
              labelId="category-label"
              name="category"
              id="category"
              fullWidth
              value={categoryFilter}
              multiple
              onChange={e => setCategoryFilter(e.target.value)}
            >
              {categoryList.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </CategorySelect>
            <FormHelperText>Leave blank to show all categories</FormHelperText>
          </FormControl>
        </CategoryContainer>
        <OtherFilters>
          <select
            name="manufacturer"
            value={data.manuFilter}
            onChange={e => setManuFilter(e.target.value)}
          >
            <option value="">Any Manufacturer</option>

            {manuList.map((manu) => (
              <option key={manu} value={manu}>
                {manu}
              </option>
            ))}
          </select>
          <select
            name="location"
            value={data.locationFilter}
            onChange={e => setLocationFilter(e.target.value)}
          >
            <option value="">Any Location</option>

            {locationList.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          {singleSection && multiSection && (
            <select
              name="sections"
              value={sectionFilter}
              onChange={e => setSectionFilter(e.target.value)}
            >
              <option value="">Any Sections</option>
              <option value={singleSection.id}>Single Section</option>
              <option value={multiSection.id}>Multi Section</option>
            </select>
          )}
          <select
            name="footage"
            value={footageFilter}
            onChange={e => setFootageFilter(e.target.value)}
          >
            <option value="">Any Sq Footage</option>
            <option value="500">&gt; 500 ft</option>
            <option value="750">&gt; 750 ft</option>
            <option value="1000">&gt; 1,000 ft</option>
            <option value="1500">&gt; 1,500 ft</option>
            <option value="2000">&gt; 2,000 ft</option>
          </select>
          <select
            name="bathrooms"
            value={bathsFilter}
            onChange={e => setBathsFilter(e.target.value)}
          >
            <option value="">Any Bathrooms</option>
            <option value="1">&gt; 1</option>
            <option value="2">&gt; 2</option>
            <option value="3">&gt; 3</option>
            <option value="4">&gt; 4</option>
            <option value="5">&gt; 5+</option>
          </select>
          <select
            name="bedrooms"
            value={bedsFilter}
            onChange={e => setBedsFilter(e.target.value)}
          >
            <option value="">Any Bedrooms</option>
            <option value="1">&gt; 1</option>
            <option value="2">&gt; 2</option>
            <option value="3">&gt; 3</option>
            <option value="4">&gt; 4</option>
            <option value="5">&gt; 5+</option>
          </select>
        </OtherFilters>
      </Filters>
    </InputContainer>
  )
}

export default FilteredInventoryEdit
