import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ArrowBack'

import * as R from 'ramda'

import CreateNew from './CreateNew'
import LocationComponent from './LocationComponent'
import { idsLens, actions } from '~/State/ducks/locations'

const Main = styled.main`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
`

const InventoryHeader = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  background: #768390;
  color: #fff;
`

const BackButton = styled(Link)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`

const InventoryHeading = styled(Typography)``

const MainNav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: ${({ selected }) => (selected ? '#efefef' : 'inherit')};
  padding: 0.5rem 2rem;
  border-left: 1px solid rgba(33, 33, 33, 0.5);
  background: ${({ selected }) =>
    selected ? 'rgba(33, 33, 33, 0.5)' : 'inherit'};

  &:last-of-type {
    border-right: 1px solid rgba(33, 33, 33, 0.5);
  }

  :hover {
    background: rgba(33, 33, 33, 0.5);
    color: #efefef;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const LocationList = styled(List)``

const ListDisplay = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  li:first-of-type {
    border-top: 1px solid rgba(33, 33, 33, 0.5);
  }

  .MuiListItemSecondaryAction-root {
    button:first-of-type {
      margin-right: 1rem;
    }
  }
`

const ModalBody = styled.div`
  width: 70%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const ModalActions = styled.div`
  margin: 0 auto;
`

const LocationManager = () => {
  const featureList = useSelector(state => {
    const ids = R.view(idsLens, state)
    return R.values(ids)
  })
  // const [featureList, setLocationList] = useState(knownLocations)
  const [confirmDeleteCat, setConfirmDeleteCat] = useState(false)
  const dispatch = useDispatch()

  const createNewLocation = newLocation => {
    dispatch({
      type: actions.CREATE_LOCATION_REQUEST,
      payload: {
        ...newLocation
      }
    })
  }

  const updateLocation = id => updatedValue => {
    dispatch({
      type: actions.UPDATE_LOCATION_REQUEST,
      payload: {
        ...updatedValue,
        id
      }
    })
  }

  const deleteLocation = id => {
    dispatch({
      type: actions.DELETE_LOCATION_REQUEST,
      payload: {
        id
      }
    })
  }

  return (
    <Main>
      <Helmet title="Location Manager" />
      <InventoryHeader>
        <BackButton to="/admin/inventory">
          <IconButton color="inherit">
            <BackIcon />
          </IconButton>
          <Typography variant="caption">Back to Inventory</Typography>
        </BackButton>
        <InventoryHeading variant="h2" align="center" color="inherit">
          Locations
        </InventoryHeading>
      </InventoryHeader>
      <MainNav>
        <NavItem to="/admin/inventory">
          <Typography>Inventory</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/categories">
          <Typography>Category Manager</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/features">
          <Typography>Feature Manager</Typography>
        </NavItem>
        <NavItem selected to="/admin/inventory/locations">
          <Typography>Location Manager</Typography>
        </NavItem>
      </MainNav>
      <CreateNew onCreate={createNewLocation} />
      <ListDisplay>
        <Typography variant="h4" gutterBottom align="left">
          Current Locations
        </Typography>
        <LocationList>
          {featureList.map(location => (
            <LocationComponent
              deleteLocation={setConfirmDeleteCat}
              key={location.id}
              location={location}
              updateLocation={updateLocation(location.id)}
            />
          ))}
        </LocationList>
      </ListDisplay>
      <Modal
        open={!!confirmDeleteCat}
        onClose={() => setConfirmDeleteCat(false)}
      >
        <ModalBody>
          <Typography align="center" variant="h5" gutterBottom>
            Are you sure you want to delete
          </Typography>
          {confirmDeleteCat && (
            <Typography variant="subtitle2" align="center" gutterBottom>
              {featureList.filter(({ id }) => id === confirmDeleteCat)[0].title}
            </Typography>
          )}
          <ModalActions>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => setConfirmDeleteCat(false)}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={() => {
                  deleteLocation(confirmDeleteCat)

                  setConfirmDeleteCat()
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
          </ModalActions>
        </ModalBody>
      </Modal>
    </Main>
  )
}

export default LocationManager
