import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

const PageLink = styled(Link)`
  background: ${({ selected }) => (selected ? '#c9cdd2' : '#e4e6e9')};
  color: black;
  border: none;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5px;

  :hover {
    background: #c9cdd2;
  }

  & {
    width: 100%;
  }
`

const PageContainer = styled.div`
  width: 100%;
`

const ChildContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 2rem;
  align-items: center;
  background: ${({ selected }) => (selected ? '#c9cdd2' : 'transparent')};

  & {
    width: 100%;
  }
`

const PagesInMenu = ({
  menu,
  pages,
  selectedPageId,
  onClick,
  queryKey = 'page'
}) => {
  const pageHash = pages
  const pageList = menu.pages

  // If no pages saved in the state
  if (!Object.keys(pageHash).length) {
    return null
  }

  return (
    <PageContainer>
      {pageList
        .filter(({ id }) => pageHash[id])
        .map(({ id, children }) => {
          if (children && children.length) {
            return (
              <PageContainer key={id}>
                <PageLink
                  to={`/admin/builder?${queryKey}=${id}`}
                  selected={selectedPageId == id}
                  onClick={onClick}
                >
                  <Typography>{(pageHash[id] || {}).title}</Typography>
                </PageLink>

                <ChildContainer>
                  <SubdirectoryArrowRightIcon />
                  <PagesInMenu
                    pages={pages}
                    menu={{ pages: children }}
                    selectedPageId={selectedPageId}
                    onClick={onClick}
                  />
                </ChildContainer>
              </PageContainer>
            )
          }
          return (
            <PageLink
              onClick={onClick}
              key={id}
              to={`/admin/builder?${queryKey}=${id}`}
              selected={selectedPageId == id}
            >
              <Typography>{(pageHash[id] || {}).title}</Typography>
            </PageLink>
          )
        })}
    </PageContainer>
  )
}

export default PagesInMenu
