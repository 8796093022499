import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'
import { format, parseISO } from 'date-fns'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/blogs'
import { idsLens as userIds } from '~/State/ducks/users'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const BlogContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const Card = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 320px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: #ffffff;
  }
`

const BlogPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  padding-right: 10rem;
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const BlogName = styled(Typography)`
  padding-bottom: 1rem;
  padding-top: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 1rem;
`

const AuthorName = styled(Typography)`
  font-family: ${({ theme }) => theme == 'Theme6' && 'DM Sans'};
`

const previewData = [
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  }
]

const Blog2 = ({ blogPosts = [], isPreview, background, text, image }) => {
  const blogs = useSelector(state => {
    const blogsById = R.view(idsLens, state)

    return blogPosts
      .filter(({ id }) => blogsById[id])
      .map(({ id, ...rest }) => ({
        title: 'My Default Title',
        ...rest,
        ...blogsById[id],
        id
      }))
  })
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const userHash = useSelector(state => {
    return R.view(userIds, state)
  })

  if (isPreview) {
    return (
      <BlogContainer>
        {previewData.map((blog, i) => (
          <Card to={blog.path} key={i}>
            <PictureContainer
              imagePadding={theme.imagePadding}
              boxShadow={theme.boxShadow}
            >
              <img src={blog.image} />
            </PictureContainer>
            <BlogPostContainer>
              <Typography variant="h5">Month, Day, Year</Typography>
              <BlogName variant="h3">{blog.title}</BlogName>
              <Typography variant="h6">Author Name</Typography>
              <Subtitle color="textPrimary" variant="body1" align="left">
                {blog.subtitle}
              </Subtitle>
            </BlogPostContainer>
          </Card>
        ))}
      </BlogContainer>
    )
  }

  return (
    <BlogContainer img={image} color={bgColor}>
      {blogs.map((blog, i) => (
        <Card to={blog.path} key={i}>
          <PictureContainer
            imagePadding={theme.imagePadding}
            boxShadow={theme.boxShadow}
          >
            <img src={blog.image} />
          </PictureContainer>
          <BlogPostContainer color={textColor}>
            <Typography variant="h5">
              {blog.last_updated &&
                format(parseISO(blog.last_updated), 'MMMM do yyyy')}
            </Typography>
            <BlogName variant="h3">{blog.title}</BlogName>
            <AuthorName theme={currentThemeEnum} variant="h6">
              {userHash[blog.author_id] && userHash[blog.author_id].first_name}{' '}
              {userHash[blog.author_id] && userHash[blog.author_id].last_name}
            </AuthorName>
            <Subtitle color="inherit" variant="body1" align="left">
              {blog.subtitle}
            </Subtitle>
          </BlogPostContainer>
        </Card>
      ))}
    </BlogContainer>
  )
}

Blog2.displayName = 'Blog 2'
Blog2.moduleName = 'Blog2'

export default Blog2
