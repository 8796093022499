import React, { useState } from 'react'

import styled from '@emotion/styled'

import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import * as editScreens from './EditScreens'

const EditModuleContainer = styled.form`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: flex-end;
  background: #e4e6e9;
  padding: 2rem;
`

const HeaderText = styled(Typography)``

const HeaderTextContainer = styled.div`
  margin: auto;
`

const CloseButton = styled(IconButton)``

const EditModule = ({
  data,
  component_name,
  index,
  updateBlock,
  closeModal
}) => {
  const [notFormUpdates, setNotFormUpdates] = useState({
    ids: data.ids || [],
    blogPosts: data.blogPosts || [],
    reviews: data.reviews || [],
    priceFilterMax: data.priceFilterMax || '',
    priceFilterMin: data.priceFilterMin || '',
    footageFilter: data.footageFilter || '',
    bedsFilter: data.bedsFilter || '',
    bathsFilter: data.bathsFilter || '',
    isSharpSpring: data.isSharpSpring || null,
    promoRows: data.promoRows || [],
    files: data.files || [],
    affiliationLogos: data.affiliationLogos || [],
    expanded: data.expanded || false
  })

  const handleSubmit = e => {
    e.preventDefault()
    // Grab Form Data
    const formData = new FormData(e.target)
    // Create Object from it
    const newValues = {}

    for (const [key, value] of formData.entries()) {
      newValues[key] = value
    }
    updateBlock(index, { data: { ...newValues, ...notFormUpdates } })
  }

  const Screen = editScreens[component_name]

  return (
    <EditModuleContainer onSubmit={handleSubmit}>
      <HeaderContainer>
        <HeaderTextContainer>
          <HeaderText variant="h5">Edit Module</HeaderText>
        </HeaderTextContainer>

        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      </HeaderContainer>

      <Screen
        data={{ ...data, ...notFormUpdates }}
        handleEdit={setNotFormUpdates}
      />

      <Button type="submit" aria-label="Update Module Data">
        Update
      </Button>
    </EditModuleContainer>
  )
}

export default EditModule
