import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

const Logout = () => {
  const [haveDispatched, setHasDispatched] = useState(false)

  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('currentUser')

    setHasDispatched(true)
  }, [haveDispatched])

  if (haveDispatched) {
    return <Redirect to="/" />
  }

  return null
}

export default Logout
