import React from 'react'
import styled from '@emotion/styled'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'

const DayLabel = styled(Typography)`
  flex: 0.5;
  padding: 4px;
  display: flex;
  align-items: center;
`

const TimeSelect = styled(Select)`
  max-width: 120px;
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 1;
`

const DayContainer = styled.div`
  display: flex;
`

const CloseAndOpenSwitch = styled(Typography)`
  display: flex;
  flex-wrap: nowrap;

  flex: 1;
`

const times = [
  {
    id: 0,
    time: '12:00am'
  },
  {
    id: 1,
    time: '12:30am'
  },
  {
    id: 2,
    time: '1:00am'
  },
  {
    id: 3,
    time: '1:30am'
  },
  {
    id: 4,
    time: '2:00am'
  },
  {
    id: 5,
    time: '2:30am'
  },
  {
    id: 6,
    time: '3:00am'
  },
  {
    id: 7,
    time: '3:30am'
  },
  {
    id: 8,
    time: '4:00am'
  },
  {
    id: 9,
    time: '4:30am'
  },
  {
    id: 10,
    time: '5:00am'
  },
  {
    id: 11,
    time: '5:30am'
  },
  {
    id: 12,
    time: '6:00am'
  },
  {
    id: 13,
    time: '6:30am'
  },
  {
    id: 14,
    time: '7:00am'
  },
  {
    id: 15,
    time: '7:30am'
  },
  {
    id: 16,
    time: '8:00am'
  },
  {
    id: 17,
    time: '8:30am'
  },
  {
    id: 18,
    time: '9:00am'
  },
  {
    id: 19,
    time: '9:30am'
  },
  {
    id: 20,
    time: '10:00am'
  },
  {
    id: 21,
    time: '10:30am'
  },
  {
    id: 22,
    time: '11:00am'
  },
  {
    id: 23,
    time: '11:30am'
  },
  {
    id: 24,
    time: '12:00pm'
  },
  {
    id: 25,
    time: '12:30pm'
  },
  {
    id: 26,
    time: '1:00pm'
  },
  {
    id: 27,
    time: '1:30pm'
  },
  {
    id: 28,
    time: '2:00pm'
  },
  {
    id: 29,
    time: '2:30pm'
  },
  {
    id: 30,
    time: '3:00pm'
  },
  {
    id: 31,
    time: '3:30pm'
  },
  {
    id: 32,
    time: '4:00pm'
  },
  {
    id: 33,
    time: '4:30pm'
  },
  {
    id: 34,
    time: '5:00pm'
  },
  {
    id: 35,
    time: '5:30pm'
  },
  {
    id: 36,
    time: '6:00pm'
  },
  {
    id: 37,
    time: '6:30pm'
  },
  {
    id: 38,
    time: '7:00pm'
  },
  {
    id: 39,
    time: '7:30pm'
  },
  {
    id: 40,
    time: '8:00pm'
  },
  {
    id: 41,
    time: '8:30pm'
  },
  {
    id: 42,
    time: '9:00pm'
  },
  {
    id: 43,
    time: '9:30pm'
  },
  {
    id: 44,
    time: '10:00pm'
  },
  {
    id: 45,
    time: '10:30pm'
  },
  {
    id: 46,
    time: '11:00pm'
  },
  {
    id: 47,
    time: '11:30pm'
  }
]

const DaySelector = ({
  name,
  day,
  isOpened,
  openTime,
  closeTime,
  onChange
}) => {
  return (
    <DayContainer>
      <DayLabel>{day}</DayLabel>
      <CloseAndOpenSwitch component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>Closed</Grid>
          <Grid item>
            <Switch
              checked={isOpened}
              onChange={e => onChange(name, 'isOpened', e.target.checked)}
            />
          </Grid>
          <Grid item>Open</Grid>
        </Grid>
      </CloseAndOpenSwitch>
      <TimeSelect
        value={openTime}
        onChange={e => onChange(name, 'openTime', e.target.value)}
        inputProps={{
          name: `${name}-open`,
          id: `${name}-open`,
          'aria-label': `${name} Open`,
          title: 'Open Time'
        }}
      >
        <MenuItem value="">Open Time</MenuItem>
        {times.map(({ id, time }) => {
          return (
            <MenuItem key={id} value={time}>
              {time}
            </MenuItem>
          )
        })}
      </TimeSelect>
      <TimeSelect
        value={closeTime}
        onChange={e => onChange(name, 'closeTime', e.target.value)}
        inputProps={{
          name: `${name}-close`,
          id: `${name}-close`,
          'aria-label': `${name} Close`,
          title: 'Closing Time'
        }}
      >
        <MenuItem value="">Closing Time</MenuItem>
        {times.map(({ id, time }) => (
          <MenuItem key={id} value={time}>
            {time}
          </MenuItem>
        ))}
      </TimeSelect>
    </DayContainer>
  )
}

export default DaySelector
