import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const Model = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49, 49, 49, 0.8);
`

const ModelPopup = styled.div`
  position: absolute;
  margin-top: 10%;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 0;
  border-radius: 11px;
  max-width: 700px;
  min-width: 400px;
  max-height: 90vh;
  border: 2px solid rgba(0, 0, 0, 0.8);
  box-shadow: 1px 1px 20px grey;
  overflow: hidden auto;
`

const CloseModelButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 7px 4px;
  background-color: #6d6d6d;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  height: 30px;
  width: 40px;
  color: white;
  font-weight: bolder;
  font-size: 1.5rem;
  cursor: pointer;
`

const ModalContent = styled.div``

const PopupContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => `url(${img})`};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`

const PhotoContainer = styled.div`
  max-width: 480px;
  margin-bottom: 1rem;

  img {
    width: 100%;
    margin: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: ${({ theme }) =>
      theme == 'Theme3' ||
      theme == 'Theme4' ||
      theme == 'Theme5' ||
      theme == 'Theme6' ||
      theme == 'Theme7' ||
      theme == 'Theme8'
        ? '#ffffff'
        : 'transparent'};
  }
`

const HeaderContainer = styled.div`
  color: ${({ color }) => color || 'inherit'};
  margin-bottom: 1rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const SubtitleTextContainer = styled.div`
  width: 90%;
  margin-bottom: 3rem;
  text-align: center;
  color: ${({ color }) => color || 'inherit'};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const CloseModelButton2 = styled(Button)`
  span {
    color: ${({ textColor }) => `${textColor}` || `inherit`};
  }
`

const HeaderText = styled(Typography)`
  line-height: 1.2;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    font-size: ${({ header }) => header == 'h1' && '32px'} !important;
  }
`

const SubtitleText = styled(Typography)``

const Popup = ({
  background,
  text,
  imageAlt,
  imageTitle,
  image = 'https://huckberry.imgix.net/uploads/post/image/864/hero_XtUb2.jpg?ixlib=rails-2.1.4&auto=compress%2Cformat&cs=tinysrgb&fit=max',
  /* img = 'https://web.archive.org/web/20200405072553if_/http://www.inmanmanufacturedhomes.com/assets/SEExterior_7A2DDA804D737CD78C7098CF91FE68D0_41PRF32643AH.jpg', */
  header = 'Lorem ipsum dolor sit amet, consectetur adipiscing',
  bgImage,
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  headerSize = 'h2',
  isPreviewed = false,
  isAddModule = false,
  popupTime = 1,
  isLive = false
}) => {
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const currentTheme = configs[currentThemeEnum || 'Theme1']
  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  useEffect(() => {
    ;((isPreviewed && !isAddModule) || isLive) &&
      setTimeout(() => {
        toggleModal()
      }, Number(popupTime) * 1000)
  }, [])

  const renderPopup = () => (
    <PopupContainer img={bgImage} color={bgColor}>
      <PhotoContainer
        theme={currentThemeEnum}
        imagePadding={theme.imagePadding}
        boxShadow={theme.boxShadow}
      >
        <img src={image} alt={imageAlt} title={imageTitle} />
      </PhotoContainer>
      <TextContainer>
        <HeaderContainer color={textColor}>
          <HeaderText
            header={headerSize}
            variant={headerSize}
            align="center"
            color="inherit"
          >
            {header}
          </HeaderText>
        </HeaderContainer>
        <SubtitleTextContainer color={textColor}>
          <SubtitleText
            variant="body1"
            align="center"
            color="inherit"
            gutterBottom
          >
            {content}
          </SubtitleText>
        </SubtitleTextContainer>
      </TextContainer>
      <CloseModelButton2 onClick={toggleModal}>Close</CloseModelButton2>
    </PopupContainer>
  )
  if ((isPreviewed && !isAddModule) || isLive) {
    return (
      <>
        {modal && (
          <>
            <Model>
              <Overlay onClick={toggleModal} />{' '}
              <ModelPopup>
                <ModalContent>
                  {renderPopup()}
                  <CloseModelButton onClick={toggleModal}>x</CloseModelButton>
                </ModalContent>
              </ModelPopup>
            </Model>
          </>
        )}
      </>
    )
  } else {
    return renderPopup()
  }
}

Popup.displayName = 'Popup'
Popup.moduleName = 'Popup'

export default Popup
