import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/locations'
// import groupHours from '~/Components/utils/groupedHours'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const LocationsContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const Card = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: ${({ color }) => `${color}` || `#e5e9ea`};

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const LocationName = styled(Typography)``

const OurLocations = styled(Typography)``

const Address = styled(Typography)`
  padding-bottom: 1rem;
`

const Phone = styled(Typography)`
  padding-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 2rem;
  width: 80%;
  padding-bottom: 2rem;
`

const LocationHours = styled(Typography)``

const WeekdayHours = styled(Typography)``

const Divider = styled.hr`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: inherit;
`

const AddressTag = styled.address`
  font-style: normal;
  color: ${({ color }) => `${color}` || `inherit`};
`

const LogoContainer = styled.div`
  max-width: 272px;
  max-height: 200px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

// const reduceHours = hours => {
//   const days = R.keys(hours)

//   const dayAbbr = {
//     monday: 'M',
//     tuesday: 'Tu',
//     wednesday: 'W',
//     thursday: 'Thu',
//     friday: 'F',
//     saturday: 'Sat',
//     sunday: 'Sun'
//   }

//   return days.reduce((a, c) => {
//     const { openTime, closeTime, isOpened } = hours[c]

//     if (!isOpened) {
//       return a
//     }
//     let set = false
//     for (const [key, { open, close }] of Object.entries(a)) {
//       if (open === openTime && close === closeTime) {
//         a[`${key}-${dayAbbr[c]}`] = a[key]
//         set = true
//         delete a[key]
//       }
//     }

//     if (!set) {
//       a[dayAbbr[c]] = { open: openTime, close: closeTime }
//     }

//     return a
//   }, {})
// }

const previewData = [
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: true,
        openTime: '6:30am',
        closeTime: '7:00pm'
      },
      monday: {
        isOpened: false,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  },
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: false,
        openTime: '',
        closeTime: ''
      },
      monday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  }
]

const Locations1 = ({
  ids = [],
  header = 'Our Locations',
  subtitle = 'subtitle',
  isPreview,
  text,
  background,
  feature,
  featureText,
  image,
  showLogo = false
}) => {
  const locations = useSelector(state => {
    const idHash = R.view(idsLens, state)

    return ids.filter(id => idHash[id]).map(id => idHash[id])
  })

  const company = useSelector(state => state.profile.company)

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  const ftColor = getBasedOnTheme(feature, currentTheme, brand)

  const ftTextColor = getBasedOnTheme(featureText, currentTheme, brand)

  if (isPreview) {
    return (
      <LocationsContainer>
        <OurLocations variant="h2">{header}</OurLocations>
        <Subtitle variant="body1" align="center">
          {subtitle}
        </Subtitle>
        <CardContainer>
          {previewData.map(({ name, address, phone, hours }, i) => {
            // const groupedHours = groupHours(hours)

            return (
              <Card key={i}>
                <AddressTag>
                  <LocationName variant="h3">{name}</LocationName>
                  <Divider></Divider>
                  <Address variant="body1" color="textPrimary">
                    {address}
                  </Address>
                  <Phone variant="body1" color="textPrimary">
                    {phone}
                  </Phone>
                  <LocationHours variant="body1" color="textPrimary">
                    <strong>Location Hours:</strong>
                  </LocationHours>
                  {/* {groupedHours.grouped.map(day => (
                    <WeekdayHours variant="body1" key={day}>
                      {day} {groupedHours.hours[day].open} -{' '}
                      {groupedHours.hours[day].close}
                    </WeekdayHours>
                  ))} */}
                  {hours.sunday.isOpened && (
                    <WeekdayHours>
                      Sunday: {hours.sunday.openTime} - {hours.sunday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.monday.isOpened && (
                    <WeekdayHours>
                      Monday: {hours.monday.openTime} - {hours.monday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.tuesday.isOpened && (
                    <WeekdayHours>
                      Tuesday: {hours.tuesday.openTime} -{' '}
                      {hours.tuesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.wednesday.isOpened && (
                    <WeekdayHours>
                      Wednesday: {hours.wednesday.openTime} -{' '}
                      {hours.wednesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.thursday.isOpened && (
                    <WeekdayHours>
                      Thursday: {hours.thursday.openTime} -{' '}
                      {hours.thursday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.friday.isOpened && (
                    <WeekdayHours>
                      Friday: {hours.friday.openTime} - {hours.friday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.saturday.isOpened && (
                    <WeekdayHours>
                      Saturday: {hours.saturday.openTime} -{' '}
                      {hours.saturday.closeTime}
                    </WeekdayHours>
                  )}
                </AddressTag>
              </Card>
            )
          })}
        </CardContainer>
      </LocationsContainer>
    )
  }

  return (
    <LocationsContainer img={image} textColor={textColor} color={bgColor}>
      <OurLocations color="inherit" component="p" variant="h2">
        {header}
      </OurLocations>
      <Subtitle color="inherit" variant="body1" align="center">
        {subtitle}
      </Subtitle>
      <CardContainer>
        {locations &&
          locations.map(({ name, address, phone, hours }, i) => {
            // const groupedHours = groupHours(hours)

            return (
              <Card color={ftColor} key={i}>
                <AddressTag color={ftTextColor}>
                  {!showLogo && (
                    <LocationName variant="h3">{name}</LocationName>
                  )}
                  {showLogo && (
                    <LogoContainer>
                      <img src={brand.logo} alt={`${company.name} Logo`} />
                    </LogoContainer>
                  )}
                  <Divider></Divider>
                  <Address variant="body1">{address}</Address>
                  <Phone variant="body1">{phone}</Phone>
                  <LocationHours variant="body1">
                    <strong>Location Hours:</strong>
                  </LocationHours>
                  {/* {groupedHours.grouped.map(day => (
                    <WeekdayHours variant="body1" key={day}>
                      {day} {groupedHours.hours[day].open} -{' '}
                      {groupedHours.hours[day].close}
                    </WeekdayHours>
                  ))} */}
                  {hours.sunday.isOpened && (
                    <WeekdayHours>
                      Sunday: {hours.sunday.openTime} - {hours.sunday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.monday.isOpened && (
                    <WeekdayHours>
                      Monday: {hours.monday.openTime} - {hours.monday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.tuesday.isOpened && (
                    <WeekdayHours>
                      Tuesday: {hours.tuesday.openTime} -{' '}
                      {hours.tuesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.wednesday.isOpened && (
                    <WeekdayHours>
                      Wednesday: {hours.wednesday.openTime} -{' '}
                      {hours.wednesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.thursday.isOpened && (
                    <WeekdayHours>
                      Thursday: {hours.thursday.openTime} -{' '}
                      {hours.thursday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.friday.isOpened && (
                    <WeekdayHours>
                      Friday: {hours.friday.openTime} - {hours.friday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.saturday.isOpened && (
                    <WeekdayHours>
                      Saturday: {hours.saturday.openTime} -{' '}
                      {hours.saturday.closeTime}
                    </WeekdayHours>
                  )}
                </AddressTag>
              </Card>
            )
          })}
      </CardContainer>
    </LocationsContainer>
  )
}

Locations1.displayName = 'Locations 1'
Locations1.moduleName = 'Locations1'

export default Locations1
