import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'; 

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -20px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

// Apply the animation to your component
const PopupContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-out 3s forwards;
  opacity: 0; /* Start invisible */
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 483px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  z-index: 1000;
  border-radius: 8px;
  font-family: 'source-sans-pro', sans-serif;
`;

const Title = styled.h3`
  display: block;
  color: #000000;
  font-family: 'acumin-pro', sans-serif;
  font-size: 20px;
  margin: 0 0 1rem;
`;

const Content = styled.div`
  margin-bottom: 1.5rem;
  line-height: 22px;
  width: 295px;
  padding: 0;
  color: #000000;
  font-size: 12px;
`;

const PrivacyLink = styled.a`
  font-family: 'source-sans-pro', Helvetica, Arial, sans-serif;
  color: #0075c9;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #0088E9;
  }
`;

const OkayButton = styled.button`
position: absolute;
    top: 34.5%;
    right: 6.21%;
    width: 70px;
    height: 35px;
    padding: 19px 5px 32px 5px;
    background-color: #0075C9;
    color: #FFFFFF;
    border: none;
    border-radius: 2px;
    font-family: 'source-sans-pro',sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;

  &:hover {
    background-color: #0065B3;
    color: #f4f4f4;
  }
}
`;

const PrivacyPolicyPopup = ({ onClose }) => {
  return (
    <PopupContainer>
      <Title>Privacy Policies</Title>
      <Content>
        Protecting your privacy is of fundamental importance to us. The following privacy notices explain how we may collect, use, and disclose your personal information.
      </Content>
      <Content>
        <PrivacyLink href="https://www.claytonhomes.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policies &gt;</PrivacyLink>
      </Content>
      <OkayButton onClick={onClose}>Okay</OkayButton>
    </PopupContainer>
  );
};

export default PrivacyPolicyPopup;
