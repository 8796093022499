import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/styles'
import { format, parseISO } from 'date-fns'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/blogs'
import { idsLens as userIds } from '~/State/ducks/users'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const BlogContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-position: center center;

  @media only screen and (max-width: 768px) {
    padding: 1rem;
  }
`

const Card = styled.div`
  max-width: 600px;
  width: 40%;
  margin: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    margin-bottom: 2rem;
  }
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  color: ${({ color }) => `${color}` || `inherit`};
`

const AuthorAndDateText = styled(Typography)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const PictureContainer = styled.div`
  display: flex;
  max-width: 600px;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    box-shadow: ${({ boxShadow = 'inherit' }) => boxShadow};
    padding: ${({ imagePadding }) => imagePadding};
    background: #ffffff;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`

const BlogTitle = styled(Typography)`
  padding-top: 1rem;
`

const Subtitle = styled(Typography)``

const previewData = [
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  },
  {
    image: 'https://i.stack.imgur.com/l60Hf.png',
    title: 'Default Blog Title',
    subtitle: 'Some Subtitle',
    path: '/my-blog-path'
  }
]

const Blog1 = ({
  blogPosts = [],
  isPreview,
  background,
  text,
  image,
  btnColor = 'primary',
  btnStyle = 'contained'
}) => {
  const blogs = useSelector(state => {
    const blogsById = R.view(idsLens, state)

    return blogPosts
      .filter(({ id }) => blogsById[id])
      .map(({ id, ...rest }) => ({
        title: 'My Default Title',
        ...rest,
        ...blogsById[id],
        id
      }))
  })

  const userHash = useSelector(state => {
    return R.view(userIds, state)
  })
  const theme = useTheme()
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <BlogContainer>
        {previewData.map((blog, i) => (
          <Card key={i}>
            <PictureContainer
              imagePadding={theme.imagePadding}
              boxShadow={theme.boxShadow}
            >
              <img src={blog.image} />
            </PictureContainer>
            <CardFooter>
              <BlogTitle variant="h3" align="center">
                {blog.title}
              </BlogTitle>
              <AuthorAndDateText
                align="center"
                variant="h6"
                color="textPrimary"
              >
                Author | Month Day Year
              </AuthorAndDateText>
              <Subtitle color="textPrimary" variant="body2" align="center">
                {blog.subtitle}
              </Subtitle>
              <ButtonContainer>
                <Button
                  component={Link}
                  to={blog.path}
                  color="primary"
                  variant="contained"
                >
                  Read Now
                </Button>
              </ButtonContainer>
            </CardFooter>
          </Card>
        ))}
      </BlogContainer>
    )
  }

  return (
    <BlogContainer img={image} color={bgColor}>
      {blogs.map((blog, i) => (
        <Card key={i}>
          <PictureContainer
            imagePadding={theme.imagePadding}
            boxShadow={theme.boxShadow}
          >
            <img src={blog.image} />
          </PictureContainer>
          <CardFooter color={textColor}>
            <BlogTitle variant="h3" align="center">
              {blog.title}
            </BlogTitle>
            <AuthorAndDateText align="center" variant="h6" color="inherit">
              {userHash[blog.author_id] && userHash[blog.author_id].first_name}{' '}
              {userHash[blog.author_id] && userHash[blog.author_id].last_name} |{' '}
              {blog.last_updated &&
                format(parseISO(blog.last_updated), 'MMMM do yyyy')}
            </AuthorAndDateText>
            <Subtitle color="inherit" variant="body2" align="center">
              {blog.subtitle}
            </Subtitle>
            <ButtonContainer>
              <Button
                component={Link}
                to={blog.path}
                color={btnColor}
                variant={btnStyle}
              >
                Read Now
              </Button>
            </ButtonContainer>
          </CardFooter>
        </Card>
      ))}
    </BlogContainer>
  )
}

Blog1.displayName = 'Blog 1'
Blog1.moduleName = 'Blog1'

export default Blog1
