import React from 'react'
import Typography from '@material-ui/core/Typography'

export const ErrorPage = ({
  msg = "Sorry! Can't find that resource!",
  code = 404
}) => (
  <div>
    <Typography gutterBottom variant="h2" align="center">
      {msg}
    </Typography>
    <Typography gutterBottom variant="h4" align="center">
      {code}
    </Typography>
  </div>
)

export default ErrorPage
