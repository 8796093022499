import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { epics as userEpics } from '../ducks/users'
import { epics as companyEpics } from '../ducks/companies'
import { epics as pageEpics } from '../ducks/pages'
import { epics as profileEpics } from '../ducks/profile'
import { epics as tokenEpics } from '../ducks/token'
import { epics as inventoryEpics } from '../ducks/inventory'
import { epics as categoryEpics } from '../ducks/categories'
import { epics as featureEpics } from '../ducks/features'
import { epics as locationEpics } from '../ducks/locations'
import { epics as blogEpics } from '../ducks/blogs'
import { epics as templateEpics } from '../ducks/templates'
import { epics as menuEpics } from '../ducks/menus'
import { epics as leadEpics } from '../ducks/leads'
import { epics as domainEpics } from '../ducks/domain'

export const rootEpic = combineEpics(
  ...userEpics,
  ...companyEpics,
  ...pageEpics,
  ...profileEpics,
  ...tokenEpics,
  ...inventoryEpics,
  ...categoryEpics,
  ...featureEpics,
  ...locationEpics,
  ...blogEpics,
  ...templateEpics,
  ...menuEpics,
  ...leadEpics,
  ...domainEpics
)

export default createEpicMiddleware
