import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import BackIcon from '@material-ui/icons/ArrowBack'
import AddIcon from '@material-ui/icons/Add'
import TrashIcon from '@material-ui/icons/Delete'
import ArchiveIcon from '@material-ui/icons/Archive'
import CloseIcon from '@material-ui/icons/Close'
import UnarchiveIcon from '@material-ui/icons/Unarchive'

import * as R from 'ramda'
import { actions, idsLens } from '~/State/ducks/inventory'
import AutoComplete from './AutoComplete'

import URL from '~/Utils/api_base'

const Main = styled.main`
  width: 100%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MainNav = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.5);
`

const NavItem = styled(Link)`
  text-decoration: none;
  color: ${({ selected }) => (selected ? '#efefef' : 'inherit')};
  padding: 0.5rem 2rem;
  border-left: 1px solid rgba(33, 33, 33, 0.5);
  background: ${({ selected }) =>
    selected ? 'rgba(33, 33, 33, 0.5)' : 'inherit'};

  &:last-of-type {
    border-right: 1px solid rgba(33, 33, 33, 0.5);
  }

  :hover {
    background: rgba(33, 33, 33, 0.5);
    color: #efefef;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
`

const AddButton = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 1rem;
`

const Section = styled.section`
  margin: 1rem 0;
  padding: 1rem;
`

const InventoryGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
`

const InventoryItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 1rem;

  :hover {
    background: #f1f2f4;
  }
`

const InventoryImg = styled.div`
  position: relative;
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`
const InventoryInfo = styled.div``

const InventoryPrice = styled.div``

const Filters = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  select:not(:first-of-type) {
    margin-left: 1rem;
  }
`

const InventoryActions = styled.div`
  display: ${({ isHovered }) => (isHovered ? 'flex' : 'none')};
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(33, 33, 33, 0.3);
`

const Icons = styled.div`
  top: 5%;
  right: 5%;
  align-items: center;
  position: absolute;
  color: #ffffff;
`

const PreviewButton = styled(Button)`
  margin-top: 0.5rem;
`

const InventoryButtons = styled.div`
  display: flex;
  flex-direction: column;
`

const Status = styled.div`
  display: ${({ sold, archive, isHovered }) =>
    (sold || archive) && !isHovered ? 'block' : 'none'};
  position: absolute;
  top: 5%;
  right: 5%;
  padding: 0.25rem;
  border: 1px solid rgba(33, 33, 33, 0.3);
  border-radius: 4.5px;
`

const DeleteModal = styled.div`
  width: 80%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ClaytonBuiltDropdown = ({ setClaytonModelID, options }) => {
  return (
    <React.Fragment>
      <Typography align="center" gutterBottom variant="h5" component="p">
        What is the Clayton Built® Model ID for this New Clayton Built® Home?
      </Typography>
      {/* <FormControl>
        <InputLabel htmlFor="clayton-id">Model ID</InputLabel>
        <Select
          value={claytonModelID}
          onChange={e => setClaytonModelID(e.target.value)}
          inputProps={{
            name: 'clayton',
            id: 'clayton-id'
          }}
        >
          {options.map(model => (
            <MenuItem value={model} key={model}>
              {model}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <AutoComplete options={options} setClaytonModel={setClaytonModelID} />
    </React.Fragment>
  )
}

const InventoryItem = ({
  title,
  mainImage,
  price,
  description,
  status,
  id,
  published,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseOver = () => setIsHovered(true)
  const handleMouseOut = () => setIsHovered(false)
  const sold = status === 'sold'
  const archive = status === 'archive'
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const baseName = useSelector(state => state.baseName || '')

  const dispatch = useDispatch()
  const history = useHistory()

  const setPreview = () => {
    localStorage.setItem(
      'currentInventory',
      JSON.stringify({ title, mainImage, price, description, status, id })
    )

    window.open(`${baseName}/preview`)
  }

  const deleteItem = () => {
    dispatch({
      type: actions.DELETE_ITEM_REQUEST,
      payload: { id },
      meta: history
    })
    setDeleteModalOpen(false)
  }

  const archiveItem = () => {
    dispatch({
      type: actions.UPDATE_ITEM_REQUEST,
      payload: {
        ...rest,
        title,
        mainImage,
        price,
        description,
        id,
        status: archive ? 'active' : 'archive'
      },
      meta: history
    })
    setArchiveModalOpen(false)
  }

  return (
    <InventoryItemWrap
      onMouseEnter={handleMouseOver}
      onTouchEnd={() => setIsHovered(is => !is)}
      onMouseLeave={handleMouseOut}
    >
      <InventoryImg>
        <img
          src={
            mainImage ||
            'https://www.tourniagara.com/wp-content/uploads/2014/10/default-img.gif'
          }
          alt={title}
        />
        <InventoryActions isHovered={isHovered}>
          <Icons>
            <IconButton
              onClick={() => setArchiveModalOpen(true)}
              color="inherit"
            >
              {status === 'archive' ? (
                <UnarchiveIcon fontSize="small" />
              ) : (
                <ArchiveIcon fontSize="small" />
              )}
            </IconButton>
            <IconButton
              onClick={() => setDeleteModalOpen(true)}
              color="inherit"
            >
              <TrashIcon fontSize="small" />
            </IconButton>
          </Icons>
          <InventoryButtons>
            <Button
              variant="contained"
              component={Link}
              to={`/admin/inventory/edit/${id}`}
            >
              Edit Item
            </Button>
            <PreviewButton onClick={setPreview} variant="contained">
              Preview Page
            </PreviewButton>
          </InventoryButtons>
        </InventoryActions>
        <Status sold={sold} archive={archive} isHovered={isHovered}>
          <Typography variant="button">
            {sold ? 'Sold' : archive ? 'Archived' : null}
          </Typography>
        </Status>
      </InventoryImg>
      <InventoryInfo>
        <Typography variant="body2">
          {published ? 'PUBLISHED' : 'UNPUBLISHED'}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        <InventoryPrice>
          <Typography variant="subtitle2">
            {price == 0
              ? 'Contact Us for Pricing'
              : `$${price.toLocaleString()}`}
          </Typography>
        </InventoryPrice>
      </InventoryInfo>
      <Modal onClose={() => setDeleteModalOpen(false)} open={deleteModalOpen}>
        <DeleteModal>
          <Typography variant="h2" component="h4" align="center" gutterBottom>
            Are you sure you want to delete
          </Typography>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
          <ButtonGroup>
            <Button color="secondary" onClick={deleteItem}>
              Delete
            </Button>
            <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
          </ButtonGroup>
        </DeleteModal>
      </Modal>
      <Modal onClose={() => setArchiveModalOpen(false)} open={archiveModalOpen}>
        <DeleteModal>
          <Typography variant="h2" component="h4" align="center" gutterBottom>
            Are you sure you want to {archive ? 'unarchive' : 'archive'}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
          <ButtonGroup>
            <Button color="secondary" onClick={archiveItem}>
              {archive ? 'Unarchive' : 'Archive'}
            </Button>
            <Button onClick={() => setArchiveModalOpen(false)}>Cancel</Button>
          </ButtonGroup>
        </DeleteModal>
      </Modal>
    </InventoryItemWrap>
  )
}

const AddModal = styled.div`
  width: 70%;
  min-height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalHeading = styled.header`
  background: #3e3e3e;
  padding: 0.25rem;
  color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ModalBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;

  .MuiFormControl-root {
    width: 80%;
  }
`

const ModalActions = styled.div`
  margin-top: 2rem;

  a:last-of-type {
    margin-left: 1rem;
  }
`

const InventoryHeader = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  background: #768390;
  color: #fff;
`

const BackButton = styled(Link)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`

const InventoryHeading = styled(Typography)``

const ViewInventory = () => {
  const allInventory = useSelector(state => {
    const ids = R.view(idsLens, state)

    return R.values(ids).sort((a, b) => {
      const aTime = new Date(a.created_at)
      const bTime = new Date(b.created_at)

      if (aTime > bTime) {
        return 1
      }

      if (bTime > aTime) {
        return -1
      }

      return 0
    })
  })

  const last4Modified = allInventory
    .sort((a, b) => {
      const aTime = new Date(a.last_updated)
      const bTime = new Date(b.last_updated)

      if (aTime > bTime) {
        return -1
      }

      if (bTime > aTime) {
        return 1
      }

      return 0
    })
    .slice(0, 4)

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [isClaytonBuilt, setIsClaytonBuilt] = useState(false)
  const [claytonModelID, setClaytonModelID] = useState()
  const [priceFilter, setPriceFilter] = useState('')
  const [footageFilter, setFootageFilter] = useState('')
  const [bedsFilter, setBedsFilter] = useState('')
  const [bathsFilter, setBathsFilter] = useState('')
  const [availableModels, setAvailableModels] = useState([])
  const domain_id = useSelector(state => state.domain.id)
  const history = useHistory()

  useEffect(() => {
    fetch(`${URL}/models/by_domain/${domain_id}`)
      .then(x => x.json())
      .then(({ data }) => {
        setAvailableModels(data.map(({ modelNumber }) => modelNumber))
      })
  }, [])

  const clearModalState = () => {
    setIsClaytonBuilt(false)
    setAddModalOpen(false)
    setClaytonModelID()
  }

  return (
    <Main>
      <InventoryHeader>
        <BackButton to="/admin">
          <IconButton color="inherit">
            <BackIcon />
          </IconButton>
          <Typography variant="caption">Back to Admin</Typography>
        </BackButton>
        <InventoryHeading variant="h2" align="center" color="inherit">
          Inventory
        </InventoryHeading>
      </InventoryHeader>
      <MainNav>
        <NavItem selected to="/admin/inventory">
          <Typography>Inventory</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/categories">
          <Typography>Category Manager</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/features">
          <Typography>Feature Manager</Typography>
        </NavItem>
        <NavItem to="/admin/inventory/locations">
          <Typography>Location Manager</Typography>
        </NavItem>
      </MainNav>
      <Content>
        <AddButton onClick={() => setAddModalOpen(true)}>
          <IconButton>
            <AddIcon />
          </IconButton>
          <Typography>Create a New Inventory Item</Typography>
        </AddButton>
        <Section>
          <Typography variant="h4" gutterBottom>
            Recently Edited Items
          </Typography>
          <InventoryGrid>
            {last4Modified.map(({ id, ...rest }) => (
              <InventoryItem key={id} {...rest} id={id} />
            ))}
          </InventoryGrid>
        </Section>
        <Section>
          <Typography variant="h4" gutterBottom align="center">
            Inventory
          </Typography>
          <Filters>
            <select
              name="price"
              value={priceFilter}
              onChange={e => setPriceFilter(e.target.value)}
            >
              <option value="">Any Price</option>
              <option value="50000">&gt; $50,000</option>
              <option value="100000">&gt; $100,000</option>
            </select>
            <select
              name="footage"
              value={footageFilter}
              onChange={e => setFootageFilter(e.target.value)}
            >
              <option value="">Any Sq Footage</option>
              <option value="500">&gt; 500 ft</option>
              <option value="750">&gt; 750 ft</option>
              <option value="1000">&gt; 1,000 ft</option>
              <option value="1500">&gt; 1,500 ft</option>
              <option value="2000">&gt; 2,000 ft</option>
            </select>
            <select
              name="bathrooms"
              value={bathsFilter}
              onChange={e => setBathsFilter(e.target.value)}
            >
              <option value="">Any Bathrooms</option>
              <option value="1">&gt; 1</option>
              <option value="2">&gt; 2</option>
              <option value="3">&gt; 3</option>
              <option value="4">&gt; 4</option>
              <option value="5">&gt; 5+</option>
            </select>
            <select
              name="bedrooms"
              value={bedsFilter}
              onChange={e => setBedsFilter(e.target.value)}
            >
              <option value="">Any Bedrooms</option>
              <option value="1">&gt; 1</option>
              <option value="2">&gt; 2</option>
              <option value="3">&gt; 3</option>
              <option value="4">&gt; 4</option>
              <option value="5">&gt; 5+</option>
            </select>
          </Filters>
          <InventoryGrid>
            {allInventory
              .filter(item => {
                const price = priceFilter ? Number(priceFilter) : 0
                const footage = footageFilter ? Number(footageFilter) : 0
                const baths = bathsFilter ? Number(bathsFilter) : 0
                const beds = bedsFilter ? Number(bedsFilter) : 0

                return (
                  item.price >= price &&
                  item.footage >= footage &&
                  item.bathrooms >= baths &&
                  item.bedrooms >= beds
                )
              })
              .map(({ id, ...rest }) => (
                <InventoryItem key={id} id={id} {...rest} />
              ))}
          </InventoryGrid>
        </Section>
      </Content>
      <Modal
        open={addModalOpen}
        aria-labelledby="add-item-title"
        aria-describedby="add-item-description"
        onClose={() => clearModalState()}
      >
        <AddModal>
          <ModalHeading>
            <Typography
              id="add-item-title"
              variant="h4"
              color="inherit"
              component="p"
            >
              Create A New Inventory Item
            </Typography>
            <IconButton onClick={() => clearModalState()} color="inherit">
              <CloseIcon />
            </IconButton>
          </ModalHeading>
          <ModalBody>
            {isClaytonBuilt ? (
              <ClaytonBuiltDropdown
                options={availableModels}
                setClaytonModelID={setClaytonModelID}
                claytonModelID={claytonModelID}
              />
            ) : (
              <React.Fragment>
                <Typography
                  align="center"
                  gutterBottom
                  variant="h5"
                  component="p"
                >
                  Is this a new Clayton Built® Item or Any Other item?
                </Typography>
                <ModalActions>
                  <Button
                    variant="contained"
                    onClick={() => setIsClaytonBuilt(true)}
                  >
                    New Clayton Built®
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    to="/admin/inventory/create"
                  >
                    Other
                  </Button>
                </ModalActions>
              </React.Fragment>
            )}
          </ModalBody>
          <ButtonGroup>
            <Button onClick={() => clearModalState()}>Cancel</Button>
            {isClaytonBuilt && (
              <Button
                onClick={() =>
                  history.push(`/admin/inventory/create?id=${claytonModelID}`)
                }
                disabled={!(availableModels.indexOf(claytonModelID) !== -1)}
              >
                Continue
              </Button>
            )}
          </ButtonGroup>
        </AddModal>
      </Modal>
    </Main>
  )
}

export default ViewInventory
