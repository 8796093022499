import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ChromePicker } from 'react-color'
import InputLabel from '@material-ui/core/InputLabel'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ImageUpload from '~/SharedComponents/ImageUpload'

const Inputs = styled.div`
  display: flex;
`

const Input = styled(ChromePicker)`
  width: 100%;
`

const ThemeAndBrandContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ThemeCard = styled.div`
  background: #f1f2f4;
  width: 360px;
  height: 360px;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4.5px;
  ${({ selected }) => (selected ? `border: 5px solid blue;` : '')}
`

const CaptionContainer = styled.div``

const CaptionText = styled(Typography)``

const ThemeContainer = styled.div`
  width: 100%;
  display: flex;
  background: white;
  justify-content: center;
  flex-wrap: wrap;
`

const ThemeText = styled(Typography)`
  padding-left: 5rem;
`

const BrandText = styled(Typography)`
  padding-left: 5rem;
`

const BrandContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ColorAndImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: space-between;
`

const InputContainer = styled.div`
  margin: 2rem;
`

const ImageUploadContainer = styled.div``

const Brand = ({
  primary = '#000',
  secondary = '#fff',
  handleThemeChange,
  handleImageChange,
  image,
  theme = 'Theme1'
}) => {
  const [primaryColor, setPrimary] = useState(primary || '')
  const [secondaryColor, setSecondary] = useState(secondary || '')

  return (
    <ThemeAndBrandContainer>
      <ThemeText variant="h4">Select a Theme</ThemeText>
      <ThemeContainer>
        <ThemeCard
          onClick={() => handleThemeChange('Theme1')}
          selected={theme === 'Theme1'}
        >
          Theme 1
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme2')}
          selected={theme === 'Theme2'}
        >
          Theme 2
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme3')}
          selected={theme === 'Theme3'}
        >
          Theme 3
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme4')}
          selected={theme === 'Theme4'}
        >
          Theme 4
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme5')}
          selected={theme === 'Theme5'}
        >
          Theme 5
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme6')}
          selected={theme === 'Theme6'}
        >
          Theme 6
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme7')}
          selected={theme === 'Theme7'}
        >
          Theme 7
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme8')}
          selected={theme === 'Theme8'}
        >
          Theme 8
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('Theme9')}
          selected={theme === 'Theme9'}
        >
          Theme 9
        </ThemeCard>
        <ThemeCard
          onClick={() => handleThemeChange('TimbTheme')}
          selected={theme === 'TimbTheme'}
        >
          Timb Theme
        </ThemeCard>
      </ThemeContainer>

      <BrandContainer>
        <BrandText variant="h4">Your Brand</BrandText>
        <ColorAndImageContainer>
          <ImageUploadContainer>
            <ImageUpload
              name="image-logo"
              image={image}
              onChange={handleImageChange}
            />
            <CaptionContainer>
              <CaptionText variant="caption">
                Suggested file types: JPG, PNG, SVGs. Logo size should be no
                smaller than XX pixels and not exceed XXk in size.
              </CaptionText>
            </CaptionContainer>
          </ImageUploadContainer>
          <Inputs>
            <InputContainer>
              <InputLabel htmlFor="primary">Primary Color</InputLabel>
              <Input
                name="primary"
                id="primary"
                aria-label="Primary Color"
                title="Primary Color"
                color={primaryColor}
                onChangeComplete={color => setPrimary(color)}
                required
              />
              <input
                hidden
                value={primaryColor.hex || primaryColor}
                name="primary-color-value"
              />
              <Button onClick={() => setPrimary('')}>Clear</Button>
            </InputContainer>
            <InputContainer>
              <InputLabel htmlFor="secondary">Secondary Color</InputLabel>
              <Input
                name="secondary"
                id="secondary"
                aria-label="Secondary Color"
                title="Secondary Color"
                color={secondaryColor}
                onChangeComplete={color => setSecondary(color)}
                required
              />
              <input
                hidden
                value={secondaryColor.hex || secondaryColor}
                name="secondary-color-value"
              />
              <Button onClick={() => setSecondary('')}>Clear</Button>
            </InputContainer>
          </Inputs>
        </ColorAndImageContainer>
      </BrandContainer>
    </ThemeAndBrandContainer>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(Brand)
