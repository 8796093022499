import { ofType } from 'redux-observable'
import { withLatestFrom, flatMap, map, tap } from 'rxjs/operators'
import { from as of$ } from 'rxjs'
import Alert from 'react-s-alert'

import * as R from 'ramda'

import { createActions, createHandler } from '~/State/utils'
import updatePage from '~/Requests/pages/updatePage'
import createPage from '~/Requests/pages/createPage'
import deletePage from '~/Requests/pages/deletePage'
import slugify from '~/Utils/slugify'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_PAGE_REQUESTED
 * @prop {string} CREATE_PAGE_SUCCESS
 * @prop {string} CREATE_PAGE_FAILURE
 * @prop {string} UPDATE_PAGE_REQUESTED
 * @prop {string} UPDATE_PAGE_FAILURE
 * @prop {string} UPDATE_PAGE_SUCCESS
 * @prop {string} DELETE_PAGE_REQUESTED
 * @prop {string} DELETE_PAGE_SUCCESS
 * @prop {string} DELETE_PAGE_FAILURE
 * @prop {string} PUBLISH_PAGE
 * @prop {string} UNPUBLISH_PAGE
 *
 *
 */

/**
 * @type {Actions}
 */

export const actions = createActions('PAGES', [
  'CREATE_PAGE_REQUESTED',
  'CREATE_PAGE_SUCCESS',
  'CREATE_PAGE_FAILURE',
  'UPDATE_PAGE_REQUESTED',
  'UPDATE_PAGE_FAILURE',
  'UPDATE_PAGE_SUCCESS',
  'DELETE_PAGE_REQUESTED',
  'DELETE_PAGE_SUCCESS',
  'DELETE_PAGE_FAILURE',
  'PUBLISH_PAGE',
  'UNPUBLISH_PAGE'
])

const rootLens = R.lensProp('pages')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)

const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const get = {
  pages: R.view(rootLens),
  pageIds: R.view(idsLens),
  byId: id => R.view(byIdLens(id))
}

export const defaultState = {
  pages: {
    ids: {
      1: {
        id: 1,
        path: '/abc',
        title: 'Page 1',
        published: true,
        blocks: [
          {
            id: 3,
            component: 'Header1',
            data: {
              address: '1234 Main St',
              city: 'Anytowmn',
              state: 'CA',
              zip: 90210,
              phone: '888-555-1234'
            }
          },
          {
            id: 4,
            component: 'Hero1',
            data: {
              header: 'Sup ladies?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          }
        ]
      },
      2: {
        id: 2,
        path: '/def',
        published: false,
        title: 'Page 2',
        blocks: [
          {
            id: 1,
            component: 'Header2',
            data: {
              address: '865 Blount Ave',
              city: 'Anytown',
              state: 'TN',
              zip: 90210,
              phone: '888-555-1234'
            }
          },
          {
            id: 2,
            component: 'Hero2',
            data: {
              header: 'Sup guys?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          }
        ]
      },
      3: {
        id: 3,
        title: 'My Nested Page',
        path: '/some/random/path',
        blocks: [
          {
            id: 5,
            component: 'Header1',
            data: {
              header: 'Sup my gregs?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          }
        ]
      },
      4: {
        id: 4,
        title: 'Another Nested Page',
        path: '/some/other/page',
        blocks: [
          {
            id: 6,
            component: 'Header1',
            data: {
              header: 'Sup my gregs?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          }
        ]
      },
      5: {
        id: 5,
        title: 'Not in a menu',
        path: '/some/nested/page',
        blocks: [
          {
            id: 5,
            component: 'Header1',
            data: {
              header: 'Sup my not gregs?',
              subtitle: 'is a sexist thing to say probably',
              ctaText: 'Buy my things or else!'
            }
          }
        ]
      }
    }
  }
}

export const reducer = createHandler({
  [actions.CREATE_PAGE_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.UPDATE_PAGE_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_PAGE_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload), state),
  [actions.PUBLISH_PAGE]: (state, action) =>
    R.over(byIdLens(action.payload.id), R.assoc('published', true), state),
  [actions.UNPUBLISH_PAGE]: (state, action) =>
    R.over(byIdLens(action.payload.id), R.assoc('published', false), state)
})

const updatePage$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_PAGE_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(updatePage(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_PAGE_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_PAGE_SUCCESS,
        payload: data,
        meta
      }
    })
  )

const createPage$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.CREATE_PAGE_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(
        createPage(
          {
            ...action.payload,
            path: slugify(action.payload.title),
            domain_id: state.domain.id
          },
          state.token
        )
      ).pipe(map(answer => ({ ...answer, history: action.meta })))
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.CREATE_PAGE_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.CREATE_PAGE_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

const deletePage$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.DELETE_PAGE_REQUESTED),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(deletePage(action.payload, state.token)).pipe(
        map(answer => ({ ...answer, history: action.meta }))
      )
    ),
    map(({ data, error, meta, history }) => {
      if (error) {
        return {
          type: actions.DELETE_PAGE_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.DELETE_PAGE_SUCCESS,
        payload: data,
        meta,
        history
      }
    })
  )

export const redirectPage$ = action$ =>
  action$.pipe(
    ofType(actions.CREATE_PAGE_SUCCESS),
    tap(action => {
      action.history.push(`/admin/builder?page=${action.payload.id}`)
    }),
    map(() => ({
      type: '@@MISC/REDIRECTED_PAGE'
    }))
  )

export const redirectPageAfterDelete$ = action$ =>
  action$.pipe(
    ofType(actions.DELETE_PAGE_SUCCESS),
    tap(action => {
      action.history.push('/admin/builder')
    }),
    map(() => ({
      type: '@@MISC/REDIRECTED_PAGE'
    }))
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_PAGE_SUCCESS, actions.UPDATE_PAGE_SUCCESS),
    tap(() => {
      Alert.success('Page Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_PAGE_FAILURE, actions.UPDATE_PAGE_FAILURE),
    tap(() => {
      Alert.error('Error Saving Page. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  updatePage$,
  createPage$,
  redirectPage$,
  deletePage$,
  redirectPageAfterDelete$,
  showSuccessNotification$,
  showErrorNofitication$
]
