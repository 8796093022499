import { createActions, createHandler } from '~/State/utils'
import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'

import createLead from '~/Requests/leads/createLead'
import deleteLead from '~/Requests/leads/deleteLead'
import updateLead from '~/Requests/leads/updateLead'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_LEAD_REQUEST
 * @prop {string} CREATE_LEAD_SUCCESS
 * @prop {string} CREATE_LEAD_FAILED
 * @prop {string} DELETE_LEAD_REQUEST
 * @prop {string} DELETE_LEAD_SUCCESS
 * @prop {string} DELETE_LEAD_FAILED
 * @prop {string} UPDATE_LEAD_REQUEST
 * @prop {string} UPDATE_LEAD_SUCCESS
 * @prop {string} UPDATE_LEAD_FAILED
 */

/**
 * @type {Actions}
 */
export const actions = createActions('LEADS', [
  'CREATE_LEAD_REQUEST',
  'CREATE_LEAD_SUCCESS',
  'CREATE_LEAD_FAILED',
  'DELETE_LEAD_REQUEST',
  'DELETE_LEAD_SUCCESS',
  'DELETE_LEAD_FAILED',
  'UPDATE_LEAD_REQUEST',
  'UPDATE_LEAD_SUCCESS',
  'UPDATE_LEAD_FAILED'
])

export const defaultState = {
  ids: {
    1: {
      id: 1,
      name: 'Name',
      company: 'Company',
      email: 'Email',
      phone: 'Phone',
      message: 'Message'
    }
  }
}

export const rootLens = R.lensProp('leads')

export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)
export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const reducer = createHandler({
  [actions.CREATE_LEAD_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.UPDATE_LEAD_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_LEAD_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const createLead$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.CREATE_LEAD_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(createLead(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.CREATE_LEAD_FAILED,
          payload: error,
          meta
        }
      }

      return {
        type: actions.CREATE_LEAD_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const deleteLead$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.DELETE_LEAD_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(deleteLead(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.DELETE_LEAD_FAILED,
          payload: error,
          meta
        }
      }

      return {
        type: actions.DELETE_LEAD_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const updateLead$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_LEAD_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(updateLead(action.payload, state.token))),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_LEAD_FAILED,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_LEAD_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const epics = [createLead$, deleteLead$, updateLead$]
