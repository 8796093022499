import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import IconButton from '@material-ui/core/IconButton'

import * as R from 'ramda'

import API_URL from '~/Utils/api_base'
import { idLens } from '~/State/ducks/domain'

const ButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;

  button:not(:first-of-type) {
    margin-left: 1rem;
  }
`

const InputsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  border: 1px solid black;
  border-radius: 4.5px;
`

const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ImageInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const SaveButton = styled(Button)`
  padding-left: 1rem;
`
//For some reason the add button won't center on certain pages, adding this empty div fixes it?
const InnerContainer = styled.div`
  img {
    max-width: 100%;
  }
`

const ImageUpload = ({ onChange, image: img, name }) => {
  const [image, setImage] = useState(img)
  const domain_id = useSelector(R.view(idLens))

  //This is getting the end of the S3 bucket url
  const getEndFromIMG = url => {
    const encoded = new URL(url)

    return encoded.pathname
  }

  const deleteImage = () => {
    const end = getEndFromIMG(img)
    const url = `${API_URL}/files${end}`
    return fetch(url, {
      method: 'DELETE'
    })
      .then(x => x.json())
      .then(({ data }) => {
        if (data) {
          onChange('')
          setImage('')
        }
      })
  }
  const reset = () => deleteImage().then(() => onChange())

  useEffect(() => {
    //We are checking to see if the current image is different than the image that was passed in
    if (image && image !== img) {
      const data = new FormData()

      data.set('file', image)

      fetch(`${API_URL}/files/${domain_id}`, {
        method: 'POST',
        body: data
      })
        .then(x => x.json())
        .then(({ data }) => {
          if (data) {
            onChange(data.link)
          }
        })
    } else if (image) {
    } else {
      onChange('')
    }
  }, [image])
  return (
    <UploadImageContainer>
      <InputsContainer>
        <InnerContainer>
          {!image && !img && (
            <IconButton component={'label'} htmlFor={name}>
              <AddCircleIcon />
            </IconButton>
          )}

          <ImageInput
            id={name}
            type="file"
            name={name}
            aria-label={name}
            title={name}
            onChange={e => {
              const { target } = e
              if (img) {
                reset().then(() => setImage(target.files[0]))
              } else {
                setImage(e.target.files[0])
              }
            }}
            accept="image/*"
          />
          <img src={img} />
        </InnerContainer>
      </InputsContainer>

      <ButtonContainer>
        {image && (
          <SaveButton
            variant="contained"
            color="primary"
            onClick={() => reset().then(() => onChange(''))}
          >
            Remove
          </SaveButton>
        )}
      </ButtonContainer>
    </UploadImageContainer>
  )
}

export default ImageUpload
