import React from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { useHistory } from 'react-router-dom'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem;

  .MuiCard-root {
    width: 350px;
    margin-bottom: 1rem;

    .MuiCardActionArea-root {
      height: 100%;
    }
  }
`

const DefaultAdminPage = () => {
  const history = useHistory()

  return (
    <Page>
      <Helmet>
        <title>PowerSlide Admin</title>
      </Helmet>
      <Main>
        <Card>
          <CardActionArea onClick={() => history.push('/admin/builder')}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Page Builder
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Go here to create, edit, or delete pages including the templates
                for blogs and inventory. Staging!
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea onClick={() => history.push('/admin/blog')}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Blog Posts
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Go here to create, edit, or delete blog posts
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea onClick={() => history.push('/admin/inventory')}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Inventory Manager
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Go here to create, edit, or delete inventory items
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardActionArea onClick={() => history.push('/admin/profile')}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Domain Profile
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Go here to create or edit users, to change themes, and to modify
                the domain-specific values such as colors, contact info, etc.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Main>
    </Page>
  )
}

export default DefaultAdminPage
