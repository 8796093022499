import React from 'react'
import styled from '@emotion/styled'

import Typography from '@material-ui/core/Typography'

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #768390;
`

const Content = () => (
  <Wrap>
    <Typography variant="h4">Your content will be displayed here</Typography>
  </Wrap>
)

export default Content
