import React, { useState } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ButtonStylePicker from './ButtonStylePicker'
import ButtonPicker from './ButtonPicker'
import ColorPicker from './ColorPicker'
import InputContainer from './Container'
import ImageUpload from '~/SharedComponents/ImageUpload'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ColorPickerWrapper = styled.div`
  padding-right: 2rem;
`

const ImageUploadAndTextContainer = styled.div`
  display: flex;
`

const ImageUploadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ColorInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  flex: 1;
  background-color: #f1f2f4;
`

const CallToActionText = styled(Typography)`
  margin-top: 2rem;
`

const IntroPhoto1 = ({ data }) => {
  const {
    background = '#ffffff',
    text = '#eeeeee',
    btnColor = 'primary',
    btnStyle = 'contained',
    headerSize = 'h2'
  } = data
  const [image, setImage] = useState(data.img);
const [bgImage, setBgImage] = useState(data.bgImage);

// Modified setImage function with console.log
const newSetImage = (newImageUrl) => {
  console.log("newSetImage:", newImageUrl);
  setImage(newImageUrl);
};

// Modified setImage function with console.log
const newSetBgImage = (newImageUrl) => {
  console.log("newSetBgImage:", newImageUrl);
  setBgImage(newImageUrl);
};


  const [headerFontSize, setHeaderFontSize] = useState(headerSize);
  
  console.log("Current image:", image);
  console.log("Current bgImage:", bgImage);

  return (
    <InputContainer>
      <InnerContainer>
        <ImageUploadAndTextContainer>
          <ImageUploadContainer>
            <Typography align="center">Featured Image</Typography>
            <ImageUpload name="image" onChange={newSetImage} image={image} />
            <input hidden value={image} name="img" />
            <TextField
              name="imageAlt"
              id="imageAlt"
              aria-label="Image Alt Text"
              title="Image Alt Text"
              label="Image Alt Text"
              margin="normal"
              variant="outlined"
              defaultValue={data.imageAlt}
            />
            <TextField
              name="imageTitle"
              id="imageTitle"
              aria-label="Image Title Text"
              title="Image Title Text"
              label="Image Title Text"
              margin="normal"
              variant="outlined"
              defaultValue={data.imageTitle}
            />
          </ImageUploadContainer>
          <TextInputContainer>
            <TextField
              name="header"
              id="header"
              aria-label="Header"
              title="Header"
              label="Header"
              margin="normal"
              variant="outlined"
              defaultValue={data.header}
            />
            <FormControl>
              <InputLabel id="header-size">Header Size</InputLabel>
              <Select
                name="headerSize"
                labelId="header-size"
                id="header-size-select"
                value={headerFontSize}
                onChange={e => setHeaderFontSize(e.target.value)}
              >
                <MenuItem value="h1">H1</MenuItem>
                <MenuItem value="h2">H2</MenuItem>
                <MenuItem value="h3">H3</MenuItem>
                <MenuItem value="h4">H4</MenuItem>
                <MenuItem value="h5">H5</MenuItem>
                <MenuItem value="h6">H6</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="subtitle"
              id="subtitle"
              aria-label="Subtitle"
              title="Subtitle"
              label="Subtitle"
              margin="normal"
              variant="outlined"
              defaultValue={data.subtitle}
              multiline
            />
            <CallToActionText>Call to Action Button</CallToActionText>
            <TextField
              name="ctaText"
              id="ctaText"
              aria-label="Call to Action Text"
              title="Call to Action Text"
              label="Call to Action Text"
              margin="normal"
              variant="outlined"
              defaultValue={data.ctaText}
            />
            <TextField
              name="ctaLink"
              id="ctaLink"
              aria-label="Call to Action Link"
              title="Call to Action Link"
              label="Call to Action Link"
              margin="normal"
              variant="outlined"
              defaultValue={data.ctaLink}
            />
          </TextInputContainer>
        </ImageUploadAndTextContainer>
        <ColorInputContainer>
          <ColorPickerWrapper>
            <ColorPicker
              color={background}
              name="background"
              title="Background Color"
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <ColorPicker color={text} name="text" title="Text Color" />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <ButtonPicker
              color={btnColor}
              name="btnColor"
              title="Button Color"
            />
          </ColorPickerWrapper>
          <ColorPickerWrapper>
            <ButtonStylePicker
              color={btnStyle}
              name="btnStyle"
              title="Button Style"
            />
          </ColorPickerWrapper>
        </ColorInputContainer>
      </InnerContainer>
      <Typography align="center">Background Image</Typography>
      <ImageUpload
        name="background-image"
        onChange={newSetBgImage}
        image={bgImage}
      />
      <input hidden value={bgImage} name="bgImage" />
    </InputContainer>
  )
}

export default IntroPhoto1
