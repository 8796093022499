import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/styles'
import * as R from 'ramda'
import Carousel from 'nuka-carousel'

import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs, getBasedOnTheme } from '~/App/PageTheme'

const ReviewContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  flex-wrap: wrap;
  background: ${({ color }) => `${color}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  background-size: cover;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  /* https://github.com/FormidableLabs/nuka-carousel/issues/349
  The wrong width is a known issue that will likely not be fixed,
  we are using !important to override their styles */

  .slider-list {
    width: 100% !important;
  }
  .slider-frame {
    width: 100% !important;
  }

  .paging-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`

const HeaderContainer = styled.div`
  color: ${({ color }) => `${color}` || `inherit`};

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

const HeaderText = styled(Typography)`
  margin-top: 1rem;
  padding-left: 5rem;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

const ReviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-left: 8rem;
  padding-right: 8rem;
  flex-grow: 1;
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ color }) => `${color}` || `inherit`};
  line-height: 40px;
  font-size: 24px;
  width: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? '75%'
      : '100%'};

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }
`

const NameAndCityContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: ${({ theme }) =>
    theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8'
      ? 'flex-start'
      : 'flex-end'};
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

const Review = styled.p`
  text-indent: -0.9rem;
  font-family: inherit;

  :before {
    display: inline-block;
    content: '"';
    font-family: inherit;
    font-size: 5rem;
    line-height: 1px;
    position: relative;
    left: -20px;
    transform: rotate(180deg);
  }
  :after {
    content: '"';
    font-size: 5rem;
    position: relative;
    bottom: -20px;
  }
`

const Name = styled(Typography)``

const City = styled(Typography)`
  font-family: ${({ theme }) => theme == 'Theme6' && 'DM Sans'} !important;
  text-transform: ${({ theme }) =>
    (theme == 'Theme6' || theme == 'Theme7' || theme == 'Theme8') &&
    'uppercase'} !important;
`

const Divider = styled.hr`
  margin-top: 3rem;
  width: 10%;
  margin-bottom: 2rem;
`

const SlideContainer = styled.div`
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const Reviews2 = ({
  reviews,
  background,
  text,
  image,
  header = 'Customer Testimonials',
  isPreview,
  name = 'Name of Person',
  location = 'City, State',
  content = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
}) => {
  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const theme = useTheme()

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <ReviewContainer color={bgColor}>
        <HeaderContainer color={textColor}>
          <HeaderText variant="h2">{header}</HeaderText>
        </HeaderContainer>
        <Carousel
          autoplay
          wrapAround
          autoplayInterval={5000}
          slidesToShow={1}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          <SlideContainer>
            <ReviewTextContainer
              fontFamily={theme.typography.fontFamily}
              color={textColor}
            >
              <Divider></Divider>

              <Review color="inherit">{content}</Review>
              <NameAndCityContainer theme={currentThemeEnum}>
                <NameContainer>
                  <Name gutterBottom variant="h3">
                    {name}
                  </Name>
                  <br />
                  <City theme={currentThemeEnum} align="right" variant="h5">
                    {location}
                  </City>
                </NameContainer>
              </NameAndCityContainer>
              <Divider></Divider>
            </ReviewTextContainer>
          </SlideContainer>
          <SlideContainer>
            <ReviewTextContainer
              fontFamily={theme.typography.fontFamily}
              color={textColor}
            >
              <Divider></Divider>

              <Review color="inherit">{content}</Review>
              <NameAndCityContainer theme={currentThemeEnum}>
                <NameContainer>
                  <Name gutterBottom variant="h3">
                    {name}
                  </Name>
                  <City theme={currentThemeEnum} align="right" variant="h5">
                    {location}
                  </City>
                </NameContainer>
              </NameAndCityContainer>
              <Divider></Divider>
            </ReviewTextContainer>
          </SlideContainer>
        </Carousel>
      </ReviewContainer>
    )
  }

  return (
    <ReviewContainer img={image} color={bgColor}>
      <HeaderContainer color={textColor}>
        <HeaderText variant="h2">{header}</HeaderText>
      </HeaderContainer>
      {reviews && reviews.length > 1 ? (
        <Carousel
          autoplay
          wrapAround
          autoplayInterval={7000}
          slidesToShow={1}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          {reviews.map((review, i) => (
            <SlideContainer key={i}>
              <ReviewTextContainer
                fontFamily={theme.typography.fontFamily}
                color={textColor}
                theme={currentThemeEnum}
              >
                <Divider></Divider>
                <Review color="inherit" variant="h3">
                  {review.content}
                </Review>
                <NameAndCityContainer theme={currentThemeEnum}>
                  <NameContainer>
                    <Name gutterBottom variant="h3">
                      {review.name}
                    </Name>
                    <City theme={currentThemeEnum} align="right" variant="h5">
                      {review.location}
                    </City>
                  </NameContainer>
                </NameAndCityContainer>

                <Divider></Divider>
              </ReviewTextContainer>
            </SlideContainer>
          ))}
        </Carousel>
      ) : (
        <div>
          {reviews &&
            reviews.map((review, i) => (
              <ReviewTextContainer
                key={i}
                fontFamily={theme.typography.fontFamily}
                color={textColor}
                theme={currentThemeEnum}
              >
                <Divider></Divider>
                <Review color="inherit" variant="h3">
                  {review.content}
                </Review>
                <NameAndCityContainer theme={currentThemeEnum}>
                  <NameContainer>
                    <Name gutterBottom variant="h3">
                      {review.name}
                    </Name>
                    <City theme={currentThemeEnum} align="right" variant="h5">
                      {review.location}
                    </City>
                  </NameContainer>
                </NameAndCityContainer>

                <Divider></Divider>
              </ReviewTextContainer>
            ))}
        </div>
      )}
    </ReviewContainer>
  )
}

Reviews2.displayName = 'Reviews 2'
Reviews2.moduleName = 'Reviews2'

export default Reviews2
