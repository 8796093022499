import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Add from '@material-ui/icons/Add'
import Menu from '@material-ui/icons/Menu'
import UpArrow from '@material-ui/icons/ArrowUpward'
import DownArrow from '@material-ui/icons/ArrowDownward'
import Button from '@material-ui/core/Button'
import Buttons from '@material-ui/core/ButtonGroup'
import DeleteForever from '@material-ui/icons/DeleteForever'
import { Typography } from '@material-ui/core'

import * as R from 'ramda'
import uuid from 'uuid/v4'

import EditModule from './EditModule'
import * as Components from '~/Components'
import ModuleSelector from './ModuleSelector'
import PageTheme from '~/App/PageTheme'

const PageEdit = styled.div`
  width: 100%;
  padding: 1.25rem;
`

const ComponentList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AddLineContainer = styled.div`
  width: 90%;
  border: 1px solid rgba(33, 33, 33, 0.5);
  position: relative;
  margin: 1rem 0;
  cursor: pointer;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const AddIcon = styled(Add)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #768390;
  color: #fff;
  border-radius: 50%;
`

const AddModuleModal = styled.div`
  width: 80%;
  height: 80%;
  overflow-y: auto;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const EditModuleModal = styled.div`
  width: 80%;
  height: 80%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
`

const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BlockDisplay = styled.div`
  width: 100%;
  display: flex;
`

const RemoveModal = styled.div`
  width: 80%;
  min-height: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RemoveModuleIcon = styled.div`
  margin: 0.5rem 0;
`

const AddLine = ({ onClick }) => (
  <AddLineContainer onClick={onClick}>
    <AddIcon />
  </AddLineContainer>
)

const BlockOptionsWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const BlockOptions = ({
  onUp = () => {},
  onDown = () => {},
  onMenu = () => {},
  onRemove = () => {},
  isFirst,
  isLast,
  canDelete,
  canEdit
}) => (
  <BlockOptionsWrap>
    <IconButton onClick={onMenu} disabled={!canEdit}>
      <Menu />
    </IconButton>
    <RemoveModuleIcon>
      <IconButton onClick={onRemove} disabled={!canDelete}>
        <DeleteForever />
      </IconButton>
    </RemoveModuleIcon>
    <IconButton onClick={onUp} disabled={isFirst}>
      <UpArrow />
    </IconButton>
    <IconButton onClick={onDown} disabled={isLast}>
      <DownArrow />
    </IconButton>
  </BlockOptionsWrap>
)

const TemplateForm = ({ blocks, id, onChange, setPageChanges }) => {
  const [currentBlocks, setCurrentBlocks] = useState(
    blocks.map(({ data, ...rest }) => ({
      ...rest,
      data: data || {}
    })) || [
      {
        component: 'CONTENT',
        data: {}
      }
    ]
  )
  const [currentAddIndex, setCurrentAddIndex] = useState(null)
  const [showAddModuleModal, setShowAddModuleModal] = useState(false)
  const [showEditModuleModal, setShowEditModuleModal] = useState(false)
  const [currentModule, setCurrentModule] = useState()
  const [toDelete, setToDelete] = useState(null)
  const pageId = id

  useEffect(() => {
    const diff = {
      id,
      blocks: currentBlocks
    }

    setPageChanges(diff)
  }, [currentBlocks])

  const components = currentBlocks.map(({ component, ...rest }) => ({
    ...rest,
    component: Components[component],
    component_name: component
  }))

  const addBlock = (index, block) => {
    setCurrentBlocks(R.insert(index, block))
    onChange()
  }

  const removeBlock = index => {
    setCurrentBlocks(blocks => blocks.filter((_, i) => i !== index))
    onChange()
    teardownModal()
  }

  const updateBlock = (index, block) => {
    setCurrentBlocks(R.adjust(index, R.mergeDeepLeft(block)))
    onChange()
    teardownModal()
  }

  const moveBlockUp = index => {
    // if index is 0, we are not going
    // to even try moving it up!
    if (!index) {
      return
    }

    setCurrentBlocks(R.move(index, index - 1))
    onChange()
  }

  const moveBlockDown = index => {
    // if index is last block, do not
    // allow to move down
    if (index === components.length) {
      return
    }

    setCurrentBlocks(R.move(index, index + 1))
    onChange()
  }

  const setupModal = index => {
    setCurrentAddIndex(index)
    setShowAddModuleModal(true)
  }

  const addBlockFromModal = component => {
    addBlock(currentAddIndex, { id: uuid(), component, data: {} })
    teardownModal()
  }

  const teardownModal = () => {
    setCurrentAddIndex(null)
    setShowAddModuleModal(false)
    setShowEditModuleModal(false)
    setToDelete(null)
  }

  const openEditModal = data => {
    setShowEditModuleModal(true)
    setCurrentModule(data)
  }

  return (
    <PageEdit key={id}>
      <ComponentList>
        {components.map(
          ({ id, component: Comp, data, component_name, ...rest }, i) => (
            <Block key={i}>
              <AddLine onClick={() => setupModal(i)} />
              <BlockDisplay>
                <PageTheme>
                  <Comp {...data} />
                </PageTheme>
                <BlockOptions
                  isFirst={i === 0}
                  isLast={i === components.length - 1}
                  onUp={() => moveBlockUp(i)}
                  onDown={() => moveBlockDown(i)}
                  onMenu={() =>
                    openEditModal({
                      id,
                      data,
                      pageId,
                      component_name,
                      ...rest,
                      index: i
                    })
                  }
                  canDelete={component_name !== 'CONTENT'}
                  canEdit={component_name !== 'CONTENT'}
                  onRemove={() => setToDelete(i)}
                />
              </BlockDisplay>
            </Block>
          )
        )}
        <AddLine onClick={() => setupModal(components.length)} />
      </ComponentList>
      <Modal
        aria-labelledby="add-block-title"
        aria-describedby="add-block-description"
        open={showAddModuleModal}
        onClose={teardownModal}
      >
        <AddModuleModal>
          <ModuleSelector
            onSelect={addBlockFromModal}
            closeModal={teardownModal}
          />
        </AddModuleModal>
      </Modal>
      <Modal
        aria-labelledby="edit-block-title"
        aria-describedby="edit-block-description"
        open={showEditModuleModal}
        onClose={teardownModal}
      >
        <EditModuleModal>
          <EditModule
            {...currentModule}
            closeModal={teardownModal}
            updateBlock={updateBlock}
          />
        </EditModuleModal>
      </Modal>
      <Modal
        aria-labelledby="remove-module-title"
        aria-describedby="remove-module-description"
        open={toDelete !== null}
        onClose={teardownModal}
      >
        <RemoveModal>
          <Typography variant="h2" gutterBottom align="center">
            Are you sure you want to delete that module?
          </Typography>
          <Buttons>
            <Button color="secondary" onClick={() => removeBlock(toDelete)}>
              Yes, remove this module.
            </Button>
            <Button color="primary" onClick={teardownModal}>
              No, keep this module.
            </Button>
          </Buttons>
        </RemoveModal>
      </Modal>
    </PageEdit>
  )
}

export default TemplateForm
