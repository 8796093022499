import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import * as R from 'ramda'

import PageTheme from '~/App/PageTheme'

import * as modules from './ModuleInfo'

import { idsLens as locationLens } from '~/State/ducks/locations'
import { idsLens as inventoryLens } from '~/State/ducks/inventory'
import { idsLens as blogLens } from '~/State/ducks/blogs'

const Selector = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: flex-end;
  background: #e4e6e9;
  padding: 2rem;
`

const ModuleList = styled(List)`
  max-height: 680px;
  overflow-y: auto;
  width: 100%;
  white-space: nowrap;
`

const Info = styled.div`
  flex-grow: 2;
  display: flex;
  height: 100%;

  padding-left: 1rem;
`

const ModuleGroupInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 680px;
  flex-grow: 2;
`

const ModuleView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  margin-left: 0.5rem;
  padding-bottom: 2rem;
`

const CloseButton = styled(IconButton)``

const ListContainer = styled.div``

const componentGroups = {
  Blog: modules.blogInfo,
  'Blog (No Image)': modules.altBlogInfo,
  Contact: modules.contactInfo,
  Popup: modules.popupInfo,
  'Featured Blog': modules.featuredBlogInfo,
  Footer: modules.footerInfo,
  Header: modules.headerInfo,
  'Header Hero': modules.headerHeroInfo,
  Hero: modules.heroInfo,
  Intro: modules.introInfo,
  'Intro Photo': modules.introPhotoInfo,
  Inventory: modules.inventoryInfo,
  Locations: modules.locationInfo,
  'New Inventory': modules.newInventoryInfo,
  Reviews: modules.reviewInfo,
  'Filtered Inventory': modules.filteredInventoryInfo,
  'Promo Images': modules.promoImageInfo,
  Text: modules.textInfo,
  'File List': modules.fileListInfo,
  Affiliations: modules.affiliationsInfo,
  'Mortgage Calculator': modules.mortgageCalcInfo,
  'Video Chat Request': modules.videoRequestInfo
}

const Preview = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

const ComponentPreview = styled.div`
  display: flex;
  flex-grow: 2;
  max-width: 100%;
  padding: 1rem;
  border: 0.5px solid gray;
  border-radius: 4.5px;
`

const ComponentInfo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChooseModuleText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled(Typography)``

const HeaderTextContainer = styled.div`
  margin: auto;
`

const viewModule = onSelect => ({ displayName, Component, info }) => {
  return (
    <ModuleView key={displayName}>
      <Preview>
        <ComponentPreview>
          <PageTheme>
            <Component isPreview isAddModule={true} />
          </PageTheme>
        </ComponentPreview>
        <ComponentInfo>{info}</ComponentInfo>
      </Preview>
      <Button
        onClick={() => onSelect(Component.moduleName)}
        color="primary"
        variant="contained"
        data-key-value={displayName}
        aria-label="Select Module"
      >
        Select Module
      </Button>
    </ModuleView>
  )
}

const ModuleSelector = ({ onSelect, closeModal }) => {
  const [selectedModule, setSelectedModule] = useState()
  const selectedModuleGroup = componentGroups[selectedModule]
  const inventory = useSelector(R.view(inventoryLens))
  const blogs = useSelector(R.view(blogLens))
  const locations = useSelector(R.view(locationLens))

  return (
    <Selector>
      <HeaderContainer>
        <HeaderTextContainer>
          <HeaderText align="center" variant="h5">
            Add a Module
          </HeaderText>
        </HeaderTextContainer>

        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      </HeaderContainer>

      <Info>
        <ListContainer>
          <Typography variant="caption" gutterBottom>
            Select Type:
          </Typography>
          <ModuleList>
            {Object.keys(componentGroups).map((componentName, i) => {
              if (componentName.indexOf('Blog') > -1 && R.isEmpty(blogs)) {
                return (
                  <Tooltip key={i} title="You have no blog posts">
                    <div>
                      <ListItem button disabled key={componentName}>
                        <ListItemText primary={componentName} />
                      </ListItem>
                    </div>
                  </Tooltip>
                )
              } else if (
                componentName.indexOf('Inventory') > -1 &&
                R.isEmpty(inventory)
              ) {
                return (
                  <Tooltip title="You have no inventory items">
                    <div>
                      <ListItem button disabled key={componentName}>
                        <ListItemText primary={componentName} />
                      </ListItem>
                    </div>
                  </Tooltip>
                )
              } else if (
                componentName === 'Locations' &&
                R.isEmpty(locations)
              ) {
                return (
                  <Tooltip title="You have no saved locations">
                    <div>
                      <ListItem button disabled key={componentName}>
                        <ListItemText primary={componentName} />
                      </ListItem>
                    </div>
                  </Tooltip>
                )
              }

              return (
                <ListItem
                  button
                  key={componentName}
                  onClick={() => setSelectedModule(componentName)}
                >
                  <ListItemText primary={componentName} />
                </ListItem>
              )
            })}
          </ModuleList>
        </ListContainer>
        <ModuleGroupInfo>
          {selectedModule ? (
            selectedModuleGroup.map(viewModule(onSelect))
          ) : (
            <ChooseModuleText>
              <Typography variant="h2">Select a module to the left</Typography>
            </ChooseModuleText>
          )}
        </ModuleGroupInfo>
      </Info>
    </Selector>
  )
}

export default ModuleSelector
