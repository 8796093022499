import URL from '~/Utils/api_base'

const prod = (template, token) =>
  fetch(`${URL}/templates/${template.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(template)
  }).then(x => x.json())

export default prod
