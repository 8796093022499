import React from 'react'
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom'

import styled from '@emotion/styled'

import Typography from '@material-ui/core/Typography'
import AccountCircle from '@material-ui/icons/AccountCircle'

import Profile from './Profile'
import Builder from './Builder'
import Inventory from './Inventory'
import Blog from './Blog'
import DefaultPage from './Default'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TopHeader = styled.div`
  width: 100%;
  background: #e4e6e9;
  color: #78838f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const CompanyNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const InventoryText = styled(Typography)`
  padding: 0 1rem;
  border-right: 1px solid #78838f;
`

const AccountIconContainer = styled.div`
  padding-left: 1rem;
`

const PowerSlideLogo = styled(Link)``

const PowerSlideLogoContainer = styled.div`
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`

const Admin = () => {
  const match = useRouteMatch()

  return (
    <Container>
      <TopHeader>
        <PowerSlideLogo to="/admin" aria-label="Powerslide Logo">
          <PowerSlideLogoContainer>
            <img
              src="https://powerslide-assets.s3.us-east-2.amazonaws.com/powerslide-logo.png"
              alt="PowerSlide"
              title="PowerSlide"
            />
          </PowerSlideLogoContainer>
        </PowerSlideLogo>
        <CompanyNameContainer>
          <Link to="/admin/inventory">
            <InventoryText>Inventory Manager</InventoryText>
          </Link>
          <Link to="/">
            <InventoryText>Website</InventoryText>
          </Link>
          <Link to="/admin/profile">
            <AccountIconContainer>
              <AccountCircle fontSize="large" />
            </AccountIconContainer>
          </Link>
        </CompanyNameContainer>
      </TopHeader>
      <Switch>
        <Route exact path={`${match.path}/profile`} component={Profile} />
        <Route exact path={`${match.path}/builder`} component={Builder} />
        <Route exact path={`${match.path}/blog`} component={Blog} />
        <Route path={`${match.path}/inventory`} component={Inventory} />
        <Route exact path={match.path} component={DefaultPage} />
      </Switch>
    </Container>
  )
}
export default Admin
