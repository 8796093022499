import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, tap, withLatestFrom } from 'rxjs/operators'
import Alert from 'react-s-alert'

import * as R from 'ramda'

import createNewFeature from '~/Requests/features/createNew'
import updateFeature from '~/Requests/features/updateFeature'
import deleteFeature from '~/Requests/features/deleteFeature'

import { createActions, createHandler } from '~/State/utils'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_FEATURE_REQUEST
 * @prop {string} CREATE_FEATURE_SUCCESS
 * @prop {string} CREATE_FEATURE_FAILURE
 * @prop {string} UPDATE_FEATURE_REQUEST
 * @prop {string} UPDATE_FEATURE_SUCCESS
 * @prop {string} UPDATE_FEATURE_FAILURE
 * @prop {string} DELETE_FEATURE_REQUEST
 * @prop {string} DELETE_FEATURE_SUCCESS
 * @prop {string} DELETE_FEATURE_FAILURE
 * @prop {string} UPDATE_FEATURE_LIST
 *
 *
 *
 */

/**
 * @type {Actions}
 */
export const actions = createActions('FEATURES', [
  'CREATE_FEATURE_REQUEST',
  'CREATE_FEATURE_SUCCESS',
  'CREATE_FEATURE_FAILURE',
  'UPDATE_FEATURE_REQUEST',
  'UPDATE_FEATURE_SUCCESS',
  'UPDATE_FEATURE_FAILURE',
  'DELETE_FEATURE_REQUEST',
  'DELETE_FEATURE_SUCCESS',
  'DELETE_FEATURE_FAILURE',
  'UPDATE_FEATURE_LIST'
])

export const rootLens = R.lensProp('features')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)
export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  features: {
    ids: {
      'acb17c51-6cc7-47ea-b11b-6c2db147321d': {
        id: 'acb17c51-6cc7-47ea-b11b-6c2db147321d',
        name: 'Feature 1',
        description:
          'I am the description of this feature. I might be two or three sentences'
      },
      '28296ca4-87f1-4c30-855f-9729013a5144': {
        id: '28296ca4-87f1-4c30-855f-9729013a5144',
        name: 'Feature 2',
        description: 'Some description that needs to be added here'
      },
      '9eec3490-9345-41f0-af73-97c1ef2343ea': {
        id: '9eec3490-9345-41f0-af73-97c1ef2343ea',
        name: 'Feature 3',
        description:
          'Look at me, maw! I am a description and I was typed by hand!'
      },
      'd2f8010f-6818-4918-a065-53cc1b55950f': {
        id: 'd2f8010f-6818-4918-a065-53cc1b55950f',
        name: 'Feature 4',
        description:
          'Oh my goodness, Becky. Look at these features. They are so big'
      }
    }
  }
}

export const reducer = createHandler({
  [actions.CREATE_FEATURE_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.UPDATE_FEATURE_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_FEATURE_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state),
  [actions.UPDATE_FEATURE_LIST]: (state, action) => {
    const updateIds = R.compose(
      ...action.payload.map(({ id, ...rest }) =>
        R.over(
          byIdLens(id),
          R.compose(
            R.mergeDeepLeft({ id, ...rest }),
            R.defaultTo({})
          )
        )
      )
    )

    return updateIds(state)
  }
})

export const createFeature$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.CREATE_FEATURE_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(createNewFeature(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.CREATE_FEATURE_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.CREATE_FEATURE_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const updateFeature$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.UPDATE_FEATURE_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(updateFeature(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_FEATURE_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.UPDATE_FEATURE_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const deleteFeature$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.DELETE_FEATURE_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(deleteFeature(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.DELETE_FEATURE_FAILURE,
          payload: error,
          meta
        }
      }

      return {
        type: actions.DELETE_FEATURE_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_FEATURE_SUCCESS, actions.UPDATE_FEATURE_SUCCESS),
    tap(() => {
      Alert.success('Feature Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_FEATURE_FAILURE, actions.UPDATE_FEATURE_FAILURE),
    tap(() => {
      Alert.error('Error Saving Feature. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  createFeature$,
  updateFeature$,
  deleteFeature$,
  showSuccessNotification$,
  showErrorNofitication$
]
