import React, { useState } from 'react'
import styled from '@emotion/styled'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import MinusIcon from '@material-ui/icons/Remove'
import { useSelector } from 'react-redux'
import uuid from 'uuid/v4'
import * as R from 'ramda'

import DaySelector from './DaySelector'
import { idLens } from '~/State/ducks/domain'
import ImageUpload from '~/SharedComponents/ImageUpload'

const CreateLocation = styled.div``

const CreateToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CreateLocationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2rem;
`

const LocationFormItem = styled.div`
  flex: 1;
  padding: 0.5rem;

  .MuiFormControl-root {
    width: 100%;
  }
`
const FormContent = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AddressContainer = styled.div``

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY']
].reduce((a, [state, initial]) => ({ ...a, [initial]: state }), {})

const StateZipContainer = styled.div`
  display: flex;
`

const StateInput = styled(FormControl)`
  flex-grow: 2;
`

const ZipInput = styled(TextField)`
  flex-grow: 1;
  margin-left: 10px;
`

const ImageUploadContainer = styled.div`
  padding-top: 2rem;
`

const CreateNew = ({ onCreate }) => {
  const domain_id = useSelector(R.view(idLens))
  const [addNew, setAddNew] = useState(false)
  const [image, setImage] = useState('')
  const [currentState, setState] = useState('')
  const [hours, setHours] = useState({
    sunday: {
      isOpened: false,
      openTime: '',
      closeTime: ''
    },
    monday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '6:00pm'
    },
    tuesday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '6:00pm'
    },
    wednesday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '6:00pm'
    },
    thursday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '6:00pm'
    },
    friday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '6:00pm'
    },
    saturday: {
      isOpened: true,
      openTime: '8:30am',
      closeTime: '5:00pm'
    }
  })

  const cancelAdd = () => {
    setAddNew(false)
    setState()
  }

  const handleLocationCreate = e => {
    e.preventDefault()
    const data = new FormData(e.target)
    const info = {
      domain_id,
      id: uuid(),
      name: data.get('name'),
      // description: data.get('description'),
      address: data.get('address'),
      address2: data.get('address-2'),
      city: data.get('city'),
      state: data.get('state'),
      zip: Number(data.get('zip')),
      phone: data.get('phone'),
      hours,
      image
    }
    onCreate(info)

    cancelAdd()
  }

  const handleChange = (name, path, value) => {
    setHours(oldHours => ({
      ...oldHours,
      ...{
        [name]: {
          ...oldHours[name],
          [path]: value
        }
      }
    }))
  }

  return (
    <CreateLocation>
      {addNew ? (
        <React.Fragment>
          <CreateToggle onClick={() => cancelAdd()}>
            <IconButton>
              <MinusIcon />
            </IconButton>
            <Typography>Cancel Create Location</Typography>
          </CreateToggle>
          <CreateLocationForm onSubmit={handleLocationCreate}>
            <FormContent>
              <LocationFormItem>
                <InnerContainer>
                  <AddressContainer>
                    <Typography>Details</Typography>
                    <TextField
                      id="name"
                      name="name"
                      label="Location Name"
                      required
                    />
                    <TextField
                      id="address"
                      name="address"
                      label="Address"
                      required
                    />
                    <TextField
                      id="address-2"
                      name="address-2"
                      label="Address 2"
                    />
                    <TextField id="city" name="city" label="City" required />
                    <StateZipContainer>
                      <StateInput margin="normal">
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Select
                          value={currentState}
                          onChange={({ target }) => setState(target.value)}
                          inputProps={{
                            name: 'state',
                            id: 'state',
                            'aria-label': 'State',
                            title: 'State'
                          }}
                          required
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {Object.keys(states).map(initial => (
                            <MenuItem key={initial} value={initial}>
                              {states[initial]}
                            </MenuItem>
                          ))}
                        </Select>
                        <input hidden value={currentState} name="state" />
                      </StateInput>
                      <ZipInput
                        type="tel"
                        name="zip"
                        id="zip"
                        aria-label="Postal Code"
                        title="Postal Code"
                        label="Postal Code"
                        margin="normal"
                        required
                      />
                    </StateZipContainer>
                    <TextField
                      name="phone"
                      aria-label="Location Phone Number"
                      title="Location Phone Number"
                      label="Location Phone Number"
                    />
                  </AddressContainer>
                  <ImageUploadContainer>
                    <Typography align="center">
                      Upload Map Image for Location
                    </Typography>
                    <ImageUpload
                      name="image"
                      onChange={setImage}
                      image={image}
                    />
                    <input hidden value={image} name="image" />
                  </ImageUploadContainer>
                </InnerContainer>
              </LocationFormItem>
              <LocationFormItem>
                <Typography>Store Hours</Typography>
                <DaySelector
                  name="sunday"
                  title="sunday"
                  day="Sunday"
                  isOpened={hours.sunday.isOpened}
                  openTime={hours.sunday.openTime}
                  closeTime={hours.sunday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="monday"
                  title="monday"
                  day="Monday"
                  isOpened={hours.monday.isOpened}
                  openTime={hours.monday.openTime}
                  closeTime={hours.monday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="tuesday"
                  title="tuesday"
                  day="Tuesday"
                  isOpened={hours.tuesday.isOpened}
                  openTime={hours.tuesday.openTime}
                  closeTime={hours.tuesday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="wednesday"
                  title="wednesday"
                  day="Wednesday"
                  isOpened={hours.wednesday.isOpened}
                  openTime={hours.wednesday.openTime}
                  closeTime={hours.wednesday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="thursday"
                  title="thursday"
                  day="Thursday"
                  isOpened={hours.thursday.isOpened}
                  openTime={hours.thursday.openTime}
                  closeTime={hours.thursday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="friday"
                  title="friday"
                  day="Friday"
                  isOpened={hours.friday.isOpened}
                  openTime={hours.friday.openTime}
                  closeTime={hours.friday.closeTime}
                  onChange={handleChange}
                />
                <DaySelector
                  name="saturday"
                  title="saturday"
                  day="Saturday"
                  isOpened={hours.saturday.isOpened}
                  openTime={hours.saturday.openTime}
                  closeTime={hours.saturday.closeTime}
                  onChange={handleChange}
                />
              </LocationFormItem>
            </FormContent>
            <Button
              type="submit"
              align="right"
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </CreateLocationForm>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CreateToggle onClick={() => setAddNew(true)}>
            <IconButton>
              <AddIcon />
            </IconButton>
            <Typography>Create Location</Typography>
          </CreateToggle>
        </React.Fragment>
      )}
    </CreateLocation>
  )
}

export default CreateNew
