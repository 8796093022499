import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import URL from '~/Utils/api_base'
import { Link } from 'react-router-dom'
import AdminMenu from '~/Admin/AdminMenu'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`
const DomainName = styled.div`
  margin-top: 5px;
  padding: 1px 0 1px 4px;
  text-decoration: none;
`
const SearchField = styled.input`
  margin: 10px 0;
  padding: 8px;
  display: block;
  border: none;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
  width: 28rem;
  font-size: 18px;
`

const domainList = [
  { key: 2, host: '5starhomes', tld: 'com' },
  { key: 3, host: 'affordablehomeschipley', tld: 'com' },
  { key: 3, host: 'affordablehomescrestview', tld: 'com' },
  { key: 4, host: 'affordablehomesdefuniak', tld: 'com' },
  { key: 5, host: 'affordablehomesfl', tld: 'com' },
  { key: 6, host: 'aikenhousingcenter', tld: 'com' },
  { key: 7, host: 'cartershomes', tld: 'com' },
  { key: 8, host: 'claytonhomesbowdonpartsandsupply', tld: 'com' },
  { key: 9, host: 'claytonhomesofhenderson', tld: 'com' },
  { key: 9, host: 'claytonhomesorchardranch', tld: 'com' },
  { key: 9, host: 'claytonhomesrockport', tld: 'com' },
  { key: 9, host: 'claytonhomesrockroseranch', tld: 'com' },
  { key: 9, host: 'claytonhomesthereserves', tld: 'com' },
  { key: 9, host: 'conversemeadowstexas', tld: 'com' },
  { key: 10, host: 'denhamhousing', tld: 'com' },
  { key: 11, host: 'edgewoodhomes', tld: 'com' },
  { key: 12, host: 'edgewoodlondon', tld: 'com' },
  { key: 13, host: 'edgewoodmiddlesboro', tld: 'com' },
  { key: 14, host: 'edgewoodwilliamsburg', tld: 'com' },
  { key: 15, host: 'fairfaxmhc', tld: 'com' },
  { key: 16, host: 'magnolia-estates', tld: 'net' },
  { key: 17, host: 'mebrookhaven', tld: 'net' },
  { key: 18, host: 'mepearl', tld: 'net' },
  { key: 19, host: 'mevicksburg', tld: 'net' },
  { key: 20, host: 'moody-properties', tld: 'com' },
  { key: 21, host: 'mountainshadowsutah', tld: 'com' },
  { key: 22, host: 'mp-centreville', tld: 'com' },
  { key: 23, host: 'mp-demopolis', tld: 'com' },
  { key: 24, host: 'pleasantvalleyiowa', tld: 'com' },
  { key: 25, host: 'powerslidecms', tld: 'com' },
  { key: 26, host: 'pricemobilehomesinc', tld: 'com' },
  { key: 27, host: 'qualityhomesbrookhaven', tld: 'com' },
  { key: 28, host: 'qualityhomesmc', tld: 'com' },
  { key: 29, host: 'regionalhomesales', tld: 'us' },
  { key: 30, host: 'summithomesbytimberline', tld: 'com' },
  { key: 31, host: 'sunsetvillageaztec', tld: 'com' },
  { key: 32, host: 'thepreserveatwhitesidecliffs', tld: 'com' },
  { key: 33, host: 'timberlineclanton', tld: 'com' },
  { key: 34, host: 'timberlinehattiesburg', tld: 'com' },
  { key: 35, host: 'timberlinehomes', tld: 'com' },
  { key: 36, host: 'timberlinehomescullman', tld: 'com' },
  { key: 37, host: 'timberlinehomesdothan', tld: 'com' },
  { key: 38, host: 'timberlinehomesmarianna', tld: 'com' },
  { key: 39, host: 'timberlinehomesofanniston', tld: 'com' },
  { key: 40, host: 'timberlinejasperal', tld: 'com' },
  { key: 41, host: 'timberlinelafayette', tld: 'com' },
  { key: 42, host: 'timberlinelakecharles', tld: 'com' },
  { key: 43, host: 'timberlinetuscaloosa', tld: 'com' },
  { key: 44, host: 'tlrhomes', tld: 'com' },
  { key: 45, host: 'turtlecreekcrossing', tld: 'com' },
  { key: 46, host: 'worldwidemobilehomes', tld: 'net' }
]

const AllDomains = () => {
  const [domains, setDomains] = useState('')

  async function getDomains() {
    const domainData = await domainList

    setDomains(domainData.sort((a, b) => a.host.localeCompare(b.host)))
    //console.log('domainData', domainData)
  }

  useEffect(() => {
    getDomains()
  }, [])

  return (
    <>
      {domains ? (
        <div>
          <div>
            {domains.map((m, idx) => {
              return (
                <DomainName key={idx.key}>
                  <Button
                    component={Link}
                    style={{
                      border: '1px solid #cfd8dc'
                    }}
                    to={{ pathname: 'http://' + m.host + '.' + m.tld }}
                    target="_blank"
                  >
                    {m.host}.{m.tld}
                  </Button>
                </DomainName>
              )
            })}
          </div>
        </div>
      ) : (
        'loading...'
      )}
    </>
  )
}

function DomainLinks() {
  const [searchTerm, setSearchTerm] = useState('')

  const [showSearchDomains, setShowSearchDomains] = useState(false)
  const onClick = () => setShowSearchDomains(true)

  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Links to Sites that are live
        </Typography>
        <div className="main-content">
          <div onClick={onClick}>
            <SearchField
              className="inputField"
              type="text"
              placeholder="Search..."
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          {showSearchDomains ? (
            domainList
              .filter(val => {
                if (searchTerm === '') {
                  return val
                } else if (
                  val.host.toLowerCase().startsWith(searchTerm.toLowerCase())
                ) {
                  return val
                }
              })
              .map(val => {
                return (
                  <DomainName key={val.key}>
                    <Button
                      component={Link}
                      style={{
                        border: '1px solid #cfd8dc'
                      }}
                      to={{ pathname: 'http://' + val.host + '.' + val.tld }}
                      target="_blank"
                    >
                      {val.host}.{val.tld}
                    </Button>
                  </DomainName>
                )
              })
          ) : (
            <AllDomains />
          )}
        </div>
      </Content>
    </Page>
  )
}

export default DomainLinks
