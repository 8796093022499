import { createActions, createHandler } from '~/State/utils'
import { map, flatMap, delay, withLatestFrom, tap } from 'rxjs/operators'
import { from as of$ } from 'rxjs'
import { ofType } from 'redux-observable'
import * as R from 'ramda'
import createUser from '~/Requests/users/createUser'
import loginUser from '~/Requests/users/loginUser'
import updateUser from '~/Requests/users/updateUser'
import deleteUser from '~/Requests/users/deleteUser'
import resetPasswordRequest from '~/Requests/users/resetPasswordRequest'
import resetPassword from '~/Requests/users/resetPassword'

/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} LOGIN_USER_REQUESTED
 * @prop {string} LOGIN_USER_SUCCESS
 * @prop {string} LOGIN_USER_FAILURE
 * @prop {string} RESET_PASSWORD_REQUEST
 * @prop {string} RESET_PASSWORD_SUCCESS
 * @prop {string} RESET_PASSWORD_FAILURE
 * @prop {string} CONFIRM_PASSWORD_REQUEST
 * @prop {string} CONFIRM_PASSWORD_SUCCESS
 * @prop {string} CONFIRM_PASSWORD_FAILURE
 * @prop {string} REGISTER_USER_NO_LOGIN
 * @prop {string} REGISTER_USER_NO_LOGIN_SUCCESS
 * @prop {string} REGISTER_USER_NO_LOGIN_FAILURE
 * @prop {string} REGISTER_USER
 * @prop {string} REMOVE_USER
 * @prop {string} UPDATE_USER
 * @prop {string} CLEAR_ERRORS
 * @prop {string} LOGOUT
 *
 */

/**
 * @type {Actions}
 */

export const actions = createActions('USERS', [
  'LOGIN_USER_REQUESTED',
  'LOGIN_USER_SUCCESS',
  'LOGIN_USER_FAILURE',
  'LOGOUT',
  'REGISTER_USER',
  'REGISTER_USER_NO_LOGIN',
  'REGISTER_USER_NO_LOGIN_SUCCESS',
  'REGISTER_USER_NO_LOGIN_FAILURE',
  'REMOVE_USER',
  'UPDATE_USER_REQUEST',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE',
  'DELETE_USER_REQUEST',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_FAILURE',
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_FAILURE',
  'CONFIRM_PASSWORD_REQUEST',
  'CONFIRM_PASSWORD_SUCCESS',
  'CONFIRM_PASSWORD_FAILURE',
  'CLEAR_ERRORS'
])

export const rootLens = R.lensProp('users')

export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)

export const currentUserIdLens = R.compose(
  rootLens,
  R.lensProp('currentUser')
)

export const userError = R.compose(
  rootLens,
  R.lensProp('error')
)

export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  users: {
    ids: {
      1: {
        userName: 'kylelee33',
        id: 1
      },
      2: {
        userName: 'otherUser',
        id: 2
      }
    },
    currentUser: null,
    error: null
  }
}

export const get = {
  users: R.view(rootLens),
  ids: R.view(idsLens),
  currentUser: state => {
    const id = R.view(currentUserIdLens, state)
    if (id) {
      return R.view(byIdLens(id), state)
    }

    return null
  },
  error: R.view(userError)
}

export const setLoggedInUser = (state, action) =>
  R.compose(
    R.set(currentUserIdLens, action.payload.id),
    R.set(byIdLens(action.payload.id), action.payload)
  )(state)

export const setError = (state, action) =>
  R.set(userError, action.payload, state)

export const reducers = {
  setLoggedInUser,
  setError
}

export const reducer = createHandler({
  [actions.LOGIN_USER_SUCCESS]: setLoggedInUser,
  [actions.LOGIN_USER_FAILURE]: setError,
  [actions.LOGOUT]: state => setLoggedInUser(state, { payload: { id: null } }),
  [actions.CLEAR_ERRORS]: state => setError(state, { payload: null }),
  [actions.REGISTER_USER_NO_LOGIN_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.REGISTER_USER_NO_LOGIN_FAILURE]: setError,
  [actions.UPDATE_USER_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),
  [actions.DELETE_USER_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const setUser$ = actions$ =>
  actions$.pipe(
    ofType(actions.LOGIN_USER_REQUESTED),
    flatMap(action => of$(loginUser(action))),
    map(({ data, error, meta }) => {
      if (data) {
        return { type: actions.LOGIN_USER_SUCCESS, payload: data, meta }
      }

      return { type: actions.LOGIN_USER_FAILURE, payload: error, meta }
    })
  )

export const registerUser$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.REGISTER_USER),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(createUser(action, state.token))),
    map(({ data, error, meta }) => {
      if (data) {
        return { type: actions.LOGIN_USER_SUCCESS, payload: data, meta }
      }

      return { type: actions.LOGIN_USER_FAILURE, payload: error, meta }
    })
  )

export const registerUserNoLogin$ = (actions$, state$) =>
  actions$.pipe(
    ofType(actions.REGISTER_USER_NO_LOGIN),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(createUser(action, state.token))),
    map(({ data, error }) => {
      if (data) {
        return { type: actions.REGISTER_USER_NO_LOGIN_SUCCESS, payload: data }
      }
      if (error) {
        return { type: actions.LOGIN_USER_NO_LOGIN_FAILURE, payload: error }
      }
    })
  )

export const updateUser$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_USER_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(updateUser(action.payload, state.token))),
    map(({ data, error }) => {
      if (data) {
        return { type: actions.UPDATE_USER_SUCCESS, payload: data }
      }
      if (error) {
      }
    })
  )

export const deleteUser$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_USER_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) => of$(deleteUser(action.payload, state.token))),
    map(({ data, error }) => {
      if (data) {
        return { type: actions.DELETE_USER_SUCCESS, payload: data }
      }
      if (error) {
      }
    })
  )

export const clearError$ = actions$ =>
  actions$.pipe(
    ofType(actions.LOGIN_USER_FAILURE),
    delay(1000),
    map(() => ({
      type: actions.CLEAR_ERRORS
    }))
  )

export const resetPasswordRequest$ = action$ =>
  action$.pipe(
    ofType(actions.RESET_PASSWORD_REQUEST),

    flatMap(action => of$(resetPasswordRequest(action.payload))),
    map(({ data, error }) => {
      if (data) {
        return { type: actions.RESET_PASSWORD_SUCCESS, payload: data }
      }
      if (error) {
      }
    })
  )

export const resetPassword$ = action$ =>
  action$.pipe(
    ofType(actions.CONFIRM_PASSWORD_REQUEST),
    flatMap(action =>
      of$(resetPassword(action.payload)).pipe(
        map(answer => ({ ...answer, history: action.meta }))
      )
    ),
    map(({ data, error, history }) => {
      if (data) {
        return {
          type: actions.CONFIRM_PASSWORD_SUCCESS,
          payload: data,
          history
        }
      }
      if (error) {
        return { type: actions.CONFIRM_PASSWORD_FAILURE, payload: error }
      }
    })
  )

export const redirectPageAfterReset$ = action$ =>
  action$.pipe(
    ofType(actions.CONFIRM_PASSWORD_SUCCESS),
    tap(action => {
      action.history.push('/login')
    }),
    map(() => ({
      type: '@@MISC/REDIRECTED_PAGE'
    }))
  )

export const epics = [
  setUser$,
  registerUser$,
  updateUser$,
  deleteUser$,
  registerUserNoLogin$,
  clearError$,
  resetPasswordRequest$,
  resetPassword$,
  redirectPageAfterReset$
]
