import React from 'react'
import styled from '@emotion/styled'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardHeader
} from '@material-ui/core/'
import Button from '@material-ui/core/Button'
import URL from '~/Utils/api_base'
import { Link } from 'react-router-dom'

import AdminMenu from '~/Admin/AdminMenu'

const Page = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`

function Dashboard() {
  return (
    <Page>
      <AdminMenu />
      <Content>
        <Typography gutterBottom variant="h4">
          Admin Dashboard
        </Typography>
        <div className="main-content">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card style={{ minHeight: '250px' }}>
                <CardHeader
                  className="card-header"
                  title="Domains"
                ></CardHeader>
                <CardContent>
                  <div>
                    <Button
                      component={Link}
                      style={{
                        border: '1px solid #cfd8dc',
                        margin: '5px 0'
                      }}
                      to="/domains"
                    >
                      Manage Domains
                    </Button>
                  </div>
                  <div>
                    <Button
                      component={Link}
                      style={{
                        border: '1px solid #cfd8dc',
                        margin: '5px 0'
                      }}
                      to="/create"
                    >
                      Add New Domain
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card style={{ minHeight: '250px' }}>
                <CardHeader
                  className="card-header"
                  title="Manufacturers"
                ></CardHeader>
                <CardContent>
                  <div>
                    <Button
                      component={Link}
                      style={{
                        border: '1px solid #cfd8dc',
                        margin: '5px 0'
                      }}
                      to="/manufacturers"
                    >
                      Manage Manufacturers
                    </Button>
                  </div>
                  <div>
                    <Button
                      component={Link}
                      style={{
                        border: '1px solid #cfd8dc',
                        margin: '5px 0'
                      }}
                      to="/create-manufacturer"
                    >
                      Add New Manufacturer
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Content>
    </Page>
  )
}

export default Dashboard
