import { createActions, createHandler } from '~/State/utils'
import * as R from 'ramda'
import { ofType } from 'redux-observable'
import { from as of$ } from 'rxjs'
import { map, flatMap, tap, withLatestFrom } from 'rxjs/operators'
import Alert from 'react-s-alert'
import createLocation from '~/Requests/locations/createLocation'
import updateLocation from '~/Requests/locations/updateLocation'
import deleteLocation from '~/Requests/locations/deleteLocation'
/**
 * The Actions available for this Domain
 *
 * @typedef {Object.<string, string>} Actions
 *
 * @prop {string} CREATE_LOCATION_REQUEST
 * @prop {string} CREATE_LOCATION_SUCCESS
 * @prop {string} CREATE_LOCATION_FAILURE
 * @prop {string} UPDATE_LOCATION_REQUEST
 * @prop {string} UPDATE_LOCATION_SUCCESS
 * @prop {string} UPDATE_LOCATION_FAILURE
 * @prop {string} DELETE_LOCATION_REQUEST
 * @prop {string} DELETE_LOCATION_SUCCESS
 * @prop {string} DELETE_LOCATION_FAILURE
 *
 */

/**
 * @type {Actions}
 */

export const actions = createActions('LOCATIONS', [
  'CREATE_LOCATION_REQUEST',
  'CREATE_LOCATION_SUCCESS',
  'CREATE_LOCATION_FAILURE',
  'UPDATE_LOCATION_REQUEST',
  'UPDATE_LOCATION_SUCCESS',
  'UPDATE_LOCATION_FAILURE',
  'DELETE_LOCATION_REQUEST',
  'DELETE_LOCATION_SUCCESS',
  'DELETE_LOCATION_FAILURE'
])

export const rootLens = R.lensProp('locations')
export const idsLens = R.compose(
  rootLens,
  R.lensProp('ids')
)
export const byIdLens = id =>
  R.compose(
    idsLens,
    R.lensProp(id)
  )

export const defaultState = {
  locations: {
    ids: {
      'fff1a681-c325-475f-8902-48d39201b4fc': {
        id: 'fff1a681-c325-475f-8902-48d39201b4fc',
        name: 'Location 1',
        address: '1234 Main St',
        city: 'Anytown',
        state: 'CA',
        zip: 90210,
        phone: '800-555-1234',
        hours: {
          sunday: {
            isOpened: false,
            openTime: '',
            closeTime: ''
          },
          monday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          tuesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          wednesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          thursday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          friday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          saturday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '5:00pm'
          }
        }
      },
      '4519d1a8-bfb7-4b8e-90d5-bf723f0d5543': {
        id: '4519d1a8-bfb7-4b8e-90d5-bf723f0d5543',
        name: 'Location 2',
        address: '1234 Main St',
        city: 'Anytown',
        state: 'CA',
        zip: 90210,
        phone: '800-555-1234',
        hours: {
          sunday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          monday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          tuesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          wednesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          thursday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          friday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          saturday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '5:00pm'
          }
        }
      },
      '6c263b86-2b7f-4079-b489-786939e43d5c': {
        id: '6c263b86-2b7f-4079-b489-786939e43d5c',
        name: 'Location 3',
        address: '1234 Main St',
        city: 'Anytown',
        state: 'CA',
        zip: 90210,
        phone: '800-555-1234',
        hours: {
          sunday: {
            isOpened: false,
            openTime: '',
            closeTime: ''
          },
          monday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          tuesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          wednesday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          thursday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          friday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '6:00pm'
          },
          saturday: {
            isOpened: true,
            openTime: '8:30am',
            closeTime: '5:00pm'
          }
        }
      },
      '8c9c25be-313a-467f-b97a-55ed2d219f9b': {
        id: '8c9c25be-313a-467f-b97a-55ed2d219f9b',
        name: 'Location 4',
        address: '1234 Main St',
        city: 'Anytown',
        state: 'CA',
        zip: 90210,
        phone: '800-555-1234',
        hours: {
          sunday: {
            isOpened: false,
            openTime: '',
            closeTime: ''
          },
          monday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '6:00pm'
          },
          tuesday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '6:00pm'
          },
          wednesday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '6:00pm'
          },
          thursday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '6:00pm'
          },
          friday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '6:00pm'
          },
          saturday: {
            isOpened: true,
            openTime: '9:30am',
            closeTime: '5:00pm'
          }
        }
      }
    }
  }
}

export const reducer = createHandler({
  [actions.CREATE_LOCATION_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),

  [actions.UPDATE_LOCATION_SUCCESS]: (state, action) =>
    R.set(byIdLens(action.payload.id), action.payload, state),

  [actions.DELETE_LOCATION_SUCCESS]: (state, action) =>
    R.over(idsLens, R.dissoc(action.payload.id), state)
})

export const createLocation$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.CREATE_LOCATION_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(createLocation(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.CREATE_LOCATION_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.CREATE_LOCATION_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const updateLocation$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.UPDATE_LOCATION_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(updateLocation(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.UPDATE_LOCATION_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.UPDATE_LOCATION_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const deleteLocation$ = (action$, state$) =>
  action$.pipe(
    ofType(actions.DELETE_LOCATION_REQUEST),
    withLatestFrom(state$),
    flatMap(([action, state]) =>
      of$(deleteLocation(action.payload, state.token))
    ),
    map(({ data, error, meta }) => {
      if (error) {
        return {
          type: actions.DELETE_LOCATION_FAILURE,
          payload: error,
          meta
        }
      }
      return {
        type: actions.DELETE_LOCATION_SUCCESS,
        payload: data,
        meta
      }
    })
  )

export const showSuccessNotification$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_LOCATION_SUCCESS, actions.UPDATE_LOCATION_SUCCESS),
    tap(() => {
      Alert.success('Location Saved Successfully', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/SUCCESS_TOAST'
    }))
  )

export const showErrorNofitication$ = actions$ =>
  actions$.pipe(
    ofType(actions.CREATE_LOCATION_FAILURE, actions.UPDATE_LOCATION_FAILURE),
    tap(() => {
      Alert.error('Error Saving Location. Please try again later.', {
        position: 'bottom-right'
      })
    }),
    map(() => ({
      type: '@@MISC/ERROR_TOAST'
    }))
  )

export const epics = [
  createLocation$,
  updateLocation$,
  deleteLocation$,
  showSuccessNotification$,
  showErrorNofitication$
]
