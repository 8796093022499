import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'

// Create a theme instance.
const defaultTheme = createTheme()

const Theme = ({ children }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

export default Theme
