import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const Container = styled.div`
  width: 80%;

  .MuiFormControl-root {
    width: 100%;
  }
`

const List = styled.ul`
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 150px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
`

const ListItem = styled.li`
  :hover {
    background: #e4e6e9;
  }
`

const Input = styled(TextField)`
  width: 100%;
`

const AutoComplete = ({ options, setClaytonModel }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState(options)
  const [showSuggestions, setShowSuggestions] = useState()
  const [input, setInput] = useState()

  useEffect(() => {
    setFilteredSuggestions(options)
  }, [options])

  useEffect(() => {
    setClaytonModel(input)
  }, [input])

  const onChange = e => {
    setInput(e.target.value)
    setFilteredSuggestions(
      options.filter(
        option =>
          option.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      )
    )
    setShowSuggestions(true)
  }

  const onClick = e => {
    setInput(e.currentTarget.innerText)
    setFilteredSuggestions([])
    setShowSuggestions(false)
  }

  return (
    <Container
      tabIndex="0"
      onFocus={() => setShowSuggestions(true)}
      onBlur={() => setShowSuggestions(false)}
      onMouseDown={e => {
        if (showSuggestions) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <Input
        fullWidth
        type="text"
        onChange={onChange}
        // onKeyDown={onKeyDown}
        value={input}
      />
      {showSuggestions ? (
        <React.Fragment>
          {filteredSuggestions.length ? (
            <List tabindex="2">
              {filteredSuggestions.map(suggestion => (
                <ListItem
                  key={suggestion}
                  onClick={e => {
                    onClick(e)
                  }}
                >
                  <Typography>{suggestion}</Typography>
                </ListItem>
              ))}
            </List>
          ) : null}
        </React.Fragment>
      ) : null}
    </Container>
  )
}

export default AutoComplete
