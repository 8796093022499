import React from 'react'
import styled from '@emotion/styled'
import { useSelector, useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import { actions } from '~/State/ducks/domain'
const Tab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RedirectList = styled(List)`
  width: 95%;
  max-width: 800px;
`

const Redirects = () => {
  const dispatch = useDispatch()
  const state_redirects = useSelector(({ redirects }) => redirects)
  const handleDeleteRedirect = redirect => {
    dispatch({
      type: actions.REMOVE_REDIRECT_REQUESTED,
      payload: redirect
    })
  }

  const handleAddRedirect = e => {
    e.preventDefault()
    e.stopPropagation()

    const data = new FormData(e.target)

    dispatch({
      type: actions.ADD_REDIRECT_REQUESTED,
      payload: {
        original_path: data.get('original_path'),
        redirect_path: data.get('redirect_path')
      }
    })
  }
  return (
    <Tab>
      <form onSubmit={handleAddRedirect}>
        <Typography>Add New Redirect</Typography>
        <TextField
          name="original_path"
          id="original_path"
          type="text"
          label="Original Path"
          helperText="Please include the /"
          required
          fullWidth
        />
        <TextField
          name="redirect_path"
          id="redirect_path"
          type="text"
          label="Redirect Path"
          helperText="Please include the /"
          required
          fullWidth
        />
        <ButtonGroup>
          <Button color="primary" type="submit">
            Create
          </Button>
        </ButtonGroup>
      </form>
      <RedirectList>
        {state_redirects.map(({ original_path, redirect_path }) => (
          <ListItem key={original_path}>
            <ListItemText
              primary={`From: ${original_path}`}
              secondary={`To: ${redirect_path}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteRedirect({ original_path })}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </RedirectList>
    </Tab>
  )
}

export default Redirects
