import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { parseUrl } from 'query-string'
// import Carousel, { Dots, arrowsPlugin } from '@brainhubeu/react-carousel'
// import '@brainhubeu/react-carousel/lib/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import BathIcon from '~/Assets/Icons/bath.png'
import BedIcon from '~/Assets/Icons/bed.png'
import RulerIcon from '~/Assets/Icons/square.png'
import Arrow from '~/Assets/Icons/slide-arrow.png'
import * as R from 'ramda'

import { idsLens as featureLens } from '~/State/ducks/features'

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 5rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 1rem;
    flex-direction: column;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 45px;
  justify-content: space-between;
  border-bottom: 1px solid #b2b1b1;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  width: 100%;

  img {
    height: auto;
    max-width: 100%;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  padding-top: 2rem;
  border-bottom: 1px solid #b2b1b1;
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: ${({ border }) => (border ? '1px solid #B2B1B1' : 'none')};
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const FeatureList = styled.ul`
  padding-top: 1rem;
  list-style: none;

  li:before {
    content: '•';
    font-size: 10pt;
    padding-right: 5px;
  }
`

const FloorPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`

const IframeContainer = styled.div`
  margin-bottom: 1rem;
`

const TourIframe = styled.iframe`
  width: 100%;
  min-height: 529px;
  border: none;
`

const LeftContainer = styled.div`
  width: 60%;
  padding-top: 2rem;
  padding-right: 1.5rem;
`

const RightContainer = styled.div`
  width: 40%;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  flex-direction: column;
  background-color: #fafafa;
`

const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
`

const TableRow = styled.div`
  display: flex;
`

const TableCell = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  border: 1px solid #b2b1b1;
  padding-left: 2rem;
  background-color: #f7f7f7;
`

const Icon = styled.img`
  height: 25px;
  width: 25px;
  position: relative;
  top: 5px;
  right: 5px;

  @media only screen and (max-width: 768px) {
    height: 20px;
    width: 20px;
    top: 0px;
  }
`

const Slider = styled(Carousel)`
  .slide {
    background: #ffffff !important;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel .control-next.control-arrow::before {
    border-left: none !important;
  }

  .carousel-slider .control-prev.control-arrow::before {
    border-right: none !important;
  }

  .thumb img {
    height: 50px;
  }

  .thumbs-wrapper .control-prev::before {
    border: solid black;
    border-right: 3px solid black !important;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .thumbs-wrapper .control-next::before {
    border: solid black;
    border-left: none !important;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .carousel img {
    pointer-events: auto;
  }
`

const InventoryItem = ({
  title,
  price,
  footage,
  bedrooms,
  bathrooms,
  description,
  features,
  floorPlan,
  mainImage,
  images,
  tourLink,
  videoLink,
  meta
}) => {
  const inventoryFeatures = useSelector(state => {
    return R.view(featureLens, state)
  })

  const currentFeatures = features.map(id => inventoryFeatures[id])
  const [width, setWidth] = useState()

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth)
      window.scrollTo(0, 0)
      window.addEventListener('resize', () => {
        setWidth(window.innerWidth)
      })
    }
  }, [])

  return (
    <MainContainer>
      {width > 768 ? (
        <>
          <HeaderContainer>
            <Typography component="h1" variant="h2">
              {title}
            </Typography>
            <Typography component="p" variant="h2">
              {price == 0
                ? 'Contact Us for Pricing'
                : `$${price.toLocaleString()}`}
            </Typography>
          </HeaderContainer>
          <LeftContainer>
            <Slider
              showStatus={false}
              arrow={Arrow}
              showIndicators={false}
              dynamicHeight={true}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button
                    type="button"
                    aria-label="previous slide / item"
                    class="control-arrow control-prev"
                    onClick={onClickHandler}
                    title={label}
                  >
                    <img
                      style={{
                        width: '30px',
                        height: '30px',
                        transform: 'rotate(180deg)'
                      }}
                      src={Arrow}
                    />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button
                    type="button"
                    aria-label="next slide / item"
                    class="control-arrow control-next"
                    onClick={onClickHandler}
                    title={label}
                  >
                    <img
                      style={{
                        width: '30px',
                        height: '30px'
                      }}
                      src={Arrow}
                    />
                  </button>
                )
              }
            >
              {images &&
                images.map(url => (
                  <div>
                    <img src={url} />
                  </div>
                ))}
            </Slider>
            <FloorPlanContainer>
              <ImageContainer>
                <img alt={`${title} Floorplan`} src={floorPlan}></img>
              </ImageContainer>
            </FloorPlanContainer>
            {tourLink || videoLink ? (
              <OptionsContainer>
                {tourLink && (
                  <IframeContainer>
                    <TourIframe frameborder="0" src={tourLink} />
                  </IframeContainer>
                )}
                {videoLink && (
                  <a href={videoLink} target="_blank" rel="noopener noreferrer">
                    <Typography>Watch a Video</Typography>
                  </a>
                )}
              </OptionsContainer>
            ) : null}
          </LeftContainer>
          <RightContainer>
            <InfoTable>
              <TableCell style={{ borderBottom: 'none' }}>
                <Icon src={RulerIcon} />
                <Typography component="p" variant="h2">
                  Square Feet
                  <br />
                  <span
                    style={{
                      color: '#9A9999',
                      position: 'relative',
                      top: '5px'
                    }}
                  >
                    {footage.toLocaleString()}
                  </span>
                </Typography>
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }}>
                <Icon src={BedIcon} />
                <Typography component="p" variant="h2">
                  Bedrooms
                  <br />
                  <span
                    style={{
                      color: '#9A9999',
                      position: 'relative',
                      top: '5px'
                    }}
                  >
                    {bedrooms}
                  </span>
                </Typography>
              </TableCell>
              <TableCell style={{ borerBottom: 'top' }}>
                <Icon src={BathIcon} />
                <Typography component="p" variant="h2">
                  Bathrooms
                  <br />
                  <span
                    style={{
                      color: '#9A9999',
                      position: 'relative',
                      top: '5px'
                    }}
                  >
                    {bathrooms}
                  </span>
                </Typography>
              </TableCell>
            </InfoTable>
            {description && (
              <DescriptionContainer
                border={
                  currentFeatures.length > 0 ||
                  (meta.files && meta.files.length > 0)
                }
              >
                <Typography
                  component="p"
                  variant="h2"
                  style={{ textTransform: 'uppercase' }}
                >
                  Description
                </Typography>
                <Typography variant="body1" style={{ paddingTop: '1rem' }}>
                  {description}
                </Typography>
              </DescriptionContainer>
            )}
            {currentFeatures.length > 0 && (
              <FeaturesContainer border={meta.files && meta.files.length > 0}>
                <Typography
                  component="p"
                  variant="h2"
                  style={{ textTransform: 'uppercase' }}
                >
                  Features
                </Typography>
                <FeatureList>
                  {currentFeatures.map(feature => (
                    <Typography
                      style={{ paddingBottom: '2px' }}
                      key={feature.name}
                    >
                      <Typography component="li" variant="body1">
                        {feature.name}
                      </Typography>
                    </Typography>
                  ))}
                </FeatureList>
              </FeaturesContainer>
            )}
            {meta.files && meta.files.length > 0 && (
              <FeaturesContainer border={false}>
                <Typography
                  component="p"
                  variant="h2"
                  style={{ textTransform: 'uppercase' }}
                >
                  Downloads
                </Typography>
                <FeatureList>
                  {meta.files.map(file => (
                    <Typography
                      style={{ paddingBottom: '2px' }}
                      key={file.path}
                    >
                      <Typography component="li" variant="body1">
                        <a
                          style={{ textDecoration: 'none', color: '#000000' }}
                          target="_blank"
                          href={file.path}
                        >
                          {file.title}
                        </a>
                      </Typography>
                    </Typography>
                  ))}
                </FeatureList>
              </FeaturesContainer>
            )}
          </RightContainer>
        </>
      ) : (
        <>
          <Slider
            showStatus={false}
            arrow={Arrow}
            showIndicators={false}
            dynamicHeight={true}
            showThumbs={false}
            style={{ paddingBottom: '1rem' }}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  aria-label="previous slide / item"
                  class="control-arrow control-prev"
                  onClick={onClickHandler}
                  title={label}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      transform: 'rotate(180deg)'
                    }}
                    src={Arrow}
                  />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  aria-label="next slide / item"
                  class="control-arrow control-next"
                  onClick={onClickHandler}
                  title={label}
                >
                  <img
                    style={{
                      width: '30px',
                      height: '30px'
                    }}
                    src={Arrow}
                  />
                </button>
              )
            }
          >
            {images &&
              images.map(url => (
                <div>
                  <img src={url} />
                </div>
              ))}
          </Slider>
          {images && images.length > 0 && (
            <Typography
              style={{ paddingTop: '8px', paddingBottom: '8px' }}
              component="p"
              variant="body2"
            >
              {images.length} {images.length > 1 ? 'Photos' : 'Photo'}
            </Typography>
          )}
          <HeaderContainer style={{ borderBottom: 'none' }}>
            <Typography
              component="h1"
              variant="h2"
              style={{
                borderBottom: '1px solid #b2b1b1',
                paddingBottom: '1rem',
                marginBottom: '1rem'
              }}
            >
              {title}
            </Typography>
            <Typography component="p" variant="h3">
              {price == 0
                ? 'Contact Us for Pricing'
                : `$${price.toLocaleString()}`}
            </Typography>
          </HeaderContainer>
          <InfoTable>
            <TableCell style={{ borderBottom: 'none' }}>
              <Icon src={RulerIcon} />
              <Typography
                style={{ fontSize: '18px' }}
                component="p"
                variant="h2"
              >
                Square Feet
                <br />
                <span
                  style={{
                    color: '#9A9999',
                    position: 'relative',
                    top: '5px'
                  }}
                >
                  {footage.toLocaleString()}
                </span>
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: 'none' }}>
              <Icon src={BedIcon} />
              <Typography
                style={{ fontSize: '18px' }}
                component="p"
                variant="h2"
              >
                Bedrooms
                <br />
                <span
                  style={{
                    color: '#9A9999',
                    position: 'relative',
                    top: '5px'
                  }}
                >
                  {bedrooms}
                </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Icon src={BathIcon} />
              <Typography
                style={{ fontSize: '18px' }}
                component="p"
                variant="h2"
              >
                Bathrooms
                <br />
                <span
                  style={{
                    color: '#9A9999',
                    position: 'relative',
                    top: '5px'
                  }}
                >
                  {bathrooms}
                </span>
              </Typography>
            </TableCell>
          </InfoTable>
          {currentFeatures.length > 0 && (
            <FeaturesContainer border={meta.files && meta.files.length > 0}>
              <Typography
                component="p"
                variant="h2"
                style={{ textTransform: 'uppercase' }}
              >
                Features
              </Typography>
              <FeatureList>
                {currentFeatures.map(feature => (
                  <Typography
                    style={{ paddingBottom: '2px' }}
                    key={feature.name}
                  >
                    <Typography component="li" variant="body1">
                      {feature.name}
                    </Typography>
                  </Typography>
                ))}
              </FeatureList>
            </FeaturesContainer>
          )}
          {meta.files && meta.files.length > 0 && (
            <FeaturesContainer border={floorPlan}>
              <Typography
                component="p"
                variant="h2"
                style={{ textTransform: 'uppercase' }}
              >
                Downloads
              </Typography>
              <FeatureList>
                {meta.files &&
                  meta.files.map(file => (
                    <Typography key={file.path}>
                      <Typography component="li" variant="body1">
                        <a
                          style={{ textDecoration: 'none', color: '#000000' }}
                          target="_blank"
                          href={file.path}
                        >
                          {file.title}
                        </a>
                      </Typography>
                    </Typography>
                  ))}
              </FeatureList>
            </FeaturesContainer>
          )}
          {floorPlan && (
            <FloorPlanContainer>
              <Typography
                component="p"
                variant="h2"
                style={{ textTransform: 'uppercase' }}
              >
                Floorplans
              </Typography>
              <ImageContainer>
                <img alt={`${title} Floorplan`} src={floorPlan}></img>
              </ImageContainer>
            </FloorPlanContainer>
          )}
          {tourLink || videoLink ? (
            <OptionsContainer>
              {tourLink && (
                <IframeContainer>
                  <TourIframe frameborder="0" src={tourLink} />
                </IframeContainer>
              )}
              {videoLink && (
                <a href={videoLink} target="_blank" rel="noopener noreferrer">
                  <Typography>Watch a Video</Typography>
                </a>
              )}
            </OptionsContainer>
          ) : null}
        </>
      )}
    </MainContainer>
  )
}

export default InventoryItem
