import React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import * as R from 'ramda'

import { idsLens } from '~/State/ducks/locations'
// import groupHours from '~/Components/utils/groupedHours'
import { themeLens, brandLens } from '~/State/ducks/profile'
import { configs } from '~/App/PageTheme'
import { getBasedOnTheme } from '~/App/PageTheme'

const LocationsContainer = styled.section`
  width: 100%;
  display: flex;
  background: ${({ color }) => `${color}` || `#e5e9ea`};
  color: ${({ textColor }) => `${textColor}` || `inherit`};
  background-image: ${({ img }) => (img ? `url(${img})` : 'none')};
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  padding-bottom: 3rem;
  padding-top: 3rem;
  margin-right: 7rem;
  flex-direction: column;

  &:first-of-type {
    border-bottom: ${({ textColor }) => `2px solid ${textColor}` || `none`};
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`

const NameAndAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LowerContainer = styled.div`
  display: flex;
`

const HoursContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
  flex: 1;
`

const NameOfLocation = styled(Typography)``

const OurLocations = styled(Typography)``

const Address = styled(Typography)`
  padding-bottom: 1rem;
  padding-top: 1rem;
  flex: 1;
`

const Phone = styled(Typography)`
  padding-bottom: 1rem;
`

const Subtitle = styled(Typography)`
  padding-top: 2rem;
  width: 80%;

  padding-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5rem;
  padding-left: 2rem;
  flex: 1;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AddressAndHoursContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const LocationHours = styled(Typography)``

const WeekdayHours = styled(Typography)``

const AddressTag = styled.address`
  font-style: normal;
`

const AddressAndPhoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const LogoContainer = styled.div`
  max-width: 272px;
  max-height: 200px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const UpperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const previewData = [
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: false,
        openTime: '',
        closeTime: ''
      },
      monday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  },
  {
    name: 'My Default Location',
    address: '1234 Main St',
    city: 'Anytown',
    state: 'CA',
    zip: 90210,
    phone: '800-555-1234',
    hours: {
      sunday: {
        isOpened: false,
        openTime: '',
        closeTime: ''
      },
      monday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      tuesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      wednesday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      thursday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      friday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '6:00pm'
      },
      saturday: {
        isOpened: true,
        openTime: '8:30am',
        closeTime: '5:00pm'
      }
    }
  }
]

const Locations3 = ({
  subtitle = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ipsum ac magna molestie rutrum. Integer vel iaculis dolor.',
  header = 'Our Locations',
  ids = [],
  isPreview,
  background,
  text,
  image,
  showLogo = false
}) => {
  const locations = useSelector(state => {
    const idHash = R.view(idsLens, state)

    return ids.filter(id => idHash[id]).map(id => idHash[id])
  })
  const company = useSelector(state => state.profile.company)

  const currentThemeEnum = useSelector(R.view(themeLens))
  const brand = useSelector(state => {
    return R.view(brandLens, state) || {}
  })

  const currentTheme = configs[currentThemeEnum || 'Theme1']

  const bgColor = getBasedOnTheme(background, currentTheme, brand)

  const textColor = getBasedOnTheme(text, currentTheme, brand)

  if (isPreview) {
    return (
      <LocationsContainer>
        <LeftContainer>
          <OurLocations variant="h2" component="p">
            {header}
          </OurLocations>
          <Subtitle color="textPrimary" variant="body1" align="left">
            {subtitle}
          </Subtitle>
        </LeftContainer>

        <RightContainer>
          {previewData.map(({ name, address, phone, hours }, i) => {
            // const groupedHours = groupHours(hours)

            return (
              <CardContainer key={i}>
                <NameAndAddressContainer>
                  <NameOfLocation variant="h3">{name}</NameOfLocation>
                  <AddressTag>
                    <AddressAndHoursContainer>
                      <AddressAndPhoneContainer>
                        <Address color="textPrimary" align="left">
                          {address}
                        </Address>
                        <Phone color="textPrimary">{phone}</Phone>
                      </AddressAndPhoneContainer>

                      <HoursContainer>
                        <LocationHours color="textPrimary">
                          <strong>Location Hours:</strong>
                        </LocationHours>
                        {/* {groupedHours.grouped.map(day => (
                          <WeekdayHours color="textPrimary" key={day}>
                            {day} {groupedHours.hours[day].open} -{' '}
                            {groupedHours.hours[day].close}
                          </WeekdayHours>
                        ))} */}
                        {hours.sunday.isOpened && (
                          <WeekdayHours>
                            Sunday: {hours.sunday.openTime} -{' '}
                            {hours.sunday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.monday.isOpened && (
                          <WeekdayHours>
                            Monday: {hours.monday.openTime} -{' '}
                            {hours.monday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.tuesday.isOpened && (
                          <WeekdayHours>
                            Tuesday: {hours.tuesday.openTime} -{' '}
                            {hours.tuesday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.wednesday.isOpened && (
                          <WeekdayHours>
                            Wednesday: {hours.wednesday.openTime} -{' '}
                            {hours.wednesday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.thursday.isOpened && (
                          <WeekdayHours>
                            Thursday: {hours.thursday.openTime} -{' '}
                            {hours.thursday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.friday.isOpened && (
                          <WeekdayHours>
                            Friday: {hours.friday.openTime} -{' '}
                            {hours.friday.closeTime}
                          </WeekdayHours>
                        )}
                        {hours.saturday.isOpened && (
                          <WeekdayHours>
                            Saturday: {hours.saturday.openTime} -{' '}
                            {hours.saturday.closeTime}
                          </WeekdayHours>
                        )}
                      </HoursContainer>
                    </AddressAndHoursContainer>
                  </AddressTag>
                </NameAndAddressContainer>
              </CardContainer>
            )
          })}
        </RightContainer>
      </LocationsContainer>
    )
  }

  return (
    <LocationsContainer img={image} textColor={textColor} color={bgColor}>
      <LeftContainer>
        <OurLocations variant="h2">{header}</OurLocations>
        <Subtitle variant="body1" align="left">
          {subtitle}
        </Subtitle>
      </LeftContainer>

      <RightContainer>
        {locations.map(({ name, address, phone, hours }, i) => {
          // const groupedHours = groupHours(hours)

          return (
            <CardContainer textColor={textColor} key={i}>
              {!showLogo && (
                <NameOfLocation variant="h3">{name}</NameOfLocation>
              )}
              {showLogo && (
                <UpperContainer>
                  <LogoContainer>
                    <img src={brand.logo} alt={`${company.name} Logo`} />
                  </LogoContainer>
                </UpperContainer>
              )}
              <LowerContainer>
                <NameAndAddressContainer>
                  <AddressTag>
                    <AddressAndPhoneContainer>
                      <Address align="left">{address}</Address>
                      <Phone>{phone}</Phone>
                    </AddressAndPhoneContainer>
                  </AddressTag>
                </NameAndAddressContainer>
                <HoursContainer>
                  <LocationHours>
                    <strong>Location Hours:</strong>
                  </LocationHours>
                  {/* {groupedHours.grouped.map(day => (
                  <WeekdayHours key={day}>
                    {day} {groupedHours.hours[day].open} -{' '}
                    {groupedHours.hours[day].close}
                  </WeekdayHours>
                ))} */}
                  {hours.sunday.isOpened && (
                    <WeekdayHours>
                      Sunday: {hours.sunday.openTime} - {hours.sunday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.monday.isOpened && (
                    <WeekdayHours>
                      Monday: {hours.monday.openTime} - {hours.monday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.tuesday.isOpened && (
                    <WeekdayHours>
                      Tuesday: {hours.tuesday.openTime} -{' '}
                      {hours.tuesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.wednesday.isOpened && (
                    <WeekdayHours>
                      Wednesday: {hours.wednesday.openTime} -{' '}
                      {hours.wednesday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.thursday.isOpened && (
                    <WeekdayHours>
                      Thursday: {hours.thursday.openTime} -{' '}
                      {hours.thursday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.friday.isOpened && (
                    <WeekdayHours>
                      Friday: {hours.friday.openTime} - {hours.friday.closeTime}
                    </WeekdayHours>
                  )}
                  {hours.saturday.isOpened && (
                    <WeekdayHours>
                      Saturday: {hours.saturday.openTime} -{' '}
                      {hours.saturday.closeTime}
                    </WeekdayHours>
                  )}
                </HoursContainer>
              </LowerContainer>
            </CardContainer>
          )
        })}
      </RightContainer>
    </LocationsContainer>
  )
}

Locations3.displayName = 'Locations 3'
Locations3.moduleName = 'Locations3'

export default Locations3
